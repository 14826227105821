import { defineStyleConfig } from '@chakra-ui/react';

export const Checkbox = defineStyleConfig({
  baseStyle: {
    control: {
      background: 'white',
      _dark: {
        background: 'neutral.900',
      },
      _checked: {
        _dark: {
          background: 'brand.base',
          color: 'white',
        },
      },
    },
  },
  variants: {
    accordion: {
      container: {
        py: 3,
        pl: 4,
        w: 'full',
      },
      label: {
        fontSize: 'md',
        _checked: {},
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'lg',
    colorScheme: 'brand',
  },
});
