import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ChakraProvider } from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';

import './index.css';
import VMApp from './App';
import { Auth0ProviderWithHistory } from './auth/auth0-provider-with-history';
import { CONFIG } from './config';
import { theme } from './theme';
import { ErrorBoundary } from 'react-error-boundary';
import GenericError from './pages/GenericError';
import packageJson from '../package.json';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error: any) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403 ||
          error?.response?.status === 404
        ) {
          return false;
        }
        return failureCount < 3;
      },
    },
  },
});

const tracingInProd = CONFIG.APP_ENV === 'prod' ? true : false;

Sentry.init({
  dsn: 'https://32f5b3a8b2c74d1b8ee11e3fcc857f9d@o1241367.ingest.sentry.io/6395084',
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [
        'localhost',
        'localhost:3000',
        'api.dev.vm.validmind.ai',
        'api.staging.vm.validmind.ai',
        'api.prod.vm.validmind.ai',
        /^\//,
      ],
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring on prod.
  tracesSampleRate: tracingInProd ? 1.0 : 0.1,
  enabled: CONFIG.APP_ENV !== 'development' && CONFIG.APP_ENV !== 'dev',
  environment: CONFIG.APP_ENV,
  release: CONFIG.SENTRY_RELEASE,

  // Session Replay
  //
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: tracingInProd ? 1.0 : 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // add vm_site tag
  beforeSend(event, hint) {
    event.tags = {
      ...event.tags,
      VM_SITE: CONFIG.VM_SITE || 'undefined',
    };
    return event;
  },
});

if (CONFIG.APP_ENV !== 'development') {
  datadogRum.init({
    applicationId: '181e62f0-f630-4deb-bfa5-d1f7c51ace90',
    clientToken: 'pub1a34dcd3669da0ad9ddb622003eca5f9',
    site: 'us5.datadoghq.com',
    service:
      process.env.REACT_APP_BUILD_TARGET === 'admin' ? 'admin-app' : 'app',
    env: CONFIG.APP_ENV,
    version: packageJson.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [CONFIG.REACT_APP_API_URL],
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} /> */}
        <ChakraProvider theme={theme}>
          <ErrorBoundary
            onError={(error: Error, info: { componentStack: string }) => {
              Sentry.captureException(error);
            }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <GenericError
                error={error}
                resetErrorBoundary={resetErrorBoundary}
              />
            )}
          >
            <VMApp />
          </ErrorBoundary>
        </ChakraProvider>
      </QueryClientProvider>
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
);
