import { useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Bars3CenterLeftIcon,
  CodeBracketSquareIcon,
  PresentationChartLineIcon,
} from '@heroicons/react/24/outline';
import { v4 as uuidv4 } from 'uuid';
import { TemplateSectionContents } from '../../models/template';
import AddBlockTestDrivenModal from '../AddBlockTestDrivenModal';
import { useContext, useMemo } from 'react';
import { canUpdateMetadata } from '../../auth/utils';
import {
  getModelDocumentType,
  ModelDocumentTypeEnum,
} from '../../models/model_document';
import UsersContext from '../../contexts/UsersContext';
import { PlusIcon } from '@heroicons/react/20/solid';
import AddBlockMetricOverTimeModal from '../AddBlockMetricOverTime';
import InventoryModelContext from '../../contexts/InventoryModel';

interface BlockExplorerProps {
  contentIndex: number;
  addContentBlock: (
    index: number,
    content: TemplateSectionContents,
  ) => Promise<void>;
}

export function BlockExplorer({
  contentIndex,
  addContentBlock,
}: BlockExplorerProps) {
  const [isFocused, setIsFocused] = useState(false);
  const addTestBlockModal = useDisclosure();
  const addUnitMetricBlockModal = useDisclosure();
  const { inventoryModel, templates } = useContext(InventoryModelContext);

  const { currentUser } = useContext(UsersContext);
  const documentType = getModelDocumentType(templates.current.documentType);
  const canAddBlocks = useMemo(
    () =>
      currentUser
        ? canUpdateMetadata(currentUser, inventoryModel?.users!, documentType)
        : false,
    [currentUser, inventoryModel?.users, documentType],
  );

  const onAddMetadataTextBlock = () => {
    const block = {
      content_type: 'text',
      content_id: uuidv4(),
    };
    addContentBlock(contentIndex, block);
  };

  const onAddTestDrivenBlock = async (
    content_type: string,
    content_id: string,
  ): Promise<void> => {
    const block = {
      content_type,
      content_id,
    };
    await addContentBlock(contentIndex++, block);
  };

  const onAddMetricOverTimeBlock = async (
    content_type: string,
    content_id: string,
  ): Promise<void> => {
    const block = {
      content_type,
      content_id,
    };
    await addContentBlock(contentIndex++, block);
  };

  if (!canAddBlocks) {
    return <></>;
  }

  return (
    <Flex
      height={10}
      width={'100%'}
      position={'relative'}
      alignItems={'stretch'}
      maxWidth={'1220px'}
    >
      <Box>
        <Menu
          matchWidth
          offset={[0, -32]}
          onClose={() => {
            setIsFocused(false);
          }}
        >
          <Tooltip
            bg={useColorModeValue('neutral.100', 'neutral.700')}
            boxShadow={'none'}
            color={useColorModeValue('neutral.800', 'neutral.200')}
            placement="bottom"
            gutter={-38}
            label={<Icon as={PlusIcon} boxSize={5} mt={1} />}
            hasArrow={false}
            fontSize="md"
            isDisabled={isFocused}
            rounded={'md'}
            outline={'2px solid'}
            outlineColor={useColorModeValue('white', 'black')}
          >
            <MenuButton
              width={'full'}
              height={10}
              bg={'transparent'}
              role="group"
              flexDirection={'row'}
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
              position={'absolute'}
              onClick={() => {
                setIsFocused(true);
              }}
            >
              <HStack w={'100%'}>
                <Box
                  w={'full'}
                  h={1}
                  bg={'transparent'}
                  borderTop={isFocused ? '1px solid' : '2px dashed'}
                  borderColor={isFocused ? 'brand.base' : 'transparent'}
                  transition={'all .3s ease-in-out'}
                  _groupHover={{
                    borderColor: isFocused
                      ? 'brand.base'
                      : useColorModeValue('neutral.300', 'neutral.700'),
                    bg: 'transparent',
                  }}
                  _groupActive={{
                    borderColor: isFocused ? 'brand.base' : 'neutral.400',
                    bg: 'neutral.50',
                  }}
                ></Box>
              </HStack>
            </MenuButton>
          </Tooltip>
          <MenuList
            py={0}
            overflow={'hidden'}
            boxShadow={'md'}
            border={'1px solid'}
            borderColor={'brand.base'}
            borderRadius={4}
          >
            <MenuGroup pl={'45%'} mx={0} title="Insert New Block">
              <MenuItem
                icon={<Icon as={Bars3CenterLeftIcon} boxSize={6} />}
                onClick={onAddMetadataTextBlock}
                pl={'45%'}
              >
                Text
              </MenuItem>
              <MenuItem
                icon={<Icon as={CodeBracketSquareIcon} boxSize={6} />}
                onClick={addTestBlockModal.onOpen}
                pl={'45%'}
              >
                Test-Driven
              </MenuItem>
              {(documentType === ModelDocumentTypeEnum.model_documentation ||
                documentType === ModelDocumentTypeEnum.monitoring) && (
                <MenuItem
                  icon={<Icon as={PresentationChartLineIcon} boxSize={6} />}
                  onClick={addUnitMetricBlockModal.onOpen}
                  pl={'45%'}
                >
                  Metric Over Time
                </MenuItem>
              )}
            </MenuGroup>
          </MenuList>
        </Menu>
      </Box>
      <AddBlockTestDrivenModal
        isOpen={addTestBlockModal.isOpen}
        onClose={addTestBlockModal.onClose}
        onAdd={onAddTestDrivenBlock}
        documentType={documentType}
      />
      <AddBlockMetricOverTimeModal
        isOpen={addUnitMetricBlockModal.isOpen}
        onClose={addUnitMetricBlockModal.onClose}
        onAdd={onAddMetricOverTimeBlock}
      />
    </Flex>
  );
}
