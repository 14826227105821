import { Box, Heading } from '@chakra-ui/react';

const StyleGuideAlertDialog = () => {
  return (
    <Box>
      <Heading as={'h2'}>AlertDialog</Heading>
    </Box>
  );
};

export default StyleGuideAlertDialog;
