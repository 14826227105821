import {
  Box,
  Heading,
  HStack,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

const StyleGuideIconButton = ({ sizes }: { sizes: string[] }) => {
  sizes = sizes.filter(f => f !== 'xs');
  const buttonVariants = ['primary', 'secondary', 'tertiary'];
  return (
    <Box>
      <Heading as={'h2'}>IconButton</Heading>
      <HStack gap={4} alignItems={'flex-start'}>
        {sizes.reverse().map(size => (
          <VStack>
            {buttonVariants.map(variant => (
              <IconButton
                aria-label="Search database"
                key={`${size}-${variant}`}
                variant={variant}
                size={size}
                icon={<Icon as={MagnifyingGlassIcon} boxSize={6} />}
              />
            ))}
          </VStack>
        ))}
      </HStack>
    </Box>
  );
};

export default StyleGuideIconButton;
