import { tagAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import colors from '../colors';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

export const Tag = defineMultiStyleConfig({
  sizes: {
    sm: {
      container: {
        fontSize: 'sm',
      },
    },
  },
  variants: {
    'role-pill': definePartsStyle(props => {
      const { colorScheme, theme } = props;
      const { bg: bgBadge } = theme.components.Badge.variants.subtle(props);
      return {
        container: {
          bg: mode(`${colorScheme}.200`, bgBadge)(props),
          borderRadius: 'full',
          minHeight: 8,
          py: 1.5,
          px: 3,
        },
      };
    }),
    solid: (props: { colorScheme: string }) => {
      return {
        container: {
          borderRadius: 'full',
          bg: `${props.colorScheme}.100`,
          color: `${props.colorScheme}.800`,

          _dark: {
            bg: `${props.colorScheme}.900`,
            color: `${props.colorScheme}.100`,
          },
        },
      };
    },
    outline: (props: { colorScheme: string }) => ({
      closeButton: {
        color: `${props.colorScheme}.700`,
        _dark: {
          color: `${props.colorScheme}.200`,
        },
      },
      container: {
        borderRadius: 'full',
        bg: `${props.colorScheme}.100`,
        color: `${props.colorScheme}.800`,
        boxShadow: `inset 0 0 0px 1px  ${
          colors[props.colorScheme as keyof typeof colors][300]
        }`,

        _hover: {
          bg: `${props.colorScheme}.50`,
          _dark: {
            bg: `${props.colorScheme}.900`,
            color: `${props.colorScheme}.50`,
          },
        },

        _dark: {
          bg: `${props.colorScheme}.950`,
          color: `${props.colorScheme}.100`,
          boxShadow: `inset 0 0 0px 1px  ${
            colors[props.colorScheme as keyof typeof colors][800]
          }`,
        },
      },
    }),
    get subtle() {
      return this.solid;
    },
  },
  defaultProps: {
    variant: 'solid',
    colorScheme: 'neutral',
  },
});
