import {
  Button,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spacer,
  Stack,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { Label } from '../Layout';
import { FindingFilters, TFindingFiltersOptions } from '../../models/finding';
import {
  MultiValue,
  Select,
  chakraComponents,
  ChakraStylesConfig,
} from 'chakra-react-select';
import './styles.css';
import { Icon } from '@chakra-ui/icons';

export interface SelectOptions {
  label: string;
  value: string;
}

interface ModelFindingsFiltersProps {
  selectedFilters?: FindingFilters;
  allFilterOptions: TFindingFiltersOptions;
  setFilters: (filters: FindingFilters) => void;
}

const selectCustomComponents = {
  ValueContainer: ({ children, ...props }: any) => {
    let [values, input] = children as any[];

    if (Array.isArray(values)) {
      const plural = values.length === 1 ? '' : 's';
      values = `${values.length} item${plural} selected`;
    }

    return (
      <chakraComponents.ValueContainer {...props}>
        {values}
        {input}
      </chakraComponents.ValueContainer>
    );
  },
};

const chakraStyles: ChakraStylesConfig = {
  container: (provided: any) => ({
    ...provided,
    minW: '250px',
  }),
};

const ArrayToSelectOptions = (arr?: any[]): SelectOptions[] => {
  return (
    arr?.map(element => ({
      label: element.name || element,
      value: element.cuid || element.id || element,
    })) || []
  );
};

export default function ModelFindingsFilters({
  selectedFilters,
  allFilterOptions,
  setFilters,
}: ModelFindingsFiltersProps) {
  const [businessUnits, setBusinessUnits] = useState<SelectOptions[]>([]);
  const [status, setStatus] = useState<SelectOptions[]>([]);
  const [assignees, setAssignees] = useState<SelectOptions[]>([]);
  const [riskAreas, setRiskAreas] = useState<SelectOptions[]>([]);
  const [inventoryModels, setInventoryModels] = useState<SelectOptions[]>([]);
  const [severities, setSeverities] = useState<SelectOptions[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedFilters) {
      // Map ids in selectedFilters to SelectOptions

      const statusOptions =
        selectedFilters.status?.map(x => {
          const option = allFilterOptions.status.find(y => x === y);
          return {
            label: option!,
            value: option!,
          };
        }) || [];
      setStatus(statusOptions);

      const assigneeOptions =
        selectedFilters.assignees?.map(x => {
          const option = allFilterOptions.assignees.find(y => x === y.cuid);
          return {
            label: option!.name,
            value: option!.cuid,
          };
        }) || [];
      setAssignees(assigneeOptions);

      const businessUnitOptions =
        selectedFilters.business_units?.map(x => {
          const option = allFilterOptions.business_units.find(
            y => x === y.cuid,
          );
          return {
            label: option!.name,
            value: option!.cuid,
          };
        }) || [];
      setBusinessUnits(businessUnitOptions);

      const riskAreasOptions =
        selectedFilters.risk_areas?.map(x => {
          const option = allFilterOptions.risk_areas.find(y => x === y.cuid);
          return {
            label: option!.name,
            value: option!.cuid,
          };
        }) || [];
      setRiskAreas(riskAreasOptions);

      const severityOptions =
        selectedFilters.severities?.map(x => {
          const option = allFilterOptions.severities.find(y => x === y.id);
          return {
            label: option!.name,
            value: option!.id.toString(),
          };
        }) || [];
      setSeverities(severityOptions);

      const invModelsOptions =
        selectedFilters.inventory_models?.map(x => {
          const option = allFilterOptions.inventory_models.find(
            y => x === y.cuid,
          );
          return {
            label: option!.name,
            value: option!.cuid,
          };
        }) || [];
      setInventoryModels(invModelsOptions);
    }
  }, [selectedFilters]);

  const onApplyFilters = () => {
    handleClose();

    setFilters({
      status: status.map(x => x.value),
      assignees: assignees.map(x => x.value),
      business_units: businessUnits.map(x => x.value),
      risk_areas: riskAreas.map(x => x.value),
      severities: severities.map(x => parseInt(x.value)),
      inventory_models: inventoryModels.map(x => x.value),
    });
  };

  const onClearFilters = () => {
    setStatus([]);
    setAssignees([]);
    setBusinessUnits([]);
    setRiskAreas([]);
    setSeverities([]);
    setInventoryModels([]);
  };

  const filterDefs = useMemo(() => {
    return [
      {
        name: 'Status',
        control: 'multi-select',
        options: ArrayToSelectOptions(allFilterOptions?.status),
        selected: status,
        onChange: (newValues: MultiValue<SelectOptions>) => {
          setStatus([...newValues]);
        },
      },
      {
        name: 'Model Inventory',
        control: 'multi-select',
        options: ArrayToSelectOptions(allFilterOptions?.inventory_models),
        selected: inventoryModels,
        onChange: (newValues: MultiValue<SelectOptions>) => {
          setInventoryModels([...newValues]);
        },
      },
      {
        name: 'Risk Area',
        control: 'multi-select',
        options: ArrayToSelectOptions(allFilterOptions?.risk_areas),
        selected: riskAreas,
        onChange: (newValues: MultiValue<SelectOptions>) => {
          setRiskAreas([...newValues]);
        },
      },
      {
        name: 'Business Units',
        control: 'multi-select',
        options: ArrayToSelectOptions(allFilterOptions?.business_units),
        selected: businessUnits,
        onChange: (newValues: MultiValue<SelectOptions>) => {
          setBusinessUnits([...newValues]);
        },
      },
      {
        name: 'Severity',
        control: 'multi-select',
        options: ArrayToSelectOptions(allFilterOptions?.severities),
        selected: severities,
        onChange: (newValues: MultiValue<SelectOptions>) => {
          setSeverities([...newValues]);
        },
      },
      {
        name: 'Assigned To',
        control: 'multi-select',
        options: ArrayToSelectOptions(allFilterOptions?.assignees),
        selected: assignees,
        onChange: (newValues: MultiValue<SelectOptions>) => {
          setAssignees([...newValues]);
        },
      },
    ];
  }, [
    allFilterOptions,
    riskAreas,
    status,
    businessUnits,
    severities,
    assignees,
    inventoryModels,
  ]);

  return (
    <>
      <Popover
        strategy={'absolute'}
        closeOnBlur={true}
        closeOnEsc={true}
        isOpen={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <PopoverTrigger>
          <Button
            leftIcon={<Icon as={FunnelIcon} boxSize={5} />}
            variant={'ghost'}
          >
            Filter
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent w={'80vw'} maxW={'90rem'} mr={5} boxShadow={'xl'}>
            <PopoverArrow />
            <PopoverHeader
              py={4}
              px={10}
              fontSize={'lg'}
              fontWeight={'semibold'}
            >
              Select Your Filters
            </PopoverHeader>
            <PopoverCloseButton size={'md'} m={2} />
            <PopoverBody
              overflowX={'auto'}
              py={5}
              px={10}
              // This is needed because there a bug with the portal
              // where select controls are still clickable even
              // after the modal is hidden
              style={{ pointerEvents: isOpen ? 'initial' : 'none' }}
            >
              <HStack spacing={12} align={'stretch'} flexWrap="wrap">
                {filterDefs.map(({ name, options, selected, onChange }) => {
                  return (
                    <Stack>
                      <Label>{name}</Label>
                      <Select
                        isMulti
                        options={options as any}
                        menuShouldScrollIntoView={false}
                        menuPortalTarget={document.body}
                        classNamePrefix="chakra-react-select"
                        tagVariant="solid"
                        //hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        // components={selectCustomComponents}
                        value={selected}
                        onChange={onChange as any}
                        placeholder="Any"
                        chakraStyles={chakraStyles}
                      />
                    </Stack>
                  );
                })}
              </HStack>
            </PopoverBody>
            <PopoverFooter p={5}>
              <Flex justifyContent={'flex-end'}>
                <Button variant="ghost" mr={3} onClick={onClearFilters}>
                  Clear All Filters
                </Button>
                <Spacer />
                <Button onClick={onApplyFilters} variant={'primary'}>
                  Apply Filters
                </Button>
              </Flex>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
}
