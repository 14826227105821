import { TStatusesWorkflowStatus } from './statuses_workflow';
import { TUser } from './user';
import { APIPaginatedResponse } from './utils';
import { TBusinessUnit } from './business_unit';
import { TRole } from './role';
import { TUseCase } from './use_case';
import { TGroup } from './group';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { TInventoryModelAnnotation } from '../api/API';
import { TFinding } from './finding';
import { Assessment, Guideline } from './guideline';
import { ModelDocument } from './model_document';
import { Template } from './template';

export interface InventoryModelDependencyItem {
  cuid: string;
  name: string;
}
export interface TInventoryModelDependency {
  cuid: string;
  dependent: InventoryModelDependencyItem;
  depends_on: InventoryModelDependencyItem;
  category: 'upstream' | 'downstream' | 'within';
}

export type TInventoryModelPaginated = APIPaginatedResponse<TInventoryModel>;

export const InventoryModelStages = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  DELETED: 'deleted',
} as const;

export type InventoryModelStage =
  typeof InventoryModelStages[keyof typeof InventoryModelStages];

export interface TInventoryModel {
  version: string;
  business_unit: TBusinessUnit;
  created_at: number;
  cuid: string;
  methodologies_data: string[];
  model_id: string;
  name: string;
  purpose: string;
  tiering: string;
  updated_at: null;
  use: string;
  use_case: TUseCase;
  users: TInventoryModelUser[];
  status: TStatusesWorkflowStatus;
  dependencies: TInventoryModelDependency[];
  group: TGroup;
  is_vendor_model: boolean;
  vendor_name?: string;
  custom_fields: { [key: string]: any };
  stage: InventoryModelStage;
  documents: ModelDocument[]; // future
  template: Template;
  template_vr: Template;
  template_monitoring: Template;
}

export enum TInventoryModelUserRoles {
  Owners = 'Model Owner',
  Validators = 'Model Validator',
  Developers = 'Model Developer',
}

export type TInventoryModelUser = {
  cuid: string;
  name: TInventoryModelUserRoles;
  title: string;
  user: TUser;
  role: TRole;
  created_at: number;
  updated_at: number;
};

export type TModelInventoryFilters = {
  cuids: string[];
  tiering: string[];
  business_units: TBusinessUnit[];
  status: TStatusesWorkflowStatus[];
  uses: string[];
  owners: TUser[];
  developers: TUser[];
  validators: TUser[];
  is_vendor_model?: boolean;
  performance_rating: number[];
  custom_fields?: any;
};

export type InventoryModelCreationState = 'new' | 'existing';

export interface TInventoryModelPost {
  name: string;
  creation_state: InventoryModelCreationState;
  business_unit_cuid: string;
  use_case_cuid: string;
  tiering: string;
  purpose: string;
  owners: string[];
  template_cuid: string;
  status_cuid: string;
  group_cuid: string;
  is_vendor_model: boolean;
  vendor_name?: string;
  custom_fields?: any;
}

export interface TInventoryModelUserPost {
  name: string;
  user: {
    cuid: string;
  };
}

export interface LongTextOptions {
  enableRichTextFormatting?: boolean;
  template?: string;
}

export type RJSFSchemaOptions = LongTextOptions | undefined;

export interface CodeProperty {
  source: string;
  variables: string[];
}

export interface NumberProperty {
  type?: 'currency';
  currencyCode?: string;
  decimalPlaces: number;
  abbreviation?: 'thousand' | 'million' | 'billion';
}

export interface RJSFSchemaSettingsProperty {
  typeId: string;
  label?: string;
  description?: string;
  uiSchema?: UiSchema;
  many?: boolean;
  requiredOnRegistration?: boolean;
  options?: RJSFSchemaOptions;
  entity?: string;
  code?: CodeProperty;
  numberOptions?: NumberProperty;
  updateRoles?: TRole[];
}

export interface RJSFSchemaSettings {
  properties: {
    [key: string]: RJSFSchemaSettingsProperty;
  };
}

export interface TInventoryModelSchema {
  schema: RJSFSchema;
  settings: RJSFSchemaSettings;
  version?: number;
  created_at?: number;
  updated_at?: number;
}

export interface TInventoryModelCustomField {
  schema: RJSFSchema;
  settings: RJSFSchemaSettings;
  key: string;
  value: any;
}

export interface TInventoryModelCustomFieldOptionalSchemaAndSettings {
  schema?: RJSFSchema;
  settings?: RJSFSchemaSettings;
  key: string;
  value: any;
}

export type TDocumentSectionStatus = 'in_progress' | 'done';

export interface TInventoryModelDocumentationSummary {
  [key: string]: {
    unresolved_threads: TInventoryModelAnnotation[];
    findings: TFinding[];
    status: TDocumentSectionStatus;
  };
}

export interface TInventoryModelValidationReportSummaryGuidelineData {
  unresolved_threads: TInventoryModelAnnotation[];
  findings: TFinding[];
  guideline: Guideline;
  assessment: Assessment;
}

export interface TInventoryModelValidationReportSummary {
  [key: string]: {
    unresolved_threads: TInventoryModelAnnotation[];
    findings: TFinding[];
    status: TDocumentSectionStatus;
    guidelines: {
      [key: string]: TInventoryModelValidationReportSummaryGuidelineData;
    };
  };
}
