import { Handle, NodeProps, Position } from 'reactflow';
import {
  displayFormatedDate,
  generateRandomCodeForNodes,
} from '../../../../../../utils';
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
  Box,
} from '@chakra-ui/react';
import NodeBox from '../../layouts/NodeBox';
import { WaitNodeType } from '../../types';
import dayjs from 'dayjs';
import {
  Cog6ToothIcon,
  ArrowRightCircleIcon,
  ClockIcon,
} from '@heroicons/react/24/outline';
import { useContext } from 'react';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import ResumeIn from '../../../../../../components/ResumeIn';
import useWorkflowExecution from '../../../../../../hooks/useWorkflowExecution';
import UsersContext from '../../../../../../contexts/UsersContext';
import useModelSchema from '../../../../../../hooks/useModelSchema';

const WAIT_TYPE_LABELS: Record<string, string> = {
  interval: 'Time interval',
  specific_date: 'Specific date/time',
  custom_field: 'Model field',
};

const formatTimeUnit = (amount: number, unit: string | null): string => {
  if (!unit) return '';
  const baseUnit = unit.toLowerCase().replace(/s$/, '');
  return amount === 1 ? baseUnit : `${baseUnit}s`;
};

function WaitNode(props: NodeProps) {
  const { currentUser } = useContext(UsersContext);
  const { setSelectedNodeId } = useWorkflow();
  const { resumeWait } = useWorkflowExecution();
  const isAdmin = currentUser?.roles.some(role => role.role.is_admin);
  const { propertyItems } = useModelSchema();

  const DEFAULT_HANDLE_STYLES = {
    background: useColorModeValue(
      'var(--chakra-colors-neutral-200)',
      'var(--chakra-colors-neutral-800)',
    ),
    borderColor: useColorModeValue(
      'var(--chakra-colors-neutral-50)',
      'var(--chakra-colors-neutral-500)',
    ),
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '50%',
    height: 16,
    width: 16,
  };

  const getFieldDisplayName = (fieldKey: string) => {
    const field = propertyItems.find(prop => prop.key === fieldKey);
    return field?.title || fieldKey; // Fallback to key if title not found
  };

  if (props.data.state_callbacks.on_enter[0].args.wait_type === null) {
    return (
      <NodeBox
        title={'WAIT'}
        icon={ClockIcon}
        bg={useColorModeValue('neutral.50', 'neutral.800')}
        border={2}
        borderColor={useColorModeValue('neutral.200', 'neutral.700')}
        borderStyle={'solid'}
        nodeProps={props}
        rounded={'lg'}
      >
        <HStack px={2} pb={2} w={'full'}>
          <Button
            leftIcon={<Icon as={Cog6ToothIcon} boxSize={4} />}
            onClick={() => setSelectedNodeId!(props.id)}
            w={'full'}
          >
            Configure
          </Button>
        </HStack>
      </NodeBox>
    );
  }

  const onResumeWait = async () => {
    if (resumeWait) {
      resumeWait.mutate({});
    }
  };

  return (
    <NodeBox
      title={'WAIT'}
      icon={ClockIcon}
      bg={useColorModeValue('neutral.50', 'neutral.800')}
      border={2}
      borderColor={useColorModeValue('neutral.200', 'neutral.700')}
      borderStyle={'solid'}
      nodeProps={props}
      rounded={'lg'}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ ...DEFAULT_HANDLE_STYLES, top: -8 }}
      />

      {props.data?.execution?.status === 'waiting' &&
        props.data?.execution?.current_node === props.id &&
        isAdmin && (
          <Tooltip label="Double click to run it now">
            <IconButton
              // isRound={true}
              style={{
                position: 'absolute',
                top: '.5rem',
                left: '-1.9rem',
                borderRight: 0,
                background: 'var(--chakra-colors-neutral-25)',
              }}
              borderColor={'neutral.200'}
              aria-label="Run now!"
              size={'sm'}
              roundedRight={'0'}
              icon={<Icon as={ArrowRightCircleIcon} boxSize={6} />}
              onDoubleClick={onResumeWait}
              isLoading={resumeWait?.isLoading}
            />
          </Tooltip>
        )}

      <HStack alignItems={'flex-start'} px={4} pb={4}>
        <VStack gap={1} alignItems={'flex-start'} w="full">
          <Text fontSize="sm" color={useColorModeValue('gray.600', 'gray.300')}>
            Type:{' '}
            <Text as="span" fontWeight="medium">
              {WAIT_TYPE_LABELS[
                props.data?.state_callbacks?.on_enter[0]?.args?.wait_type
              ] || 'Time interval'}
            </Text>
          </Text>

          {props.data?.state_callbacks?.on_enter[0]?.args?.wait_type ===
            'interval' && (
            <Text fontSize="md" fontWeight="medium">
              {props.data?.state_callbacks?.on_enter[0]?.args?.wait_amount}{' '}
              {formatTimeUnit(
                props.data?.state_callbacks?.on_enter[0]?.args?.wait_amount,
                props.data?.state_callbacks?.on_enter[0]?.args?.wait_unit,
              )}
            </Text>
          )}

          {props.data?.state_callbacks?.on_enter[0]?.args?.wait_type ===
            'specific_date' && (
            <Text fontSize="md" fontWeight="medium">
              Until{' '}
              {displayFormatedDate(
                dayjs(
                  props.data?.state_callbacks?.on_enter[0]?.args
                    ?.specific_datetime,
                ).unix(),
              )}
            </Text>
          )}

          {props.data?.state_callbacks?.on_enter[0]?.args?.wait_type ===
            'custom_field' && (
            <Text fontSize="md" fontWeight="medium">
              Field:{' '}
              {getFieldDisplayName(
                props.data?.state_callbacks?.on_enter[0]?.args?.custom_field,
              )}
            </Text>
          )}

          {props.data?.execution?.status === 'waiting' &&
            props.data?.execution?.current_node === props.id && (
              <Box pt={1}>
                <ResumeIn
                  targetTimestamp={props.data?.execution?.resume_wait_at}
                />
              </Box>
            )}
        </VStack>
      </HStack>

      <Handle
        type="source"
        position={Position.Bottom}
        style={{ ...DEFAULT_HANDLE_STYLES, bottom: -8 }}
      />
    </NodeBox>
  );
}

WaitNode.type = 'wait';
WaitNode.autoRuns = true;

WaitNode.getDefaultNode = (): WaitNodeType => {
  return {
    id: `${WaitNode.type}_${generateRandomCodeForNodes()}`,
    type: WaitNode.type,
    data: {
      state_callbacks: {
        on_enter: [
          {
            func: 'run_wait',
            args: {
              wait_type: null, // Default: 'interval' or 'specific_datetime'
              wait_unit: null, // Default unit for interval
              wait_amount: null, // Default amount for interval
              specific_datetime: null, // Null by default, used for 'specific_datetime'
              custom_field: null, // Optional: Model field reference
            },
          },
        ],
        on_exit: [],
      },
      transition_callbacks: {
        conditions: [],
        prepare: [],
        before: [],
        after: [],
      },
    },
    position: { x: 0, y: 0 },
  };
};

export default WaitNode;
