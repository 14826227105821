import { useContext, useEffect, useId, useMemo, useRef, useState } from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  Box,
  Flex,
  HStack,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { UsersInit } from './UsersInit';
import { useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { TInventoryModelMetadata } from '../../models';
import { MetadataUploadAdapterPlugin } from './MetadataUploadAdapterPlugin';
import { getFeedItems } from './MentionsSupport';
import {
  CKEditorFeatures,
  ClassicEditor,
  ENABLE_CKEDITOR_INSPECTOR,
  generateToolbarItems,
} from './index';
import { useLocation, useSearchParams } from 'react-router-dom';
import UsersContext from '../../contexts/UsersContext';
import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import { CONFIG } from '../../config';
import GenerateWithAIModal from '../GenerateWithAIModal';
import { AIGenerationConfig } from './AITextContentEditor';
import { CommentsAdapter } from './CommentsAdapter';
import { TrackChangesAdapter } from './TrackChangesAdapter';
import ConfirmationAlert from '../ConfirmationAlert';
import { RevisionHistoryAdapter } from './RevisionHistoryAdapter';
import { forEach } from 'lodash';
import { InventoryModelStages } from '../../models/inventory_model';
import InventoryModelContext from '../../contexts/InventoryModel';
import RevisionHistoryButtonPlugin from './RevisionHistoryButtonPlugin';

interface CKEditorWrapperProps {
  text: string;
  metadata?: TInventoryModelMetadata;
  readOnly: boolean;
  onChange?: (text: string) => void;
  onSave?: (text: string, silent?: boolean, documentType?: string) => void;
  onSaveRevisions?: (revisionsData: any, documentType?: string) => void;
  searchKeywords?: string[];
  setEditorToParents?: (editor: any) => void;
  onEditorBlockDelete?: () => void;
  aiGenerationConfig?: AIGenerationConfig;
  onEditorReady?: (ready: boolean) => void;
  enabledFeatures?: CKEditorFeatures;
  autoSave?: boolean;
  hideTooltip?: boolean;
  showOutline?: boolean;
  withHeight?: boolean;
}

const WAIT_BEFORE_SCROLL_TO_BLOCK_IN_MS = 600;

const EditorContainer = ({
  isDisabled,
  hideTooltip,
  children,
}: {
  isDisabled?: boolean;
  hideTooltip: boolean;
  children: any;
}) => {
  if (hideTooltip) {
    return <>{children}</>;
  } else {
    return (
      <Tooltip
        gutter={0}
        bg={useColorModeValue('neutral.200', 'neutral.700')}
        boxShadow={'none'}
        color={useColorModeValue('neutral.800', 'neutral.200')}
        placement="top-end"
        label="Click to edit this block"
        openDelay={1000}
        isDisabled={isDisabled}
        offset={[1, 0]}
        fontSize="md"
      >
        {children}
      </Tooltip>
    );
  }
};

export const COMMENT_ADDED_DOCUMENT_TYPE = 'comment-added';

export function CKEditorWrapper({
  text,
  metadata,
  onChange,
  onSave,
  onSaveRevisions,
  readOnly = false,
  searchKeywords,
  setEditorToParents,
  onEditorBlockDelete,
  onEditorReady,
  aiGenerationConfig,
  enabledFeatures = {
    images: true,
    comments: true,
    revisions: true,
    deleteBlock: true,
    generateWithAI: true,
  },
  autoSave = true,
  hideTooltip = false,
  showOutline = false,
  withHeight = false,
}: CKEditorWrapperProps) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { currentUser, organizationUsers } = useContext(UsersContext);
  const { inventoryModel } = useContext(InventoryModelContext);
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [commentsOnly, setCommentsOnly] = useState(false);
  const [aiGeneratedText, setAIGeneratedText] = useState('');
  const [showAIContentConfirmation, setShowAIContentConfirmation] =
    useState(false);

  const editorContainerRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const revisionViewerContainerRef = useRef<HTMLDivElement>(null);
  const revisionViewerEditorElementRef = useRef<HTMLDivElement>(null);
  const revisionViewerSidebarContainerRef = useRef<HTMLDivElement>(null);
  const [editor, setEditor] = useState<any>();
  const toast = useToast();
  let editorId = useId();

  const generateAIModalControl = useDisclosure();

  useEffect(() => {
    if (currentUser && organizationUsers.length > 0) {
      setIsLayoutReady(true);
    }
  }, [currentUser, organizationUsers]);

  useEffect(() => {
    setCommentsOnly(readOnly);
  }, [readOnly]);

  useEffect(() => {
    if (setEditorToParents) {
      setEditorToParents(editor);
    }
  }, [editor]);

  useEffect(() => {
    const hash = location.hash;
    const dataComment = searchParams.get('data-comment');
    // Don't scroll to the validation guidelines block
    if ((hash && hash.indexOf('validation.guidelines') === -1) || dataComment) {
      setTimeout(() => {
        const element = dataComment
          ? document.querySelector(`[data-comment=${dataComment}]`)
          : document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, WAIT_BEFORE_SCROLL_TO_BLOCK_IN_MS);
    }
  }, [location, searchParams]);

  useEffect(() => {
    if (editor && enabledFeatures.comments) {
      editor.plugins.get('CommentsOnly').isEnabled = commentsOnly;
      const toolbarElement = editor.ui.view.toolbar.element;
      toolbarElement.style.display = commentsOnly ? 'none' : 'flex';

      searchKeywords?.forEach(keyword => {
        editor.execute('find', keyword);
      });
    }
  }, [commentsOnly, editor, searchKeywords, enabledFeatures.comments]);

  useEffect(() => {
    if (editor && enabledFeatures.deleteBlock) {
      editor.execute('deleteBlock', { isVisible: true });
    }
  }, [editor, enabledFeatures.deleteBlock]);

  useEffect(() => {
    if (editor && enabledFeatures.generateWithAI) {
      editor.execute('generateWithAI', { isVisible: true });
    }
  }, [editor, enabledFeatures.generateWithAI]);

  const onEditorAutoSave = async (editor: any) => {
    const documentType =
      localStorage.getItem(COMMENT_ADDED_DOCUMENT_TYPE) || undefined;

    editor.execute('saveIndicator', { isLoading: true });

    // editor only save revisions and auto-saves when onSaveRevisions is provided
    if (enabledFeatures.revisions && onSaveRevisions) {
      const revisionTracker = editor.plugins.get('RevisionTracker');
      await revisionTracker.update();

      const revisionHistory = editor.plugins.get('RevisionHistory');
      // Update the revisions state.
      const revisionsData = revisionHistory.getRevisions({ toJSON: true });
      await onSaveRevisions(revisionsData, documentType);
    }

    if (!commentsOnly) {
      const editorData = editor.data.get();
      onSave?.(editorData, undefined, documentType);
    }

    localStorage.removeItem(COMMENT_ADDED_DOCUMENT_TYPE);

    // timeout 1 second to hide the save indicator
    setTimeout(() => {
      editor.execute('saveIndicator', { isLoading: false });
    }, 1000);
  };

  const users = useMemo(() => {
    return organizationUsers.map(u => ({
      id: u.cuid,
      name: u.name,
      avatar: u.picture,
    }));
  }, [organizationUsers]);

  const usersForMentions = useMemo(() => {
    return organizationUsers.map(u => ({
      id: `@${u.name}`, // autocompleted value
      text: `@${u.name}`, // displayed value in content
      userId: u.cuid, // attr data-user-id
    }));
  }, [organizationUsers]);

  if (metadata) {
    editorId = `${editorId}${metadata.content_id}`;
  }

  let removedPlugins: string[] = [];
  let extraPlugins: any[] = [
    UsersInit,
    MetadataUploadAdapterPlugin,
    RevisionHistoryButtonPlugin,
  ];

  if (!enabledFeatures.images) {
    //removedPlugins.push('ImageInsert', 'EasyImage', 'ImageUpload');
  }

  if (enabledFeatures.comments) {
    extraPlugins.push(CommentsAdapter, TrackChangesAdapter);
  } else {
    removedPlugins.push('Comments');
    removedPlugins.push('TrackChanges');
  }

  if (enabledFeatures.revisions) {
    extraPlugins.push(RevisionHistoryAdapter);
  } else {
    removedPlugins.push('RevisionHistory', 'RevisionTracker');
  }

  const toolbarItems = generateToolbarItems(enabledFeatures);

  const saveAIContent = async (aiText: string) => {
    if (enabledFeatures.revisions) {
      const revisionTracker = editor.plugins.get('RevisionTracker');
      revisionTracker.saveRevision({ name: 'Generated with AI' });
    }
    editor.data.set(aiText);
    onEditorAutoSave(editor);
  };

  return (
    <>
      <ConfirmationAlert
        open={showAIContentConfirmation}
        title={'Confirm Replacement'}
        dialogBody={
          'Injecting this new AI Generation will replace anything that has been previously written in this text block. Please confirm this action, this cannot be undone.'
        }
        onConfirm={confirmed => {
          if (confirmed) {
            saveAIContent(aiGeneratedText);
          }
          setAIGeneratedText('');
          setShowAIContentConfirmation(false);
        }}
        confirmButton={'Yes, replace it'}
        cancelButton={'Cancel'}
      />
      {aiGenerationConfig && (
        <GenerateWithAIModal
          isOpen={generateAIModalControl.isOpen}
          onClose={generateAIModalControl.onClose}
          onAccept={(aiText: string) => {
            generateAIModalControl.onClose();
            if (editor.data.get() !== '') {
              setAIGeneratedText(aiText);
              setShowAIContentConfirmation(true);
            } else {
              saveAIContent(aiText);
            }
          }}
          aiGenerationConfig={aiGenerationConfig}
        />
      )}
      <div
        className={'scroll-to-anchor'}
        id={`${metadata?.content_id.replace(/_/g, '-')}`}
      ></div>
      <Flex id={'editor-container'} ref={editorContainerRef}>
        <Box w={'full'}>
          {isLayoutReady && (
            <EditorContainer
              hideTooltip={hideTooltip}
              isDisabled={isEditing || readOnly}
            >
              <Box
                id={`editor-${editorId}`}
                className={`commentsOnly-${commentsOnly} ${
                  showOutline ? 'show-outline' : ''
                } ${withHeight ? 'with-height' : ''}`}
              >
                <CKEditor
                  id={`editor-${editorId}`}
                  // @ts-ignore ClassicEditor is generated from webpack build, don't try to patch it
                  editor={ClassicEditor}
                  data={text || ''}
                  onReady={async (editor: any) => {
                    if (ENABLE_CKEDITOR_INSPECTOR) {
                      CKEditorInspector.attach('editor', editor);
                    }
                    setEditor(editor);
                    onEditorReady?.(true);
                    if (enabledFeatures.comments) {
                      const annotationsUIs =
                        editor.plugins.get('AnnotationsUIs');
                      annotationsUIs.switchTo('narrowSidebar');

                      // When the editor is ready, get the data-comments param that comes from the activity feed.
                      // To activate the annotation 'wait' for the repositories to load the comments and suggestions,
                      // search, and activate the comment thread.
                      setTimeout(() => {
                        // set the comments to read-only when the model is archived
                        if (
                          inventoryModel?.stage !== InventoryModelStages.ACTIVE
                        ) {
                          const commentsRepository =
                            editor.plugins.get('CommentsRepository');
                          const threads =
                            commentsRepository.getCommentThreads();
                          forEach(threads, thread => {
                            thread.canComment = false;
                          });
                        }

                        const dataComment = searchParams.get('data-comment');

                        if (dataComment) {
                          const marker = [...editor.model.markers].filter(
                            marker => marker.name.includes(dataComment),
                          );
                          if (marker.length) {
                            const markerRange = marker[0].getRange();

                            editor.model.change((writer: any) =>
                              writer.setSelection(markerRange),
                            );
                          }
                        }
                      }, WAIT_BEFORE_SCROLL_TO_BLOCK_IN_MS);
                    }
                  }}
                  onChange={(event: any, editor: any) => {
                    onChange?.(editor.getData());
                    // console.log('> onChange', event, data);
                  }}
                  onError={(error: any, rest: any) => {
                    console.log('#### CKEditor onError', error);
                  }}
                  onFocus={(event: any, editor: any) => {
                    setIsEditing(true);
                  }}
                  onBlur={(event: any, editor: any) => {
                    setIsEditing(false);
                  }}
                  config={{
                    sidebar: {
                      container: sidebarRef.current,
                    },
                    balloonToolbar: enabledFeatures.comments ? ['comment'] : [],
                    extraContext: {
                      queryClient,
                      getAccessTokenSilently,
                      inventoryModel,
                      metadata,
                      toast,
                      onEditorAutoSave,
                      onDeleteBlockButtonPressed: onEditorBlockDelete,
                      onGenerateWithAIButtonPressed:
                        generateAIModalControl.onOpen,
                      users,
                      me: currentUser,
                    },
                    // commentsOnly,
                    licenseKey: CONFIG.REACT_APP_CKEDITOR_LICENSE_KEY,
                    extraPlugins: extraPlugins,
                    // Force removing MathType cause it makes a call to external
                    // domain wiris.net and it's blocked in many banks
                    removePlugins: ['MathType', ...removedPlugins],
                    toolbar: toolbarItems,
                    autosave: autoSave
                      ? {
                          waitingTime: 2000,
                          async save(editor: any) {
                            await onEditorAutoSave(editor);
                          },
                        }
                      : false,
                    mention: {
                      feeds: [
                        {
                          marker: '@',
                          feed: (queryText: string) =>
                            getFeedItems(queryText, usersForMentions),
                          minimumCharacters: 1,
                        },
                      ],
                    },
                    comments: {
                      maxCommentsWhenCollapsed: 2, // Show the first comment and two most recent comments when collapsed.
                      maxCommentCharsWhenCollapsed: 100, // Make comments shorter when collapsed.
                      maxThreadTotalWeight: 600, // Allow for up to 3 comments before collapsing.
                      editorConfig: {
                        mention: {
                          feeds: [
                            {
                              marker: '@',
                              feed: (queryText: string) =>
                                getFeedItems(queryText, usersForMentions),
                              minimumCharacters: 1,
                            },
                          ],
                        },
                      },
                    },
                    revisionHistory: {
                      editorContainer: editorContainerRef.current,
                      viewerContainer: revisionViewerContainerRef.current,
                      viewerEditorElement:
                        revisionViewerEditorElementRef.current,
                      viewerSidebarContainer:
                        revisionViewerSidebarContainerRef.current,
                    },
                    // Needed otherwise MathType causes a config error on re-render
                    mathTypeParameters: {
                      editorParameters: { language: 'en' }, // MathType config, including language
                    },
                  }}
                />
              </Box>
            </EditorContainer>
          )}
        </Box>

        <Box className={'narrow'} id={`sidebar`} ref={sidebarRef}></Box>
      </Flex>

      <HStack
        id={`revision-viewer-container-${editorId}`}
        ref={revisionViewerContainerRef}
        className={'revision-viewer-container'}
        h={'full'}
      >
        <Box
          id={`revision-viewer-editor-${editorId}`}
          className="revision-viewer-editor"
          ref={revisionViewerEditorElementRef}
        ></Box>
        <Box
          className={'sidebar-container'}
          id={`revision-viewer-sidebar-${editorId}`}
          ref={revisionViewerSidebarContainerRef}
          w={'30% !important'}
        ></Box>
      </HStack>
    </>
  );
}
