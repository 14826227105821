// https://auth0.com/blog/complete-guide-to-react-user-authentication
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { CONFIG } from '../config';

export const Auth0ProviderWithHistory = ({ children }: any) => {
  const domain = CONFIG.REACT_APP_AUTH0_DOMAIN as string;
  const clientId = CONFIG.REACT_APP_AUTH0_CLIENT_ID as string;
  const audience = CONFIG.REACT_APP_AUTH0_AUDIENCE as string;

  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    if (appState && appState.appStateJSON) {
      const appStateJSON = appState.appStateJSON;
      const parsedAppStateJSON = JSON.parse(decodeURIComponent(appStateJSON));
      const isInvitedUser = !!parsedAppStateJSON?.isNewUser;

      const inviteQuery = isInvitedUser ? '&invitedUser=true' : '';

      const queryString = `?appStateJSON=${appStateJSON}${inviteQuery}`;
      window.location.search = queryString;
    }
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
