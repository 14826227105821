import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Text,
} from '@chakra-ui/react';
import { AssessmentEvidence } from '../../../../models/guideline';
import {
  convertToSectionTree,
  TemplateSectionContents,
  TemplateSectionTree,
} from '../../../../models/template';
import { useContext } from 'react';
import { ModelDocumentTypeEnum } from '../../../../models/model_document';
import ContentRow from '../../../ContentRow';
import InventoryModelContext from '../../../../contexts/InventoryModel';

interface EvidenceDocumentTreeProps {
  documentType: ModelDocumentTypeEnum;
  evidences: AssessmentEvidence[];
  onRemoveEvidence: (
    documentType: ModelDocumentTypeEnum,
    content: TemplateSectionContents,
  ) => void;
}

export default function EvidenceDocumentTree({
  documentType,
  evidences,
  onRemoveEvidence,
}: EvidenceDocumentTreeProps) {
  const { templates } = useContext(InventoryModelContext);
  const tree = convertToSectionTree(templates.documentation.sections);

  const renderTree = (
    items: TemplateSectionTree[],
    indices: number[],
    parentSection?: TemplateSectionTree,
  ) => {
    return items.map((item: TemplateSectionTree, index: number) => {
      const newIndices = [...indices, index + 1];
      if (!item.sections || item.sections.length === 0) {
        const contents = item.contents || [];
        if (contents.length === 0) {
          const section = {
            content_type: 'text',
            content_id: item.id,
          } as TemplateSectionContents;
          contents.push(section);
        }

        // Filter contents to only those that match evidence
        const matchingContents = contents.filter(content =>
          evidences.some(
            e =>
              e.evidence_content_id === content.content_id &&
              e.evidence_content_type === content.content_type,
          ),
        );

        // If no contents match evidence, skip this item entirely
        if (
          matchingContents.length === 0 &&
          (!item.sections || item.sections.length === 0)
        ) {
          return null;
        }

        return (
          <Accordion allowMultiple>
            <AccordionItem borderBottom={0}>
              <AccordionButton as={Button} variant={'ghost'} rounded={0}>
                <HStack w={'full'}>
                  <Text fontFamily={'monospace'} fontSize={'xs'}>
                    {newIndices.join('.')}.
                  </Text>
                  <Text>{item.title}</Text>
                </HStack>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pr={2}>
                {contents.map((content, index) => {
                  if (
                    evidences.some(
                      e =>
                        e.evidence_content_id === content.content_id &&
                        e.evidence_content_type === content.content_type,
                    )
                  ) {
                    return (
                      <Box>
                        <HStack w={'full'}>
                          <ContentRow
                            documentType={
                              ModelDocumentTypeEnum.model_documentation
                            }
                            content={content}
                            onRemoveContent={() =>
                              onRemoveEvidence(documentType, content)
                            }
                          />
                        </HStack>
                      </Box>
                    );
                  }
                })}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        );
      } else {
        return (
          <Box>
            {item.sections && item.sections.length > 0 ? (
              <>{renderTree(item.sections, newIndices, item)}</>
            ) : null}
          </Box>
        );
      }
    });
  };

  return (
    <Box data-testid="model-documentation-outline">{renderTree(tree, [])}</Box>
  );
}
