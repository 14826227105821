import { Handle, NodeProps, Position } from 'reactflow';
import {
  Alert,
  AlertIcon,
  HStack,
  Icon,
  Text,
  useColorModeValue,
  VStack,
  Button,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import NodeBox from '../../layouts/NodeBox';
import { Cog6ToothIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { useCallback, useContext, useState } from 'react';
import { UsersContext } from '../../../../../../contexts';
import { TUserWithoutRoles } from '../../../../../../models/user';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { ApprovalNodeType } from '../../types';
import { useOrgRoles } from '../../../../../../hooks/useOrgRoles';

function ApprovalNode(props: NodeProps<ApprovalNodeType['data']>) {
  const { organizationUsers } = useContext(UsersContext);
  const { orgRoles } = useOrgRoles();
  const [users, setUsers] = useState<TUserWithoutRoles[]>([]);
  const { setSelectedNodeId } = useWorkflow();

  type Resolver = {
    [operator: string]: [{ var: string }, string];
  };

  const parseVotersQuery = useCallback(
    (query: Resolver): string => {
      if (
        !query ||
        typeof query !== 'object' ||
        Array.isArray(query) ||
        Object.keys(query).length === 0
      ) {
        return 'Configuration Required';
      }

      const operator = Object.keys(query)[0];
      const [variable, value] = query[operator];

      if (!variable || !variable.var || !value) {
        return 'Configuration Required';
      }

      if (variable.var === 'user.roles') {
        const roleName = orgRoles.find(role => role.cuid === value)?.name;
        return `User Roles: ${roleName}`;
      } else if (variable.var === 'model.custom_fields') {
        return `Model Inventory Field: ${value}`;
      } else {
        return 'Configuration Required';
      }
    },
    [orgRoles],
  );

  if (
    (Object.keys(props.data.state_callbacks.on_enter[0].args.voters) || [])
      .length === 0
  ) {
    return (
      <NodeBox
        title={'APPROVAL'}
        icon={UserGroupIcon}
        bg={useColorModeValue('neutral.50', 'neutral.800')}
        border={2}
        borderColor={useColorModeValue('neutral.200', 'neutral.700')}
        borderStyle={'solid'}
        nodeProps={props}
        rounded={'lg'}
      >
        <HStack px={2} pb={2} w={'full'}>
          <Button
            leftIcon={<Icon as={Cog6ToothIcon} boxSize={4} />}
            onClick={() => setSelectedNodeId!(props.id)}
            w={'full'}
          >
            Configure
          </Button>
        </HStack>
      </NodeBox>
    );
  }
  const DEFAULT_HANDLE_STYLES = {
    background: useColorModeValue(
      'var(--chakra-colors-neutral-200)',
      'var(--chakra-colors-neutral-800)',
    ),
    borderColor: useColorModeValue(
      'var(--chakra-colors-neutral-50)',
      'var(--chakra-colors-neutral-500)',
    ),
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '50%',
    height: 16,
    width: 16,
  };

  return (
    <NodeBox
      title={'APPROVAL'}
      icon={UserGroupIcon}
      bg={useColorModeValue('neutral.50', 'neutral.800')}
      border={2}
      borderColor={useColorModeValue('neutral.200', 'neutral.700')}
      borderStyle={'solid'}
      nodeProps={props}
      rounded={'lg'}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ ...DEFAULT_HANDLE_STYLES, top: -8 }}
      />
      <VStack alignItems={'flex-start'} px={2} pb={2}>
        <VStack gap={2} alignItems={'flex-start'} w={'full'}>
          <VStack
            w={'full'}
            alignItems={'start'}
            bg={useColorModeValue('neutral.100', 'neutral.850')}
            p={4}
            gap={0}
            rounded={'md'}
          >
            <Text fontSize={'small'}>
              <strong>Title:</strong>{' '}
              {props.data.state_callbacks.on_enter[0].args.title}
            </Text>
            <Text fontSize={'small'} maxW={500}>
              <strong>Message:</strong>{' '}
              {props.data.state_callbacks.on_enter[0].args.message}
            </Text>

            <Text fontSize={'small'}>
              <strong>Approvers:</strong>{' '}
              {parseVotersQuery(
                props.data.state_callbacks.on_enter[0].args.voters,
              )}
            </Text>

            <Text fontSize={'small'}>
              <strong>Threshold:</strong>
              {props.data.state_callbacks.on_enter[0].args.vote_threshold}
            </Text>
          </VStack>
        </VStack>
        {(props.data.state_callbacks.on_enter[0].args.on_approved === null ||
          props.data.state_callbacks.on_enter[0].args.on_rejected === null) && (
          <Alert status="warning" size={'xs'}>
            <AlertIcon boxSize="4" />
            <Text fontSize={'xs'}>
              {props.data.state_callbacks.on_enter[0].args.on_approved ===
                null &&
              props.data.state_callbacks.on_enter[0].args.on_rejected === null
                ? 'Missing approval and rejection connection'
                : props.data.state_callbacks.on_enter[0].args.on_approved ===
                  null
                ? 'Missing approval connection'
                : 'Missing rejection connection'}
            </Text>
          </Alert>
        )}
      </VStack>
      <Handle
        id="approved"
        type="source"
        position={Position.Right}
        style={{ ...DEFAULT_HANDLE_STYLES, background: 'green', right: -8 }}
      />
      <Handle
        id="rejected"
        type="source"
        position={Position.Left}
        style={{ ...DEFAULT_HANDLE_STYLES, background: 'red', left: -8 }}
      />
    </NodeBox>
  );
}

ApprovalNode.type = 'approval';
ApprovalNode.autoRuns = true;

ApprovalNode.getDefaultNode = (): ApprovalNodeType => {
  const uuid = uuidv4();
  return {
    id: `${ApprovalNode.type}_${uuid}`,
    type: ApprovalNode.type,
    data: {
      state_callbacks: {
        on_enter: [
          {
            func: 'run_approvals',
            args: {
              id: uuid,
              allow_restart: true,
              on_approved: null,
              on_rejected: null,
              voters: [],
              vote_threshold: 100,
              auto_close_on_threshold: false,
              auto_close_after_days: 10,
              title: 'Approval',
              message: 'Please approve this action',
            },
          },
        ],
        on_exit: [],
      },
      transition_callbacks: {
        prepare: [],
        conditions: [],
        before: [],
        after: [],
      },
    },
    position: { x: 0, y: 0 },
  };
};

export default ApprovalNode;
