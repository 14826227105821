import { useEffect, useRef, useState } from 'react';
import {
  Box,
  HStack,
  Icon,
  Stack,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import { Label } from '../Layout';

import { QuestionIcon } from '@chakra-ui/icons';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { PencilIcon } from '@heroicons/react/24/outline';
import { isMissing } from '../NewCustomFields/CustomFieldValidator';
import MoreInfoPopOver, { MoreInfoPopOverProps } from '../MoreInfoPopOver';

export interface EditableFieldProps {
  onSave?: (onFinished: (success: boolean) => void) => void;
  onCancel?: () => void;
  value?: any;
  title: string;
  description?: string;
  renderField: (mode: 'edit' | 'read-only', isSaving: boolean) => JSX.Element;
  isRequired?: boolean;
  errors?: string[];
  readOnly?: boolean;
  hideLabel?: boolean;
  saveButtonLabel?: string;
  moreInfoPopoverProps?: MoreInfoPopOverProps;
}

interface FieldTitleProps {
  title: string;
  description?: string;
  hideLabel?: boolean;
  isRequired?: boolean;
  overrideTooltip?: JSX.Element;
}
const FieldTitle = ({
  title,
  description,
  hideLabel,
  isRequired,
  overrideTooltip,
}: FieldTitleProps) => {
  if (hideLabel) {
    return <></>;
  }
  return (
    <HStack w={'full'} alignItems={'flex-start'}>
      <Label>{title}</Label>
      {isRequired && <Label color="red.500">*</Label>}
      {!overrideTooltip && description && (
        <Tooltip
          hasArrow
          label={description!}
          placement={'left-start'}
          offset={[-4, 8]}
        >
          <QuestionIcon
            color={useColorModeValue('neutral.300', 'neutral.700')}
            visibility={'hidden'}
            _groupHover={{ visibility: 'visible' }}
          />
        </Tooltip>
      )}
      {overrideTooltip}
    </HStack>
  );
};
const EditableField = ({
  value,
  errors,
  title,
  description,
  isRequired,
  renderField,
  onSave,
  onCancel,
  moreInfoPopoverProps,
  readOnly = false,
  hideLabel = false,
  saveButtonLabel = 'Save',
}: EditableFieldProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsEditingSaving] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const stackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isEditing) {
      setIsActive(true);
    }
  }, [isEditing]);

  useEffect(() => {
    // Function to check if the clicked target is outside the Stack
    function handleClickOutside(event: any) {
      if (stackRef.current && !stackRef.current.contains(event.target)) {
        setIsActive(false); // Change the editing state
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [stackRef]);

  const calculateBoxShadow = () => {
    if (isActive && isEditing)
      return useColorModeValue(
        `0px 0px 0px 7px var(--chakra-colors-brandSecondary-25),
        0px 0px 0px 8px var(--chakra-colors-brand-base)`,
        `0px 0px 0px 7px var(--chakra-colors-brandSecondary-950),
        0px 0px 0px 8px var(--chakra-colors-brand-base)`,
      );
    if (!isActive && isEditing)
      return useColorModeValue(
        '0px 0px 0px 7px var(--chakra-colors-brandSecondary-25)',
        '0px 0px 0px 7px var(--chakra-colors-brandSecondary-950)',
      );
    return '0px 0px 0px 7px transparent, 0px 0px 0px 7px transparent';
  };

  const calculateHoverBoxShadow = () => {
    if (isEditing && isActive)
      return useColorModeValue(
        '0px 0px 0px 7px var(--chakra-colors-brandSecondary-25), 0px 0px 0px 8px var(--chakra-colors-brand-base)',
        '0px 0px 0px 7px var(--chakra-colors-brandSecondary-950), 0px 0px 0px 8px var(--chakra-colors-brand-base)',
      );
    if (isEditing && !isActive)
      return useColorModeValue(
        '0px 0px 0px 7px var(--chakra-colors-brandSecondary-25), 0px 0px 0px 8px var(--chakra-colors-brandSecondary-25)',
        '0px 0px 0px 7px var(--chakra-colors-brandSecondary-950), 0px 0px 0px 8px var(--chakra-colors-brandSecondary-base)',
      );
    return useColorModeValue(
      '0px 0px 0px 7px var(--chakra-colors-brandSecondary-25), 0px 0px 0px 8px var(--chakra-colors-brandSecondary-25)',
      '0px 0px 0px 7px var(--chakra-colors-brandSecondary-950), 0px 0px 0px 8px var(--chakra-colors-brandSecondary-950)',
    );
  };

  const onCancelWrapper = () => {
    setIsEditing(false);
    onCancel?.();
  };

  const onSaveWrapper = async () => {
    if (readOnly) {
      return;
    }

    setIsEditingSaving(true);
    onSave?.(() => {
      setIsEditing(false);
      setIsEditingSaving(false);
    });
  };

  const mode = isEditing && !readOnly ? 'edit' : 'read-only';

  return (
    <Tooltip
      gutter={0}
      fontSize="md"
      offset={[8, 4]}
      boxShadow={'none'}
      placement="top-end"
      isDisabled={isEditing || readOnly}
      label={<Icon as={PencilIcon} boxSize={4} />}
      px={2}
      py={1}
      roundedTop={'md'}
      roundedBottom={'none'}
      bg={useColorModeValue('brandSecondary.25', 'brandSecondary.950')}
      color={useColorModeValue('brandSecondary.800', 'neutral.200')}
    >
      <Stack
        ref={stackRef}
        w={'full'}
        gap={2}
        borderRadius={'2px'}
        cursor={isEditing || readOnly ? 'default' : 'pointer'}
        onClick={() => {
          setIsActive(true);
          if (!isEditing && !readOnly) {
            setIsEditing(true);
          }
        }}
        bg={
          isEditing
            ? useColorModeValue('brandSecondary.25', 'brandSecondary.950')
            : useColorModeValue('white', 'black')
        }
        boxShadow={calculateBoxShadow()}
        _hover={
          readOnly
            ? {}
            : {
                bg: useColorModeValue(
                  'brandSecondary.25',
                  'brandSecondary.950',
                ),
                boxShadow: calculateHoverBoxShadow(),
              }
        }
        role="group"
        data-testid="editable-field"
      >
        <Box display="flex" alignItems="center">
          {isRequired && isMissing(value) && (
            <Tooltip
              label="Required field is missing"
              hasArrow
              placement="left"
            >
              <Icon
                mr={1}
                boxSize={5}
                as={ExclamationCircleIcon}
                color="red.500"
              />
            </Tooltip>
          )}
          <FieldTitle
            title={title}
            description={description}
            hideLabel={hideLabel}
            isRequired={isRequired}
            overrideTooltip={
              !!moreInfoPopoverProps ? (
                <MoreInfoPopOver {...moreInfoPopoverProps} />
              ) : undefined
            }
          />
        </Box>
        <VStack alignItems={'flex-start'}>
          {renderField(mode, isSaving)}
          {errors && errors.length > 0 && (
            <Text fontSize="small" color="red.500">
              {errors[0]}
            </Text>
          )}
          {mode === 'edit' && (
            <HStack alignSelf={'flex-end'}>
              {onCancel && (
                <Button
                  variant={'ghost'}
                  isDisabled={isSaving}
                  onClick={onCancelWrapper}
                  size={'sm'}
                >
                  Cancel
                </Button>
              )}
              {onSave && (
                <Button
                  type="submit"
                  isLoading={isSaving}
                  isDisabled={(errors && errors.length > 0) || isSaving}
                  onClick={onSaveWrapper}
                  variant={'primary'}
                  size={'sm'}
                >
                  {saveButtonLabel}
                </Button>
              )}
            </HStack>
          )}
        </VStack>
      </Stack>
    </Tooltip>
  );
};

export default EditableField;
