import {
  Box,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Fade,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  Spacer,
  Stack,
  VStack,
  useColorModeValue,
  useEditableControls,
  useToast,
  Button,
} from '@chakra-ui/react';
import { forwardRef, useEffect, useRef, useState, useContext } from 'react';
import {
  CheckIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../../../api/API';
import { TBusinessUnit } from '../../../models/business_unit';
import { UsersContext } from '../../../contexts';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';

interface BusinessUnitItemProps {
  item: TBusinessUnit;
  canDelete: boolean;
  canUpdate: boolean;
  onDelete: (id: string) => void;
  onUpdateTitle: (id: string, newTitle: string) => void;
}

function EditableControls() {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup size="sm">
      <IconButton
        aria-label="submit"
        bg={'transparent'}
        icon={<Icon as={CheckIcon} boxSize={4} strokeWidth={2} />}
        size="sm"
        {...getSubmitButtonProps()}
      />
      <IconButton
        aria-label="cancel"
        bg={'transparent'}
        icon={<Icon as={XMarkIcon} boxSize={4} strokeWidth={2} />}
        size="sm"
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : (
    <Flex display="none" _groupHover={{ display: 'inline-flex' }}>
      <IconButton
        aria-label="edit"
        bg={'transparent'}
        size="sm"
        icon={<Icon as={PencilIcon} />}
        {...getEditButtonProps()}
      />
    </Flex>
  );
}

const BusinessUnitItem = forwardRef<HTMLDivElement, BusinessUnitItemProps>(
  function BusinessUnitItem(
    { item, canDelete, canUpdate, onDelete, onUpdateTitle },
    ref,
  ) {
    const [businessUnitTitle, setBusinessUnitTitle] = useState(item.name);
    return (
      <Box w="full" ref={ref}>
        <Fade in={true} transition={{ enter: { duration: 0.5, delay: 0.2 } }}>
          <HStack
            pr={2}
            pl={4}
            border="1px solid"
            borderColor={'var(--chakra-colors-chakra-border-color)'}
            w="full"
            bg={useColorModeValue('neutral.50', 'neutral.800')}
            rounded="md"
            role="group"
            py={2}
          >
            <Editable
              defaultValue={businessUnitTitle}
              submitOnBlur={false}
              isDisabled={!canUpdate}
              onSubmit={() => onUpdateTitle(item.cuid, businessUnitTitle)}
              as={HStack}
            >
              <EditablePreview />
              <Input
                rounded={'md'}
                px={2}
                size={'sm'}
                border={'1px solid'}
                borderColor={useColorModeValue('neutral.100', 'neutral.800')}
                focusBorderColor="brand.base"
                onChange={e => setBusinessUnitTitle(e.target.value)}
                backgroundColor={useColorModeValue('white', 'black')}
                as={EditableInput}
              />
              {canUpdate && <EditableControls />}
            </Editable>
            <Spacer />
            {canDelete && (
              <IconButton
                variant="ghost"
                size="sm"
                icon={<Icon as={TrashIcon} boxSize={4} />}
                aria-label="Remove"
                _groupHover={{ display: 'block' as const }}
                display="none"
                onClick={() => onDelete(item.cuid)}
                _hover={{
                  bg: useColorModeValue('red.100', 'red.700'),
                  color: useColorModeValue('red.600', 'red.200'),
                }}
                color={useColorModeValue('neutral.800', 'neutral.200')}
              />
            )}
          </HStack>
        </Fade>
      </Box>
    );
  },
);

export function BusinessUnitsPanel() {
  const [inputValue, setInputValue] = useState('');
  const [lastAddedItemId, setLastAddedItemId] = useState<string | null>(null);
  const newItemRef = useRef<HTMLDivElement>(null);

  const { getAccessTokenSilently } = useAuth0();
  const { userHasPermission } = useContext(UsersContext);
  const toast = useToast();
  const queryClient = useQueryClient();

  const canReadBusinessUnit = userHasPermission(['read_business_unit'], 'all');
  const canAddBusinessUnit = userHasPermission(['add_business_unit'], 'all');
  const canDeleteBusinessUnit = userHasPermission(
    ['delete_business_unit'],
    'all',
  );
  const canUpdateBusinessUnit = userHasPermission(
    ['update_business_unit'],
    'all',
  );

  if (!canReadBusinessUnit) {
    return null;
  }

  const { data: businessUnits = [] } = useQuery(
    ['business-units'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetOrganizationBusinessUnits(accessToken);
    },
    {
      onSuccess: businessUnits =>
        businessUnits.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
    },
  );

  const createBusinessUnitMutation = useMutation(
    [],
    async ({ name }: { name: string }) => {
      const accessToken = await getAccessTokenSilently();
      return API.PostOrganizationBusinessUnits(accessToken, { name });
    },
    {
      onSuccess: data => {
        setLastAddedItemId(data.cuid);
        void queryClient.invalidateQueries('business-units');
        setInputValue('');
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error creating business unit',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  const updateBusinessUnitTitleMutation = useMutation(
    [],
    async ({ cuid, newTitle }: { cuid: string; newTitle: string }) => {
      const accessToken = await getAccessTokenSilently();
      return API.PatchOrganizationBusinessUnit(accessToken, cuid, newTitle);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('business-units');
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error updating business unit',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  const deleteBusinessUnitMutation = useMutation(
    [],
    async ({ cuid }: { cuid: string }) => {
      const accessToken = await getAccessTokenSilently();
      return API.DeleteOrganizationBusinessUnit(accessToken, cuid);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('business-units');
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error deleting business unit',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  const onAddBusinessUnit = () => {
    createBusinessUnitMutation.mutate({ name: inputValue });
  };

  const onUpdateBusinessUnitTitle = (cuid: string, newTitle: string) => {
    updateBusinessUnitTitleMutation.mutate({ cuid, newTitle });
  };

  const onDeleteBusinessUnit = (cuid: string) => {
    deleteBusinessUnitMutation.mutate({ cuid });
  };

  useEffect(() => {
    if (lastAddedItemId && newItemRef.current) {
      newItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setLastAddedItemId(null);
    }
  }, [lastAddedItemId, businessUnits]);

  return (
    <Stack
      w="full"
      gap={4}
      padding={4}
      border="1px solid"
      rounded="md"
      borderColor={'var(--chakra-colors-chakra-border-color)'}
      h="full"
    >
      <HStack>
        <Heading as="h3">Business Units</Heading>
        <Spacer />
        <MoreInfoPopOver
          title="Manage Business Units"
          link="https://docs.validmind.ai/guide/configuration/set-up-your-organization.html#manage-business-units"
        />
      </HStack>
      <VStack alignItems="flex-start" w="full" gap={4}>
        {canAddBusinessUnit && (
          <HStack w="full" data-testId="add-business-unit">
            <Input
              type="text"
              size={'sm'}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              placeholder="Type a new business unit name"
              bg={useColorModeValue('white', 'neutral.800')}
            />
            <Spacer />
            <Button
              onClick={() => onAddBusinessUnit()}
              isDisabled={!inputValue}
              leftIcon={<Icon as={PlusIcon} boxSize={4} />}
              variant={'outline'}
              size={'sm'}
              w={'content'}
            >
              Add
            </Button>
          </HStack>
        )}
        <Box scrollBehavior="smooth" overflowY="auto" w="full" maxH={96}>
          <VStack w="full" gap={1}>
            {businessUnits.map(businessUnit => (
              <BusinessUnitItem
                ref={businessUnit.cuid === lastAddedItemId ? newItemRef : null}
                key={businessUnit.cuid}
                item={businessUnit}
                canDelete={canDeleteBusinessUnit}
                canUpdate={canUpdateBusinessUnit}
                onDelete={onDeleteBusinessUnit}
                onUpdateTitle={onUpdateBusinessUnitTitle}
              />
            ))}
          </VStack>
        </Box>
      </VStack>
    </Stack>
  );
}
