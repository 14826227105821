import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  VStack,
  PopoverHeader,
  Box,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  Icon,
  MenuList,
  MenuDivider,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Input,
  Textarea,
  ModalFooter,
  Spacer,
} from '@chakra-ui/react';
import API from '../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import {
  BookmarkIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import MoreInfoPopOver from '../MoreInfoPopOver';
import { TSavedView, TSavedViewTypes } from '../../models/saved_view';
import { Label } from '../Layout';

type AddOrEditViewModalProps = {
  isOpen: boolean;
  existingView?: TSavedView;
  onClose: () => void;
  onSave: ({
    id,
    name,
    description,
  }: {
    id?: string;
    name: string;
    description: string;
  }) => Promise<void>;
};

export const AddOrEditViewModal = ({
  isOpen,
  existingView,
  onClose,
  onSave,
}: AddOrEditViewModalProps) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    await onSave({ name, description, id: existingView?.cuid });
    setIsSubmitting(false);
    onClose();
  };

  useEffect(() => {
    if (existingView) {
      setName(existingView.name);
      setDescription(existingView.description || '');
    } else {
      setName('');
      setDescription('');
    }
  }, [existingView]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{!!existingView ? 'Edit' : 'Add New'} View</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={4}>
            <FormControl>
              <Label mb={2}>View Name</Label>
              <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder=""
              />
            </FormControl>
            <FormControl>
              <Label mb={2}>Description</Label>
              <Textarea
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="What will this view be about..."
              ></Textarea>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            isDisabled={!name}
            isLoading={isSubmitting}
            onClick={onSubmit}
            variant={'primary'}
          >
            {!!existingView ? 'Save Changes' : 'Add New View'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ViewDeleteConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const cancelRef = useRef() as any;
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete View
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              onClick={onConfirm}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

type ManageViewsButtonProps = {
  viewType: TSavedViewTypes;
  onViewSelected: (savedView: TSavedView) => void;
  getViewContent: () => any;
};

const ManageViewsButton: React.FC<ManageViewsButtonProps> = ({
  viewType,
  onViewSelected,
  getViewContent,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const viewDeleteConfirmModal = useDisclosure();
  const addOrEditViewModal = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [views, setViews] = useState<TSavedView[]>([]);
  const [selectedView, setSelectedView] = useState<TSavedView | undefined>(
    undefined,
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();
      API.GetSavedViews(accessToken, viewType).then(v => {
        setViews(v);
      });
      setIsLoading(false);
    })();
  }, [viewType]);

  const addOrEditView = useCallback(
    async ({
      name,
      description,
      id,
    }: {
      name: string;
      description?: string;
      id?: string;
    }) => {
      const accessToken = await getAccessTokenSilently();
      if (id) {
        await API.UpdateSavedView(accessToken, id, { name, description });
      } else {
        await API.CreateSavedView(accessToken, {
          type: viewType,
          name,
          description,
          content: getViewContent(),
        });
      }
      const v = await API.GetSavedViews(accessToken, viewType);
      setViews(v);
    },
    [getAccessTokenSilently, getViewContent, viewType],
  );

  return (
    <>
      <ViewDeleteConfirmModal
        isOpen={viewDeleteConfirmModal.isOpen}
        onClose={viewDeleteConfirmModal.onClose}
        onConfirm={async () => {
          if (selectedView) {
            const accessToken = await getAccessTokenSilently();
            await API.DeleteSavedView(accessToken, selectedView.cuid);
            const v = await API.GetSavedViews(accessToken, viewType);
            setViews(v);
          }
          viewDeleteConfirmModal.onClose();
        }}
      />
      <AddOrEditViewModal
        existingView={selectedView}
        isOpen={addOrEditViewModal.isOpen}
        onClose={addOrEditViewModal.onClose}
        onSave={addOrEditView}
      />
      <Popover>
        <PopoverTrigger>
          <Button variant="ghost" isDisabled={isLoading} isLoading={isLoading}>
            <Icon as={BookmarkIcon} boxSize={5} mr={2} />
            Manage Views
          </Button>
        </PopoverTrigger>
        <PopoverContent w="50rem" maxW={'90rem'}>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>
            <Button
              colorScheme="blue"
              onClick={() => {
                setSelectedView(undefined);
                addOrEditViewModal.onOpen();
              }}
            >
              Save As View
            </Button>
          </PopoverHeader>
          <PopoverBody overflowY="scroll">
            <VStack>
              {views.map(view => (
                <Box
                  display={'flex'}
                  key={view.cuid}
                  onClick={() => onViewSelected(view)}
                  bgColor="white"
                  w={'100%'}
                  border="1px solid #e2e8f0"
                  borderRadius={4}
                  p={3}
                  cursor={'pointer'}
                  _hover={{
                    bgColor: '#FAFAFA',
                  }}
                >
                  <HStack justifyContent="space-between" w="full">
                    <HStack>
                      <Text>{view.name}</Text>
                      <MoreInfoPopOver
                        title={view.name}
                        description={view.description}
                        placement="right-end"
                        iconProps={{
                          ml: 2,
                          fill: 'brand.base',
                        }}
                      />
                    </HStack>
                    <Box>
                      <Menu>
                        <MenuButton
                          onMouseDown={e => e.stopPropagation()}
                          onMouseUp={e => e.stopPropagation()}
                          as={IconButton}
                          aria-label="Options"
                          icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                          variant="ghost"
                          size="sm"
                        />
                        <MenuList
                          onMouseDown={e => e.stopPropagation()}
                          onMouseUp={e => e.stopPropagation()}
                        >
                          <MenuItem
                            icon={<Icon as={PencilIcon} boxSize={4} />}
                            onClick={() => {
                              setSelectedView(view);
                              addOrEditViewModal.onOpen();
                            }}
                          >
                            Edit View
                          </MenuItem>
                          <MenuDivider />
                          <MenuItem
                            color="red.500"
                            icon={<Icon as={TrashIcon} boxSize={4} />}
                            onClick={async () => {
                              setSelectedView(view);
                              viewDeleteConfirmModal.onOpen();
                            }}
                          >
                            Remove View
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Box>
                  </HStack>
                </Box>
              ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default ManageViewsButton;
