import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { TInventoryModel } from '../../models/inventory_model';

interface UseValidationGuidelineHookProps {
  inventoryModel: TInventoryModel;
  content_id: string;
}
export default function useValidationGuideline({
  inventoryModel,
  content_id,
}: UseValidationGuidelineHookProps) {
  const { getAccessTokenSilently } = useAuth0();

  const queryResponse = useQuery(
    [
      'inventory-model',
      inventoryModel.cuid,
      'validation_guideline',
      content_id,
    ],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetValidationGuideline(
        accessToken,
        inventoryModel,
        content_id,
      );
    },
    {
      retry: false,
    },
  );

  return { testResult: queryResponse.data, ...queryResponse };
}
