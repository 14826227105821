import {
  Button,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { TableCellsIcon } from '@heroicons/react/24/outline';
import { Icon } from '@chakra-ui/icons';
import SortableChecklist from '../SortableChecklist';
import MoreInfoPopOver from '../MoreInfoPopOver';

type Column = {
  label: string;
  id: string;
};

interface TableColumnPicker {
  selectedColumnIds: string[];
  allColumns: readonly Column[];
  setColumns: (columnIds: string[]) => void;
}

export default function TableColumnPicker({
  selectedColumnIds,
  allColumns,
  setColumns,
}: TableColumnPicker) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const isLoading = false;

  const selectedColumns = selectedColumnIds;

  return (
    <>
      <Popover
        strategy={'absolute'}
        closeOnBlur={true}
        closeOnEsc={true}
        isOpen={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <PopoverTrigger>
          <Button
            leftIcon={<Icon as={TableCellsIcon} boxSize={5} />}
            variant={'ghost'}
          >
            Manage Columns
          </Button>
        </PopoverTrigger>
        <PopoverContent
          w={'96'}
          maxW={'90rem'}
          bgColor={useColorModeValue('neutral.50', 'neutral.900')}
          mr={5}
          boxShadow={'xl'}
        >
          <PopoverArrow bgColor={'neutral.50'} />
          <HStack gap={0}>
            <PopoverHeader
              py={4}
              px={5}
              fontSize={'lg'}
              fontWeight={'semibold'}
            >
              Manage Columns
            </PopoverHeader>
            <MoreInfoPopOver
              title="Customize Your View"
              description="Configure the information that displays on the model inventory. Changes are automatically saved and will not affect other users."
              link="https://docs.validmind.ai/guide/model-inventory/customize-model-inventory-layout.html"
              placement="right-end"
              iconProps={{
                ml: -2,
              }}
            />
          </HStack>
          <PopoverCloseButton size={'md'} m={2} />
          <PopoverBody>
            <VStack w={'full'} gap={0.5}>
              <SortableChecklist
                selectedIds={selectedColumns}
                options={[...allColumns]}
                setSelectedIds={(newList: string[]) => {
                  setColumns(newList);
                }}
              />
              {/* <CheckboxGroup colorScheme="brand" value={selectedColumnIds}>
                    <Stack spacing={2}>
                      {allColumns.map((col: Column) => (
                        <Checkbox
                          value={col.id}
                          onChange={e => {
                            const newSelectedColumns = [...selectedColumnIds];
                            const value = e.target.value;
                            const existingIndex =
                              newSelectedColumns.indexOf(value);

                            // We're unchecking the checkbox
                            if (existingIndex >= 0) {
                              newSelectedColumns.splice(existingIndex, 1);
                            } else {
                              newSelectedColumns.push(value);
                            }
                            setColumns(newSelectedColumns);
                          }}
                        >
                          {col.label}
                        </Checkbox>
                      ))}
                    </Stack>
                  </CheckboxGroup> */}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}
