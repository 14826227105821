import { useContext, useMemo, useState } from 'react';
import { TInventoryModel } from '../../../models/inventory_model';
import SidebarContext from '../../../contexts/SidebarContext';
import { Sidebar } from '../../Sidebar';
import { Flex, useColorModeValue } from '@chakra-ui/react';

const AppSidebarLayout = ({ children }: any) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [inventoryModel, setInventoryModel] = useState<TInventoryModel>();
  const [inSettings, setInSettings] = useState(false);
  const { SIDEBAR_SIZES } = useContext(SidebarContext);

  const sidebarContextMemo = useMemo(
    () => ({
      SIDEBAR_SIZES,
      isExpanded,
      setIsExpanded,
      inventoryModel,
      setInventoryModel,
      inSettings,
      setInSettings,
    }),
    [
      SIDEBAR_SIZES,
      isExpanded,
      setIsExpanded,
      inventoryModel,
      setInventoryModel,
      inSettings,
      setInSettings,
    ],
  );

  return (
    <SidebarContext.Provider value={sidebarContextMemo}>
      <Sidebar />
      <Flex
        ml={SIDEBAR_SIZES[isExpanded ? 0 : 1]}
        transition="margin .2s"
        height="100vh"
        overflow={'auto'}
        bg={useColorModeValue('white', 'black')}
        className="no-scrollbar"
      >
        {children}
      </Flex>
    </SidebarContext.Provider>
  );
};

export default AppSidebarLayout;
