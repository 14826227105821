import { Box, Checkbox, Heading, SimpleGrid, Stack } from '@chakra-ui/react';

const StyleGuideCheckbox = ({ sizes }: { sizes: string[] }) => {
  sizes = sizes.filter(s => s !== 'xs' && s !== 'sm');
  return (
    <Box>
      <Heading as={'h2'}>Checkbox</Heading>
      <Stack gap={4}>
        <SimpleGrid gap={4} columns={1}>
          {sizes.map(size => (
            <Checkbox size={size}>Checkbox {size}</Checkbox>
          ))}
        </SimpleGrid>
      </Stack>
    </Box>
  );
};

export default StyleGuideCheckbox;
