import {
  Icon,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
} from '@chakra-ui/react';
import { TRole } from '../../models';
import { PlusIcon, UserIcon } from '@heroicons/react/24/outline';
import { getRoleUIProps } from '../../utils';

interface RolePillProps {
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onAdd?: React.MouseEventHandler<HTMLButtonElement>;
  role: TRole;
}

export default function RolePill({ role, onClose, onAdd }: RolePillProps) {
  const roleUIProps = getRoleUIProps(role.name);
  const roleIcon = roleUIProps.icon;
  const colorScheme = roleUIProps.colorScheme;
  return (
    <Tag
      variant={onClose || onAdd ? 'outline' : 'solid'}
      colorScheme={colorScheme}
      data-testid="role-pill"
    >
      {<TagLeftIcon as={roleIcon || UserIcon} boxSize={5} />}
      <TagLabel p={0} m={0} pointerEvents={'none'}>
        {role.name}
      </TagLabel>
      {onClose && <TagCloseButton onClick={onClose} boxSize={5} />}
      {onAdd && (
        <TagCloseButton onClick={onAdd}>
          <Icon as={PlusIcon} boxSize={5} />
        </TagCloseButton>
      )}
    </Tag>
  );
}
