import { Box } from '@chakra-ui/react';

interface ModelsUsingThisTemplateProps {
  selectedTemplateId: string;
}
export default function ModelsUsingThisTemplate({
  selectedTemplateId,
}: ModelsUsingThisTemplateProps) {
  // TODO: Implement this hook with models
  // const {
  //   projectResults: { results: projectsWithTemplate = [] } = {},
  //   isLoading,
  // } = useProjects({
  //   filters: { template_cuid: selectedTemplateId },
  //   enabled: !!selectedTemplateId,
  // });

  return (
    <Box>
      {/* <Label>MODELS USING THIS TEMPLATE</Label> */}
      {/* <LoadingContainer isLoading={isLoading}>
        <Box h={'20rem'} overflow={'scroll'}>
          {projectsWithTemplate.length === 0 ? (
            <Text color="neutral.500" fontStyle={'italic'}>
              This template is not being used by any models.
            </Text>
          ) : (
            <UnorderedList spacing={2} my={2}>
              {projectsWithTemplate.map(p => (
                <ListItem key={p.cuid}>
                  <Link
                    as={RouterLink}
                    to={`/model-inventory/${p.inventory_model.cuid}`}
                  >
                    {p.name}
                  </Link>
                </ListItem>
              ))}
            </UnorderedList>
          )}
        </Box>
      </LoadingContainer> */}
    </Box>
  );
}
