import {
  Box,
  Flex,
  HStack,
  Icon,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import WorkflowApprovalActions from '../../components/WorkflowApprovalActions';
import { ShieldCheckIcon } from '@heroicons/react/24/outline';
import { ContentPageTitle } from '../../components/Layout';

type Props = {};
export default function Approvals({}: Props) {
  return (
    <Box
      py={10}
      px={8}
      flex={1}
      w={'full'}
      overflow={'auto'}
      className="no-scrollbar"
      bg={useColorModeValue('white', 'black')}
    >
      <Stack>
        <HStack>
          <Flex width={'full'} justify={'space-between'}>
            <HStack gap={5} pl={2} color={'inherit'}>
              <Icon as={ShieldCheckIcon} boxSize={10} />
              <ContentPageTitle>Approvals</ContentPageTitle>
            </HStack>
          </Flex>
        </HStack>

        <WorkflowApprovalActions variant={'page'} voterStatus={'pending'} />
      </Stack>
    </Box>
  );
}
