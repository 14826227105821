import { Layout } from 'react-grid-layout';

export const GRID_COLUMN_AMOUNT = 4;

export const DEFAULT_DASHBOARD_LAYOUTS = {
  version: 1,
  dashboard: {
    lg: [
      {
        i: 'finding-summary',
        x: 0,
        y: 0,
        w: 2,
        h: 8,
      },
      {
        i: 'recent-activity',
        x: 2,
        y: 0,
        w: 2,
        h: 8,
      },
    ],
  },
};

export const AddToEndOfLayout = (
  layouts: Layout[],
  layoutToAdd: { i: string; w: number; h: number },
) => {
  let x = 0;
  let y = 0;

  // Find highest y value
  const highestY = layouts.reduce((acc, layout) => {
    return layout.y > acc ? layout.y : acc;
  }, 0);

  // in those layouts, find the highest x value
  const highestX = layouts
    .filter(layout => layout.y === highestY)
    .reduce((acc, layout) => {
      return layout.x > acc ? layout.x : acc;
    }, 0);

  // Find the layout with the highest x and y
  const lastLayout = layouts.find(
    layout => layout.x === highestX && layout.y === highestY,
  )!;

  if (!lastLayout) {
    layouts.push({ ...layoutToAdd, x, y });
    return;
  }

  const lastLayoutWidth = lastLayout.x + lastLayout.w;

  if (lastLayoutWidth + layoutToAdd.w > GRID_COLUMN_AMOUNT) {
    x = 0;
    y = lastLayout.y + lastLayout.h;
  } else {
    x = lastLayout.x + lastLayout.w;
    y = lastLayout.y;
  }

  layouts.push({ ...layoutToAdd, x, y });
};

export const PrependLayouts = (
  sourceLayouts: Layout[],
  destinationLayouts: Layout[],
): Layout[] => {
  const newLayout = { lg: [] } as any;

  sourceLayouts.forEach(layout => {
    AddToEndOfLayout(newLayout.lg, layout);
  });

  destinationLayouts.forEach(layout => {
    AddToEndOfLayout(newLayout.lg, layout);
  });

  return newLayout.lg;
};
