import { Box, Heading, SimpleGrid, VStack, Select } from '@chakra-ui/react';

const StyleGuideSelect = ({ sizes }: { sizes: string[] }) => {
  const variants = ['outline', 'unstyled', 'flushed', 'filled'];
  return (
    <Box>
      <Heading as={'h2'}>Select</Heading>
      <SimpleGrid columns={2} gap={12}>
        {sizes.reverse().map(size => (
          <VStack>
            {variants.map(variant => (
              <Select
                variant={variant}
                size={size}
                placeholder={`${variant} ${size}`}
              />
            ))}
          </VStack>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default StyleGuideSelect;
