import { useContext, useState } from 'react';
import Highlight from 'react-highlight';
import { CONFIG } from '../../../config';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { Label, MainContentArea } from '../../../components/Layout';
import ContentContainer from '../../../components/Layout/ContentContainer';
import { SubsectionHeader } from '../../../components/SubsectionHeader';
import {
  Box,
  HStack,
  Heading,
  Link,
  Stack,
  Text,
  VStack,
  Switch,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { LoadingContainer } from '../../../components/LoadingContainer';
import { Copyright } from '../../../components/Copyright';
import UsersContext from '../../../contexts/UsersContext';
import MasterSearchBar from '../../../components/Layout/MasterSearchBar';
import { Icon } from '@chakra-ui/icons';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { TInventoryModel } from '../../../models/inventory_model';

const onCopyToClipboard = async (e: any) => {
  if (!navigator.clipboard) {
    console.log('Update your browser to use the clipboard');
    return;
  }
  await navigator.clipboard.writeText(e.currentTarget.value);
  const btn = e.target as HTMLElement;
  const normalLabel = btn.innerHTML;
  btn.textContent = 'Copied!';
  btn.classList.replace('text-primary', 'text-green-600');
  btn.classList.add('animate-pulse');
  btn.blur();
  setTimeout(() => {
    btn.innerHTML = normalLabel;
    btn.classList.remove('animate-pulse');
    btn.classList.replace('text-green-600', 'text-primary');
  }, 2000);
};

interface GettingStartedProps {
  inventoryModel?: TInventoryModel;
  containerSize?: string;
  showSearchBar?: boolean;
}

export default function GettingStarted({
  inventoryModel,
  containerSize,
  showSearchBar = true,
}: GettingStartedProps) {
  const modelParameter = inventoryModel
    ? inventoryModel.cuid
    : '<model-identifier>';
  const { currentUser, currentOrganization } = useContext(UsersContext);
  const [includeMonitoring, setIncludeMonitoring] = useState(false);

  const getInitFrameworkSnippet = (
    hideValues: boolean,
    includeMonitoring: boolean,
  ) => {
    const monitoringFlag = includeMonitoring
      ? `,
  monitoring = True`
      : '';

    const initSdkSnippet = `import validmind as vm

vm.init(
  api_host = "${CONFIG.REACT_APP_API_URL}/tracking",
  api_key = "${
    currentUser?.api_clients.find(
      apiClient => apiClient.organization_cuid === currentOrganization?.cuid,
    )?.api_key
  }",
  api_secret = "${
    hideValues
      ? 'API_SECRET'
      : currentUser?.api_clients.find(
          apiClient =>
            apiClient.organization_cuid === currentOrganization?.cuid,
        )?.api_secret
  }",
  model = "${modelParameter}"${monitoringFlag}
)`;
    return initSdkSnippet;
  };

  return (
    <LoadingContainer isLoading={!currentUser}>
      <MainContentArea gap={4}>
        <Box mx={10}>
          <VStack w={'full'}>
            <HStack w={'full'}>{showSearchBar && <MasterSearchBar />}</HStack>
            <SubsectionHeader
              pageId="getting_started"
              moreInfo={
                <MoreInfoPopOver
                  title="ValidMind Library"
                  description="The library helps you streamline model documentation by automating the generation of drafts."
                  link="https://docs.validmind.ai/developer/get-started-developer-framework.html"
                  placement="right-start"
                  iconProps={{
                    ml: 2,
                  }}
                />
              }
            />
          </VStack>
          <ContentContainer containerSize={containerSize}>
            <Stack gap={4}>
              <Text>
                ValidMind generates a unique <i>code snippet</i> for each
                registered model to connect with your developer environment. For
                example, you can use the code snippet shown here with a Jupyter
                notebook to upload documentation for this model.
              </Text>
              <Heading as="h3">Copy your code snippet</Heading>
              <Text>
                Use the button to copy your code snippet, including the{' '}
                <code>API_SECRET</code>:
              </Text>
            </Stack>

            <Box
              rounded="md"
              bg={useColorModeValue('neutral.100', 'neutral.200')}
              p={6}
            >
              <Highlight className="code-snippet">
                {getInitFrameworkSnippet(true, includeMonitoring)}
              </Highlight>
              <Box textAlign="right">
                <Button
                  value={getInitFrameworkSnippet(false, includeMonitoring)}
                  leftIcon={<Icon as={DocumentDuplicateIcon} boxSize={5} />}
                  onClick={onCopyToClipboard}
                >
                  Copy snippet to clipboard
                </Button>
              </Box>
            </Box>

            <Stack>
              <HStack
                border="1px solid #E2E8F0"
                borderRadius="md"
                p={4}
                spacing={4}
                alignItems={'center'}
              >
                <Stack>
                  <HStack>
                    <Label>ENABLE ONGOING MONITORING? </Label>
                    <MoreInfoPopOver
                      title="Ongoing Monitoring"
                      description="Learn more about ongoing monitoring and how to enable it."
                      link="https://docs.validmind.ai/guide/monitoring/ongoing-monitoring.html"
                      placement="right-start"
                      iconProps={{
                        ml: 2,
                      }}
                    />
                  </HStack>

                  <HStack alignItems="center">
                    <Switch
                      colorScheme="blue"
                      isChecked={includeMonitoring}
                      onChange={() => setIncludeMonitoring(!includeMonitoring)}
                    />
                    <Text>
                      Updates your code snippet to collect ongoing monitoring
                      data in a template
                    </Text>
                  </HStack>
                </Stack>
              </HStack>
            </Stack>
            <Stack gap={4}>
              <Heading as="h3">Set up your developer environment</Heading>
              <Text>
                Install the client library for the ValidMind Developer
                Framework:
              </Text>
            </Stack>

            <Box
              rounded="md"
              bg={useColorModeValue('neutral.100', 'neutral.200')}
              p={6}
            >
              <Highlight className="code-snippet">
                {CONFIG.SDK_PIP_INSTALL_COMMAND}
              </Highlight>
            </Box>

            <Stack spacing={4}>
              <Text>
                Next, paste the code snippet into your own source code and run
                it, or try it with a{' '}
                <Link
                  href="https://docs.validmind.ai/developer/samples-jupyter-notebooks.html"
                  target="_blank"
                  fontWeight="bold"
                >
                  code sample
                </Link>
                .
              </Text>

              <Text>
                After the client library has connected with ValidMind, you can
                begin documenting and testing your models. Your uploads are
                available under{' '}
                <Link href="documentation" fontWeight="bold">
                  Documentation
                </Link>
                .
              </Text>
              {/* <Text>
                <Link
                  href="https://docs.validmind.ai/developer/get-started-developer-framework.html"
                  target="_blank"
                  fontWeight="bold"
                  isExternal
                  color={'brand.base'}
                >
                  Learn more ...
                  <ExternalLinkIcon mx="2px" />
                </Link>
              </Text> */}
            </Stack>
          </ContentContainer>
          <Copyright />
        </Box>
      </MainContentArea>
    </LoadingContainer>
  );
}
