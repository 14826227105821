import useTestResult from '../../../hooks/useTestResult';
import _ from 'lodash';
import ContentRowLayout from '../ContentRowLayout';
import { CodeBracketSquareIcon } from '@heroicons/react/24/outline';
import {
  FailedBadge,
  PassedBadge,
  TestResultContent,
} from '../../Templates/ModelDocumentation/TestResultContent';
import { ModelDocumentTypeEnum } from '../../../models/model_document';
import { Box, Spacer, Text } from '@chakra-ui/react';
import { ContentRowProps } from '../index';
import { AxiosError } from 'axios';

function ContentRowTest({
  content,
  documentType,
  isChecked = false,
  onCheck,
  onRemoveContent,
}: ContentRowProps) {
  const responseFields = ['passed', 'test_name'];
  const { testResult, isLoading, isError, error } = useTestResult(
    content.content_id,
    documentType,
    true,
    responseFields,
  );

  const { content_id, options = {} } = content;
  let { title } = options;
  let testTitle = title || content_id || '';
  // Grab the last part of the test ID since it's a namespaced ID, e.g. validmind.data_validation.TestNmae
  title = testTitle.split('.').pop();
  title = _.startCase(title);

  const notFound =
    !testResult && isError && (error as AxiosError)?.response?.status === 404;

  return (
    <ContentRowLayout
      icon={CodeBracketSquareIcon}
      onCheck={onCheck ? () => onCheck(content) : undefined}
      isChecked={notFound ? false : isChecked}
      details={{
        content: (
          <TestResultContent
            readOnly={true}
            contents={{
              content_id: content.content_id,
              content_type: content.content_type,
            }}
            documentType={ModelDocumentTypeEnum.model_documentation}
            overrideDocumentType={ModelDocumentTypeEnum.model_documentation}
          />
        ),
      }}
      onRemoveContent={onRemoveContent}
      disabled={notFound}
    >
      <Text fontWeight={'bold'} fontStyle={notFound ? 'oblique' : 'normal'}>
        {title}
      </Text>
      <Text
        fontSize={'sm'}
        fontWeight={'normal'}
        fontStyle={notFound ? 'oblique' : 'normal'}
      >
        {content_id}
      </Text>
      {testResult && <Spacer />}
      {testResult && (
        <Box>
          {testResult?.passed === null ? (
            ''
          ) : testResult?.passed ? (
            <PassedBadge />
          ) : (
            <FailedBadge />
          )}
        </Box>
      )}
    </ContentRowLayout>
  );
}

export default ContentRowTest;
