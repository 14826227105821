import React, { useContext, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  DocumentMagnifyingGlassIcon,
  EllipsisVerticalIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import ContentRowDetailsModal from '../ContentRowDetailsModal';
import ConfirmationAlert from '../../ConfirmationAlert';
import { DangerMenuItem } from '../../DangerMenuItem';
import { isInventoryModelValidator } from '../../../auth/utils';
import UsersContext from '../../../contexts/UsersContext';
import InventoryModelContext from '../../../contexts/InventoryModel';

interface ContentRowLayoutProps {
  icon: any;
  isChecked?: boolean;
  onCheck?: Function;
  details?: {
    content: React.ReactNode;
  };
  children: React.ReactNode;
  onRemoveContent?: Function;
  disabled?: boolean;
}

function ContentRowLayout({
  icon,
  isChecked,
  onCheck,
  details,
  children,
  onRemoveContent,
  disabled = false,
}: ContentRowLayoutProps) {
  const [showDetails, setShowDetails] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { currentUser } = useContext(UsersContext);
  const { inventoryModel } = useContext(InventoryModelContext);

  const onConfirmDelete = (confirmed: boolean) => {
    if (onRemoveContent && confirmed) {
      onRemoveContent();
    }
    setConfirmDelete(false);
  };

  const canRemoveEvidence = useMemo(() => {
    return isInventoryModelValidator(currentUser!, inventoryModel);
  }, [currentUser, inventoryModel]);

  return (
    <>
      <HStack gap={4} w={'full'}>
        {onCheck && (
          <Checkbox
            disabled={disabled}
            isChecked={isChecked}
            onChange={e => {
              e.stopPropagation();
              onCheck();
            }}
          />
        )}
        <HStack
          gap={2}
          w={'full'}
          role="group"
          _hover={{
            bg: useColorModeValue('brandSecondary.25', 'brandSecondary.950'),
          }}
          onDoubleClick={() => !onCheck && setShowDetails(true)}
        >
          <Icon as={icon} boxSize={6} />
          <HStack w={'full'} gap={1} alignItems={'center'}>
            {children}
            {!onCheck && (
              <Box
                visibility={'hidden'}
                _groupHover={{
                  visibility: 'visible',
                }}
              >
                <Menu>
                  <MenuButton
                    as={IconButton}
                    variant="ghost"
                    aria-label="Options"
                    icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                  />
                  <MenuList>
                    {details && (
                      <MenuItem
                        icon={
                          <Icon as={DocumentMagnifyingGlassIcon} boxSize={5} />
                        }
                        onClick={() => setShowDetails(true)}
                      >
                        View Details
                      </MenuItem>
                    )}

                    {onRemoveContent && canRemoveEvidence && (
                      <DangerMenuItem
                        icon={<Icon as={TrashIcon} boxSize={5} />}
                        onClick={() => setConfirmDelete(true)}
                      >
                        Remove Evidence
                      </DangerMenuItem>
                    )}
                  </MenuList>
                </Menu>
              </Box>
            )}
          </HStack>
        </HStack>
      </HStack>

      {details && (
        <ContentRowDetailsModal
          isOpen={showDetails}
          onClose={() => setShowDetails(false)}
          content={details?.content}
        />
      )}

      <ConfirmationAlert
        title={'Remove Linked Evidence?'}
        dialogBody={
          'Are you sure you would like to remove the linked evidence from the report?'
        }
        open={confirmDelete}
        onConfirm={onConfirmDelete}
      />
    </>
  );
}

export default ContentRowLayout;
