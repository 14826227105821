import { useCallback, useContext, useEffect, useState } from 'react';
import RecordDetailsPage from '../../../../components/Layout/RecordDetailsPage';
import {
  Box,
  FormControl,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
  VStack,
  Select,
  Link,
  Icon,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Tag,
} from '@chakra-ui/react';

import InventoryModelContext from '../../../../contexts/InventoryModel';
import MasterHeader from '../../../../components/Layout/MasterHeader';
import { Label } from '../../../../components/Layout';
import MasterStatusBar from '../../../../components/Layout/MasterStatusBar';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import API from '../../../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import ModelInventoryItem from '../../../../components/ModelInventoryItem';
import FindingsWidget from '../../../../components/FindingsWidget';
import ActivityFeedWidget from '../../../../components/ActivityFeedWidget';
import {
  InventoryModelStages,
  TInventoryModel,
  TInventoryModelDependency,
  TInventoryModelUser,
  TInventoryModelUserPost,
} from '../../../../models/inventory_model';
import DependenciesFlow from './DependenciesFlow';
import { Edge, MarkerType, Node } from 'reactflow';
import { EventFilters } from '../../../../models/event';
import { Copyright } from '../../../../components/Copyright';
import { canUpdateInventoryModel } from '../../../../auth/utils';
import MasterSearchBar from '../../../../components/Layout/MasterSearchBar';
import UsersContext from '../../../../contexts/UsersContext';
import AttributesRail from '../../../../components/Layout/AttributesRail';
import CoreField from '../../../../components/CustomFields/CoreField';
import WorkflowExecutionsViewer from '../../../../components/WorkflowExecutionsViewer';
import WorkflowApprovalActions from '../../../../components/WorkflowApprovalActions';
import WorkflowStatusActions from '../../../../components/WorkflowStatusActions';
import { FieldValues } from '../../../../hooks/useFields';
import ManagedCustomField from '../../../../components/NewCustomFields/ManagedCustomField';
import useModelSchema from '../../../../hooks/useModelSchema';
import { SchemaPropertyItem } from '../../../../models/json_schemas';
import ManagedField from '../../../../components/ManagedField';
import _ from 'lodash';
import { EmptyStateDisplay } from '../../../../components/EmptyStateDisplay';
import { useGroups } from '../../../../hooks/useGroups';
import EditableField from '../../../../components/EditableField';
import { TGroup, TRole } from '../../../../models';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { DependencyManagerModal } from './DependenciesFlow/DependencyManagerModal';
import { InfoIcon } from '@chakra-ui/icons';
import ManageAttachmentsField from '../../../../components/ManageAttachmentsField';
import ModelStageField from '../../../../components/ModelStageField';
import MoreInfoPopOver from '../../../../components/MoreInfoPopOver';
import { removeAfterDot } from '../../../../utils';
import AvatarProxy from '../../../../components/AvatarProxy';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

interface InventoryModelDependenciesItemProps {
  dependency: TInventoryModelDependency;
}

function InventoryModelDependenciesItem({
  dependency,
}: InventoryModelDependenciesItemProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { data: inventoryModel, isLoading } = useQuery(
    ['model-inventory', dependency.depends_on.cuid],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetInventoryModel(accessToken, dependency.depends_on.cuid);
    },
    {
      onError: err => {
        // track errors
      },
    },
  );

  if (inventoryModel) {
    return <ModelInventoryItem inventoryModel={inventoryModel} layout="row" />;
  }

  return null;
}

function InventoryModelDependencies() {
  const { inventoryModel } = useContext(InventoryModelContext);
  const [initialNodes, setInitialNodes] = useState<Node[]>([]);
  const [initialEdges, setInitialEdges] = useState<Edge[]>([]);

  useEffect(() => {
    const deps = inventoryModel?.dependencies || [];

    const _initialNodes: Node[] = [];
    const _initialEdges: Edge[] = [];
    _initialNodes.push({
      id: inventoryModel?.cuid!,
      position: { x: 0, y: 0 },
      data: { label: `${inventoryModel?.name!}` },
      className: 'current-model-in-dependency',
      style: {
        fontFamily: 'inherit',
        backgroundColor: 'var(--chakra-colors-neutral-100)',
        color: 'var(--chakra-colors-neutral-700)',
        borderColor: 'var(--chakra-colors-brand-base)',
        fontWeight: 'bold',
        borderRadius: 8,
        borderWidth: 2,
      },
    });

    // inserts upstream dependencies, connecting to this model
    deps
      .filter(d => d.category === 'upstream')
      .map(d => {
        _initialNodes.push({
          id: d.depends_on.cuid,
          position: { x: 0, y: 0 },
          data: { label: d.depends_on.name },
          style: {
            backgroundColor: 'var(--chakra-colors-sky-200)',
            color: 'var(--chakra-colors-sky-800)',
            borderColor: 'var(--chakra-colors-sky-500)',
            fontWeight: 'bold',
            borderRadius: 8,
          },
        });
        _initialEdges.push({
          source: d.depends_on.cuid,
          target: inventoryModel?.cuid!,
          id: d.cuid,
          label: 'Upstream',
          style: {
            stroke: 'var(--chakra-colors-sky-500)',
            strokeDasharray: '5, 5',
            strokeWidth: 2,
          },
          markerEnd: {
            type: MarkerType.Arrow,
            color: 'var(--chakra-colors-sky-500)',
          },
        });
      });

    // inserts downstream dependencies, connecting from this model
    deps
      .filter(d => d.category === 'downstream')
      .map(d => {
        _initialNodes.push({
          id: d.depends_on.cuid,
          position: { x: 0, y: 0 },
          data: { label: d.depends_on.name },
          style: {
            backgroundColor: 'var(--chakra-colors-purple-200)',
            color: 'var(--chakra-colors-purple-800)',
            fontWeight: 'bold',
            borderColor: 'var(--chakra-colors-purple-500)',
            borderRadius: 8,
          },
        });
        _initialEdges.push({
          source: inventoryModel?.cuid!,
          target: d.depends_on.cuid,
          id: d.cuid,
          label: 'Downstream',
          style: {
            stroke: 'var(--chakra-colors-purple-500)',
            strokeDasharray: '5, 5',
            strokeWidth: 2,
          },
          markerEnd: {
            type: MarkerType.Arrow,
            color: 'var(--chakra-colors-purple-500)',
          },
        });
      });

    setInitialNodes(_initialNodes);
    setInitialEdges(_initialEdges);
  }, [inventoryModel?.dependencies]);

  // inserts this model, the center of the dependency graph
  // const within_deps_label = deps
  //   .filter(d => d.category === 'within')
  //   .map(d => d.depends_on.name)
  //   .join(',');

  return (
    <Stack>
      {initialNodes.length > 0 && (
        <Box
          width={'full'}
          height={300}
          border={'1px solid'}
          borderColor={'neutral.200'}
          borderRadius={8}
          bg={'white'}
          mt={4}
        >
          <DependenciesFlow
            key={inventoryModel?.cuid}
            initialNodes={initialNodes}
            initialEdges={initialEdges}
          />
        </Box>
      )}

      <Stack>
        {inventoryModel?.dependencies.map(dep => (
          <InventoryModelDependenciesItem key={dep.cuid} dependency={dep} />
        ))}
      </Stack>
    </Stack>
  );
}

interface InventoryModelOverviewProps {}

export interface DeleteInventoryModelUserVariables {
  inventoryModel: TInventoryModel;
  inventoryModelUser: TInventoryModelUser;
}

export interface CreateInventoryModelUserVariables {
  inventoryModel: TInventoryModel;
  inventoryModelUserPost: TInventoryModelUserPost;
}

interface ResetWorkflowConfirmationModal {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

interface IInventoryModelUser {
  email: string;
  name: string;
  picture: string;
  role?: TRole;
}

export const StakeholderItem = ({ user }: { user?: IInventoryModelUser }) => {
  return (
    <HStack>
      {user ? (
        <AvatarProxy name={user.name} src={user.picture} size={'sm'} />
      ) : (
        <Icon as={ExclamationCircleIcon} boxSize={5} color={'neutral.500'} />
      )}

      <VStack align={'start'} spacing={0}>
        <Text>
          {user ? (
            <Text>{user.name}</Text>
          ) : (
            <Text color={'neutral.500'}>Unassigned</Text>
          )}
        </Text>
        <Text fontSize="sm" margin={'0px'}>
          {user?.role?.name}
        </Text>
      </VStack>
    </HStack>
  );
};

const ResetWorkflowConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
}: ResetWorkflowConfirmationModal) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reset Workflow</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <VStack align="flex-start">
            <Text color="red">
              Warning: This action will reset the workflow for this model and
              set it to its initial state.
            </Text>
            <Text>
              Are you sure you want to reset the workflow for this model?
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button isDisabled={isLoading} variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            colorScheme="brand"
            onClick={async () => {
              setIsLoading(true);
              try {
                await onConfirm();
              } finally {
                setIsLoading(false);
              }
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default function InventoryModelOverview({}: InventoryModelOverviewProps) {
  const resetWorkflowModal = useDisclosure();
  const { getAccessTokenSilently } = useAuth0();
  const { currentUser, currentOrganization } = useContext(UsersContext);
  const { inventoryModel, userHasInventoryModelPermission } = useContext(
    InventoryModelContext,
  );
  const { propertyItems } = useModelSchema();
  const queryClient = useQueryClient();
  const { groups } = useGroups();

  const isAdmin = currentUser?.roles.some(role => role.role.is_admin);

  const [group, setGroup] = useState<TGroup | undefined>(inventoryModel?.group);

  useEffect(() => {
    setGroup(inventoryModel?.group);
  }, [inventoryModel?.group.cuid]);

  const invalidateInventoryModelQueries = useCallback(
    (inventoryModel: TInventoryModel) => {
      queryClient.invalidateQueries(['inventory-model', inventoryModel.cuid]);
    },
    [queryClient],
  );

  const deleteInventoryModelUser = useMutation(
    async (variables: DeleteInventoryModelUserVariables) => {
      const accessToken = await getAccessTokenSilently();
      const { inventoryModel, inventoryModelUser } = variables;
      return API.DeleteInventoryModelUser(
        accessToken,
        inventoryModel,
        inventoryModelUser,
      );
    },
    {
      onSuccess: (data, { inventoryModel }) => {
        invalidateInventoryModelQueries(inventoryModel);
      },
    },
  );

  // useMutation to use API.CreateInventoryModelUser
  const createInventoryModelUser = useMutation(
    async (variables: CreateInventoryModelUserVariables) => {
      const accessToken = await getAccessTokenSilently();
      const { inventoryModel, inventoryModelUserPost } = variables;
      return API.PostInventoryModelUser(
        accessToken,
        inventoryModel,
        inventoryModelUserPost,
      );
    },
    {
      onSuccess: (data, { inventoryModel }) => {
        invalidateInventoryModelQueries(inventoryModel);
      },
    },
  );

  const roleNameMapping: Record<string, string> = {
    'Model Developer': 'Developers',
    'Model Validator': 'Validators',
    'Model Owner': 'Owners',
    Owners: 'Model Owner',
    Validators: 'Model Validator',
    Developers: 'Model Developer',
  };

  const updateUsers = (
    userCUIDs: string[],
    existingUsers: TInventoryModelUser[],
    label: string,
  ): Promise<any[]> => {
    const existingUsersCUIDs = existingUsers.map(u => u.user.cuid);
    const newUsers = _.difference(
      userCUIDs,
      existingUsers.map(u => u.user.cuid),
    );
    const removedUsers = _.difference(existingUsersCUIDs, userCUIDs);

    const promises: Promise<any>[] = [];

    if (inventoryModel) {
      newUsers.forEach(cuid => {
        promises.push(
          createInventoryModelUser.mutateAsync({
            inventoryModel,
            inventoryModelUserPost: {
              user: { cuid: cuid },
              name: roleNameMapping[label] || label,
            },
          }),
        );
      });

      removedUsers.forEach(cuid => {
        const inventoryModelUser = existingUsers.find(
          u => cuid === u.user.cuid,
        );
        if (inventoryModelUser) {
          promises.push(
            deleteInventoryModelUser.mutateAsync({
              inventoryModel,
              inventoryModelUser,
            }),
          );
        }
      });
    }
    return Promise.all(promises);
  };

  const eventFilters: EventFilters = {
    inventory_models: [inventoryModel?.cuid!],
  };

  const roleUsers = (roleName: string) => {
    if (inventoryModel) {
      return inventoryModel.users.filter(u => u.role.name === roleName);
    }
    return [];
  };

  const isReadOnlyField = (key: string) => {
    const canUpdateField =
      isAdmin || userHasInventoryModelPermission(['update_cf_' + key], 'any');
    return !canUpdateField;
  };

  const readOnlyFields =
    !canUpdateInventoryModel(
      currentUser!,
      inventoryModel!.users.filter(u =>
        ['Model Owner', 'Model Validator'].includes(u.name),
      ),
    ) || inventoryModel?.stage !== InventoryModelStages.ACTIVE;

  const sortByTitle = (a: any, b: any) => {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  };

  const sidebarCustomFields: SchemaPropertyItem[] = [];
  let mainCustomFields: SchemaPropertyItem[] = [];

  propertyItems.sort(sortByTitle).forEach(propertyItem => {
    if (
      propertyItem.typeId === 'string:multi-line' ||
      propertyItem.typeId === 'array:attachments'
    ) {
      mainCustomFields.push(propertyItem);
    } else {
      sidebarCustomFields.push(propertyItem);
    }
  });

  // Separate attachment fields from other fields
  const attachmentFields = mainCustomFields.filter(
    field => field.typeId === 'array:attachments',
  );
  const nonAttachmentFields = mainCustomFields.filter(
    field => field.typeId !== 'array:attachments',
  );

  // Combine the sorted fields with attachment fields at the end
  mainCustomFields = [...nonAttachmentFields, ...attachmentFields];

  const roleNameOrder: Record<string, number> = {
    'Model Owner': 1,
    'Model Developer': 2,
    'Model Validator': 3,
  };

  const roleControls = currentOrganization?.roles
    .filter(r => r.scope === 'Model')
    .map(r => ({
      label: roleNameMapping[r.name] || r.name,
      sequence: roleNameOrder[r.name] || 0,
      existingUsers: roleUsers(r.name),
    }))
    .sort(function (a, b) {
      return a.sequence - b.sequence;
    });

  const getVendorNames = useCallback<() => Promise<string[]>>(async () => {
    const accessToken = await getAccessTokenSilently();
    const res = await API.GetModelInventory(
      accessToken,
      undefined,
      undefined,
      1,
      10000,
    );
    return Promise.resolve(
      _.chain(res.results)
        .filter(model => !!model.is_vendor_model)
        .map(model => model.vendor_name!)
        .compact()
        .uniq()
        .sort()
        .value(),
    );
  }, []);

  return (
    <>
      <ResetWorkflowConfirmationModal
        isOpen={resetWorkflowModal.isOpen}
        onClose={resetWorkflowModal.onClose}
        onConfirm={async () => {
          const accessToken = await getAccessTokenSilently();
          await API.ResetWorkflow(
            accessToken,
            'InventoryModel.created',
            inventoryModel!.cuid,
          );
          resetWorkflowModal.onClose();
          // Reload the page
          window.location.reload();
        }}
      />
      <RecordDetailsPage
        right={
          <AttributesRail>
            <VStack alignItems={'flex-start'} gap={1}>
              <Icon
                as={InfoIcon}
                color={useColorModeValue('neutral.500', 'neutral.200')}
                mt={1}
              />
              <Text>
                Need help editing this page?
                <br />
                <Link
                  isExternal
                  href={
                    'https://docs.validmind.ai/guide/model-inventory/edit-model-inventory-fields.html'
                  }
                  target="_blank"
                >
                  Open documentation
                  <Icon ml={1} boxSize={3} as={ArrowTopRightOnSquareIcon} />
                </Link>
              </Text>
            </VStack>
            <VStack align="start" spacing={0} w={'full'}>
              {inventoryModel && (
                <CoreField
                  inventoryModel={inventoryModel}
                  propertyKey={'model_id'}
                  readOnly={readOnlyFields}
                />
              )}
            </VStack>
            <VStack align="start" spacing={0} w={'full'}>
              {inventoryModel && (
                <CoreField
                  inventoryModel={inventoryModel}
                  propertyKey={'tiering'}
                  readOnly={readOnlyFields}
                />
              )}
            </VStack>

            {inventoryModel?.version && (
              <VStack align="start" spacing={0}>
                <Label>Version</Label>
                <Text as={'pre'}>{inventoryModel?.version}</Text>
              </VStack>
            )}

            {inventoryModel && (
              <>
                <FormControl role="group">
                  <VStack alignItems={'flex-start'}>
                    <HStack gap={0} w="full">
                      <ModelStageField
                        inventoryModel={inventoryModel}
                        onSave={() =>
                          invalidateInventoryModelQueries(inventoryModel)
                        }
                        readOnly={!isAdmin}
                      />
                    </HStack>
                  </VStack>
                </FormControl>

                <FormControl role="group">
                  <VStack alignItems={'flex-start'}>
                    <HStack gap={0} w="full">
                      <EditableField
                        readOnly={readOnlyFields}
                        saveButtonLabel={isAdmin ? 'Reset Workflow' : ''}
                        moreInfoPopoverProps={{
                          title: 'Model Status',
                          description:
                            'Manage lifecycle processes using workflows and transitions.',
                          link: 'https://docs.validmind.ai/guide/model-workflows/working-with-model-workflows.html',
                          placement: 'right-end',
                          iconProps: {
                            ml: 2,
                            opacity: 0,
                            _groupHover: { opacity: 1 },
                          },
                        }}
                        onCancel={() => {}}
                        onSave={async onFinished => {
                          resetWorkflowModal.onOpen();
                          onFinished(true);
                        }}
                        title={'Model Status'}
                        renderField={(mode: 'edit' | 'read-only') => {
                          return (
                            <Box>
                              <Tag
                                data-testid="current-status"
                                colorScheme={
                                  inventoryModel.status
                                    ? removeAfterDot(
                                        inventoryModel.status.colors.primary,
                                      )
                                    : 'neutral'
                                }
                                size="lg"
                              >
                                {inventoryModel.status
                                  ? inventoryModel.status.name
                                  : 'No status'}
                              </Tag>
                            </Box>
                          );
                        }}
                      />
                    </HStack>

                    <WorkflowExecutionsViewer
                      triggerId={'InventoryModel.created'}
                      targetCuid={inventoryModel.cuid}
                    />
                  </VStack>
                </FormControl>

                <WorkflowStatusActions
                  triggerId={'InventoryModel.created'}
                  entityCuid={inventoryModel.cuid}
                  fromStatus={inventoryModel.status}
                  defaultCustomFieldValues={
                    inventoryModel.custom_fields as FieldValues
                  }
                  onSave={() => {
                    invalidateInventoryModelQueries(inventoryModel);
                    queryClient.invalidateQueries(['ActivityFeedWidget']);
                  }}
                />
              </>
            )}

            {inventoryModel && (
              <VStack align="start" spacing={0} w={'full'}>
                <EditableField
                  readOnly={readOnlyFields}
                  onSave={async onFinished => {
                    const accessToken = await getAccessTokenSilently();

                    await API.PatchModelInventoryGroup(
                      accessToken,
                      inventoryModel.cuid,
                      group!.cuid,
                    );

                    onFinished(true);
                  }}
                  onCancel={() => {
                    setGroup(inventoryModel.group);
                  }}
                  title={'Group'}
                  renderField={(mode: 'edit' | 'read-only') => {
                    if (mode === 'edit') {
                      return (
                        <Select
                          bg={useColorModeValue('white', 'neutral.850')}
                          focusBorderColor="brand.base"
                          value={group?.cuid}
                          onChange={e => {
                            setGroup(
                              groups.find(g => g.cuid === e.target.value),
                            );
                          }}
                        >
                          {groups.map(group => (
                            <option key={group.cuid} value={group.cuid}>
                              {group.name}
                            </option>
                          ))}
                        </Select>
                      );
                    }
                    return <Text>{group?.name}</Text>;
                  }}
                />
              </VStack>
            )}

            <VStack align="start" spacing={0} w={'full'}>
              {inventoryModel && (
                <CoreField
                  inventoryModel={inventoryModel}
                  propertyKey={'business_unit'}
                  readOnly={readOnlyFields}
                />
              )}
            </VStack>

            <VStack align="start" spacing={0} w={'full'}>
              {inventoryModel && (
                <CoreField
                  inventoryModel={inventoryModel}
                  propertyKey={'use_case'}
                  readOnly={readOnlyFields}
                />
              )}
            </VStack>

            <VStack align="start" spacing={0} w={'full'}>
              {inventoryModel && (
                <CoreField
                  isCoreField={true}
                  inventoryModel={inventoryModel}
                  propertyKey={'is_vendor_model'}
                  readOnly={readOnlyFields}
                />
              )}
            </VStack>
            <VStack align="start" spacing={0} w={'full'}>
              {inventoryModel && inventoryModel.is_vendor_model && (
                <ManagedField
                  jsonSchema={{
                    label: 'Vendor Name',
                    type: 'auto-complete',
                    props: {
                      suggestions: getVendorNames,
                    },
                    key: 'vendor_name',
                    returnType: 'array',
                    isRequired: false,
                  }}
                  readOnly={readOnlyFields}
                  initialValue={inventoryModel.vendor_name}
                  onEdit={async (text, onSuccess) => {
                    const accessToken = await getAccessTokenSilently();
                    await API.PostModelInventoryCustomField(
                      accessToken,
                      inventoryModel,
                      'vendor_name',
                      {
                        vendor_name: text,
                      },
                    );
                    onSuccess();
                  }}
                />
              )}
            </VStack>

            {inventoryModel && sidebarCustomFields.length && (
              <Stack w={'full'}>
                <VStack gap={12} alignItems={'flex-start'} w={'full'}>
                  {sidebarCustomFields.map(property => (
                    <ManagedCustomField
                      inventoryModel={inventoryModel}
                      propertyKey={property.key}
                      readOnly={isReadOnlyField(property.key)} // permissions for custom fields
                    />
                  ))}
                </VStack>
              </Stack>
            )}
          </AttributesRail>
        }
      >
        <VStack w={'full'}>
          <HStack w={'full'}>
            <MasterSearchBar />
          </HStack>
          <MasterHeader>
            <MasterStatusBar
              item={inventoryModel}
              isMain={true}
              url="/model-inventory"
              readOnly={readOnlyFields}
            />
          </MasterHeader>
        </VStack>
        {inventoryModel && inventoryModel.cuid && (
          <>
            {/*
              voterStatus is null here because we still want to
              show the approval results even if the user has already voted.
            */}
            <WorkflowApprovalActions
              targetType={'InventoryModel'}
              targetCuid={inventoryModel.cuid}
              voterStatus={null}
            />
          </>
        )}
        <VStack
          divider={
            <StackDivider
              borderColor={useColorModeValue('neutral.200', 'neutral.800')}
            />
          }
          spacing={12}
          align="stretch"
        >
          <SimpleGrid columns={{ md: 1, lg: 2, xl: 3 }} spacing={10}>
            {inventoryModel && currentUser && (
              <>
                {roleControls?.map(roleControl => (
                  <ManagedField
                    jsonSchema={{
                      label: roleControl.label,
                      type: 'model-user',
                      props: {
                        many: true,
                      },
                      key: roleControl.label,
                      returnType: 'array',
                      isRequired: false,
                    }}
                    readOnly={readOnlyFields}
                    initialValue={roleControl.existingUsers.map(
                      u => u.user.cuid,
                    )}
                    onEdit={async (newUserCUIDs, onSuccess) => {
                      await updateUsers(
                        newUserCUIDs,
                        roleControl.existingUsers,
                        roleControl.label,
                      );
                      onSuccess();
                    }}
                    overrideDisplay={mode => {
                      if (
                        mode !== 'edit' &&
                        roleControl.existingUsers.length === 0
                      ) {
                        return (
                          <EmptyStateDisplay variant="no-user">
                            <Heading as={'h5'}>
                              No {roleControl.label.toLowerCase()} have been set
                              yet.
                            </Heading>
                            <Text align={'center'}>Click to add one.</Text>
                          </EmptyStateDisplay>
                        );
                      }
                      return null;
                    }}
                  />
                ))}
              </>
            )}
          </SimpleGrid>

          <>
            <HStack>
              <Heading as={'h3'}>Model Interdependencies</Heading>
              <MoreInfoPopOver
                title="Model Interdependencies"
                description="Link two or more models in your inventory together."
                link="https://docs.validmind.ai/guide/model-inventory/configure-model-interdependencies.html"
              />
              {inventoryModel &&
                inventoryModel.stage === InventoryModelStages.ACTIVE &&
                (inventoryModel?.dependencies || []).length > 0 && (
                  <>
                    <Spacer />
                    <DependencyManagerModal
                      inventoryModel={inventoryModel}
                      buttonVariant="ghost"
                    />
                  </>
                )}
            </HStack>
            {(inventoryModel?.dependencies || []).length > 0 ? (
              <InventoryModelDependencies />
            ) : (
              <EmptyStateDisplay variant="no-workflow">
                <Heading as={'h5'}>No dependencies have been set yet.</Heading>
                <Text pb={4}>
                  Dependencies are useful to understand how this model is
                  <br />
                  affected by or affects other models in your inventory.
                </Text>
                {inventoryModel &&
                  inventoryModel.stage === InventoryModelStages.ACTIVE && (
                    <DependencyManagerModal
                      inventoryModel={inventoryModel}
                      buttonVariant="ghost"
                    />
                  )}
              </EmptyStateDisplay>
            )}
          </>

          <Stack gap={10}>
            {inventoryModel?.purpose && (
              <Box>
                <CoreField
                  inventoryModel={inventoryModel}
                  propertyKey={'purpose'}
                  readOnly={readOnlyFields}
                />
              </Box>
            )}

            {inventoryModel && mainCustomFields.length && (
              <>
                {mainCustomFields.map(property => {
                  if (property.typeId === 'array:attachments') {
                    return (
                      <>
                        <ManageAttachmentsField
                          key={property.key}
                          entityType="inventory_model"
                          entityCuid={inventoryModel?.cuid}
                          entityFieldId={property.key}
                          canUpload={!readOnlyFields}
                          canDelete={!readOnlyFields}
                          title={property.title}
                        />
                      </>
                    );
                  }

                  return (
                    <Box>
                      <ManagedCustomField
                        inventoryModel={inventoryModel}
                        propertyKey={property.key}
                        readOnly={isReadOnlyField(property.key)}
                      />
                    </Box>
                  );
                })}
              </>
            )}
          </Stack>

          <Stack>
            <FindingsWidget
              inventoryModel={inventoryModel}
              variant={'model-inventory-summary'}
              allowAddFinding={
                inventoryModel?.stage === InventoryModelStages.ACTIVE
              }
            />
          </Stack>

          <Stack>
            <ActivityFeedWidget
              variant={'model-inventory-summary'}
              filters={eventFilters}
              seeAllUrl={`/model-inventory/${inventoryModel?.cuid}/activity/`}
            />
          </Stack>
        </VStack>
        <Copyright />
      </RecordDetailsPage>
    </>
  );
}
