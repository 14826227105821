import {
  JSONTemplate,
  OfflineDocumentationFile,
  TemplateVersion,
} from './template';

export enum ModelDocumentTypeEnum {
  model_documentation = 'model_documentation',
  validation_report = 'validation_report',
  monitoring = 'monitoring',
}

export function getModelDocumentType(
  documentType: string,
): ModelDocumentTypeEnum {
  // We should use `model_documentation` for everything. Right now the
  // URL route /documentation is preventing us so we have to add this ugly hack.
  let newDocumentType = documentType;
  if (documentType === 'documentation') {
    newDocumentType = 'model_documentation';
  }
  const types = Object.keys(ModelDocumentTypeEnum);
  const values = Object.values(ModelDocumentTypeEnum) as string[];
  return types[
    values.indexOf(newDocumentType) as keyof object
  ] as ModelDocumentTypeEnum;
}

export interface ModelDocument {
  cuid: string;
  type: ModelDocumentTypeEnum;
  template: JSONTemplate;
  base_template: TemplateVersion;
  is_offline: boolean;
  offline_files: OfflineDocumentationFile[];
  created_at: number;
  updated_at: number;

  link: {
    url: string;
    label: string;
  };

  order: number; // order of appearance in any model documents list

  settings: ModelDocumentSettings; // the model owner sets these configurations in the model documents settings
  perms: any[]; // user requesting this document can perform this array of pre-validated permissions on this resource without any additional calls to the server
}

interface ModelDocumentSettings {
  enabled_features: {
    ck: string[]; // 'commenting', 'revision-history', 'track-changes' // so far used on all the document types
    blocks: string[]; // 'metadata', 'metrics', 'tests', etc. // so far used on all the document types
    ai: string[]; // 'basic-generation', 'advanced-generation'
    findings: string[]; // 'add-findings' // so far used on documentation only
    guidelines: string[]; // 'show-guidelines' // so far used on documentation only
    status: string[]; // 'manage-status' // so far used on documentation only
    assessments: string[]; // 'manage-assessment' // so far used on validation report only
    feeds: string[]; // 'show-activity-feed'
  };
  layout: {
    use_insights_sidebar: boolean;
  };
}
