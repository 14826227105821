import React, { useEffect, useRef, useState, ReactNode } from 'react';

interface ScalableTextProps {
  children: ReactNode;
  maxFontSize?: number;
}

const ScalableText: React.FC<ScalableTextProps> = ({
  children,
  maxFontSize = 140,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const textRef = useRef<HTMLDivElement | null>(null);
  const [fontSize, setFontSize] = useState<number>(16);

  useEffect(() => {
    const container = containerRef.current;
    const text = textRef.current;
    if (!container || !text) return;

    const adjustFontSize = () => {
      const containerWidth = container.offsetWidth;

      // Estimate initial font size based on container width and text length
      const textLength = String(children).length;
      const calculatedFontSize = Math.min(
        containerWidth / textLength,
        maxFontSize,
      );

      text.style.fontSize = `${calculatedFontSize}px`;

      // Check if the text still overflows and adjust if necessary
      if (text.offsetWidth > containerWidth) {
        let testFontSize = calculatedFontSize;
        while (text.offsetWidth > containerWidth && testFontSize > 0) {
          testFontSize -= 0.5;
          text.style.fontSize = `${testFontSize}px`;
        }
        setFontSize(testFontSize);
      } else {
        setFontSize(calculatedFontSize);
      }
    };

    // Observe container resizing to adjust font size dynamically
    const resizeObserver = new ResizeObserver(adjustFontSize);
    resizeObserver.observe(container);

    adjustFontSize();

    return () => {
      resizeObserver.disconnect();
    };
  }, [children, maxFontSize]);

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', height: '100%', overflow: 'hidden' }}
    >
      <div
        ref={textRef}
        style={{
          fontSize: `${fontSize}px`,
          whiteSpace: 'nowrap', // Keeps text on one line
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ScalableText;
