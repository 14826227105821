import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { Text, VStack, Box, Heading, Tag } from '@chakra-ui/react';
import API from '../../../api/API';
import InventoryModelTierLevelBadge from '../../../components/InventoryModelTierLevelBadge';
import { DataTable } from '../../../components/DataTable';
import { TInventoryModel } from '../../../models/inventory_model';
import { Icon } from '@chakra-ui/icons';
import { CubeIcon } from '@heroicons/react/24/outline';
import { removeAfterDot } from '../../../utils';

interface GroupModelTableProps {
  groupCuid: string;
}

export default function GroupModelTable({ groupCuid }: GroupModelTableProps) {
  const { getAccessTokenSilently } = useAuth0();

  const { data } = useQuery([groupCuid], async () => {
    const accessToken = await getAccessTokenSilently();
    return await API.GetGroupInventoryModels(accessToken, groupCuid);
  });

  const groupModels = data || [];

  const tableColumns = [
    {
      Header: ' ',
      Cell: ({ row }: any) => {
        return <Icon boxSize={6} color={'neutral.400'} as={CubeIcon} />;
      },
    },
    {
      Header: 'Model Name',
      accessor: 'name',
      Cell: ({ value }: { value: TInventoryModel['name'] }) => {
        return (
          <Text fontSize="sm" fontWeight="semibold">
            {value}
          </Text>
        );
      },
    },
    {
      Header: 'Business Unit',
      accessor: 'business_unit.name',
      Cell: ({
        value,
      }: {
        value: TInventoryModel['business_unit']['name'];
      }) => {
        return <Text fontSize="sm">{value}</Text>;
      },
    },
    {
      Header: 'Risk Rating',
      accessor: 'tiering',
      Cell: ({ value }: { value: TInventoryModel['tiering'] }) => {
        return (
          <Box py={2}>
            <InventoryModelTierLevelBadge level={value} size={'sm'} />
          </Box>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: { value: TInventoryModel['status'] }) => {
        return (
          <Tag
            data-testid="status-badge"
            size="sm"
            colorScheme={
              value ? removeAfterDot(value.colors.primary) : 'neutral'
            }
          >
            {value ? value.name : 'No status'}
          </Tag>
        );
      },
    },
  ];

  return (
    <VStack
      alignItems="start"
      pt={6}
      w="full"
      data-testid="group-model-table"
      mx={1}
    >
      <Heading as="h3" fontSize="sm" fontWeight="semibold">
        Models Visible to Group
      </Heading>
      <Box w="full">
        <DataTable data={groupModels} columns={tableColumns} />
      </Box>
    </VStack>
  );
}
