import {
  Box,
  FormLabel,
  Heading,
  VStack,
  SimpleGrid,
  Switch,
} from '@chakra-ui/react';

const StyleGuideSwitch = ({ sizes }: { sizes: string[] }) => {
  sizes = sizes.filter(s => s !== 'xs');
  return (
    <Box>
      <Heading as={'h2'}>Switch</Heading>
      <SimpleGrid columns={3}>
        {sizes.map(size => (
          <VStack>
            <FormLabel htmlFor={size}>Checkbox {size}</FormLabel>
            <Switch id={size} key={size} size={size}></Switch>
          </VStack>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default StyleGuideSwitch;
