import { Route, Routes } from 'react-router-dom';
import { LoadingContainer } from '../components/LoadingContainer';
import Organizations from './pages/Organizations';
import Home from './pages/Home';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Flex,
  VStack,
  Link,
  Text,
  Icon,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { HomeIcon, UsersIcon } from '@heroicons/react/24/solid';
import { ArrowLeftEndOnRectangleIcon } from '@heroicons/react/24/outline';
import { useState, useEffect } from 'react';
import { UNAUTHORIZED_EVENT } from '../api/AdminAPI';
import UnauthorizedAccess from './pages/UnauthorizedAccess';
import OrganizationDefaults from './pages/OrganizationDefaults';
import { Cog6ToothIcon as CogIcon } from '@heroicons/react/24/outline';
import ValidMindLogo from '../components/ValidMindLogo';
import { Auth0PrivateRoute } from '../components/PrivateRoute';

// Define sidebar items
const SidebarItems = [
  {
    label: 'Home',
    icon: HomeIcon,
    path: '/admin',
  },
  {
    label: 'Organizations',
    icon: UsersIcon,
    path: '/admin/organizations',
  },
  {
    label: 'Organization Defaults',
    icon: CogIcon,
    path: '/admin/organization-defaults',
  },
];

function AdminApp() {
  const { isLoading: isUserLoading, logout } = useAuth0();
  const location = useLocation();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const onLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    const handleUnauthorized = () => {
      setIsUnauthorized(true);
      console.error('Unauthorized access detected');
    };

    window.addEventListener(UNAUTHORIZED_EVENT, handleUnauthorized);

    return () => {
      window.removeEventListener(UNAUTHORIZED_EVENT, handleUnauthorized);
    };
  }, []);

  if (isUnauthorized) {
    return <UnauthorizedAccess />;
  }

  return (
    <LoadingContainer isLoading={isUserLoading}>
      <Flex h="100vh">
        {/* Sidebar */}
        <Box
          w="15%"
          maxW="400px"
          bg={bgColor}
          borderRight="1px"
          borderColor={borderColor}
          py={8}
          position="fixed"
          h="full"
          display="flex"
          flexDirection="column"
        >
          <Box px={4} mb={8}>
            <ValidMindLogo />
          </Box>

          <VStack spacing={2} align="stretch" flex="1">
            {SidebarItems.map(item => (
              <Link
                key={item.path}
                as={RouterLink}
                to={item.path}
                textDecoration="none"
                _hover={{ textDecoration: 'none' }}
                _focus={{ textDecoration: 'none' }}
              >
                <Flex
                  align="center"
                  p={3}
                  mx={4}
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  bg={
                    location.pathname === item.path
                      ? 'brand.base'
                      : 'transparent'
                  }
                  color={location.pathname === item.path ? 'white' : 'gray.700'}
                  _hover={{
                    bg:
                      location.pathname === item.path
                        ? 'brand.500'
                        : 'neutral.100',
                  }}
                >
                  <Icon
                    as={item.icon}
                    mr={4}
                    w={4}
                    h={4}
                    color={
                      location.pathname === item.path ? 'white' : 'brand.base'
                    }
                  />
                  <Text fontSize="sm" fontWeight="medium">
                    {item.label}
                  </Text>
                </Flex>
              </Link>
            ))}
          </VStack>

          {/* Logout Button */}
          <Box px={4} mt="auto" mb={4}>
            <Button
              width="full"
              variant="ghost"
              onClick={onLogout}
              leftIcon={<Icon as={ArrowLeftEndOnRectangleIcon} w={5} h={5} />}
              justifyContent="flex-start"
            >
              Logout
            </Button>
          </Box>
        </Box>

        {/* Main Content */}
        <Box ml="15%" w="85%" p={8}>
          <Routes>
            <Route element={<Auth0PrivateRoute />}>
              <Route path="/" element={<Home />} />
              <Route path="/organizations" element={<Organizations />} />
              <Route
                path="/organization-defaults"
                element={<OrganizationDefaults />}
              />
            </Route>
          </Routes>
        </Box>
      </Flex>
    </LoadingContainer>
  );
}

export default AdminApp;
