import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Text,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import API from '../../api/API';

export default function AcceptInvitationButton({
  inviteCuid,
}: {
  inviteCuid: string;
}) {
  const { loginWithRedirect } = useAuth0();
  const { data, isLoading } = useQuery([], async () => {
    return API.GetInvitationCheck(inviteCuid);
  });

  if (isLoading) {
    return <Text>Please wait...</Text>;
  }

  if (data?.result === false) {
    return (
      <Alert status="warning">
        <AlertIcon />
        <AlertDescription>This invitation expired.</AlertDescription>
      </Alert>
    );
  }

  const appState = {
    inviteCuid: inviteCuid,
  };
  const appStateJSON = encodeURIComponent(JSON.stringify(appState));

  if (data?.user_exists === true) {
    const options = {
      appState: { appStateJSON: appStateJSON },
    };
    return (
      <Button
        onClick={() => {
          loginWithRedirect(options);
        }}
      >
        Accept Invitation
      </Button>
    );
  }

  const newUserAppState = {
    inviteCuid: inviteCuid,
    isNewUser: true,
  };

  const newUserAppStateJSON = encodeURIComponent(
    JSON.stringify(newUserAppState),
  );

  const options = {
    appState: { appStateJSON: newUserAppStateJSON },
    screen_hint: 'signup',
  };

  return (
    <Button onClick={() => loginWithRedirect(options)}>
      Accept Invitation
    </Button>
  );
}
