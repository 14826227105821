import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SubsectionHeader } from '../../../components/SubsectionHeader';
import { UsersContext } from '../../../contexts';
import {
  TContentIdGuidance,
  TInventoryModelMetadataContentIdEnum,
} from '../../../models/metadata';
import {
  getSectionFromLookup,
  TemplateSection,
  TemplateSectionContents,
} from '../../../models/template';
import { LoadingContainer } from '../../LoadingContainer';
import { MetadataContentEditor } from '../../Templates/ModelDocumentation';
import ContentContainer from '../ContentContainer';
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  ListItem,
  Spacer,
  Stack,
  Text,
  UnorderedList,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import ActivityFeedWidget from '../../ActivityFeedWidget';
import { EventFilters, TEvent } from '../../../models/event';
import API from '../../../api/API';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  LightBulbIcon,
} from '@heroicons/react/24/outline';
import AddFindingModal from '../../AddFindingModal';
import DocumentationContext from '../../../contexts/DocumentationContext';
import {
  TEST_DESCRIPTION_CONTENT_ID_PREFIX,
  TestResultContent,
} from '../../Templates/ModelDocumentation/TestResultContent';
import {
  ModelDocumentTypeEnum,
  getModelDocumentType,
} from '../../../models/model_document';
import { Copyright } from '../../Copyright';
import SectionContainer from '../SectionContainer';
import { EmptyStateDisplay } from '../../EmptyStateDisplay';
import { BlockExplorer } from '../../BlockExplorer';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { findFirstSection, removeBlockOptimisticUI } from './utils';
import DocumentationPageSidebar from './DocumentationPageSidebar';
import MasterSearchBar from '../MasterSearchBar';
import DocumentSectionStatusSelect from '../../DocumentSectionStatusSelect';
import {
  InventoryModelStages,
  TDocumentSectionStatus,
  TInventoryModel,
} from '../../../models/inventory_model';
import { AIGenerationConfig } from '../../TextContentEditor/AITextContentEditor';
import { canUpdateDocument } from '../../../auth/utils';
import { useCurrentDocument } from '../../../hooks/useModelInventory';
import { GuidelineContent } from '../../Templates/ModelDocumentation/RiskAssessmentContent';
import RiskAssessmentSummary from '../../Templates/ModelDocumentation/RiskAssessmentContent/RiskAssessmentSummary';
import { useUserUISettings } from '../../../hooks/useUserUISettings';
import { getDocumentType } from '../../../utils';
import UnitMetricContent from '../../Templates/UnitMetricContent';
import InventoryModelContext, {
  InventoryModelTemplates,
} from '../../../contexts/InventoryModel';

export interface SectionContentsProps {
  contents: TemplateSectionContents;
  removeBlock?: (content: TemplateSectionContents) => void;
  readOnly?: boolean;
  hideHeader?: boolean;
  overrideDocumentType?: string;
  documentType?: string;
  customAIGenerationConfig?: AIGenerationConfig;
}

interface ISupportedContentTemplates {
  [key: string]: React.FC<SectionContentsProps>;
}

const SUPPORTED_CONTENT_TEMPLATES: ISupportedContentTemplates = {
  metadata_text: MetadataContentEditor,
  metric: TestResultContent,
  test: TestResultContent,
  text: MetadataContentEditor,
  guideline: GuidelineContent,
  unit_metric: UnitMetricContent,
};

/**
 * Allow templates to render a section with no contents. This is a default behavior
 * we provide to allow "text only" sections to be rendered. When no contents are
 * defined we assume there's a single content_type: text with the same id
 * as the section id.
 *
 * Example:
 *
 *  - id: conceptual_soundness
 *    title: Conceptual Soundness
 *
 * Rendered as:
 *
 *  - id: conceptual_soundness
 *    title: Conceptual Soundness
 *    contents:
 *      - content_id: conceptual_soundness
 *        content_type: text
 */
const getInitialSectionContents = (templatePage: TemplateSection) => {
  if (templatePage.hasOwnProperty('contents')) {
    return templatePage.contents;
  }
  return [
    { content_id: templatePage.id, content_type: 'text' },
  ] as TemplateSectionContents[];
};

const sectionTitle = (page: TemplateSection) => {
  const hrefPageId = `h-${page.id.replace(/_/g, '-')}`;
  const title = page.index ? `${page.index}. ${page.title}` : page.title;
  return (
    <Heading as={'h2'} id={hrefPageId}>
      {title}
    </Heading>
  );
};

interface AddBlockToSectionVariables {
  documentType: string;
  templateSection: TemplateSection;
  index: number;
  content: TemplateSectionContents;
}

interface RemoveBlockToSectionVariables {
  documentType: string;
  templateSection: TemplateSection;
  content: TemplateSectionContents;
}

const renderTemplatePageContents = (
  inventoryModel: TInventoryModel,
  documentType: string,
  templateSection: TemplateSection,
  sidebarHidden: boolean,
  renderTitle: boolean = false,
  addBlock: any,
  removeBlock: UseMutationResult<any, unknown, RemoveBlockToSectionVariables>,
  renderAfterTitle?: JSX.Element | undefined,
) => {
  const contents = getInitialSectionContents(templateSection) || [];

  const renderLeft = () => {
    const seeAllActivityUrl = `/model-inventory/${
      inventoryModel!.cuid
    }/activity`;
    let eventFilters: EventFilters = {
      inventory_models: [inventoryModel!.cuid],
    };
    const docType = getModelDocumentType(documentType);

    // Get the content ids of every module inside this page
    const contentIds = contents.map(c => {
      const prefix =
        c.content_type === 'test'
          ? `${TEST_DESCRIPTION_CONTENT_ID_PREFIX}:`
          : '';
      return `Metadata:content_id:${docType}#${prefix}${c.content_id}`;
    });

    eventFilters['action_objects'] = [
      `DocumentSectionStatus:section_id:${docType}#${templateSection.id}`,
      ...contentIds,
    ];
    eventFilters['targets'] = contentIds;

    const addContentBlock = (
      index: number,
      content: TemplateSectionContents,
    ): Promise<void> => {
      return addBlock.mutateAsync({
        documentType,
        templateSection,
        index,
        content,
      });
    };

    const removeContentBlock = (content: TemplateSectionContents) => {
      removeBlock.mutate({ documentType, templateSection, content });
    };

    return (
      <Flex id="grid-sticky-left" direction="column" align="center">
        {renderTitle && (
          <>
            <ContentContainer>
              <HStack>
                {sectionTitle(templateSection)}
                {!!renderAfterTitle && renderAfterTitle}
              </HStack>
            </ContentContainer>
            <Spacer mb={5} />
          </>
        )}
        <SectionContainer>
          {contents.length === 0 && (
            <BlockExplorer contentIndex={0} addContentBlock={addContentBlock} />
          )}
          {contents.map((content, contentIndex) => {
            const { content_id, content_type } = content;
            let ContentTemplate;

            // Check if we're trying to render a metric or test for which there is a dynamic component
            let renderElement;

            if (content_type === 'assessment_summary') {
              renderElement = (
                <RiskAssessmentSummary
                  guidelinesContentIds={contents
                    .filter(c => c.content_type === 'guideline')
                    .map(c => c.content_id)}
                />
              );
            } else {
              ContentTemplate =
                SUPPORTED_CONTENT_TEMPLATES[content_type as keyof object];
              renderElement = (
                <ContentTemplate
                  documentType={documentType}
                  contents={content}
                  removeBlock={removeContentBlock}
                />
              );
            }

            if (!renderElement) {
              console.log(
                `Unsupported content template (content_id=${content_id}, content_type=${content_type})`,
              );
              return null;
            }

            return (
              <VStack key={`content-${contentIndex}`} gap={0}>
                <ContentContainer>{renderElement}</ContentContainer>
                {/* <Divider borderWidth={1} mt={'1rem !important'} /> */}
                {inventoryModel!.stage === InventoryModelStages.ACTIVE && (
                  <BlockExplorer
                    contentIndex={contentIndex}
                    addContentBlock={addContentBlock}
                  />
                )}
              </VStack>
            );
          })}
        </SectionContainer>
        <ContentContainer>
          <ActivityFeedWidget
            variant={'documentation'}
            filters={eventFilters}
            seeAllUrl={seeAllActivityUrl}
            expanded={false}
          />
        </ContentContainer>
      </Flex>
    );
  };

  const renderRight = () => {
    let contentIds: string[] = [];
    contents.forEach(content => {
      const { content_id } = content;
      contentIds.push(
        `Metadata:content_id:${getModelDocumentType(
          documentType,
        )}#${content_id}`,
      );
    });

    let guidance: TContentIdGuidance | undefined;
    if (templateSection && templateSection.guidelines) {
      guidance = {
        title: templateSection.title,
        guidance: templateSection.guidelines,
      };
    }

    return (
      <Box
        id="grid-sticky-right"
        sx={{
          position: 'sticky',
          top: '10',
        }}
        px={4}
        py={4}
      >
        <Heading
          as={'h2'}
          fontSize={'lg'}
          color={'neutral.300'}
          textTransform={'capitalize'}
          mb={8}
        >
          {documentType.replace('_', ' ')} Guidelines
        </Heading>
        {guidance?.guidance && guidance.guidance.length > 0 ? (
          <UnorderedList>
            {guidance?.guidance.map((paragraph, index) => (
              <ListItem key={`paragraph-${index}`}>
                <Text fontSize={{ base: 'sm', '2xl': 'md' }}>{paragraph}</Text>
              </ListItem>
            ))}
          </UnorderedList>
        ) : (
          <EmptyStateDisplay variant="no-guidelines">
            <Heading as={'h5'}>No guidelines</Heading>
            <Text align={'center'} color={'neutral.400'}>
              Couldn't find any guidelines for this section.
              <br />
              <br />
              Ask your administrator to check the model template.
            </Text>
          </EmptyStateDisplay>
        )}

        {documentType === 'documentation' && (
          <Center mt={10}>
            <AddFindingModal
              buttonBoxProps={{ position: 'relative' }}
              buttonVariant="primary"
              sectionId={templateSection.id}
            />
          </Center>
        )}
      </Box>
    );
  };

  return (
    <>
      <GridItem
        id="grid-section-contents"
        borderBottom={'1px solid'}
        borderBottomColor={useColorModeValue('neutral.200', 'neutral.700')}
        paddingY={9}
        paddingX={10}
      >
        {renderLeft()}
      </GridItem>
      <GridItem
        id="grid-section-guidelines-comments"
        bg="neutral.700"
        color={'neutral.300'}
        borderBottom={'2px solid #2D3A50'}
        paddingY={8}
        hidden={sidebarHidden}
      >
        {renderRight()}
      </GridItem>
    </>
  );
};

interface IDocumentationPageProps {
  pageId?: TInventoryModelMetadataContentIdEnum | string;
  pageContents?: JSX.Element;
}

export default function DocumentationPage({
  pageId: componentPageId,
  pageContents: customPageContents,
}: IDocumentationPageProps) {
  const { currentUser } = useContext(UsersContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { inventoryModel, setInventoryModel, setTemplates, templates } =
    useContext(InventoryModelContext);
  const [latestEvent, setLatestEvent] = useState<TEvent>();
  const { pageId: routePageId, documentType: documentTypeParam } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();

  const rightSectionWidth = 424;

  const { getHideVMInsightsSidebar, updateHideVMInsightsSidebar } =
    useUserUISettings();

  const persistedRightSidebarHidden =
    documentTypeParam === 'validation-report' ||
    documentTypeParam === 'monitoring'
      ? true
      : getHideVMInsightsSidebar();

  const [rightSidebarHidden, setRightSidebarHidden] = useState<boolean>(
    persistedRightSidebarHidden,
  );

  useEffect(() => {
    // no pageId in url? redirect to home page of this document type
    if (!routePageId && templates.current.home) {
      const contentUrl = templates.current.home.id.replace(/_/g, '-');
      const homeUrl = `${location.pathname}/${contentUrl}`;
      navigate(homeUrl);
    }
  }, [routePageId, templates.current.home]);

  const { error } = useCurrentDocument(
    inventoryModel!,
    getDocumentType(documentTypeParam),
  );

  useEffect(() => {
    if (error) {
      toast({
        title: "You don't have permissions to view this document.",
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      });
      navigate('/model-inventory');
    }
  }, [error]);

  useEffect(() => {
    // Hide right sidebar when in validation report
    if (['validation-report', 'monitoring'].includes(documentTypeParam || '')) {
      setRightSidebarHidden(true);
    }
    // On the documentation page,
    // sync the right sidebar visibility with the user's settings
    if (
      documentTypeParam === 'documentation' &&
      rightSidebarHidden !== persistedRightSidebarHidden
    ) {
      updateHideVMInsightsSidebar(rightSidebarHidden);
    }
  }, [persistedRightSidebarHidden, rightSidebarHidden, documentTypeParam]);

  const userCanUpdateDocument = useMemo(
    () => canUpdateDocument(currentUser, inventoryModel, documentTypeParam),
    [currentUser, inventoryModel, documentTypeParam],
  );

  useEffect(() => {
    if (documentTypeParam && inventoryModel) {
      const documentType = documentTypeParam.replace(/-/g, '_');

      const allTemplates: InventoryModelTemplates = {
        ...templates,
        get current() {
          let content = allTemplates.documentation;
          if (documentType == 'validation_report') {
            content = allTemplates.validation_report;
          } else if (documentType == 'monitoring') {
            content = allTemplates.monitoring;
          }

          const home = findFirstSection(content.sections || []);
          return {
            content,
            documentType,
            home,
          };
        },
      };

      setTemplates(allTemplates);
    }
  }, [documentTypeParam, inventoryModel, templates.isReady]);

  const addBlock = useMutation(
    [],
    async ({
      documentType,
      templateSection,
      index,
      content,
    }: AddBlockToSectionVariables) => {
      const accessToken = await getAccessTokenSilently();

      return API.AddBlockToSection(
        accessToken,
        inventoryModel!,
        getModelDocumentType(documentType) as
          | 'model_documentation'
          | 'validation_report'
          | 'monitoring',
        templateSection,
        index,
        content,
      );
    },
    {
      onSuccess: () => {
        // refetching inventory model will re-hydrate the sitemap, keep showing newly added block.
        return queryClient.invalidateQueries([
          'inventory-model',
          inventoryModel!.cuid,
        ]);
      },
      onError: (error, variables) => {
        if (error instanceof Error) {
          toast({
            title: error.message,
            status: 'error',
            isClosable: true,
            position: 'bottom-right',
          });
        }
        // TODO: track this metric with sentry in order to know the need of developing a content recovery strategy.
        // "resetting inventory model" will re-hydrate the sitemap, removing newly added block.
        const { templateSection, index, content } = variables;
        const updatedInventoryModel = removeBlockOptimisticUI(
          inventoryModel!,
          templateSection,
          index,
          content,
        );
        setInventoryModel!(updatedInventoryModel);
      },
    },
  );

  const removeBlock = useMutation(
    [],
    async ({
      documentType,
      templateSection,
      content,
    }: RemoveBlockToSectionVariables) => {
      const accessToken = await getAccessTokenSilently();
      return API.RemoveBlockFromSection(
        accessToken,
        inventoryModel!,
        getModelDocumentType(documentType) as
          | 'model_documentation'
          | 'validation_report'
          | 'monitoring',
        templateSection,
        content,
      );
    },
    {
      onSuccess: () => {
        // refetching project will re-hydrate the sitemap, keep showing newly added block.
        queryClient.invalidateQueries([
          'inventory-model',
          inventoryModel!.cuid,
        ]);

        toast({
          title: 'Block was removed successfully',
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        });
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: error.message,
            status: 'error',
            isClosable: true,
            position: 'bottom-right',
          });
        }
      },
    },
  );

  const {
    data: documentationOutline = {},
    refetch: refetchDocumentationOverview,
  } = useQuery(
    ['documentation-outline'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetDocumentationSummary(
        accessToken,
        inventoryModel!.cuid,
      );
    },
    {
      enabled: !!inventoryModel,
    },
  );

  const setDocumentSectionStatus = useCallback(
    async (status: TDocumentSectionStatus, sectionId: string) => {
      if (documentTypeParam) {
        const documentType = documentTypeParam.replace(/-/g, '_');
        if (documentType === 'documentation') {
          const accessToken = await getAccessTokenSilently();
          API.PatchDocumentSectionStatus(
            accessToken,
            inventoryModel!.cuid,
            'model_documentation',
            sectionId,
            status,
          );
          refetchDocumentationOverview();
        }
      }
    },
    [inventoryModel, documentTypeParam],
  );

  // Used to determine whether validation report has test results
  const { data: validatorTestResultsKeys } = useQuery(
    [
      inventoryModel!.cuid,
      'test-results-keys',
      ModelDocumentTypeEnum.validation_report,
    ],
    async () => {
      const token = await getAccessTokenSilently();
      return API.GetModelInventoryTestResultsKeys(
        token,
        inventoryModel!,
        false,
        ModelDocumentTypeEnum.validation_report,
      );
    },
    {
      // enable only for model validation
      enabled: location.pathname.includes('/validation-report/'),
    },
  );

  const hasValidationReportResults =
    !!validatorTestResultsKeys && validatorTestResultsKeys.length > 0;

  // componentPageId takes precedence over routePageId
  const pageId = componentPageId || routePageId;

  const templatePage =
    pageId && getSectionFromLookup(templates?.current.content.lookup, pageId);

  // Rendering page contents:
  // 1. If customPageContents is provided, render that
  // 2. If contentPage has a `contents` attribute, render its definition
  let pageContents = customPageContents;

  if (templatePage && !customPageContents) {
    // If we are trying to render a condensed page, we need to render the
    // each of those pages contents individually with renderTemplatePageContents.
    if (templatePage.index_only && templatePage.condensed) {
      // Find all pages for which this page is the parent
      // TODO: simplify this logic, it's very slow. We're calling
      // the lookup inside since the content.sections from the backend
      // don't have numbered indexes
      const childSections = templates.current.content.sections
        .filter(section => section.parent_section === templatePage.id)
        .map(section =>
          getSectionFromLookup(templates?.current.content.lookup, section.id),
        );

      pageContents = (
        <React.Fragment>
          {childSections.map((section, index) => {
            let renderDocumentationStatusSelect: JSX.Element | undefined;
            const sectionOverview = documentationOutline[section.id];
            if (sectionOverview && documentTypeParam !== 'validation-report') {
              renderDocumentationStatusSelect = (
                <DocumentSectionStatusSelect
                  key={section.id}
                  readOnly={
                    !userCanUpdateDocument ||
                    inventoryModel!.stage !== InventoryModelStages.ACTIVE
                  }
                  initialStatus={sectionOverview.status}
                  setStatus={status => {
                    setDocumentSectionStatus(status, section.id);
                  }}
                />
              );
            }
            return (
              <React.Fragment key={`subpage-${index}`}>
                {renderTemplatePageContents(
                  inventoryModel!,
                  templates.current.documentType,
                  section,
                  rightSidebarHidden,
                  true,
                  addBlock,
                  removeBlock,
                  renderDocumentationStatusSelect,
                )}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      );
    } else {
      pageContents = renderTemplatePageContents(
        inventoryModel!,
        templates.current.documentType,
        templatePage,
        rightSidebarHidden,
        false,
        addBlock,
        removeBlock,
      );
    }
  }

  const templateColumns = {
    base: `repeat(${rightSidebarHidden ? '0' : '1'}, 1fr ${
      rightSectionWidth / 1.5
    }px)`,
    '2xl': `repeat(${
      rightSidebarHidden ? '0' : '1'
    }, 1fr ${rightSectionWidth}px)`,
  };

  return (
    <DocumentationContext.Provider
      value={{
        hasValidationReportResults: hasValidationReportResults,
        currentSection: templatePage,
        latestEvent,
        setLatestEvent,
      }}
    >
      <LoadingContainer isLoading={false}>
        <Flex minHeight="100vh">
          <Box className="no-scrollbar" width={'250px'} overflow={'auto'}>
            <Flex
              id="inventory-model-side-nav-submenu"
              direction="column"
              align="flex-start"
              py={24}
              px={2}
              height="100%"
              minHeight="-webkit-fill-available; -moz-available"
              width="250px"
              borderRightWidth={1}
              position="fixed"
            >
              <DocumentationPageSidebar />
            </Flex>
          </Box>
          <Box
            flex={1}
            overflow={'auto'}
            key={location.pathname}
            className="no-scrollbar"
          >
            <Grid id="content-grid" templateColumns={templateColumns} gap={0}>
              <GridItem id="grid-section-top-left" position="relative">
                <HStack
                  w={'full'}
                  justify={'space-between'}
                  alignItems={'self-end'}
                  pl={8}
                >
                  <MasterSearchBar />
                  <Button
                    variant={'ghost'}
                    onClick={() => setRightSidebarHidden(false)}
                    hidden={
                      !rightSidebarHidden ||
                      templates.current.documentType === 'validation_report' ||
                      templates.current.documentType === 'monitoring'
                    }
                    rightIcon={<Icon as={ChevronDoubleLeftIcon} boxSize={5} />}
                  >
                    ValidMind Insights™
                  </Button>
                </HStack>
              </GridItem>
              <GridItem
                id="grid-section-top-right"
                bg="neutral.700"
                position="relative"
                hidden={rightSidebarHidden}
              >
                <Button
                  w={'fit-content'}
                  color={'neutral.400'}
                  variant={'ghost'}
                  position="absolute"
                  bottom={0}
                  right={0}
                  alignSelf={'flex-start'}
                  _hover={{ bg: 'transparent', color: 'neutral.300' }}
                  _active={{ bg: 'transparent' }}
                  onClick={() => setRightSidebarHidden(true)}
                  rightIcon={<Icon as={ChevronDoubleRightIcon} boxSize={5} />}
                >
                  Collapse
                </Button>
              </GridItem>
              <GridItem
                id="grid-section-title"
                borderBottom={'1px solid'}
                borderBottomColor={useColorModeValue(
                  'neutral.200',
                  'neutral.700',
                )}
                pb={4}
                pl={8}
              >
                <Flex align={'stretch'} justify={'center'}>
                  <SubsectionHeader
                    pageId={pageId}
                    renderAfterTitle={() => {
                      if (templatePage) {
                        const sectionOverview =
                          documentationOutline[templatePage.id];

                        if (
                          templatePage &&
                          !templatePage.index_only &&
                          sectionOverview &&
                          documentTypeParam !== 'validation-report'
                        ) {
                          return (
                            <Box mt={2}>
                              <DocumentSectionStatusSelect
                                readOnly={
                                  !userCanUpdateDocument ||
                                  inventoryModel!.stage !==
                                  InventoryModelStages.ACTIVE
                                }
                                initialStatus={sectionOverview.status}
                                setStatus={status => {
                                  setDocumentSectionStatus(
                                    status,
                                    templatePage.id,
                                  );
                                }}
                              />
                            </Box>
                          );
                        }
                      }
                    }}
                  />
                </Flex>
              </GridItem>
              <GridItem
                id="grid-insights-title"
                bg="neutral.700"
                borderBottom={'2px solid #2D3A50'}
                hidden={rightSidebarHidden}
              >
                <Stack justify={'end'} p={5}>
                  <Icon w={8} h={8} as={LightBulbIcon} color={'neutral.500'} />
                  <Text color={'neutral.300'} fontSize={'xl'}>
                    ValidMind Insights™
                  </Text>
                </Stack>
              </GridItem>

              {pageContents}
            </Grid>

            {/* this other grid is used to fill rest of the page height */}
            <Grid flex={1} templateColumns={templateColumns} gap={0}>
              <GridItem>
                <Copyright />
              </GridItem>
              <GridItem bg="neutral.700" hidden={rightSidebarHidden} />
            </Grid>
          </Box>
        </Flex>
      </LoadingContainer>
    </DocumentationContext.Provider>
  );
}
