import { useContext, useEffect, useState } from 'react';
import { Button, HStack, Icon, Select } from '@chakra-ui/react';
import { UsersContext } from '../../../../../contexts';
import { PlusIcon } from '@heroicons/react/24/outline';
import { getSchemaPropertyByKey } from '../../../../../utils';

interface FieldFormProps {
  variant?: 'multiple_select' | 'single_select';
  onAddField: (field: string) => void;
  addedFields?: string[];
  selectedField?: string;
  filterSettingTypeIds?: string[];
}

const FieldForm = ({
  variant = 'multiple_select',
  onAddField,
  addedFields = [],
  selectedField,
  filterSettingTypeIds = [],
}: FieldFormProps) => {
  const { currentOrganization } = useContext(UsersContext);
  const [field, setField] = useState('');

  useEffect(() => {
    if (selectedField) {
      setField(selectedField);
    }
  }, [selectedField]);

  const onChangeField = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setField(e.target.value);
    if (variant === 'single_select') {
      onAddField(e.target.value);
    }
  };

  const onAddConditionClick = () => {
    onAddField(field);
    if (variant === 'multiple_select') {
      setField('');
    }
  };

  const availableFields = Object.keys(
    currentOrganization?.inventory_model_schema.custom.schema.properties || {},
  )
    .filter(k => !addedFields.includes(k))
    .filter(k => {
      if (filterSettingTypeIds?.length > 0) {
        const setting =
          currentOrganization?.inventory_model_schema.custom.settings
            .properties[k];
        if (setting) {
          return filterSettingTypeIds.includes(setting.typeId);
        }
      } else {
        return true;
      }
    })
    .sort((a, b) => {
      const [schemaA] = getSchemaPropertyByKey(
        currentOrganization!.inventory_model_schema.custom,
        a,
      );
      const [schemaB] = getSchemaPropertyByKey(
        currentOrganization!.inventory_model_schema.custom,
        b,
      );
      return (schemaA?.title || '').localeCompare(schemaB?.title || '');
    });

  return (
    <HStack>
      <Select
        value={field}
        onChange={onChangeField}
        size="sm"
        rounded={'md'}
        focusBorderColor="brand.base"
      >
        <option value="">Select field</option>
        {availableFields.map(k => {
          const [schema, setting] = getSchemaPropertyByKey(
            currentOrganization!.inventory_model_schema.custom,
            k,
          );
          return (
            <option value={k} key={k}>
              {schema.title}
            </option>
          );
        })}
      </Select>
      {variant === 'multiple_select' && (
        <Button
          isDisabled={!field}
          onClick={onAddConditionClick}
          size="sm"
          colorScheme="brand"
          leftIcon={<Icon as={PlusIcon} boxSize={4} />}
        >
          Add Field
        </Button>
      )}
    </HStack>
  );
};

export default FieldForm;
