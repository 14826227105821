import { useContext } from 'react';
import WorkflowExecutionContext from '../../contexts/WorkflowExecutionContext';

function useWorkflowExecution() {
  const context = useContext(WorkflowExecutionContext);

  if (context === undefined) {
    throw new Error(
      'useWorkflowExecution must be used within a WorkflowExecutionProvider',
    );
  }

  return context;
}

export default useWorkflowExecution;
