import {
  Box,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  VStack,
} from '@chakra-ui/react';

const StyleGuideRadio = ({ sizes }: { sizes: string[] }) => {
  sizes = sizes.filter(s => s !== 'xs' && s !== 'sm');
  return (
    <Box>
      <Heading as={'h2'}>Radio</Heading>
      <VStack gap={4}>
        <RadioGroup>
          <HStack>
            {sizes.map(size => (
              <Radio value={size} size={size}>
                {size}
              </Radio>
            ))}
          </HStack>
        </RadioGroup>
      </VStack>
    </Box>
  );
};

export default StyleGuideRadio;
