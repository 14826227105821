import {
  Box,
  Center,
  CircularProgress,
  HStack,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../../../../api/API';
import MoreInfoPopOver from '../../../MoreInfoPopOver';
import InventoryModelContext from '../../../../contexts/InventoryModel';

interface RiskAssessmentSummaryProps {
  guidelinesContentIds: string[];
}
export default function RiskAssessmentSummary({
  guidelinesContentIds,
}: RiskAssessmentSummaryProps) {
  const { inventoryModel } = useContext(InventoryModelContext);
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading } = useQuery(
    [
      'inventory-model',
      inventoryModel?.cuid!,
      'risk-assessment-summary',
      guidelinesContentIds,
    ],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetValidationGuidelineSummary(
        accessToken,
        inventoryModel!,
        guidelinesContentIds,
      );
    },
  );

  if (isLoading) {
    return (
      <Center p={12}>
        <Stack justify={'center'} align={'center'}>
          <CircularProgress
            size="40px"
            thickness="2px"
            isIndeterminate
            color="brand.base"
          />
          <Text fontSize={'sm'} color={'inherit'}>
            Please hold...
          </Text>
        </Stack>
      </Center>
    );
  }

  return (
    <>
      <Box
        p={1}
        rounded={'full'}
        overflow={'hidden'}
        border={'3px solid'}
        borderColor={useColorModeValue('neutral.100', 'neutral.800')}
      >
        <Box rounded={'full'} overflow={'hidden'}>
          {data && data.length > 0 && (
            <HStack spacing={1}>
              {data
                .sort((ap, ap2) => ap.order - ap2.order)
                .filter(ap => ap.name !== 'No Assessment')
                .map(ap => {
                  return (
                    <VStack
                      p={2}
                      pt={4}
                      gap={0}
                      w={'full'}
                      key={ap.cuid}
                      rounded={'md'}
                      bg={ap.style.bg}
                    >
                      <Text
                        fontSize={'2rem'}
                        fontWeight={'bold'}
                        lineHeight={1}
                        opacity={1}
                        blendMode={'multiply'}
                        color={ap.style.color}
                      >
                        {ap.count}
                      </Text>
                      <HStack gap={1}>
                        <Text
                          fontSize={'sm'}
                          blendMode={'multiply'}
                          fontWeight={'bold'}
                          color={ap.style.color}
                        >
                          {ap.name}
                        </Text>
                        <MoreInfoPopOver
                          title={ap.name}
                          description={ap.description}
                          placement="bottom"
                          iconProps={{
                            color: ap.style.color,
                            _hover: {
                              color: ap.style.color,
                              opacity: 0.5,
                              cursor: 'help',
                            },
                          }}
                        />
                      </HStack>
                    </VStack>
                  );
                })}
            </HStack>
          )}
        </Box>
      </Box>
      {data && data.filter(ap => ap.name === 'No Assessment')[0].count > 0 && (
        <Center>
          <HStack gap={1}>
            <Text fontWeight={'bold'}>
              {data.filter(ap => ap.name === 'No Assessment')[0].count}
            </Text>
            <Text fontSize={'sm'}>
              Guidelines yet to be assessed
              <MoreInfoPopOver
                title="Validation Report Overview"
                description="View validation guidelines, collaborate with model developers, add or edit content to your draft validation report, link to evidence, or work with model findings."
                link="https://docs.validmind.ai/guide/model-validation/preparing-validation-reports.html"
                placement="bottom"
                iconProps={{
                  ml: 2,
                }}
              />
            </Text>
          </HStack>
        </Center>
      )}
    </>
  );
}
