import {
  Stack,
  VStack,
  Text,
  Button,
  useToast,
  Heading,
  Image,
  Center,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import API from '../../api/API';
import Envelope from './img/envelope.jpg';

const VerificationErrorPage: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const [isSending, setIsSending] = React.useState(false);
  const [alreadySent, setAlreadySent] = React.useState(false);
  const userId = searchParams.get('userId');

  if (!userId) {
    return <>Invalid UserID</>;
  }

  const resendVerificationEmail = async () => {
    try {
      setIsSending(true);
      const email_sent = await API.ResendVerificationEmail(userId);

      // This means the user is already verified, redirect to dashboard
      if (!email_sent) {
        navigate('/dashboard');
      }

      setAlreadySent(true);
    } catch (err) {
      toast({
        title: 'Error',
        description: 'Failed to resend verification email',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setAlreadySent(false);
      console.error(err);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Stack
      w="100%"
      h="100%"
      bgColor={'neutral.50'}
      justifyContent="center"
      alignItems="center"
    >
      <VStack bgColor="white" maxW={500} padding={10} alignItems={'flex-start'}>
        <Heading as={'h2'}>You’re almost there!</Heading>
        <Heading as={'h3'}>
          We sent you email to verify your new account.
        </Heading>
        <Center w={'full'}>
          <Image src={Envelope} alt="Check your email" />
        </Center>
        <Text fontSize="lg" mb={5}>
          Please check your inbox for a verification email from ValidMind
          Support, click the "Confirm my account" link, and then click the
          button below to continue.
        </Text>
        <Button
          variant={'primary'}
          w="full"
          size="lg"
          onClick={() => navigate('/dashboard')}
        >
          Continue
        </Button>
        {!alreadySent && (
          <Text>
            If you haven't recieved your email yet,{' '}
            <Button
              isLoading={isSending}
              variant="link"
              onClick={resendVerificationEmail}
            >
              resend verification email.
            </Button>
          </Text>
        )}
        {alreadySent && <Text>Verification email sent</Text>}
      </VStack>
    </Stack>
  );
};

export default VerificationErrorPage;
