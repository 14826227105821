import { SVGProps } from 'react';

function ValidMindVLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="28"
      height="30"
      viewBox="0 0 28 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      aria-labelledby="validMind validMindDescription"
      role="img"
    >
      <title id="ValidMindVLogo">
        ValidMind - Model Risk Management, Simplified.
      </title>
      <desc id="validMindDescription">
        ValidMind increases the speed and efficiency of model validation
        activities for Financial Institutions.
      </desc>
      <path
        d="M3.09927 13.9424H8.81593L7.79227 11.9734H2.07196L3.09927 13.9424Z"
        fill="#DE257E"
      />
      <path
        d="M9.69661 15.6382H3.9845L5.13657 17.8449H10.8432L9.69661 15.6382Z"
        fill="#DE257E"
      />
      <path
        d="M21.7857 0L12.845 21.6964L11.7248 19.5407H6.02087L11.2366 29.7646H14.5207L27.1663 0H21.7857Z"
        fill="#DE257E"
      />
      <path
        d="M6.91155 10.2785L5.72942 8.00261H0L1.1876 10.2785H6.91155Z"
        fill="#DE257E"
      />
    </svg>
  );
}

export default ValidMindVLogo;
