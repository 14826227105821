import { Box, Heading } from '@chakra-ui/react';

const StyleGuideProgress = () => {
  return (
    <Box>
      <Heading as={'h2'}>Progress</Heading>
    </Box>
  );
};

export default StyleGuideProgress;
