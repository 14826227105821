import { TInventoryModel } from '../../models/inventory_model';
import DocumentHeader, {
  getDocumentHeaderBreadcrumbs,
} from '../DocumentHeader';
import { useMemo } from 'react';

interface Props {
  inventoryModel: TInventoryModel;
}

const MonitoringHeader = ({ inventoryModel }: Props) => {
  const breadcrumbLinks = useMemo(
    () => getDocumentHeaderBreadcrumbs(inventoryModel, 'Monitoring'),
    [inventoryModel],
  );

  return (
    <DocumentHeader
      inventoryModel={inventoryModel}
      breadcrumbLinks={breadcrumbLinks}
    />
  );
};

export default MonitoringHeader;
