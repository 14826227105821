import { Box, Heading } from '@chakra-ui/react';

const StyleGuideHighlight = () => {
  return (
    <Box>
      <Heading as={'h2'}>Highlight</Heading>
    </Box>
  );
};

export default StyleGuideHighlight;
