import { Box, Heading } from '@chakra-ui/react';

const StyleGuidePopover = () => {
  return (
    <Box>
      <Heading as={'h2'}>Popover</Heading>
    </Box>
  );
};

export default StyleGuidePopover;
