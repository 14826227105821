import { useEffect, useState } from 'react';

export default function useField<T = any>(defaultState?: T) {
  const [value, setValue] = useState<T | undefined>();
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    setValue(defaultState);
  }, [defaultState]);

  return { value, setValue, errors, setErrors };
}
