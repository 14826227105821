import { Alert } from './alert';
import { Button } from './button';
import { Checkbox } from './checkbox';
import { FormLabel } from './formlabel';
import { Input } from './input';
import { Link } from './link';
import { Modal } from './modal';
import { Popover } from './popover';
import { Select } from './select';
import { Tabs } from './tabs';
import { Tag } from './tag';
import { Textarea } from './textarea';

const components = {
  Alert,
  Button,
  Checkbox,
  FormLabel,
  Input,
  Link,
  Modal,
  Popover,
  Select,
  Tabs,
  Tag,
  Textarea,
};

export default components;
