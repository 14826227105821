import { Box, Heading } from '@chakra-ui/react';

const StyleGuideCard = () => {
  return (
    <Box>
      <Heading as={'h2'}>Card</Heading>
    </Box>
  );
};

export default StyleGuideCard;
