import { Box, Button, Heading, SimpleGrid, VStack } from '@chakra-ui/react';

const StyleGuideButton = ({ sizes }: { sizes: string[] }) => {
  const buttonVariants = ['primary', 'secondary', 'tertiary', 'link'];
  return (
    <Box>
      <Heading as={'h2'}>Buttons</Heading>

      <SimpleGrid columns={2} gap={12}>
        {sizes.reverse().map(size => (
          <VStack>
            {buttonVariants.map(variant => (
              <Button key={`${size}-${variant}`} variant={variant} size={size}>
                {variant} {size}
              </Button>
            ))}
          </VStack>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default StyleGuideButton;
