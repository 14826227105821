import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  FormControl,
  GridItem,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  SlideFade,
  Spacer,
  Stack,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  useSteps,
  useRadioGroup,
  HStack,
  useColorModeValue,
  Collapse,
  VStack,
  FormHelperText,
  Select,
  Button,
} from '@chakra-ui/react';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import BusinessUnitsSelect from '../ModelInventoryInputs/BusinessUnitsSelect';
import { TBusinessUnit } from '../../models/business_unit';
import UseCasesSelect from '../ModelInventoryInputs/UseCasesSelect';
import TierLevelBadgePicker from '../ModelInventoryInputs/TierRatingField';
import { useMutation } from 'react-query';
import { TUser } from '../../models';
import API from '../../api/API';
import {
  InventoryModelCreationState,
  TInventoryModelPost,
} from '../../models/inventory_model';
import { useNavigate } from 'react-router-dom';
import UsersContext from '../../contexts/UsersContext';
import TemplatesSelect from '../ModelInventoryInputs/TemplatesSelect';
import GroupSelect from '../GroupSelect';
import { TGroup } from '../../models/group';
import {
  useGroups,
  useGroupTemplates,
  useGroupUsers,
} from '../../hooks/useGroups';
import { Label } from '../Layout';
import { CustomFieldJSONSchema } from '../NewCustomFields/types';
import _ from 'lodash';
import useFields from '../../hooks/useFields';
import CustomFieldRenderer from '../NewCustomFields/CustomFieldRenderer';
import { CheckCircleIcon, Icon } from '@chakra-ui/icons';
import ManagedField from '../ManagedField';
import { EmptyStateDisplay } from '../EmptyStateDisplay';
import AutoCompleteTextInput from '../AutoCompleteTextInput';
import RadioCard from '../RadioCard';
import { useStatusesWorkflows } from '../../pages/Settings/Workflows/hooks/useStatuses';

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import MoreInfoPopOver from '../MoreInfoPopOver';

interface ModelInventoryAddProps {
  open?: boolean;
  requiredCustomFields: CustomFieldJSONSchema[];
}

type CustomFieldValueMap = {
  [key: string]: any;
};

export default function ModelInventoryAdd({
  open = false,
  requiredCustomFields,
}: ModelInventoryAddProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { currentUser, currentOrganization, userHasPermission } =
    useContext(UsersContext);
  const { data: statusesWorkflows } = useStatusesWorkflows();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState('');
  const [templateCuid, setTemplateCuid] = useState('');
  const [useCaseCuid, setUseCaseCuid] = useState('');
  const [statusCuid, setStatusCuid] = useState('');
  const [businessUnit, setBusinessUnit] = useState<TBusinessUnit | undefined>();
  const [purpose, setPurpose] = useState('');
  const [group, setGroup] = useState<TGroup>();
  const [tier, setTier] = useState<string | undefined>('1');
  const [owners, setOwners] = useState<TUser[]>([]);
  const [isVendorModel, setIsVendorModel] = useState(false);
  const [vendorName, setVendorName] = useState<string | undefined>();
  const [creationState, setCreationState] = useState<
    InventoryModelCreationState | ''
  >('');
  const { fieldValues, fieldErrors, setFieldValue, setFieldError } =
    useFields();
  const navigate = useNavigate();

  const titleRef = useRef<any>(null);

  const { groups } = useGroups();
  const { users } = useGroupUsers({ group });
  const { templates, isLoading: isLoadingTemplates } = useGroupTemplates({
    group,
  });

  const fieldRefs: any = {};

  useEffect(() => {
    if (open) {
      onOpen();
    }
  }, [open]);

  useEffect(() => {
    requiredCustomFields.forEach(rcf => {
      setFieldValue(rcf.key, undefined);
      setFieldError(rcf.key, []);
    });
  }, [requiredCustomFields]);

  // add me as a model owner by default
  useEffect(() => {
    if (currentUser) {
      setOwners([currentUser]);
    }
  }, [currentUser]);

  useEffect(() => {
    if (groups.length > 0) {
      setGroup(groups[0]);
    }
  }, [groups]);

  const createInventoryModel = useMutation(
    async (newModel: TInventoryModelPost) => {
      const accessToken = await getAccessTokenSilently();
      return await API.CreateInventoryModel(accessToken, newModel);
    },
    {
      onSuccess: data => {
        onClose();
        navigate(`/model-inventory/${data.cuid}/overview`);
      },
    },
  );

  const handleSubmit = async () => {
    let customFieldValueMap: CustomFieldValueMap = {};

    Object.keys(fieldValues).forEach(fieldKey => {
      customFieldValueMap[fieldKey] = fieldValues[fieldKey];
    });

    const newModel: TInventoryModelPost = {
      name,
      creation_state: creationState === '' ? 'new' : creationState,
      use_case_cuid: useCaseCuid,
      business_unit_cuid: businessUnit?.cuid!,
      purpose,
      tiering: tier!,
      owners: owners.map(o => o.cuid),
      template_cuid: templateCuid,
      status_cuid: statusCuid,
      group_cuid: group!.cuid,
      is_vendor_model: isVendorModel,
      vendor_name: isVendorModel ? vendorName : undefined,
      custom_fields: customFieldValueMap,
    };
    createInventoryModel.mutate(newModel);
    titleRef.current?.scrollIntoView();
  };

  const customFieldChunks = _.chunk(requiredCustomFields, 6);

  const steps = [
    { title: 'Step 1', description: 'Basic Model Information' },
    ...customFieldChunks.map((cf, idx) => ({
      title: `Step ${idx + 2}`,
      description: 'Additional Model Information',
    })),
  ];

  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: steps.length,
  });

  const isLastStep = activeStep === steps.length - 1;

  let submitButtonDisabled = false;

  if (activeStep === 0) {
    submitButtonDisabled = !(
      name &&
      creationState &&
      useCaseCuid &&
      businessUnit &&
      purpose &&
      tier &&
      group &&
      owners.length > 0 &&
      (!isVendorModel || vendorName) &&
      (creationState === 'existing' || templateCuid) &&
      (creationState === 'new' || statusCuid)
    );
  } else {
    submitButtonDisabled = !!requiredCustomFields.find(
      ({ key }) => fieldErrors[key].length > 0,
    );
  }

  const getVendorNames = useCallback<() => Promise<string[]>>(async () => {
    const accessToken = await getAccessTokenSilently();
    const res = await API.GetModelInventory(
      accessToken,
      undefined,
      undefined,
      1,
      10000,
    );
    return Promise.resolve(
      _.chain(res.results)
        .filter(model => !!model.is_vendor_model)
        .map(model => model.vendor_name!)
        .compact()
        .uniq()
        .sort()
        .value(),
    );
  }, []);

  const canCreateModel = userHasPermission(['create_model'], 'all');

  const options: InventoryModelCreationState[] = ['new', 'existing'];
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'creationState',
    defaultValue: '',
    onChange: (value: InventoryModelCreationState) => {
      if (value === 'existing') {
        setTemplateCuid('');
      } else {
        setStatusCuid('');
      }
      setCreationState(value);
    },
  });

  const statusByCategoryInWorkflow = useMemo(() => {
    return statusesWorkflows?.filter(category =>
      category.categories.includes('models'),
    );
  }, [statusesWorkflows]);

  return (
    <>
      {canCreateModel && (
        <Button
          variant="primary"
          data-testid="new-model-button"
          leftIcon={<Icon as={PlusIcon} boxSize={5} />}
          onClick={onOpen}
        >
          Register Model
        </Button>
      )}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size={'4xl'}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent my={5} maxHeight="calc(100% - 40px)">
          <ModalHeader ref={titleRef}>
            Register New Model
            <MoreInfoPopOver
              title="About Model Registration"
              link="https://docs.validmind.ai/guide/model-inventory/register-models-in-inventory.html"
              placement="bottom"
              iconProps={{
                ml: 2,
              }}
            />
          </ModalHeader>
          <ModalBody my={0} py={0}>
            {steps.length > 1 && (
              <Stepper size="sm" colorScheme="brand" index={activeStep} mb={5}>
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box>
                      <StepTitle as="h5">{step.title}</StepTitle>
                      <Text w={'full'} fontSize={'sm'} color={'neutral.500'}>
                        {step.description}
                      </Text>
                    </Box>

                    <StepSeparator />
                  </Step>
                ))}
              </Stepper>
            )}
          </ModalBody>
          <ModalCloseButton />
          <ModalBody pb={6} overflowY="scroll">
            <Box>
              {activeStep === 0 && (
                <SlideFade in={isOpen} offsetX="40px" offsetY={0} delay={0.1}>
                  <Stack spacing={8}>
                    {createInventoryModel.isError && (
                      <Alert status="error">
                        <AlertIcon />
                        <AlertTitle fontSize="xs">
                          Could not create model:
                        </AlertTitle>
                        <AlertDescription fontSize="xs">
                          {API.getAPIErrorMessage(createInventoryModel.error)}
                        </AlertDescription>
                      </Alert>
                    )}
                    <Text>
                      Register your model to access ValidMind's documentation
                      and validation features. Fill in all fields to complete
                      model registration.{' '}
                    </Text>

                    <FormControl>
                      <Label mb={2}>MODEL NAME</Label>
                      <Input
                        type="text"
                        value={name}
                        onChange={event => setName(event.target.value)}
                        focusBorderColor="brand.base"
                      />
                    </FormControl>

                    <Stack spacing={8}>
                      {groups.length > 1 && (
                        <Box>
                          <FormControl>
                            <Label mb={2}>GROUP</Label>
                            <GroupSelect group={group} onSelect={setGroup} />
                            <FormHelperText>
                              Determines which users are able to see this model.
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      )}
                      <Box>
                        <FormControl>
                          <Label mb={2}>What type of model is it?</Label>
                          <SimpleGrid columns={2} spacing="2">
                            {options.map(value => {
                              const radio = getRadioProps({ value });
                              return (
                                <RadioCard
                                  borderWidth={1}
                                  px={4}
                                  py={2}
                                  color={
                                    creationState === value
                                      ? 'brand.800'
                                      : useColorModeValue(
                                          'neutral.800',
                                          'neutral.200',
                                        )
                                  }
                                  _hover={{
                                    bg:
                                      creationState === value
                                        ? 'brand.50'
                                        : 'brandSecondary.50',
                                    borderColor:
                                      creationState === value
                                        ? 'brand.base'
                                        : 'brandSecondary.600',
                                  }}
                                  rounded={'md'}
                                  key={value}
                                  {...radio}
                                  transition={'all 0.3s ease-out'}
                                >
                                  <HStack alignItems={'flex-start'}>
                                    <VStack gap={0} alignItems={'flex-start'}>
                                      <Text fontWeight={'bold'}>
                                        {value == 'new'
                                          ? `New Model`
                                          : `Existing Model`}
                                      </Text>
                                      <Text>
                                        {value == 'new'
                                          ? `Never documented or validated`
                                          : `Previously documented or validated`}
                                      </Text>
                                    </VStack>
                                    <Spacer />
                                    <Icon
                                      as={CheckCircleIcon}
                                      color={
                                        creationState === value
                                          ? 'brand.base'
                                          : useColorModeValue(
                                              'neutral.200',
                                              'neutral.800',
                                            )
                                      }
                                      boxSize={5}
                                      transition={'all 0.3s ease-in-out'}
                                    />
                                  </HStack>
                                </RadioCard>
                              );
                            })}
                          </SimpleGrid>
                        </FormControl>
                      </Box>

                      <Collapse in={creationState === 'new'} animateOpacity>
                        <VStack gap={1} alignItems={'flex-start'}>
                          <FormControl>
                            <Label mb={2}>TEMPLATE</Label>
                            <TemplatesSelect
                              templates={templates.filter(
                                t => t.type === 'model_documentation',
                              )}
                              templateCuid={templateCuid}
                              setTemplateCuid={setTemplateCuid}
                            />
                            <FormHelperText>
                              New models use customizable documentation and
                              validation report templates from ValidMind
                            </FormHelperText>
                          </FormControl>
                        </VStack>
                      </Collapse>
                      <Collapse
                        in={creationState !== '' && creationState !== 'new'}
                        animateOpacity
                      >
                        <Box>
                          <FormControl>
                            <Label mb={2}>MODEL STATUS</Label>
                            <Select
                              placeholder="Select status"
                              value={statusCuid}
                              onChange={e => setStatusCuid(e.target.value)}
                            >
                              {statusByCategoryInWorkflow?.map(
                                (category, index) => (
                                  <>
                                    {category.statuses.map(status => (
                                      <option
                                        key={status.cuid}
                                        value={status.cuid}
                                      >
                                        {status.name}
                                      </option>
                                    ))}
                                  </>
                                ),
                              )}
                            </Select>
                            <FormHelperText>
                              Existing models should be registered with a status
                              that reflects your current inventory
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Collapse>
                    </Stack>

                    <SimpleGrid columns={2} spacing={8}>
                      <Box>
                        <FormControl>
                          <Label mb={2}>BUSINESS UNIT</Label>
                          <BusinessUnitsSelect
                            businessUnit={businessUnit}
                            setBusinessUnit={setBusinessUnit}
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl>
                          <Label mb={2}>USE CASE</Label>
                          <UseCasesSelect
                            useCaseCuid={useCaseCuid}
                            setUseCaseCuid={setUseCaseCuid}
                          />
                        </FormControl>
                      </Box>
                    </SimpleGrid>
                    <SimpleGrid columns={2} spacing={8}>
                      <FormControl>
                        <Label mb={2}>Is It a Vendor Model?</Label>
                        <HStack alignItems={'self-start'}>
                          <Switch
                            size={'md'}
                            isChecked={isVendorModel}
                            onChange={e => setIsVendorModel(e.target.checked)}
                            colorScheme="brand"
                            mt={1}
                          />
                          <Text>
                            Indicates a third-party model from an external
                            supplier
                          </Text>
                        </HStack>
                      </FormControl>
                      <FormControl
                        visibility={isVendorModel ? 'visible' : 'hidden'}
                      >
                        <Label mb={2}>Vendor Name</Label>
                        <AutoCompleteTextInput
                          value={vendorName}
                          onChange={(value: string) => {
                            setVendorName(value);
                          }}
                          options={getVendorNames}
                        />
                      </FormControl>
                    </SimpleGrid>
                    <FormControl>
                      <Label mb={2}>PURPOSE</Label>
                      <Textarea
                        placeholder={
                          'Describe how you plan to use this model in your organization.'
                        }
                        value={purpose}
                        onChange={event => setPurpose(event.target.value)}
                        rows={3}
                        focusBorderColor="brand.base"
                      ></Textarea>
                    </FormControl>
                    <SimpleGrid columns={2} spacing={8}>
                      <Stack py={2}>
                        <Label>PRELIMINARY RISK TIER</Label>
                        <Text>Classify the model by expected risk</Text>
                        <TierLevelBadgePicker
                          value={tier}
                          setValue={setTier}
                          size="sm"
                        />
                      </Stack>
                      <Stack py={2}>
                        <ManagedField
                          jsonSchema={{
                            label: 'Owners',
                            type: 'model-user',
                            props: {
                              many: true,
                            },
                            key: 'owners',
                            returnType: 'array',
                            isRequired: false,
                          }}
                          initialValue={owners.map(o => o.cuid)}
                          onEdit={async (newUserCUIDs, onSuccess) => {
                            setOwners(
                              users.filter(u => newUserCUIDs.includes(u.cuid)),
                            );
                            onSuccess();
                          }}
                          overrideDisplay={mode => {
                            if (mode !== 'edit' && owners.length === 0) {
                              return (
                                <EmptyStateDisplay variant="no-user">
                                  <Heading as={'h5'}>
                                    No owners have been set yet.
                                  </Heading>
                                  <Text align={'center'}>
                                    Click to add one.
                                  </Text>
                                </EmptyStateDisplay>
                              );
                            }
                            return null;
                          }}
                        />
                      </Stack>
                    </SimpleGrid>
                  </Stack>
                </SlideFade>
              )}
              {activeStep > 0 && (
                <SlideFade
                  in={activeStep > 0}
                  offsetX="40px"
                  offsetY={0}
                  delay={0.1}
                >
                  <SimpleGrid columns={2} spacing={8}>
                    {customFieldChunks[activeStep - 1].map(
                      (customField, idx) => {
                        const fieldValue = fieldValues[customField.key];
                        const fieldError = fieldErrors[customField.key];
                        const nextItem =
                          customFieldChunks[activeStep - 1][idx + 1];
                        let colspan = 1;

                        if (customField.type === 'multi-line') {
                          colspan = 2;
                        } else if (
                          idx % 2 === 0 &&
                          (!nextItem || nextItem.type === 'multi-line')
                        ) {
                          // if this item is even and the next item is null
                          // or a multi-line field, make it span 2 columns
                          colspan = 2;
                        }
                        return (
                          <GridItem key={customField.key} colSpan={colspan}>
                            <HStack alignItems={'flex-start'} gap={0}>
                              <Label mb={2}>{customField.label}</Label>
                              {customField.description && (
                                <MoreInfoPopOver
                                  title={customField.label}
                                  description={customField.description}
                                  placement="bottom"
                                  iconProps={{
                                    ml: 2,
                                    mt: -0.5,
                                  }}
                                />
                              )}
                            </HStack>
                            <CustomFieldRenderer
                              ref={ref => {
                                fieldRefs[customField.key] = ref;
                              }}
                              schema={customField}
                              value={fieldValue}
                              onChange={value => {
                                setFieldValue(customField.key, value);
                              }}
                              mode="edit"
                              onError={errors => {
                                setFieldError(customField.key, errors);
                              }}
                            />
                            {fieldError && fieldError.length > 0 && (
                              <Text fontSize="small" color="red.500">
                                {fieldError[0]}
                              </Text>
                            )}
                          </GridItem>
                        );
                      },
                    )}
                  </SimpleGrid>
                </SlideFade>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            {activeStep > 0 && !createInventoryModel.isLoading && (
              <Button
                mr={2}
                onClick={() => {
                  goToPrevious();
                }}
                // isLoading={createInventoryModel.isLoading}
                leftIcon={<Icon as={ChevronLeftIcon} boxSize={4} />}
              >
                Back
              </Button>
            )}
            <Button
              variant={'ghost'}
              onClick={onClose}
              disabled={createInventoryModel.isLoading}
            >
              Cancel
            </Button>
            <Spacer />
            <Button
              size={'sm'}
              variant={'primary'}
              onClick={() => {
                if (activeStep > 0) {
                  let hasErrors = false;

                  customFieldChunks[activeStep - 1]
                    .map(customField => customField.key)
                    .forEach(customFieldKey => {
                      const errors = fieldRefs[customFieldKey].validate();
                      if (errors.length > 0) {
                        hasErrors = true;
                        setFieldError(customFieldKey, errors);
                      }
                    });

                  if (hasErrors) {
                    return;
                  }
                }
                if (!isLastStep) {
                  goToNext();
                } else {
                  handleSubmit();
                }
              }}
              isDisabled={
                submitButtonDisabled || createInventoryModel.isLoading
              }
              isLoading={createInventoryModel.isLoading}
              rightIcon={
                !isLastStep ? (
                  <Icon as={ChevronRightIcon} boxSize={4} />
                ) : undefined
              }
            >
              {isLastStep ? 'Register Model' : 'Next'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
