import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Switch,
  useColorModeValue,
} from '@chakra-ui/react';
import { Label } from '../Layout';
import { DragHandleIcon, MinusIcon } from '@chakra-ui/icons';
// import { MinusIcon } from '@heroicons/react/24/solid';

export type Option = { id: string; label: string };

export type SortableItemType = Option & {
  isChecked: boolean;
  onToggle?: (checked: boolean) => void;
  onRemove?: (id: string) => void;
  textProps?: any;
  textTransform?: 'uppercase' | 'capitalize' | 'lowercase' | 'none';
  size?: 'sm' | 'md';
};

const SortableItem = ({
  id,
  label,
  isChecked,
  onToggle,
  onRemove,
  textProps,
  textTransform = 'uppercase',
  size = 'md',
}: SortableItemType) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <HStack
      p={size === 'sm' ? 0 : 2}
      pr={size === 'sm' ? 1 : 2}
      w={'full'}
      style={style}
      rounded={'sm'}
      {...attributes}
      role={'group'}
      ref={setNodeRef}
      // border={'1px solid'}
      zIndex={isDragging ? 100 : 'auto'}
      transition={'bg 0.2s ease-in-out'}
      // bg={useColorModeValue('white', 'neutral.850')}
      borderColor={useColorModeValue('neutral.200', 'neutral.800')}
      _hover={{ bg: useColorModeValue('brandSecondary.25', 'neutral.800') }}
    >
      {onToggle && (
        <HStack alignItems="flex-start">
          <Switch
            size={'sm'}
            id={id}
            isChecked={isChecked}
            colorScheme="brand"
            onChange={() => onToggle(!isChecked)}
            hidden={!onToggle}
            mt={0.5}
          ></Switch>
          <FormLabel {...textProps} htmlFor={id} m={0}>
            {label}
          </FormLabel>
        </HStack>
      )}

      {onRemove && (
        <IconButton
          // isRound={true}
          aria-label="Remove"
          icon={<Icon as={MinusIcon} boxSize={size === 'sm' ? 3 : 4} />}
          onClick={() => onRemove(id)}
          size={'xs'}
          variant={'ghost'}
        />
      )}
      {!onToggle && <Label {...textProps}>{label}</Label>}
      <Spacer />
      <Icon
        as={DragHandleIcon}
        {...listeners}
        cursor={'grab'}
        visibility={'hidden'}
        color={'neutral.500'}
        _groupHover={{
          visibility: 'visible',
          // color: useColorModeValue('neutral.400', 'neutral.400')
        }}
        transition={'color 0.4s ease-in-out'}
        boxSize={size === 'sm' ? 3 : 4}
      />
    </HStack>
  );
};

export default SortableItem;
