import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from 'react-query';
import API from '../../api/API';
import { TUser } from '../../models';
import { DashboardLayouts, Onboarding, ReportLayouts } from '../../models/user';
import { useContext, useMemo } from 'react';
import UsersContext from '../../contexts/UsersContext';
import _ from 'lodash';
import {
  AddToEndOfLayout,
  DEFAULT_DASHBOARD_LAYOUTS,
  GRID_COLUMN_AMOUNT,
} from '../../layoutUtils';
import { BarCharts } from '../../components/ReportChart';
import { ReportCountMap } from '../../components/ReportCount';

export const CURRENT_REPORT_LAYOUT_VERSION = 2;
export const CURRENT_DASHBOARD_LAYOUT_VERSION = 1;

const COUNT_WIDGET_WIDTH = 1;
const COUNT_WIDGET_HEIGHT = 2;

const CHART_WIDGET_WIDTH = 2;
const CHART_WIDGET_HEIGHT = 4;

const getDefaultReportLayout = () => {
  const modelsLayout = { lg: [] } as any;
  const findingsLayout = { lg: [] } as any;

  const modelCountKeys = Object.keys(ReportCountMap).filter(
    key => ReportCountMap[key].category === 'models',
  );
  const findingCountKeys = Object.keys(ReportCountMap).filter(
    key => ReportCountMap[key].category === 'findings',
  );

  const modelChartKeys = Object.keys(BarCharts).filter(
    key => BarCharts[key].category === 'models',
  );
  const findingChartKeys = Object.keys(BarCharts).filter(
    key => BarCharts[key].category === 'findings',
  );

  // We have custom logic to set the count's width
  _.chunk(findingCountKeys, GRID_COLUMN_AMOUNT).forEach(chunk => {
    let countWidth = 1;

    if (chunk.length === 1) {
      countWidth = GRID_COLUMN_AMOUNT;
    } else if (chunk.length === 2) {
      countWidth = GRID_COLUMN_AMOUNT / 2;
    }

    chunk.forEach(key => {
      AddToEndOfLayout(findingsLayout.lg, {
        i: key,
        w: countWidth,
        h: COUNT_WIDGET_HEIGHT,
      });
    });
  });

  findingChartKeys.forEach(key => {
    AddToEndOfLayout(findingsLayout.lg, {
      i: key,
      w: CHART_WIDGET_WIDTH,
      h: CHART_WIDGET_HEIGHT,
    });
  });

  // We have custom logic to set the count's width
  _.chunk(modelCountKeys, GRID_COLUMN_AMOUNT).forEach(chunk => {
    let countWidth = 1;

    if (chunk.length === 1) {
      countWidth = GRID_COLUMN_AMOUNT;
    } else if (chunk.length === 2) {
      countWidth = GRID_COLUMN_AMOUNT / 2;
    }

    chunk.forEach(key => {
      AddToEndOfLayout(modelsLayout.lg, {
        i: key,
        w: countWidth,
        h: COUNT_WIDGET_HEIGHT,
      });
    });
  });

  modelChartKeys.forEach(key => {
    AddToEndOfLayout(modelsLayout.lg, {
      i: key,
      w: CHART_WIDGET_WIDTH,
      h: CHART_WIDGET_HEIGHT,
    });
  });

  return {
    version: CURRENT_REPORT_LAYOUT_VERSION,
    findings: findingsLayout,
    models: modelsLayout,
  };
};

const defaultOnboardingSettings: Onboarding = {
  isDismissed: false,
  getAccount: true,
  registerModel: false,
  startDocumentation: false,
  collaborateDocumentation: false,
  submitDocumentation: false,
  findResources: false,
};

export function useUserUISettings() {
  const { getAccessTokenSilently } = useAuth0();
  const { currentUser: user, setCurrentUser } = useContext(UsersContext);

  const patchUISettings = useMutation(
    [],
    async ({ user }: { user: TUser }) => {
      const accessToken = await getAccessTokenSilently();
      return await API.PatchCurrentUserUISettings(
        accessToken,
        user.ui_settings,
      );
    },
    {},
  );

  const updateOnboarding = (updates: Partial<Onboarding>) => {
    let onboarding: Onboarding = user?.ui_settings.onboarding || {};
    const updatedUser = {
      ...user,
      ui_settings: {
        ...user?.ui_settings,
        onboarding: { ...onboarding, ...updates },
      },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  const updateColorMode = (colorMode: string = 'light') => {
    const updatedUser = {
      ...user,
      ui_settings: {
        ...user?.ui_settings,
        colorMode,
      },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  const resetOnboarding = () => {
    updateOnboarding(defaultOnboardingSettings);
  };

  const getColorModeSetting = (): string => {
    return user?.ui_settings?.colorMode || 'light';
  };

  const getOnboardingSetting = (name: keyof Onboarding): boolean => {
    return user?.ui_settings?.onboarding?.[name] ?? false;
  };

  const setOnboardingSetting = (
    name: keyof Onboarding,
    value: boolean,
  ): void => {
    let onboarding: Onboarding = user?.ui_settings.onboarding || {};
    onboarding[name] = value;
    const updatedUser = {
      ...user,
      ui_settings: { ...user?.ui_settings, onboarding },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  const isCompleted = useMemo(() => {
    return _.every(
      _.omit(user?.ui_settings.onboarding, 'isDismissed'),
      value => value === true,
    );
  }, [user?.ui_settings.onboarding]);

  const getModelInventoryColumns = (): string[] | undefined => {
    return user?.ui_settings?.modelInventoryColumns?.columns;
  };

  const updateModelInventoryColumns = (modelInventoryColumns: string[]) => {
    const updatedUser = {
      ...user,
      ui_settings: {
        ...user?.ui_settings,
        modelInventoryColumns: {
          version: '1.0',
          columns: modelInventoryColumns,
        },
      },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  const getModelFindingsColumns = (): string[] | undefined => {
    return user?.ui_settings?.modelFindingsColumns?.columns;
  };

  const updateModelFindingsColumns = (modelFindingsColumns: string[]) => {
    const updatedUser = {
      ...user,
      ui_settings: {
        ...user?.ui_settings,
        modelFindingsColumns: {
          version: '1.0',
          columns: modelFindingsColumns,
        },
      },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  const getDisplayTableModel = (): boolean => {
    if (user?.ui_settings?.displayTableModel !== undefined) {
      return user?.ui_settings?.displayTableModel!;
    }
    return true;
  };

  const updateDisplayTableModel = (displayTableModel: boolean) => {
    const updatedUser = {
      ...user,
      ui_settings: {
        ...user?.ui_settings,
        displayTableModel,
      },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  const getHideVMInsightsSidebar = () => {
    if (user?.ui_settings?.hideVMInsightsSidebar !== undefined) {
      return user?.ui_settings?.hideVMInsightsSidebar!;
    }
    return true;
  };

  const updateHideVMInsightsSidebar = (hideVMInsightsSidebar: boolean) => {
    const updatedUser = {
      ...user,
      ui_settings: {
        ...user?.ui_settings,
        hideVMInsightsSidebar,
      },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  const getUserReportLayouts = (): ReportLayouts => {
    return user?.ui_settings.reportLayouts || getDefaultReportLayout();
  };

  const setUserReportLayouts = (reportLayouts: ReportLayouts) => {
    const updatedUser = {
      ...user,
      ui_settings: {
        ...user?.ui_settings,
        reportLayouts,
      },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  const getDashboardLayouts = (): DashboardLayouts => {
    return user?.ui_settings.dashboardLayouts || DEFAULT_DASHBOARD_LAYOUTS;
  };

  const setDashboardLayouts = (dashboardLayouts: DashboardLayouts) => {
    const updatedUser = {
      ...user,
      ui_settings: {
        ...user?.ui_settings,
        dashboardLayouts,
      },
    } as TUser;
    setCurrentUser(updatedUser);
    patchUISettings.mutate({ user: updatedUser });
  };

  return {
    user,
    updateOnboarding,
    resetOnboarding,
    getOnboardingSetting,
    setOnboardingSetting,
    getColorModeSetting,
    patchUISettings,
    defaultOnboardingSettings,
    isCompleted,
    updateColorMode,
    getModelInventoryColumns,
    updateModelInventoryColumns,
    getModelFindingsColumns,
    updateModelFindingsColumns,
    getDisplayTableModel,
    updateDisplayTableModel,
    getHideVMInsightsSidebar,
    updateHideVMInsightsSidebar,
    getUserReportLayouts,
    setUserReportLayouts,
    getDashboardLayouts,
    setDashboardLayouts,
  };
}
