import { TInventoryModel } from '../../models/inventory_model';
import DocumentHeader, {
  getDocumentHeaderBreadcrumbs,
} from '../DocumentHeader';
import { useMemo } from 'react';

interface Props {
  inventoryModel: TInventoryModel;
}

const ValidationReportHeader = ({ inventoryModel }: Props) => {
  const breadcrumbLinks = useMemo(
    () => getDocumentHeaderBreadcrumbs(inventoryModel, 'Validation Summary'),
    [inventoryModel],
  );

  return (
    <DocumentHeader
      inventoryModel={inventoryModel}
      breadcrumbLinks={breadcrumbLinks}
    />
  );
};

export default ValidationReportHeader;
