import { Handle, NodeProps, Position } from 'reactflow';
import {
  Alert,
  AlertIcon,
  Button,
  HStack,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { generateRandomCodeForNodes } from '../../../../../../../utils';
import NodeBox from '../../../layouts/NodeBox';
import { Cog6ToothIcon } from '@heroicons/react/20/solid';
import useWorkflow from '../../../../../../../hooks/useWorkflow';
import { BranchRouterNodeType } from '../../../types';
import { useMemo } from 'react';
import { Branch } from '../../../Panels/BranchPanel';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';

function BranchRouterNode(props: NodeProps) {
  const { setSelectedNodeId } = useWorkflow();

  if (props.data.state_callbacks.on_enter[0].args.branches.length === 0) {
    return (
      <NodeBox
        title={'CONDITION BRANCH'}
        icon={ArrowsPointingOutIcon}
        bg={useColorModeValue('neutral.50', 'neutral.800')}
        border={2}
        borderColor={useColorModeValue('neutral.200', 'neutral.700')}
        borderStyle={'solid'}
        nodeProps={props}
        rounded={'lg'}
      >
        <HStack px={2} pb={2} w={'full'}>
          <Button
            leftIcon={<Icon as={Cog6ToothIcon} boxSize={4} />}
            onClick={() => setSelectedNodeId!(props.id)}
            size={'sm'}
            w={'full'}
          >
            Configure
          </Button>
        </HStack>
      </NodeBox>
    );
  }

  const DEFAULT_HANDLE_STYLES = {
    background: useColorModeValue(
      'var(--chakra-colors-neutral-200)',
      'var(--chakra-colors-neutral-800)',
    ),
    borderColor: useColorModeValue(
      'var(--chakra-colors-neutral-50)',
      'var(--chakra-colors-neutral-500)',
    ),
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '50%',
    height: 16,
    width: 16,
  };

  const hasAtLeastTwoEqual = useMemo(() => {
    const items = props.data.state_callbacks.on_enter[0].args.branches.map(
      (b: Branch) => b.query,
    );
    const itemSet = new Set<string>();
    for (const item of items) {
      if (itemSet.has(item)) {
        return true;
      }
      itemSet.add(item);
    }
    return false;
  }, [props.data.state_callbacks.on_enter[0].args.branches]);

  return (
    <NodeBox
      title={'CONDITION BRANCH'}
      icon={ArrowsPointingOutIcon}
      bg={useColorModeValue('neutral.50', 'neutral.800')}
      border={2}
      borderColor={useColorModeValue('neutral.200', 'neutral.700')}
      borderStyle={'solid'}
      nodeProps={props}
      rounded={'lg'}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ ...DEFAULT_HANDLE_STYLES, top: -8 }}
      />
      <Stack alignItems={'flex-start'} px={2} pb={2}>
        <VStack gap={0} alignItems={'flex-start'} pt={1}>
          <Text fontSize={'xs'}>
            {props.data.state_callbacks.on_enter[0].args.branches.length} path
            {props.data.state_callbacks.on_enter[0].args.branches.length > 1 &&
              `s`}
          </Text>
        </VStack>

        {hasAtLeastTwoEqual && (
          <Alert status="warning" size={'xs'}>
            <AlertIcon />
            <Text fontSize={'xs'}>Conditions must be mutually exclusive</Text>
          </Alert>
        )}
      </Stack>

      <Handle
        type="source"
        position={Position.Bottom}
        style={{ ...DEFAULT_HANDLE_STYLES, bottom: -8 }}
      />
    </NodeBox>
  );
}

BranchRouterNode.type = 'branch_router';
BranchRouterNode.autoRuns = true;

BranchRouterNode.getDefaultNode = (): BranchRouterNodeType => {
  return {
    id: `${BranchRouterNode.type}_${generateRandomCodeForNodes()}`,
    type: BranchRouterNode.type,
    data: {
      state_callbacks: {
        on_enter: [
          {
            func: 'run_branch_resolver',
            args: {
              branches: [
                {
                  trigger_id: null,
                  name: 'default',
                  query: '{"and": [true]}',
                  is_default: true,
                },
              ],
            },
          },
        ],
        on_exit: [],
      },
      transition_callbacks: {
        conditions: [],
        prepare: [],
        before: [],
        after: [],
      },
    },
    position: { x: 0, y: 0 },
  };
};

export default BranchRouterNode;
