import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { Label } from '../Layout';
import {
  Box,
  Heading,
  Icon,
  ListItem,
  Stack,
  Text,
  Link,
  UnorderedList,
  Tag,
} from '@chakra-ui/react';
import { Template } from '../../models';
import { TemplateVersion } from '../../models/template';
import { TInventoryModel } from '../../models/inventory_model';
import { CubeTransparentIcon } from '@heroicons/react/24/outline';
import { DataTable } from '../DataTable';
import { useNavigate } from 'react-router-dom';
import InventoryModelTierLevelBadge from '../InventoryModelTierLevelBadge';
import { LoadingContainer } from '../LoadingContainer';
import { EmptyStateDisplay } from '../EmptyStateDisplay';
import { removeAfterDot } from '../../utils';

interface TemplateVersionModelsAsTableProps {
  models: TInventoryModel[];
  isLoading: boolean;
}

interface TemplateVersionModelsProps {
  documentType: string;
  template: Template;
  templateVersion: TemplateVersion;
  variant?: 'default' | 'table';
  onEmptyCheck?: (isEmpty: boolean) => void;
}

function TemplateVersionModelsAsTable({
  models,
  isLoading,
}: TemplateVersionModelsAsTableProps) {
  const navigate = useNavigate();

  const tableColumns = [
    {
      Header: ' ',
      Cell: ({ row }: any) => {
        return (
          <Icon boxSize={6} color={'neutral.400'} as={CubeTransparentIcon} />
        );
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ row }: any) => {
        return <Box fontWeight={'bold'}>{row.values.name}</Box>;
      },
    },
    {
      Header: 'Tier',
      accessor: 'tiering',
      Cell: ({ value }: any) => {
        return <InventoryModelTierLevelBadge level={value} />;
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: any) => {
        return (
          <Tag
            data-testid="status-badge"
            size="sm"
            colorScheme={
              value ? removeAfterDot(value.colors.primary) : 'neutral'
            }
          >
            {value ? value.name : 'No Status'}
          </Tag>
        );
      },
    },
  ];
  return (
    <LoadingContainer isLoading={isLoading}>
      <Stack>
        {(models || []).length > 0 ? (
          <DataTable
            data={models}
            columns={tableColumns}
            enableSort={false}
            pageSize={10}
            isInteractive={true}
            onClickRow={(row: any) => {
              navigate(`/model-inventory/${row.original.cuid}/overview`);
            }}
          />
        ) : (
          <EmptyStateDisplay variant="no-models">
            <Heading as={'h5'}>
              No models using this version of the template
            </Heading>
            <Text align={'center'}>
              If you'd like to use this version of the template, find a model
              and swap it's template to this.
            </Text>
          </EmptyStateDisplay>
        )}
      </Stack>
    </LoadingContainer>
  );
}

export default function TemplateVersionModels({
  documentType,
  template,
  templateVersion,
  variant = 'default',
  onEmptyCheck,
}: TemplateVersionModelsProps) {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { data: models, isLoading } = useQuery(
    ['template', template.cuid, 'version', templateVersion.cuid, 'models'],
    async () => {
      const token = await getAccessTokenSilently();
      const response = API.GetModelsByTemplateVersion(
        token,
        documentType,
        template,
        templateVersion,
      );
      return response;
    },
    {
      onSuccess: data => {
        if (onEmptyCheck) {
          onEmptyCheck(data.length === 0);
        }
      },
    },
  );

  function goToModelPage(model: TInventoryModel) {
    navigate(`/model-inventory/${model.cuid}/overview`);
  }

  if (variant === 'table') {
    return (
      <TemplateVersionModelsAsTable
        models={models || []}
        isLoading={isLoading}
      />
    );
  }

  return (
    <>
      <Stack>
        <Label>{`Version ${templateVersion.version_number}`}</Label>
        <UnorderedList>
          {(models || []).length > 0 ? (
            <>
              {(models || []).map(model => {
                return (
                  <ListItem>
                    <Link
                      data-testid="model-using-template"
                      onClick={() => goToModelPage(model)}
                    >
                      {model.name}
                    </Link>
                  </ListItem>
                );
              })}
            </>
          ) : (
            <Text fontSize={'x-small'}>-</Text>
          )}
        </UnorderedList>
      </Stack>
    </>
  );
}
