import { Badge, Box, Heading, SimpleGrid, VStack } from '@chakra-ui/react';

const StyleGuideBadge = ({
  colors,
  sizes,
}: {
  colors: string[];
  sizes: string[];
}) => {
  return (
    <Box>
      <Heading as={'h2'}>Badge</Heading>
      <SimpleGrid columns={3} spacing={4}>
        {sizes.map(fs => (
          <Badge variant="solid" colorScheme={'gray'} mb={1} fontSize={fs}>
            {fs}
          </Badge>
        ))}
      </SimpleGrid>
      <SimpleGrid columns={[1, 2]} spacing={4}>
        {colors.map(color => (
          <VStack key={color} gap={1} alignItems={'flex-start'}>
            <Heading as="h3" size="sm">
              {color}
            </Heading>
            <Badge variant="outline" colorScheme={color} mb={1}>
              Outline {color}
            </Badge>
            <Badge variant="solid" colorScheme={color} mb={1}>
              Solid {color}
            </Badge>
            <Badge variant="subtle" colorScheme={color} mb={1}>
              Subtle {color}
            </Badge>
          </VStack>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default StyleGuideBadge;
