import { TemplateSectionContents } from '../../models/template';
import { ModelDocumentTypeEnum } from '../../models/model_document';
import React from 'react';
import ContentRowMetadata from './ContentRowMetadata';
import ContentRowTest from './ContentRowTest';
import { Text } from '@chakra-ui/react';

interface SupportedContentRowTemplates {
  [key: string]: React.FC<ContentRowProps>;
}

const SUPPORTED_CONTENT_ROW_TEMPLATES: SupportedContentRowTemplates = {
  text: ContentRowMetadata,
  metadata_text: ContentRowMetadata,
  metric: ContentRowTest,
  test: ContentRowTest,
};

export interface ContentRowProps {
  documentType: ModelDocumentTypeEnum;
  content: TemplateSectionContents;
  isChecked?: boolean;
  onCheck?: (content: TemplateSectionContents) => void;
  onRemoveContent?: () => void;
}

export default function ContentRow({
  content,
  documentType,
  isChecked = false,
  onCheck,
  onRemoveContent,
}: ContentRowProps) {
  if (!(content.content_type in SUPPORTED_CONTENT_ROW_TEMPLATES)) {
    console.log(
      `Unsupported content template (content_id=${content.content_id}, content_type=${content.content_type})`,
    );
    return (
      <Text>
        content_id=${content.content_id}, content_type=${content.content_type}
      </Text>
    );
  }
  const Row =
    SUPPORTED_CONTENT_ROW_TEMPLATES[content.content_type as keyof object];
  return (
    <Row
      documentType={documentType}
      content={content}
      isChecked={isChecked}
      onCheck={onCheck}
      onRemoveContent={onRemoveContent}
    />
  );
}
