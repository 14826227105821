import { FieldDisplayProps } from '../../index';
import { VStack, Text, Button } from '@chakra-ui/react';
import NullFieldDisplay from '../NullFieldDisplay';

const URIFieldDisplay = ({ field }: FieldDisplayProps) => {
  const fieldHasValue = !!field.value;
  if (fieldHasValue) {
    return (
      <VStack>
        <a href={field.value} target="_blank">
          <Button>Go to URL</Button>
        </a>
        <Text fontSize={'sm'}>{field.value}</Text>
      </VStack>
    );
  }
  return <NullFieldDisplay />;
};

export default URIFieldDisplay;
