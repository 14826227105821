import { Box, CircularProgress, Heading } from '@chakra-ui/react';

const StyleGuideCircularProgress = () => {
  return (
    <Box>
      <Heading as={'h2'}>CircularProgress</Heading>
      <CircularProgress isIndeterminate />
    </Box>
  );
};

export default StyleGuideCircularProgress;
