import {
  Box,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Wrap,
} from '@chakra-ui/react';
import React from 'react';

const colors = [
  'neutral.500',
  'red.500',
  'sunset.500',
  'orange.500',
  'amber.500',
  'yellow.500',
  'citron.500',
  'lime.500',
  'green.500',
  'mint.500',
  'teal.500',
  'cyan.500',
  'blue.500',
  'indigo.500',
  'purple.500',
  'pink.500',
  'rose.500',
  'emerald.500',
  'sky.500',
  'violet.500',
  'fuchsia.500',
  'gray.500',
];

type ColorPickerProps = {
  selectedColorHex: string;
  colorsHex?: string[];
  onChange: (color: string) => void;
};

const ColorPicker: React.FC<ColorPickerProps> = ({
  selectedColorHex,
  colorsHex = colors,
  onChange,
}) => {
  return (
    <Popover placement="right">
      <PopoverTrigger>
        <Box
          borderWidth={1}
          borderColor={'var(--chakra-colors-chakra-border-color)'}
          borderStyle="solid"
          p={2}
          borderRadius={'md'}
          w={14}
          h={14}
        >
          <Box bgColor={selectedColorHex} w="full" h="full" cursor="pointer" />
        </Box>
      </PopoverTrigger>
      <PopoverContent maxW={80} padding={2}>
        <PopoverArrow />
        <Wrap spacing={1}>
          {colorsHex.map(color => (
            <Box
              key={color}
              bg={color}
              w={9}
              h={9}
              cursor="pointer"
              borderWidth={color === selectedColorHex ? '2px' : '0'}
              borderColor={
                color === selectedColorHex ? 'brand.base' : 'gray.300'
              }
              onClick={() => onChange(color)}
              transition="transform 0.2s"
              _hover={{ transform: 'scale(1.1)' }}
              rounded={'sm'}
            />
          ))}
        </Wrap>
      </PopoverContent>
    </Popover>
  );
};

export default ColorPicker;
