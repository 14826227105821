import { useLocation } from 'react-router-dom';
import { Fragment, useContext } from 'react';
import SidebarContext from '../../../contexts/SidebarContext';
import {
  Box,
  Collapse,
  Divider,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { SmartLink } from '../../SmartLink';
import ApprovalBadge from '../../ApprovalsBadge';

export interface IMenuItem {
  key: string;
  label: string;
  path: string;
  icon: any;
  secondaryIcon?: any;
  submenuItems?: IMenuItem[];
  show?: boolean;
}

interface MenuItemProps {
  itemKey: string;
  label: string;
  path: string;
  icon: any;
  secondaryIcon?: any;
  submenuItems?: IMenuItem[];
  isSubmenuItem?: boolean;
}
const MenuItem = ({
  itemKey,
  label,
  icon,
  secondaryIcon,
  path,
  submenuItems,
  isSubmenuItem,
}: MenuItemProps) => {
  const location = useLocation();
  const { isExpanded } = useContext(SidebarContext);
  let isCurrentPage = false;
  const currentPath = location.pathname.concat(location.hash);
  if (currentPath.includes(path!)) {
    isCurrentPage = true;
  } else if (
    currentPath.includes('/documentation') &&
    path.includes('/documentation-overview')
  ) {
    isCurrentPage = true;
  }
  const hasSubmenu = !!submenuItems?.length;
  const calculatePaddingLeft = () => {
    switch (true) {
      case hasSubmenu:
        return 1;
      case isExpanded && !hasSubmenu:
        return 6;
      default:
        return 0;
    }
  };
  return (
    <Tooltip
      label={label}
      placement="right"
      isDisabled={isExpanded || hasSubmenu}
      openDelay={50}
      closeDelay={25}
      hasArrow={false}
      gutter={-1}
      bg={useColorModeValue('neutral.100', 'brandSecondary.950')}
      color={
        isCurrentPage
          ? useColorModeValue('brand.600', 'brand.base')
          : useColorModeValue('brand.dark', 'neutral.200')
      }
      py={3}
      roundedBottomRight={4}
      shadow={'md'}
    >
      <Flex
        direction={'column'}
        pt={3}
        pr={hasSubmenu ? 1 : 0}
        pb={hasSubmenu ? 1 : 3}
        pl={calculatePaddingLeft()}
        transition={'background 0.5s'}
        // roundedRight={isSubmenuItem ? 0 : 6}
        boxShadow={
          isCurrentPage && !hasSubmenu
            ? 'inset 4px 0px 0px 0px var(--chakra-colors-brand-base)'
            : 0
        }
        cursor={isCurrentPage && hasSubmenu ? 'default' : 'pointer'}
        bg={
          hasSubmenu
            ? useColorModeValue('neutral.100', 'whiteAlpha.300')
            : 'transparent'
        }
        color={
          isCurrentPage && !hasSubmenu
            ? useColorModeValue('brand.600', 'brand.base')
            : useColorModeValue('neutral.600', 'neutral.300')
        }
        // roundedLeft={(isCurrentPage && !hasSubmenu) || isSubmenuItem ? 0 : 6}
        _hover={{
          bg: hasSubmenu
            ? useColorModeValue('neutral.100', 'whiteAlpha.300')
            : useColorModeValue('brandSecondary.25', 'brand.950'),
          color:
            isCurrentPage && !hasSubmenu
              ? useColorModeValue('brand.700', 'brand.base')
              : useColorModeValue('brandSecondary.base', 'brandSecondary.25'),
        }}
      >
        <SmartLink
          data-testid={`MenuItem-${itemKey}`}
          to={path}
          isExternal={path.startsWith('http')}
        >
          <HStack
            justify={isExpanded ? 'flex-start' : 'center'}
            pr={isExpanded ? 1 : 0}
            pb={hasSubmenu ? 3 : 0}
            pl={hasSubmenu && isExpanded ? 5 : 0}
            alignItems={'flex-start'}
            gap={2.5}
          >
            <Icon as={icon} boxSize={6} />

            <HStack hidden={!isExpanded} pt={0.5}>
              <Text fontWeight={'bold'}>
                {label} {itemKey === 'approvals' && <ApprovalBadge />}
              </Text>
              {secondaryIcon && (
                <Icon
                  as={secondaryIcon}
                  boxSize={3}
                  hidden={!isExpanded}
                  color={
                    isCurrentPage && !hasSubmenu
                      ? useColorModeValue('brand.600', 'brand.400')
                      : useColorModeValue('neutral.700', 'neutral.300')
                  }
                />
              )}
            </HStack>
          </HStack>
        </SmartLink>

        <Collapse in={hasSubmenu} animateOpacity>
          <Stack
            bg={useColorModeValue('white', 'neutral.900')}
            rounded={3}
            borderRadius={4}
            gap={0}
          >
            {submenuItems?.map(item => (
              <Fragment key={item.key}>
                <MenuItem
                  itemKey={item.key}
                  label={item.label}
                  icon={item.icon}
                  path={item.path}
                  isSubmenuItem={true}
                />
                {item.key.endsWith('overview') && (
                  <Box mt={'0 !important'}>
                    <Divider
                      borderColor={useColorModeValue(
                        'neutral.300',
                        'neutral.700',
                      )}
                    />
                  </Box>
                )}
              </Fragment>
            ))}
          </Stack>
        </Collapse>
      </Flex>
    </Tooltip>
  );
};

export default MenuItem;
