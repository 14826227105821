import {
  Box,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  useColorModeValue,
} from '@chakra-ui/react';
import { CustomFieldProps, CustomFieldSchemaArrayProps } from '../types';
import { useCallback, useMemo, useState } from 'react';
import { Select } from 'chakra-react-select';
import NullFieldDisplay from '../Generic/NullFieldDisplay';

const ArrayFieldDisplay = ({ value }: { value: any[] }) => {
  return (
    <HStack spacing={2}>
      <Wrap>
        {value.map((v: string) => (
          <WrapItem>
            <Tag key={v} variant="solid">
              {v}
            </Tag>
          </WrapItem>
        ))}
      </Wrap>
    </HStack>
  );
};

export default function CustomSelect({
  mode,
  value,
  onChange,
  schema,
}: CustomFieldProps) {
  const schemaProps = schema.props as CustomFieldSchemaArrayProps;

  const initialValue = (() => {
    if (value) {
      if (schema.type === 'single-select') {
        return {
          value,
          label: value,
        };
      } else {
        return value.map((v: any) => ({
          value: v,
          label: v,
        }));
      }
    }
  })();
  const [localValue, setLocalValue] = useState<any>(initialValue);
  const multiple = schema.type === 'multiple';

  const options = useMemo(() => {
    return (
      schemaProps.items?.map((i: any) => ({
        value: i,
        label: i,
      })) || []
    );
  }, [schema.props]);

  const onChangeCallback = useCallback(
    (newValue: any) => {
      setLocalValue(newValue);
      if (schema.type === 'single-select') {
        onChange(newValue.value);
      } else if (schema.type === 'multiple') {
        onChange(newValue.map(({ value }: any) => value));
      }
    },
    [schema.type],
  );

  if (mode === 'display' || mode === 'read-only') {
    if (value) {
      let valueAsArray = value;

      if (!Array.isArray(value)) {
        valueAsArray = [value];
      }

      return <ArrayFieldDisplay value={valueAsArray} />;
    } else if (mode === 'read-only') {
      return <NullFieldDisplay />;
    } else {
      return null;
    }
  }

  return (
    <Box bg={useColorModeValue('white', 'neutral.850')} rounded={'md'}>
      <Select
        isMulti={multiple}
        options={options}
        onChange={onChangeCallback}
        value={localValue}
        isClearable={false}
        menuPosition="fixed"
        focusBorderColor="brand.base"
        chakraStyles={{
          placeholder: provided => ({
            ...provided,
            paddingLeft: '3px',
          }),
        }}
      />
    </Box>
  );
}
