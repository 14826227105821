import {
  Box,
  Heading,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from '@chakra-ui/react';
import { useOrganizations } from '../../hooks/admin';

function Home() {
  const { organizations, isLoading } = useOrganizations();
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Box>
      <Heading mb={6}>Admin Dashboard</Heading>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
        <Stat
          p={6}
          bg={bgColor}
          borderRadius="lg"
          border="1px"
          borderColor={borderColor}
          shadow="sm"
        >
          <StatLabel fontSize="lg">Total Organizations</StatLabel>
          <StatNumber fontSize="4xl">
            {isLoading ? '-' : organizations?.length || 0}
          </StatNumber>
        </Stat>

        <Stat
          p={6}
          bg={bgColor}
          borderRadius="lg"
          border="1px"
          borderColor={borderColor}
          shadow="sm"
        >
          <StatLabel fontSize="lg">Total Models (TBD)</StatLabel>
          <StatNumber fontSize="4xl">99</StatNumber>
        </Stat>

        <Stat
          p={6}
          bg={bgColor}
          borderRadius="lg"
          border="1px"
          borderColor={borderColor}
          shadow="sm"
        >
          <StatLabel fontSize="lg">Total Users (TBD)</StatLabel>
          <StatNumber fontSize="4xl">999</StatNumber>
        </Stat>
      </SimpleGrid>
    </Box>
  );
}

export default Home;
