/**
 * TODO: refactor React Router routes so we can define a header layout
 * that fits any given route
 */
import { Box, Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { defaultContentWidths } from '..';

export default function InventoryModelHeader({ children }: { children: any }) {
  const currentPath = useLocation();
  // Check if path matches a finding page path to change the header layout
  const isFindingPath = /\/inventory-models\/\w+\/findings\/\w+/.test(
    currentPath.pathname,
  );

  // Temporary layout hack for finding details paths until we refactor
  // all routes to have their own unique layouts
  if (isFindingPath) {
    return (
      <Flex
        id="inventory-model-header"
        direction="row"
        justify="center"
        w={'full'}
        maxWidth={'1220px'}
        gap={16}
        color="neutral.800"
        pt={6}
        pb={4}
        mt={'0 !important'}
      >
        <Flex direction="column" align="left" w="75%" gap={4}>
          {children}
        </Flex>
        {/* Record attributes rail */}
        <Box w="25%"></Box>
      </Flex>
    );
  }
  // Temporary hack to expand the width of the container whenever
  // we are in the inventory-model-overview route
  const isInventoryModelOverview = currentPath.pathname.includes(
    'documentation-overview',
  );
  const isValidationReportOverview = currentPath.pathname.includes(
    'validation-report/overview',
  );
  const contentWidth =
    isInventoryModelOverview || isValidationReportOverview
      ? '100%'
      : defaultContentWidths;

  return (
    <Flex
      id="inventory-model-header"
      direction="column"
      w={contentWidth}
      gap={2}
      pt={6}
      pb={4}
      mt={'0 !important'}
    >
      {children}
    </Flex>
  );
}
