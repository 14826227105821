import { Box, Heading, Input, SimpleGrid, VStack } from '@chakra-ui/react';

const StyleGuideInput = ({ sizes }: { sizes: string[] }) => {
  const variants = [
    'outline',
    // , 'unstyled', 'flushed', 'filled'
  ];

  return (
    <Box>
      <Heading as={'h2'}>Input</Heading>
      <SimpleGrid columns={2} gap={12}>
        {sizes.reverse().map(size => (
          <VStack>
            {variants.map(variant => (
              <Input
                variant={variant}
                size={size}
                placeholder={`${variant} ${size}`}
              />
            ))}
          </VStack>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default StyleGuideInput;
