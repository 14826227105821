import { ReactNode, useEffect, useState } from 'react';
import useQueryBuilder from '../../../../hooks/useQueryBuilder';
import { parseJsonLogic } from 'react-querybuilder/parseJsonLogic';
import { ActionProps, QueryBuilder, RuleGroupType } from 'react-querybuilder';
import { formatQuery } from 'react-querybuilder/formatQuery';
import {
  FormControl,
  Input,
  Stack,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  Icon,
  IconButton,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { Label } from '../../../../../../../components/Layout';
import { Branch } from '../index';
import {
  EllipsisVerticalIcon,
  PlusIcon,
  SquaresPlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { DangerMenuItem } from '../../../../../../../components/DangerMenuItem';

interface BranchItemProps {
  branch: Branch;
  onEdit: (branch: Branch) => void;
  onDelete: (branch: Branch) => void;
}
const getButtonIcon = (title: ReactNode) => {
  switch (title) {
    case 'Add rule':
      return <Icon as={PlusIcon} boxSize={4} />;
    case 'Add group':
      return <Icon as={SquaresPlusIcon} boxSize={4} />;
    case 'Remove group':
    case 'Remove rule':
      return <Icon as={TrashIcon} boxSize={4} />;
    default:
      console.log('Unhandled button title:', title);
      return <Icon as={PlusIcon} boxSize={4} />;
  }
};

export const getQueryBuilderButton = (props: ActionProps) => (
  <Button
    aria-label={props.title}
    onClick={props.handleOnClick}
    isDisabled={props.disabled}
    size={'sm'}
    variant={'outline'}
    leftIcon={getButtonIcon(props.title || '')}
  >
    {props.title}
  </Button>
);

export const controlElements = {
  removeRuleAction: getQueryBuilderButton,
  addGroupAction: getQueryBuilderButton,
  addRuleAction: getQueryBuilderButton,
  cloneGroupAction: getQueryBuilderButton,
  cloneRuleAction: getQueryBuilderButton,
  lockGroupAction: getQueryBuilderButton,
  lockRuleAction: getQueryBuilderButton,
  removeGroupAction: getQueryBuilderButton,
};
const BranchItem = ({ branch, onEdit, onDelete }: BranchItemProps) => {
  const [name, setName] = useState(branch.name);
  const { query, setQuery, fields } = useQueryBuilder(
    parseJsonLogic(JSON.parse(branch.query)) as RuleGroupType,
  );
  useEffect(() => {
    onEdit({
      ...branch,
      name,
    });
  }, [name]);

  useEffect(() => {
    const localQuery = JSON.stringify(formatQuery(query, 'jsonlogic'));
    if (localQuery !== branch.query) {
      onEdit({
        ...branch,
        query: localQuery,
      });
    }
  }, [query, branch.query]);

  return (
    <VStack
      border={'1px solid'}
      bg={useColorModeValue('white', 'neutral.950')}
      borderColor={'var(--chakra-colors-chakra-border-color)'}
      p={2}
      gap={4}
      w={'full'}
      rounded={'md'}
      position={'relative'}
    >
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
          variant="ghost"
          size={'sm'}
          alignSelf={'flex-end'}
          position={'absolute'}
          top={2}
          right={2}
          zIndex={10}
        />
        <MenuList>
          <DangerMenuItem
            icon={<Icon as={TrashIcon} boxSize={4} />}
            onClick={() => onDelete(branch)}
          >
            Remove Path
          </DangerMenuItem>
        </MenuList>
      </Menu>
      <VStack mt={4} gap={4} w={'full'}>
        <FormControl>
          <Label mb={2} w={'contents'}>
            Path Name
          </Label>
          <Input
            size={'sm'}
            placeholder={'Path Name'}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <Label mb={2}>Conditions</Label>
          <Stack>
            <QueryBuilder
              fields={
                fields.filter(f => f.name.startsWith('custom_fields.')) || []
              }
              query={query}
              onQueryChange={setQuery}
              controlElements={controlElements}
            />
          </Stack>
        </FormControl>
      </VStack>
    </VStack>
  );
};

export default BranchItem;
