import { RJSFSchemaSettingsProperty } from './inventory_model';

export enum FieldTypeId {
  SingleLine = 'string:single-line',
  MultiLine = 'string:multi-line',
  SingleSelect = 'string:single-select',
  MultipleSelect = 'array:multiple',
  Checkbox = 'boolean:checkbox',
  Number = 'number',
  Uri = 'string:uri',
  Date = 'string:date',
  Attachments = 'array:attachments',
  DateTime = 'string:date-time',
  Email = 'string:email',
  User = 'array:model-user',
  CodePython = 'string:code-python',
  HTML = 'string:html',
  Tiering = 'string:tiering',
  BusinessUnit = 'string:business-unit',
  UseCase = 'string:use-case',
}

export interface SchemaPropertyItem {
  key: string;
  title: string;
  type: string;
  typeId: string;
  description?: string;
  requiredOnRegistration: boolean;
  settings: RJSFSchemaSettingsProperty;
}
