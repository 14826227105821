import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { CellProps } from 'react-table';
import { useAuth0 } from '@auth0/auth0-react';
import { AddIcon } from '@chakra-ui/icons';
import {
  Text,
  HStack,
  VStack,
  Box,
  useDisclosure,
  Button,
  IconButton,
  Checkbox,
  CheckboxGroup,
  Stack,
  Icon,
} from '@chakra-ui/react';
import API from '../../api/API';
import UsersContext from '../../contexts/UsersContext';
import { TUser } from '../../models';
import { TUserRole } from '../../models/role';
import { getUserRolesForOrg } from '../../models/user';
import AddUserRoleModal from '../AddUserRolesModal';
import ConfirmationAlert from '../ConfirmationAlert';
import { DataTable } from '../DataTable';
import RolePill from '../RolePill';
import AvatarProxy from '../AvatarProxy';

interface TUserRoleWithUserName extends TUserRole {
  user: {
    cuid: string;
    name: string;
  };
}

interface UserRoleTableProps {
  users: TUser[];
  selectedUserCuids?: string[];
  enableRoleManagement: boolean;
  onRoleAdded?: () => void;
  onRoleDeleted?: () => void;
  onUserSelect?: (selectedUsers: TUser[]) => TUser[];
}

export default function UserRoleTable({
  users,
  selectedUserCuids = [],
  enableRoleManagement,
  onRoleAdded,
  onRoleDeleted,
  onUserSelect,
}: UserRoleTableProps) {
  const { currentUser, currentOrganization } = useContext(UsersContext);
  const { getAccessTokenSilently } = useAuth0();
  const [selectedUsers, setSelectedUsers] = useState<TUser[]>(
    users.filter(({ cuid }) => selectedUserCuids?.includes(cuid)),
  );
  const [roleToDelete, setRoleToDelete] =
    useState<TUserRoleWithUserName | null>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const deleteUserRole = useMutation(
    [],
    async (roleId: string) => {
      const accessToken = await getAccessTokenSilently();
      return API.DeleteUserRole(accessToken, roleId);
    },
    {
      onSuccess: onRoleDeleted,
    },
  );

  const handleAddRole = (users: TUser[]) => {
    setSelectedUsers(users);
    onOpen();
  };

  const handleRoleDeleteConfirmed = (confirmed: boolean) => {
    if (confirmed && roleToDelete) {
      deleteUserRole.mutate(roleToDelete.cuid);
    }
    setRoleToDelete(null);
  };

  let columns = [
    {
      Header: 'Name',
      Cell: ({ row: { original: user } }: CellProps<TUser>) => {
        return (
          <HStack gap={4}>
            <AvatarProxy
              name={user.name}
              boxSize={10}
              src={user.picture}
              ignoreFallback
            />
            <Box noOfLines={2}>
              <Text fontWeight={'semibold'}>
                {user.cuid === currentUser?.cuid
                  ? `${user.name} (you)`
                  : user.name}
              </Text>
              <Text fontSize={'sm'}>{user.email}</Text>
            </Box>
          </HStack>
        );
      },
      style: {
        width: '25%',
      },
    },
    {
      Header: 'Title',
      accessor: 'job_title',
      Cell: ({ value }: CellProps<{}>) => {
        return <Text>{value}</Text>;
      },
      style: {
        width: '20%',
      },
    },
    {
      Header: 'Role',
      Cell: ({ row: { original: user } }: CellProps<TUser>) => {
        return (
          <HStack gap={2}>
            {getUserRolesForOrg(user, currentOrganization?.cuid).map(
              userRole => {
                const isCurrentUserAdminRole =
                  userRole.user.cuid === currentUser?.cuid &&
                  userRole.role.is_admin;
                return (
                  <RolePill
                    key={userRole.cuid}
                    role={userRole.role}
                    onClose={
                      !enableRoleManagement || isCurrentUserAdminRole
                        ? undefined
                        : () =>
                            setRoleToDelete({
                              ...userRole,
                              user: { ...userRole.user, name: user.name },
                            })
                    }
                  />
                );
              },
            )}
            {enableRoleManagement ? (
              <IconButton
                aria-label={'add-role'}
                data-testid={'add-role-plus-button'}
                rounded={'full'}
                size={'sm'}
                onClick={() => handleAddRole([user])}
                icon={<Icon as={AddIcon} boxSize={3} />}
              />
            ) : null}
          </HStack>
        );
      },
    },
  ];

  if (onUserSelect) {
    columns.unshift({
      Header: ' ',
      Cell: ({ row: { original: user } }: CellProps<TUser>) => {
        return <Checkbox value={user.cuid} />;
      },
      style: {
        width: '3rem',
      },
    });
  }

  return (
    <VStack w={'full'} data-testid={'user-role-table'}>
      <Box w={'full'} overflowY={'auto'}>
        <CheckboxGroup
          colorScheme={'pink'}
          onChange={(values: string[]) => {
            const filteredUsers = users.filter(user =>
              values.includes(user.cuid),
            );
            setSelectedUsers(onUserSelect?.(filteredUsers) ?? filteredUsers);
          }}
          value={selectedUsers.map(user => user.cuid)}
        >
          <DataTable data={users} columns={columns} />
        </CheckboxGroup>
      </Box>
      {enableRoleManagement ? (
        <Stack w={'full'} alignItems={'end'}>
          <Button
            isDisabled={selectedUsers.length === 0}
            onClick={() => handleAddRole(selectedUsers)}
            variant={'primary'}
          >
            Assign New Role{' '}
            {selectedUsers.length ? `to ${selectedUsers.length} Users` : ''}
          </Button>
        </Stack>
      ) : null}
      <AddUserRoleModal
        isOpen={isOpen}
        onClose={onClose}
        users={selectedUsers}
        onRoleAdded={onRoleAdded}
      />
      <ConfirmationAlert
        open={Boolean(roleToDelete)}
        title={`Remove ${roleToDelete?.role.name} role from ${roleToDelete?.user.name}?`}
        dialogBody={`Are you sure you'd like to remove ${roleToDelete?.role.name} role from this user?`}
        onConfirm={handleRoleDeleteConfirmed}
      />
    </VStack>
  );
}
