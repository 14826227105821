import {
  Box,
  BoxProps,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import FindingsWidget from '../../components/FindingsWidget';
import ActivityFeedWidget from '../../components/ActivityFeedWidget';
import React, { useContext } from 'react';
import { Copyright } from '../../components/Copyright';
import UsersContext from '../../contexts/UsersContext';
import GetStartedChecklist from '../../components/GetStartedChecklist';
import AvatarProxy from '../../components/AvatarProxy';
import GridView from '../../components/GridView';
import {
  CURRENT_DASHBOARD_LAYOUT_VERSION,
  useUserUISettings,
} from '../../hooks/useUserUISettings';
import { GridViewItem } from '../../components/GridView/GridViewItem';
import MoreInfoPopOver from '../../components/MoreInfoPopOver';

interface DashboardBoxProps extends BoxProps {
  title: string;
  children: React.ReactNode;
}

const DashboardBox = ({ children, title, ...rest }: DashboardBoxProps) => (
  <Box
    p={6}
    w={'full'}
    rounded={8}
    borderWidth={1}
    bg={useColorModeValue('neutral.25', 'neutral.900')}
    borderColor={useColorModeValue('neutral.200', 'neutral.800')}
    {...rest}
  >
    <HStack>
      <Text fontSize={'xl'} fontWeight={'semibold'} color={'inherit'}>
        {title}
      </Text>
    </HStack>
    {children}
  </Box>
);

export default function Dashboard() {
  const { currentUser: user } = useContext(UsersContext);
  const settings = useUserUISettings();
  const dashboardLayouts = settings.getDashboardLayouts();

  return (
    <Box
      px={8}
      flex={1}
      w={'full'}
      overflow={'auto'}
      className="no-scrollbar"
      bg={useColorModeValue('white', 'black')}
    >
      <HStack alignItems={'center'} pt={9} mb={8} gap={4}>
        <AvatarProxy size="md" name={user?.name} src={user?.picture} />

        <Stack>
          <HStack>
            <Heading as={'h1'}>Welcome, {user?.name}!</Heading>
            <MoreInfoPopOver
              title="Customize Your Dashboard"
              link="https://docs.validmind.ai/guide/configuration/customize-your-dashboard.html"
            />
          </HStack>
        </Stack>
      </HStack>
      {/* <SimpleGrid columns={{ base: 1, xl: 2 }} gap={4}>
        {components.map((item: any) => item)}
      </SimpleGrid> */}
      <GridView
        layouts={dashboardLayouts.dashboard}
        onLayoutChange={(_, allLayouts) => {
          settings.setDashboardLayouts({
            dashboard: allLayouts as any,
            version: CURRENT_DASHBOARD_LAYOUT_VERSION,
          });
        }}
      >
        {dashboardLayouts.dashboard.lg.map(layout => {
          if (layout.i === 'finding-summary') {
            return (
              <GridViewItem key={layout.i} title={'Model Findings'}>
                <FindingsWidget
                  variant={'user-summary'}
                  user={user || undefined}
                />
              </GridViewItem>
            );
          } else if (layout.i === 'recent-activity') {
            return (
              <GridViewItem key={layout.i} title={'Recent Activity'}>
                <ActivityFeedWidget variant={'user-summary'} />
              </GridViewItem>
            );
          }
        })}
      </GridView>

      <Copyright />

      <GetStartedChecklist />
    </Box>
  );
}
