import {
  Center,
  Fade,
  Image,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import NoComments from './img/no-comments.svg';
import NoFindings from './img/no-findings-inventory-model.svg';
import NoGuidelines from './img/no-guidelines.svg';
import NoModels from './img/no-models.svg';
import NoReports from './img/no-reports.svg';
import WaitAppDoc from './img/waiting-app-doc.svg';
import WaitAppRep from './img/waiting-app-rep.svg';
import NoActivity from './img/no-activity.svg';
import NoWorkflow from './img/no-workflow.svg';
import NoActivityLight from './img/no-activity-light.svg';
import NoHistory from './img/no-history.svg';
import NoUser from './img/user-missing.svg';

export interface IEmptyStateDisplayProps {
  variant?:
    | 'no-comments'
    | 'no-findings'
    | 'no-guidelines'
    | 'no-models'
    | 'no-reports'
    | 'wait-app-doc'
    | 'no-activity'
    | 'no-workflow'
    | 'wait-app-rep'
    | 'no-activity-light'
    | 'no-history'
    | 'no-user';
  children?: any;
}

export function EmptyStateDisplay({
  variant,
  children,
}: IEmptyStateDisplayProps) {
  const illustration: string =
    variant === 'no-comments'
      ? NoComments
      : variant === 'no-findings'
      ? NoFindings
      : variant === 'no-guidelines'
      ? NoGuidelines
      : variant === 'no-models'
      ? NoModels
      : variant === 'no-reports'
      ? NoReports
      : variant === 'wait-app-doc'
      ? WaitAppDoc
      : variant === 'wait-app-rep'
      ? WaitAppRep
      : variant === 'no-activity'
      ? NoActivity
      : variant === 'no-workflow'
      ? NoWorkflow
      : variant === 'no-activity-light'
      ? NoActivityLight
      : variant === 'no-history'
      ? NoHistory
      : variant === 'no-user'
      ? NoUser
      : '';

  return (
    <Fade in={true} transition={{ enter: { duration: 0.3 } }}>
      <Center color={'neutral.500'}>
        <VStack
          spacing={0}
          mb={0}
          bg={useColorModeValue(
            'radial-gradient(circle, var(--chakra-colors-neutral-100) 20%, transparent 50%);',
            'radial-gradient(circle, var(--chakra-colors-neutral-850) 20%, transparent 50%);',
          )}
        >
          <Image src={illustration} alt={variant} boxSize={'120px'} />
          <VStack
            spacing={1}
            fontStyle={'oblique'}
            color={useColorModeValue('neutral.400', 'neutral.500')}
            gap={2}
          >
            {children}
          </VStack>
        </VStack>
      </Center>
    </Fade>
  );
}
