import { TDocumentSectionStatus } from '../../models/inventory_model';
import { TInventoryModelAnnotation } from '../../api/API';
import { TFinding } from '../../models';
import {
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tag,
  TagLeftIcon,
  Text,
} from '@chakra-ui/react';
import {
  ChatBubbleLeftEllipsisIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';
import { Icon } from '@chakra-ui/icons';
import UnresolvedConversationsPopover from '../UnresolvedConversationsPopover';
import FindingIndicator from '../FindingIndicator';

interface Props {
  children?: React.ReactNode;
  status: TDocumentSectionStatus;
  annotations: TInventoryModelAnnotation[];
  findings: TFinding[];
  resolveAnnotation: (annotation: TInventoryModelAnnotation) => void;
  onAnnotationClick: (annotation: TInventoryModelAnnotation) => void;
}

const SectionStatusIndicators = ({
  children,
  status,
  annotations,
  findings,
  resolveAnnotation,
  onAnnotationClick,
}: Props) => {
  const { highFindings, mediumFindings, lowFindings } = findings.reduce(
    (acc, finding) => {
      if (finding.severity.level === 3) {
        acc.lowFindings.push(finding);
      } else if (finding.severity.level === 2) {
        acc.mediumFindings.push(finding);
      } else if (finding.severity.level === 1) {
        acc.highFindings.push(finding);
      }
      return acc;
    },
    {
      highFindings: [] as TFinding[],
      mediumFindings: [] as TFinding[],
      lowFindings: [] as TFinding[],
    },
  );

  return (
    <HStack>
      {annotations.length > 0 && (
        <Popover trigger="hover" placement={'left'}>
          <PopoverTrigger>
            <Tag size={'sm'} rounded={'full'}>
              <TagLeftIcon boxSize={4} as={ChatBubbleLeftEllipsisIcon} />
              {annotations.length}
            </Tag>
          </PopoverTrigger>
          <PopoverContent shadow={'md'} w={96}>
            <PopoverArrow />
            <PopoverHeader background={'transparent'}>
              <HStack>
                <Icon as={ChatBubbleLeftEllipsisIcon} boxSize={5} />
                <Text
                  whiteSpace={'normal'}
                  fontWeight={'normal'}
                  textAlign={'left'}
                >
                  {annotations.length} Unresolved Conversations
                </Text>
              </HStack>
            </PopoverHeader>
            <PopoverBody bg={'white'} maxH={'25rem'} overflow={'scroll'}>
              <UnresolvedConversationsPopover
                annotations={annotations || []}
                resolveAnnotation={resolveAnnotation}
                onAnnotationClick={onAnnotationClick}
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
      {highFindings.length && (
        <FindingIndicator findings={highFindings} severity={'High'} />
      )}
      {mediumFindings.length && (
        <FindingIndicator findings={mediumFindings} severity={'Medium'} />
      )}
      {lowFindings.length && (
        <FindingIndicator findings={lowFindings} severity={'Low'} />
      )}
      {status === 'done' && (
        <Tag size={'sm'} colorScheme="green" rounded={'full'}>
          <TagLeftIcon boxSize={4} as={CheckIcon} mr={0} />
        </Tag>
      )}
      {children}
    </HStack>
  );
};

export default SectionStatusIndicators;
