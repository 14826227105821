import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';
import AITextContentEditor, {
  AIGenerationConfig,
  AIGenerationStatus,
  AITextContentEditorMethods,
} from '../TextContentEditor/AITextContentEditor';
import {
  CheckIcon,
  DownloadIcon,
  RepeatIcon,
  TimeIcon,
} from '@chakra-ui/icons';

interface GenerateWithAIModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: (text: string) => void;
  aiGenerationConfig: AIGenerationConfig;
}

const GenerateWithAIModal: FC<GenerateWithAIModalProps> = ({
  isOpen,
  onClose,
  onAccept,
  aiGenerationConfig,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const aiEditorRef = useRef<AITextContentEditorMethods>(null);

  const toast = useToast();

  const [prompt, setPrompt] = useState<string>('');
  const [hasStarted, setHasStarted] = useState(false);
  const [aiStatus, setAiStatus] = useState<AIGenerationStatus>('idle');
  const [trapFocus, setTrapFocus] = useState(false);

  const promptEnabled = aiGenerationConfig?.options?.enableUserPrompt || false;

  useEffect(() => {
    setTrapFocus(true);

    if (isOpen) {
      if (!promptEnabled) {
        handleGenerate();
      } else {
        setHasStarted(false);
        setPrompt('');
      }
    }
  }, [isOpen]);

  const handleGenerate = () => {
    // check if prompt is too long
    if (promptEnabled && prompt.length > 10000) {
      toast({
        title: 'Error',
        description:
          'Please enter a prompt that is less than 10000 characters.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });

      return;
    }

    setHasStarted(true);
    setTimeout(() => {
      aiEditorRef.current?.generate();
    }, 0);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size={'3xl'}
      trapFocus={trapFocus}
    >
      <ModalOverlay />
      <ModalContent maxHeight="calc(100% - 40px)">
        <ModalHeader>
          ValidMind AI Content Builder <sup className="beta">Beta</sup>
          <Text color={'neutral.500'} fontSize="sm" fontWeight="normal">
            Our content builder generates high-quality written content using
            state-of-the-art Generative AI.
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody ref={scrollRef} pt={0} pb={0} overflowY="scroll">
          {!hasStarted ? (
            <Box flex={1} flexGrow={1} w="100%">
              <Text mb={2}>
                (Optional) Describe the content you would like to generate in a
                few words. Include any information that you would like included
                in the generated documentation.
              </Text>
              <Textarea
                value={prompt}
                onChange={e => setPrompt(e.target.value)}
                placeholder="Enter a prompt..."
                size="lg"
                resize="vertical"
                minH="100px"
                isInvalid={prompt.length > 1000}
              />
            </Box>
          ) : (
            <Box flex={1} flexGrow={1} w="100%">
              <AITextContentEditor
                ref={aiEditorRef}
                prompt={prompt}
                aiGenerationConfig={aiGenerationConfig}
                onStatusChanged={setAiStatus}
                onContentChanged={() => {
                  if (scrollRef.current) {
                    scrollRef.current.scrollTo(
                      0,
                      scrollRef.current.scrollHeight,
                    );
                  }
                }}
              />
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack w="100%" justify="space-between">
            {!hasStarted && promptEnabled ? (
              <>
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant={'primary'}
                  onClick={handleGenerate}
                  leftIcon={<CheckIcon />}
                >
                  Generate
                </Button>
              </>
            ) : (
              <>
                <Button
                  isDisabled={aiStatus !== 'idle'}
                  onClick={() => {
                    if (promptEnabled) {
                      setHasStarted(false);
                    } else {
                      handleGenerate();
                    }
                  }}
                  leftIcon={<RepeatIcon />}
                >
                  Try Again
                </Button>
                <HStack>
                  <Button variant="ghost" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    isDisabled={aiStatus !== 'idle'}
                    onClick={() => {
                      const text = aiEditorRef.current?.getText();
                      onAccept(text || '');
                    }}
                    leftIcon={
                      aiStatus === 'idle' ? <DownloadIcon /> : <TimeIcon />
                    }
                  >
                    {aiStatus === 'idle' ? 'Accept Text' : 'Generating...'}
                  </Button>
                </HStack>
              </>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GenerateWithAIModal;
