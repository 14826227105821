import {
  Box,
  Button,
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Tag,
  TagLeftIcon,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { TemplateSectionTree } from '../../models/template';
import { useContext } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { TInventoryModelAnnotation } from '../../api/API';
import {
  TDocumentSectionStatus,
  TInventoryModelDocumentationSummary,
} from '../../models/inventory_model';
import { TFinding } from '../../models';
import {
  ChatBubbleLeftEllipsisIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';
import UnresolvedConversationsPopover from '../UnresolvedConversationsPopover';
import FindingIndicator from '../FindingIndicator';
import { getSectionLink } from '../../utils';
import InventoryModelContext from '../../contexts/InventoryModel';

interface DocumentOutlineProps {
  data: TemplateSectionTree[];
  indices?: number[];
  documentationOutline: TInventoryModelDocumentationSummary;
  allowMultiple?: boolean;
  resolveAnnotation: (annotation: TInventoryModelAnnotation) => void;
  documentTypeUrl?: string;
}
const SectionStatusIndicators = ({
  status,
  annotations,
  findings,
  resolveAnnotation,
  onAnnotationClick,
}: {
  status: TDocumentSectionStatus;
  annotations: TInventoryModelAnnotation[];
  findings: TFinding[];
  resolveAnnotation: (annotation: TInventoryModelAnnotation) => void;
  onAnnotationClick: (annotation: TInventoryModelAnnotation) => void;
}) => {
  const { highFindings, mediumFindings, lowFindings } = findings.reduce(
    (acc, finding) => {
      if (finding.severity.level === 3) {
        acc.lowFindings.push(finding);
      } else if (finding.severity.level === 2) {
        acc.mediumFindings.push(finding);
      } else if (finding.severity.level === 1) {
        acc.highFindings.push(finding);
      }
      return acc;
    },
    {
      highFindings: [] as TFinding[],
      mediumFindings: [] as TFinding[],
      lowFindings: [] as TFinding[],
    },
  );

  return (
    <HStack>
      {annotations.length > 0 && (
        <Popover trigger="hover" placement={'left'}>
          <PopoverTrigger>
            <Tag size={'sm'} rounded={'full'}>
              <TagLeftIcon boxSize={4} as={ChatBubbleLeftEllipsisIcon} />
              {annotations.length}
            </Tag>
          </PopoverTrigger>
          <PopoverContent shadow={'md'} w={96}>
            <PopoverArrow />
            <PopoverHeader background={'transparent'}>
              <HStack>
                <Icon as={ChatBubbleLeftEllipsisIcon} boxSize={5} />
                <Text
                  whiteSpace={'normal'}
                  fontWeight={'normal'}
                  textAlign={'left'}
                >
                  {annotations.length} Unresolved Conversations
                </Text>
              </HStack>
            </PopoverHeader>
            <PopoverBody bg={'white'} maxH={'25rem'} overflow={'scroll'}>
              <UnresolvedConversationsPopover
                annotations={annotations || []}
                resolveAnnotation={resolveAnnotation}
                onAnnotationClick={onAnnotationClick}
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
      {highFindings.length && (
        <FindingIndicator findings={highFindings} severity={'High'} />
      )}
      {mediumFindings.length && (
        <FindingIndicator findings={mediumFindings} severity={'Medium'} />
      )}
      {lowFindings.length && (
        <FindingIndicator findings={lowFindings} severity={'Low'} />
      )}
      {status === 'done' && (
        <Tag size={'sm'} colorScheme="green" rounded={'full'}>
          <TagLeftIcon boxSize={4} as={CheckIcon} mr={0} />
        </Tag>
      )}
    </HStack>
  );
};
const DocumentOutline = ({
  data,
  indices = [],
  documentationOutline,
  resolveAnnotation,
  documentTypeUrl = 'documentation',
}: DocumentOutlineProps) => {
  const { inventoryModel } = useContext(InventoryModelContext);
  const navigate = useNavigate();

  const navigateOutline =
    (section: TemplateSectionTree, parentSection?: TemplateSectionTree) =>
    () => {
      const url = getSectionLink(
        documentTypeUrl,
        section,
        inventoryModel!,
        parentSection,
      );
      navigate(url);
    };

  const renderItems = (
    items: TemplateSectionTree[],
    indices: number[],
    parentSection?: TemplateSectionTree,
  ) => {
    return items.map((item: TemplateSectionTree, index: number) => {
      const newIndices = [...indices, index + 1];
      const sectionOfOutline = documentationOutline[item.id];
      if (!item.sections || item.sections.length === 0) {
        return (
          <Button
            py={6}
            pl={4}
            w={'full'}
            mb={'1px'}
            rounded={0}
            border={'none'}
            variant={'ghost'}
            borderTop={'1px solid'}
            onClick={navigateOutline(item, parentSection)}
            borderColor={useColorModeValue('neutral.200', 'neutral.800')}
          >
            <HStack w={'full'}>
              <Text fontFamily={'monospace'} fontSize={'xs'}>
                {newIndices.join('.')}.
              </Text>
              <Text>{item.title}</Text>
              <Spacer />
              <SectionStatusIndicators
                status={sectionOfOutline?.status || 'in_progress'}
                annotations={sectionOfOutline?.unresolved_threads || []}
                findings={sectionOfOutline?.findings || []}
                resolveAnnotation={resolveAnnotation}
                onAnnotationClick={annotation => {
                  const section =
                    parentSection && parentSection.condensed
                      ? `${parentSection!.id.replace(/_/g, '-')}`
                      : item.id.replace(/_/g, '-');

                  let url = `/model-inventory/${
                    inventoryModel!.cuid
                  }/documentation/${section}`;

                  navigate({
                    pathname: url,
                    search: `${createSearchParams({
                      'data-comment': `${annotation.cuid}`,
                    })}`,
                  });
                }}
              />
            </HStack>
          </Button>
        );
      } else {
        return (
          <VStack
            gap={'1px'}
            borderTop={'1px solid'}
            alignItems={'flex-start'}
            borderColor={useColorModeValue('neutral.200', 'neutral.800')}
          >
            <Box
              py={3}
              px={4}
              mb={0}
              w={'full'}
              rounded={0}
              border={'none'}
              fontWeight={'semi-bold'}
              borderColor={useColorModeValue('neutral.200', 'neutral.800')}
              transition={'all 0.2s ease-in-out'}
            >
              <HStack w={'full'}>
                <Text fontFamily={'monospace'} fontSize={'xs'}>
                  {newIndices.join('.')}.
                </Text>
                <Text>{item.title}</Text>
                <Spacer />
                {/* <SectionStatusIndicators
                  annotations={sectionOfOutline?.unresolved_threads || []}
                /> */}
              </HStack>
            </Box>
            {item.sections && item.sections.length > 0 ? (
              <Box
                ml={5}
                w={'calc(100% - 1.2rem)'}
                borderLeft={
                  item.sections && item.sections.length > 0 ? 'solid' : 'none'
                }
                borderColor={useColorModeValue('neutral.200', 'neutral.800')}
              >
                {renderItems(item.sections, newIndices, item)}
              </Box>
            ) : null}
          </VStack>
        );
      }
    });
  };

  return (
    <Box data-testid="model-documentation-outline">
      {renderItems(data, indices)}
    </Box>
  );
};

export default DocumentOutline;
