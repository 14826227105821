import { Box, Heading } from '@chakra-ui/react';

const StyleGuideList = () => {
  return (
    <Box>
      <Heading as={'h2'}>List</Heading>
    </Box>
  );
};

export default StyleGuideList;
