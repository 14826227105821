import { createContext } from 'react';
import { TInventoryModel } from '../models/inventory_model';

interface SidebarContextValues {
  SIDEBAR_SIZES: string[];
  inventoryModel?: TInventoryModel;
  setInventoryModel: (inventoryModel?: TInventoryModel) => void;
  inSettings?: boolean;
  setInSettings: (inSettings: boolean) => void;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

const SidebarContext = createContext<SidebarContextValues>({
  SIDEBAR_SIZES: ['220px', '62px'],
  setInventoryModel: () => {},
  setIsExpanded: () => {},
  inSettings: false,
  setInSettings: () => {},
  isExpanded: false,
});

export default SidebarContext;
