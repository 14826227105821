import { useState } from 'react';
import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Icon,
} from '@chakra-ui/react';
import { TDocumentSectionStatus } from '../../models/inventory_model';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

type StatusOption = {
  value: TDocumentSectionStatus;
  label: string;
  bgColor: string;
  color: string;
  circleColor: string;
};

export const DOCUMENTATION_SECTION_STATUS: StatusOption[] = [
  {
    value: 'in_progress',
    label: 'In Progress',
    bgColor: 'blue.100',
    color: 'blue.700',
    circleColor: 'blue.500',
  },
  {
    value: 'done',
    label: 'Done',
    bgColor: 'green.100',
    color: 'green.700',
    circleColor: 'green.500',
  },
];

type StatusRowProps = {
  label: string;
  value: string;
  isChecked: boolean;
  setStatus: (status: string) => void;
};

const StatusRow = ({ label, value, isChecked, setStatus }: StatusRowProps) => {
  const statusObj =
    value === 'in_progress'
      ? DOCUMENTATION_SECTION_STATUS[0]
      : DOCUMENTATION_SECTION_STATUS[1];
  return (
    <HStack
      onClick={() => {
        setStatus(value);
      }}
      w={'full'}
    >
      <Text color={statusObj.color} fontSize={'sm'}>
        {label}
      </Text>
    </HStack>
  );
};

type DocumentSectionStatusSelectProps = {
  readOnly?: boolean;
  initialStatus: TDocumentSectionStatus;
  setStatus: (status: TDocumentSectionStatus) => void;
};

const DocumentSectionStatusSelect = ({
  readOnly = false,
  initialStatus,
  setStatus,
}: DocumentSectionStatusSelectProps) => {
  const [status, _setStatus] = useState<TDocumentSectionStatus>(initialStatus);

  const currentStatus = DOCUMENTATION_SECTION_STATUS.find(
    stat => stat.value === status,
  );

  if (readOnly) {
    return (
      <Text
        fontSize={'sm'}
        mr={2}
        bgColor={currentStatus?.bgColor}
        color={currentStatus?.color}
        rounded={'full'}
        px={3}
      >
        {currentStatus?.label}
      </Text>
    );
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        size={'xs'}
        rightIcon={<Icon as={ChevronDownIcon} boxSize={4} strokeWidth={2} />}
        bgColor={currentStatus?.bgColor}
        color={currentStatus?.color}
        rounded={'full'}
        fontWeight={'normal'}
        px={3}
      >
        <HStack gap={2}>
          <Text fontSize={'sm'} mr={2}>
            {currentStatus?.label}
          </Text>
        </HStack>
      </MenuButton>
      <MenuList>
        {DOCUMENTATION_SECTION_STATUS.map(({ value, label }) => (
          <MenuItem key={value}>
            <StatusRow
              key={value}
              label={label}
              value={value}
              isChecked={status === value}
              setStatus={() => {
                if (status !== value) {
                  _setStatus(value);
                  setStatus(value);
                }
              }}
            />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default DocumentSectionStatusSelect;
