import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';
import { HStack, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { generateRandomCodeForNodes } from '../../../../../../../utils';
import NodeBox from '../../../layouts/NodeBox';
import useWorkflow from '../../../../../../../hooks/useWorkflow';
import { BranchRouteNodeType } from '../../../types';

function BranchRouteNode(props: NodeProps) {
  const { setSelectedNodeId } = useWorkflow();
  const { getEdges } = useReactFlow();
  const DEFAULT_HANDLE_STYLES = {
    background: useColorModeValue(
      'var(--chakra-colors-neutral-200)',
      'var(--chakra-colors-neutral-800)',
    ),
    borderColor: useColorModeValue(
      'var(--chakra-colors-neutral-50)',
      'var(--chakra-colors-neutral-500)',
    ),
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '50%',
    height: 8,
    width: 8,
  };

  const edgesConnectedToSource = getEdges().filter(
    edge => edge.source === props.id,
  );

  return (
    <NodeBox
      onDoubleClick={() => setSelectedNodeId!(props.data.branch.router_id)}
      bg={useColorModeValue('neutral.50', 'neutral.800')}
      border={1}
      borderColor={useColorModeValue('neutral.200', 'neutral.700')}
      borderStyle={'dashed'}
      nodeProps={props}
      rounded={'100'}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ ...DEFAULT_HANDLE_STYLES, top: -8 }}
      />
      <HStack alignItems={'flex'} px={4} py={1}>
        <VStack gap={0} alignItems={'flex-start'}>
          <VStack w={'full'} alignItems={'start'} gap={0} rounded={'md'}>
            <Text fontSize={'xs'}>{props.data.branch.name}</Text>
          </VStack>
        </VStack>
      </HStack>

      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          ...DEFAULT_HANDLE_STYLES,
          bottom: -8,
          background:
            edgesConnectedToSource.length > 0
              ? DEFAULT_HANDLE_STYLES.background
              : useColorModeValue(
                  'var(--chakra-colors-red-200)',
                  'var(--chakra-colors-red-800)',
                ),
          borderColor:
            edgesConnectedToSource.length > 0
              ? DEFAULT_HANDLE_STYLES.borderColor
              : useColorModeValue(
                  'var(--chakra-colors-red-50)',
                  'var(--chakra-colors-red-500)',
                ),
        }}
      />
    </NodeBox>
  );
}

BranchRouteNode.type = 'branch_route';
BranchRouteNode.autoRuns = true;

BranchRouteNode.getDefaultNode = (): BranchRouteNodeType => {
  return {
    id: `${BranchRouteNode.type}_${generateRandomCodeForNodes()}`,
    type: BranchRouteNode.type,
    data: {
      branch: {
        router_id: null,
        name: null,
      },
      state_callbacks: {
        on_enter: [],
        on_exit: [],
      },
      transition_callbacks: {
        conditions: [
          {
            func: 'jsonlogic',
            args: {
              query: true,
            },
          },
        ],
        prepare: [],
        before: [],
        after: [],
      },
    },
    position: { x: 0, y: 0 },
  };
};

export default BranchRouteNode;
