import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tag,
  VStack,
} from '@chakra-ui/react';
import {
  ArrowsUpDownIcon,
  ChartBarSquareIcon,
  ChartPieIcon,
  HashtagIcon,
  Square3Stack3DIcon,
  ViewColumnsIcon,
} from '@heroicons/react/24/outline';
import { Select, components } from 'chakra-react-select';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import {
  TReportGrouping,
  TReportingDataset,
  TReportMetric,
  TReportSorting,
  TVisualizationExtraSettings,
  TDashboardVisualizationJSON,
} from '../../models/report';
import QueryBuilder, {
  Field,
  RuleGroupType,
  defaultOperators,
} from 'react-querybuilder';
import RenderVisualization from '../Reporting/Visualizations/RenderVisualization';
import ColorPicker from '../ColorPicker';
import _ from 'lodash';
import { Label } from '../Layout';

const VisualizationDefinitions = [
  {
    id: 'bar-chart',
    name: 'Bar Chart',
    icon: ChartBarSquareIcon,
    properties: ['metrics', 'grouping', 'filtering', 'sorting'],
    propertyOptions: {
      grouping: {
        max: 2,
      },
    },
  },
  {
    id: 'pie-chart',
    name: 'Pie Chart',
    icon: ChartPieIcon,
    properties: ['metrics', 'grouping', 'filtering'],
  },
  {
    id: 'counter',
    name: 'Counter',
    icon: HashtagIcon,
    properties: ['metrics', 'filtering'],
  },
];

const { Option: SelectOption } = components;
const OptionWithType = (props: any) => (
  <SelectOption {...props}>
    <HStack w="full" justifyContent="space-between">
      <Box>{props.data.label}</Box>
      <Tag>{props.data.type}</Tag>
    </HStack>
  </SelectOption>
);

const VisualizationOption = (props: any) => {
  const Icon = VisualizationDefinitions.find(
    v => v.id === props.data.value,
  )?.icon;
  return (
    <SelectOption {...props}>
      <HStack>
        {Icon && <Icon width={24} />}
        <Box>{props.data.label}</Box>
      </HStack>
    </SelectOption>
  );
};

const MetricSelector = () => {
  const { dataset, datasets, metrics, setMetrics } = useContext(Context);

  const getActionOptions = () => {
    if (metrics.length > 0) {
      // Get the TReportMetric type
      const metricType = datasets
        .find(v => v.id === dataset)
        ?.metrics.find(v => v.column === metrics[0].column)?.type;

      switch (metricType) {
        case 'string':
          return [
            { label: 'Count', value: 'count' },
            {
              label: 'Count %',
              value: 'count_pct',
            },
          ];
        case 'number':
          return [
            { label: 'Count', value: 'count' },
            { label: 'Count %', value: 'count_pct' },
            { label: 'Sum', value: 'sum' },
            { label: 'Average', value: 'avg' },
            { label: 'Min', value: 'min' },
            { label: 'Max', value: 'max' },
          ];
        case 'date':
          return [
            { label: 'Count', value: 'count' },
            {
              label: 'Count %',
              value: 'count_pct',
            },
          ];
        case 'boolean':
          return [
            { label: 'Count', value: 'count' },
            {
              label: 'Count %',
              value: 'count_pct',
            },
          ];
      }
    }
    return [];
  };

  const actionOptions = getActionOptions();

  const metricOptions =
    datasets
      .find(v => v.id === dataset)
      ?.metrics.map(v => ({
        label: v.name,
        value: v.column,
        type: v.type,
      })) || [];

  return (
    <FieldSet label="Metrics">
      <HStack w="full">
        <Box display="flex" flex={1}>
          {metrics.length > 0 && (
            <Select
              isSearchable={false}
              onChange={e =>
                setMetrics([{ column: e!.value, action: 'count' }])
              }
              options={metricOptions}
              value={metricOptions.find(a => a.value === metrics[0].column)}
              components={{ Option: OptionWithType }}
              chakraStyles={{
                container: provided => ({
                  ...provided,
                  width: '100%',
                }),
              }}
              menuPortalTarget={document.body}
              styles={{
                // Casting to any because of a bug in the types
                menuPortal: base => ({ ...base, zIndex: 9999 } as any),
              }}
            />
          )}
        </Box>
        {metrics.length > 0 && (
          <div>
            <Select
              isSearchable={false}
              onChange={e =>
                setMetrics([
                  { column: metrics[0].column, action: e!.value as any },
                ])
              }
              options={actionOptions}
              value={actionOptions.find(a => a.value === metrics[0].action)}
              menuPortalTarget={document.body}
              styles={{
                // Casting to any because of a bug in the types
                menuPortal: base => ({ ...base, zIndex: 9999 } as any),
              }}
            />
          </div>
        )}
      </HStack>
    </FieldSet>
  );
};

const BarChartGroupStyleSelector = () => {
  const { extraSettings, setExtraSettings } = React.useContext(Context);

  const groupingStyleOptions = [
    { label: 'Grouped', value: 'grouped', icon: ViewColumnsIcon },
    { label: 'Stacked', value: 'stacked', icon: Square3Stack3DIcon },
  ];

  return (
    <FieldSet label="Bar Chart Grouping Style">
      <HStack w="full">
        <Select
          isSearchable={false}
          options={groupingStyleOptions}
          value={groupingStyleOptions.find(
            v =>
              v.value === (extraSettings?.barChartGroupingStyle || 'grouped'),
          )}
          onChange={e => {
            setExtraSettings({
              ...extraSettings,
              barChartGroupingStyle: e!.value as 'grouped' | 'stacked',
            });
          }}
          chakraStyles={{
            container: provided => ({
              ...provided,
              width: '100%',
            }),
          }}
          components={{
            Option: props => (
              <SelectOption {...props}>
                <HStack>
                  {props.data.icon && <props.data.icon width={24} />}
                  <Box>{props.data.label}</Box>
                </HStack>
              </SelectOption>
            ),
          }}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 } as any),
          }}
        />
      </HStack>
    </FieldSet>
  );
};

const GroupingSelector = ({ max = 1 }: { max?: number }) => {
  const { dataset, datasets, metrics, grouping, setGrouping } =
    React.useContext(Context);

  const groupingOptions =
    datasets
      .find(v => v.id === dataset)
      ?.metrics.map(v => ({
        label: v.name,
        value: v.column,
        type: v.type,
      })) || [];

  const swapGrouping = () => {
    const newGrouping = [...grouping];
    const temp = newGrouping[0];
    newGrouping[0] = newGrouping[1];
    newGrouping[1] = temp;
    setGrouping(newGrouping);
  };

  return (
    <FieldSet label="Grouping">
      <HStack w="full">
        {grouping.length > 1 && (
          <IconButton
            h="full"
            aria-label="swap"
            icon={<ArrowsUpDownIcon width={20} />}
            onClick={swapGrouping}
          />
        )}
        <VStack w="full">
          {grouping.length > 0 &&
            grouping.map((group, index) => (
              <HStack w="full" p={0}>
                <Select
                  key={`grouping-${index}`}
                  isSearchable={false}
                  onChange={e => {
                    const newGrouping = [...grouping];
                    newGrouping[index] = {
                      column: e!.value,
                    };
                    setGrouping(newGrouping);
                  }}
                  options={groupingOptions}
                  value={groupingOptions.find(a => a.value === group.column)}
                  chakraStyles={{
                    container: provided => ({
                      ...provided,
                      width: '100%',
                    }),
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    // Casting to any because of a bug in the types
                    menuPortal: base => ({ ...base, zIndex: 9999 } as any),
                  }}
                  components={{ Option: OptionWithType }}
                />
                {index > 0 && (
                  <Button
                    onClick={() => {
                      const newGrouping = [...grouping];
                      newGrouping.splice(index, 1);
                      setGrouping(newGrouping);
                    }}
                  >
                    Remove
                  </Button>
                )}
                {
                  // Add a new grouping if the current grouping is less than the max
                  index === grouping.length - 1 && grouping.length < max && (
                    <Button
                      onClick={() => {
                        setGrouping([
                          ...grouping,
                          { column: groupingOptions[0].value },
                        ]);
                      }}
                    >
                      Add
                    </Button>
                  )
                }
              </HStack>
            ))}
        </VStack>
      </HStack>
    </FieldSet>
  );
};

const Context = React.createContext({
  visualizationType: '' as string | null | undefined,
  setVisualizationType: (type: string) => {},
  dataset: null as string | null | undefined,
  setDataset: (dataset: string) => {},
  metrics: [] as TReportMetric[],
  setMetrics: (metrics: TReportMetric[]) => {},
  grouping: [] as TReportGrouping[],
  setGrouping: (grouping: TReportGrouping[]) => {},
  sorting: [] as TReportSorting[],
  setSorting: (sorting: TReportSorting[]) => {},
  filtering: undefined as RuleGroupType | undefined,
  setFiltering: (filtering: RuleGroupType) => {},
  datasets: [] as TReportingDataset[],
  extraSettings: {} as TVisualizationExtraSettings | undefined,
  setExtraSettings: (extraSettings: TVisualizationExtraSettings) => {},
});

const RenderVisualizationSettings = () => {
  const {
    visualizationType,
    dataset,
    grouping,
    extraSettings,
    setExtraSettings,
  } = React.useContext(Context);

  const settingsElements = [];

  if (visualizationType && dataset) {
    const visualizationDefinition = VisualizationDefinitions.find(
      v => v.id === visualizationType,
    );

    if (visualizationDefinition) {
      if (visualizationDefinition.properties.includes('metrics')) {
        settingsElements.push(<MetricSelector />);
      }
      if (visualizationDefinition.properties.includes('grouping')) {
        settingsElements.push(
          <GroupingSelector
            max={visualizationDefinition.propertyOptions?.grouping?.max}
          />,
        );
      }
      if (visualizationDefinition.properties.includes('filtering')) {
        settingsElements.push(<FilterComponent />);
      }
      if (visualizationDefinition.properties.includes('sorting')) {
        settingsElements.push(<SortingComponent />);
      }

      // Bar Chart specific settings
      if (visualizationType === 'bar-chart') {
        if (grouping.length === 2) {
          settingsElements.push(<BarChartGroupStyleSelector />);
        }
      }

      // Counter specific settings
      if (visualizationType === 'counter') {
        settingsElements.push(
          <FieldSet label="Color">
            <ColorPicker
              selectedColorHex={extraSettings?.valueColor || '#000000'}
              onChange={color =>
                setExtraSettings({ ...extraSettings, valueColor: color })
              }
            />
          </FieldSet>,
        );
      }
    }
  }

  return <>{settingsElements}</>;
};

const FilterComponent = () => {
  const { dataset, datasets, filtering, setFiltering } = useContext(Context);

  const selectedDataset = datasets.find(v => v.id === dataset);

  // Mapping 'type' to operators based on the data type
  const getOperators = (
    fieldName: string,
    { fieldData }: { fieldData: Field },
  ) => {
    switch (fieldData.datatype) {
      case 'string':
        return [
          { name: '=', label: 'is' },
          { name: '!=', label: 'is not' },
          ...defaultOperators.filter(op =>
            [
              'contains',
              'beginsWith',
              'endsWith',
              'doesNotContain',
              'doesNotBeginWith',
              'doesNotEndWith',
              'null',
              'notNull',
              'in',
              'notIn',
            ].includes(op.name),
          ),
        ];
      case 'number':
        return [
          ...defaultOperators.filter(op => ['=', '!='].includes(op.name)),
          { name: '<', label: 'less than' },
          { name: '<=', label: 'less than or equal to' },
          { name: '>', label: 'greater than' },
          { name: '>=', label: 'greater than or equal to' },
          ...defaultOperators.filter(op =>
            ['null', 'notNull'].includes(op.name),
          ),
        ];
      case 'date':
        return [
          { name: '=', label: 'on' },
          { name: '!=', label: 'not on' },
          { name: '<', label: 'before' },
          { name: '<=', label: 'on or before' },
          { name: '>', label: 'after' },
          { name: '>=', label: 'on or after' },
          ...defaultOperators.filter(op =>
            ['null', 'notNull'].includes(op.name),
          ),
        ];
    }
    return defaultOperators;
  };

  // Generating query fields based on the dataset's metrics
  const fields: Field[] = selectedDataset!.metrics.map(metric => ({
    name: metric.column, // Reference the column name in the dataset
    label: metric.name, // Display name for the field
    datatype: metric.type, // Data type of the field
    //inputType: metric.type === 'boolean' ? ['checkbox'] : undefined, // Boolean fields should have checkbox input
  }));

  return (
    <FieldSet label="Filtering">
      <QueryBuilder
        fields={fields}
        query={filtering}
        onQueryChange={setFiltering}
        getOperators={getOperators}
      />
    </FieldSet>
  );
};

const SortingComponent = () => {
  const { dataset, datasets, metrics, grouping, sorting, setSorting } =
    useContext(Context);

  const columnOptions = useMemo(() => {
    // Options should include first metric and first grouping
    const firstMetric = metrics.at(0);
    const firstGrouping = grouping.at(0)?.column;

    // Get selected dataset
    const selectedDataset = datasets.find(v => v.id === dataset);

    // Find friendly names for the first metric and first grouping
    // from the dataset's metrics

    const metricName = selectedDataset?.metrics.find(
      v => v.column === firstMetric?.column,
    )?.name;
    const groupingName = selectedDataset?.metrics.find(
      v => v.column === firstGrouping,
    )?.name;

    return [
      {
        label: `${metricName} (${firstMetric?.action})`,
        value: `metric`,
      },
      { label: `${groupingName}`, value: `${firstGrouping}` },
    ];
  }, [dataset, datasets, metrics, grouping]);

  const sortingOptions = [
    { label: 'Descending', value: 'desc' },
    { label: 'Ascending', value: 'asc' },
  ];

  // Clear sorting when metrics or grouping change
  useEffect(() => {
    setSorting([]);
  }, [metrics, grouping]);

  return (
    <FieldSet label="Sorting">
      <HStack w="full">
        <Select
          isSearchable={false}
          options={columnOptions}
          value={
            columnOptions.find(v => v.value === sorting[0]?.column) ||
            columnOptions[0]
          }
          onChange={e => {
            setSorting([{ column: e!.value, direction: 'desc' }]);
          }}
          chakraStyles={{
            container: provided => ({
              ...provided,
              width: '100%',
            }),
          }}
          menuPortalTarget={document.body}
          styles={{
            // Casting to any because of a bug in the types
            menuPortal: base => ({ ...base, zIndex: 9999 } as any),
          }}
        />
        <Select
          isSearchable={false}
          options={sortingOptions}
          value={
            sortingOptions.find(v => v.value === sorting[0]?.direction) ||
            sortingOptions[0]
          }
          onChange={e => {
            setSorting([
              {
                column: sorting[0]?.column || 'metric',
                direction: e!.value as any,
              },
            ]);
          }}
          chakraStyles={{
            container: provided => ({
              ...provided,
              width: '300px',
            }),
          }}
          menuPortalTarget={document.body}
          styles={{
            // Casting to any because of a bug in the types
            menuPortal: base => ({ ...base, zIndex: 9999 } as any),
          }}
        />
      </HStack>
    </FieldSet>
  );
};

const FieldSet = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <Stack
    w="full"
    p={2}
    border={1}
    borderRadius={'md'}
    borderStyle={'solid'}
    alignItems={'normal'}
    borderColor={'var(--chakra-colors-chakra-border-color)'}
    gap={2}
  >
    <Label>{label}</Label>
    {children}
  </Stack>
);

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    data: TDashboardVisualizationJSON,
    visualizationCuid?: string,
  ) => Promise<void>;
  existingVisualizationId?: string;
  existingVisualizationJSON?: TDashboardVisualizationJSON;
}

const AddOrEditVisualizationModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  existingVisualizationId,
  existingVisualizationJSON,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [title, setTitle] = React.useState<string | null>();
  const [visualizationType, setVisualizationType] = React.useState<
    string | null
  >();
  const [dataset, setDataset] = React.useState<string | null>();
  const [metrics, setMetrics] = React.useState<TReportMetric[]>([]);
  const [grouping, setGrouping] = React.useState<TReportGrouping[]>([]);
  const [sorting, setSorting] = React.useState<TReportSorting[]>([]);
  const [filtering, setFiltering] = React.useState<RuleGroupType | undefined>(
    undefined,
  );
  const [extraSettings, setExtraSettings] = React.useState<
    TVisualizationExtraSettings | undefined
  >();
  const [isRenderReady, setIsRenderReady] = React.useState(false);

  const { isLoading: isLoadingDatasets, data: datasets } = useQuery(
    ['reporting', 'datasets'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      const results = await API.GetReportingDatasets(accessToken);
      return results.filter(r => !r.hideInUI);
    },
    {
      initialData: [],
    },
  );

  const visualizationOptions = VisualizationDefinitions.map(v => ({
    label: v.name,
    value: v.id,
  }));

  const datasetOptions =
    datasets?.map(v => ({
      label: v.name,
      value: v.id,
    })) || [];

  const cleanAndSubmit = useCallback(async () => {
    if (!title || !visualizationType || !dataset) {
      return;
    }

    const id = existingVisualizationId;

    const dataObject = {
      title,
      type: visualizationType,
      dataset,
    } as TDashboardVisualizationJSON;

    const visualizationDefinition = VisualizationDefinitions.find(
      v => v.id === visualizationType,
    );

    if (visualizationDefinition) {
      if (visualizationDefinition.properties.includes('metrics')) {
        dataObject.metrics = metrics;
      }
      if (visualizationDefinition.properties.includes('grouping')) {
        dataObject.grouping = grouping;
      }
      if (visualizationDefinition.properties.includes('sorting')) {
        dataObject.sorting = sorting;
      }
      if (visualizationDefinition.properties.includes('filtering')) {
        dataObject.filtering = filtering;
      }
      if (extraSettings) {
        dataObject.extraSettings = extraSettings;
      }
    }

    onSubmit(dataObject, id);
  }, [
    existingVisualizationId,
    title,
    visualizationType,
    dataset,
    metrics,
    grouping,
    sorting,
    filtering,
    extraSettings,
  ]);

  // Handled passed in visualization data
  useEffect(() => {
    if (existingVisualizationJSON) {
      setTitle(existingVisualizationJSON.title);
      setVisualizationType(existingVisualizationJSON.type);
      setDataset(existingVisualizationJSON.dataset);
      setMetrics(existingVisualizationJSON.metrics || []);
      setGrouping(existingVisualizationJSON.grouping || []);
      setSorting(existingVisualizationJSON.sorting || []);
      if (existingVisualizationJSON.filtering) {
        setFiltering(
          _.cloneDeep(existingVisualizationJSON.filtering) as RuleGroupType,
        );
      } else {
        setFiltering(undefined);
      }
      if (existingVisualizationJSON.extraSettings) {
        setExtraSettings(
          _.cloneDeep(
            existingVisualizationJSON.extraSettings,
          ) as TVisualizationExtraSettings,
        );
      } else {
        setExtraSettings(undefined);
      }
    }

    if (!existingVisualizationJSON || !isOpen) {
      setTitle(null);
      setVisualizationType(null);
      setDataset(null);
      setMetrics([]);
      setGrouping([]);
      setSorting([]);
      setFiltering(undefined);
      setExtraSettings(undefined);
    }
  }, [existingVisualizationJSON, isOpen]);

  // Set the default value of metrics and grouping, when a dataset/visualization is changed
  useEffect(() => {
    if (dataset && visualizationType) {
      const datasetMetrics = datasets!.find(v => v.id === dataset)?.metrics;
      if (datasetMetrics && datasetMetrics.length > 0) {
        if (!metrics || metrics.length === 0) {
          setMetrics([
            {
              column: datasetMetrics[0].column,
              action: 'count',
            },
          ]);
        }
        if (!grouping || grouping.length === 0) {
          setGrouping([
            {
              column: datasetMetrics[0].column,
            },
          ]);
        }
      }

      const visualizationDefinition = VisualizationDefinitions.find(
        v => v.id === visualizationType,
      );

      // get max grouping
      if (visualizationDefinition) {
        const maxGrouping =
          visualizationDefinition.propertyOptions?.grouping?.max || 1;
        if (grouping.length > maxGrouping) {
          setGrouping(grouping.slice(0, maxGrouping));
        }
      }
    }
  }, [dataset, visualizationType, datasets, metrics, grouping]);

  useEffect(() => {
    if (isOpen && visualizationType && dataset) {
      setTimeout(() => {
        setIsRenderReady(true);
      }, 500);
    } else {
      setIsRenderReady(false);
    }
  }, [isOpen, visualizationType, dataset]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
      size={'6xl'}
    >
      <Context.Provider
        value={{
          visualizationType,
          setVisualizationType,
          dataset,
          setDataset,
          metrics,
          setMetrics,
          grouping,
          setGrouping,
          sorting,
          setSorting,
          filtering,
          setFiltering,
          datasets: datasets || [],
          extraSettings,
          setExtraSettings,
        }}
      >
        <ModalOverlay />
        <ModalContent
          h={visualizationType && dataset ? '95vh' : 'auto'}
          w={visualizationType && dataset ? '100%' : '33%'}
          transition={'all .3s ease-in-out'}
          maxW="95%"
          maxH="95vh"
        >
          <ModalHeader>
            {existingVisualizationJSON ? 'Edit' : 'Add'} Visualization
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" flex={1} overflow={'hidden'}>
            <HStack spacing={4} flex={1}>
              <VStack
                minWidth={visualizationType && dataset ? '33%' : '100%'}
                h="100%"
                align="flex-start"
                overflowY={'auto'}
              >
                <FieldSet label="Title">
                  <Input
                    value={title || ''}
                    onChange={e => setTitle(e.target.value)}
                  />
                </FieldSet>
                <FieldSet label="Visualization Type">
                  <Select
                    size={'md'}
                    colorScheme="brand"
                    isSearchable={false}
                    options={visualizationOptions}
                    value={visualizationOptions.find(
                      v => v.value === visualizationType,
                    )}
                    onChange={e => {
                      setVisualizationType(e?.value);
                    }}
                    menuPortalTarget={document.body}
                    components={{ Option: VisualizationOption }}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 } as any),
                    }}
                  />
                </FieldSet>
                <FieldSet label="Dataset">
                  <Select
                    size={'md'}
                    colorScheme="brand"
                    isSearchable={false}
                    onChange={e => {
                      setMetrics([]);
                      setGrouping([]);
                      setDataset(e?.value);
                    }}
                    options={datasetOptions}
                    value={datasetOptions.find(v => v.value === dataset)}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 } as any),
                    }}
                  />
                </FieldSet>
                {visualizationType && dataset && (
                  <VStack mt={4} w={'full'} alignItems={'flex-start'}>
                    <Divider mb={4} />
                    <Heading as={'h4'} mb={2}>
                      Configure {_.startCase(dataset)}{' '}
                      {_.startCase(visualizationType)}
                    </Heading>
                  </VStack>
                )}
                <RenderVisualizationSettings />
              </VStack>
              {visualizationType && dataset && (
                <VStack h="full" flexGrow={1}>
                  <Box w="100%" h="100%">
                    {isRenderReady && (
                      <RenderVisualization
                        isEditing
                        visualizationJSON={{
                          title: title || '',
                          type: visualizationType,
                          dataset,
                          metrics,
                          grouping,
                          sorting,
                          filtering,
                          extraSettings,
                        }}
                      />
                    )}
                  </Box>
                </VStack>
              )}
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Spacer />
            <Button
              variant={'primary'}
              onClick={cleanAndSubmit}
              isDisabled={!title || !visualizationType || !dataset}
            >
              {existingVisualizationJSON ? 'Save' : 'Add'} Visualization
            </Button>
          </ModalFooter>
        </ModalContent>
      </Context.Provider>
    </Modal>
  );
};

export default AddOrEditVisualizationModal;
