// ... existing imports ...
import { MenuItem, MenuItemProps, useColorModeValue } from '@chakra-ui/react';

export function DangerMenuItem(props: MenuItemProps) {
  return (
    <MenuItem
      {...props}
      color={useColorModeValue('red.600', 'red.300')}
      _hover={{
        bg: useColorModeValue('red.50', 'red.900'),
      }}
    />
  );
}
