import { ChangeEvent, useEffect, useState } from 'react';
import {
  HStack,
  Heading,
  Spacer,
  Switch,
  Text,
  VStack,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import { Label } from '../Layout';
import { useUserUISettings } from '../../hooks/useUserUISettings';

export default function ProfileOnboardingSettings() {
  const { user, resetOnboarding, setOnboardingSetting, isCompleted } =
    useUserUISettings();
  const [statusName, setStatusName] = useState('');
  const [switchStatus, setSwitchStatus] = useState(false);

  useEffect(() => {
    if (user?.ui_settings.onboarding?.isDismissed) {
      setStatusName('Dismissed');
      setSwitchStatus(false);
    } else {
      setStatusName(isCompleted ? 'Completed' : 'In Progress');
      setSwitchStatus(true);
    }
  }, [user?.ui_settings.onboarding, user?.ui_settings.onboarding?.isDismissed]);

  const onSwitchStatus = (checked: boolean) => {
    setOnboardingSetting('isDismissed', !checked);
    setSwitchStatus(checked);
  };

  const onRestart = () => {
    resetOnboarding();
  };

  return (
    <VStack
      border={'1px solid'}
      borderColor={useColorModeValue('neutral.200', 'neutral.700')}
      borderRadius={'lg'}
      width={'full'}
      p={4}
      spacing={4}
      alignItems={'flex-start'}
      gap={4}
    >
      <Heading as="h3">Onboarding</Heading>
      <HStack alignItems={'flex-start'} w={'full'}>
        <Switch
          id="email-notifications"
          isChecked={switchStatus}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onSwitchStatus(e.target.checked)
          }
          colorScheme="brand"
        />
        <Label>Getting Started Guide</Label>
        <Spacer />
        <HStack>
          <Text fontWeight={'semibold'}>{statusName}</Text>
          {statusName === 'Completed' && (
            <Button variant="tertiary" onClick={onRestart}>
              Restart
            </Button>
          )}
        </HStack>
      </HStack>
    </VStack>
  );
}
