const primaryBrandColor = '#DF2780';
const brandSecondaryColor = '#448F96';

// Colors scales were created using https://colorbox.io/
// To modify them import the file 'src/theme/base/colorsConfiguration.json'
// https://kvyn.medium.com/introducing-the-new-colorbox-e0109c021729
// TODO: Decide what to do with [sky, violet, fuchsia, gray, emerald]
// Currently they are using chakra's colours.

const colors = {
  current: 'currentColor',
  black: '#000000',
  white: '#FFFFFF',
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },
  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },
  brand: {
    25: '#faedf3',
    50: '#fbd2e6',
    100: '#fdb6d8',
    200: '#f382b8',
    300: '#e8519a',
    400: '#de267e',
    base: '#de267e',
    500: '#be1063',
    600: '#9e034d',
    700: '#7e003c',
    800: '#5e002d',
    850: '#490023',
    900: '#35001a',
    950: '#210110',
    1000: '#0d0106',
  },
  brandSecondary: {
    25: '#edf8fa',
    50: '#d4f5f9',
    100: '#bbf1f8',
    baseLight: '#bbf1f8',
    200: '#8ad5de',
    300: '#60b9c5',
    400: '#44969f',
    500: '#2b7f89',
    600: '#196972',
    700: '#0e535b',
    800: '#083e44',
    base: '#083e44',
    850: '#053136',
    900: '#032528',
    950: '#02181b',
    1000: '#010c0d',
  },
  neutral: {
    25: '#fafbfb',
    50: '#f5f8f8',
    100: '#f1f5f5',
    200: '#dfe6e6',
    300: '#c5cece',
    400: '#a3adad',
    500: '#7d8686',
    600: '#585f60',
    700: '#3a3f3f',
    800: '#242728',
    850: '#1e2020',
    900: '#171919',
    950: '#141616',
    1000: '#111313',
  },
  red: {
    25: '#faeeedff',
    50: '#f8d6d4ff',
    100: '#f7bdbaff',
    200: '#e98e89ff',
    300: '#dc625cff',
    400: '#ce3d35ff',
    500: '#b0241cff',
    600: '#92130cff',
    700: '#740a04ff',
    800: '#560501ff',
    850: '#430401ff',
    900: '#310301ff',
    950: '#1f0301ff',
    1000: '#0d0101ff',
  },
  sunset: {
    25: '#faefedff',
    50: '#f9dad4ff',
    100: '#f9c4bbff',
    200: '#ec9889ff',
    300: '#e0705cff',
    400: '#d34d34ff',
    500: '#b4331bff',
    600: '#96210bff',
    700: '#771503ff',
    800: '#580e00ff',
    850: '#450b01ff',
    900: '#320801ff',
    950: '#200601ff',
    1000: '#0d0201ff',
  },
  orange: {
    25: '#faf1edff',
    50: '#faddd1ff',
    100: '#fac9b5ff',
    200: '#eea180ff',
    300: '#e27b50ff',
    400: '#d65a26ff',
    500: '#b74210ff',
    600: '#983004ff',
    700: '#792400ff',
    800: '#5a1b00ff',
    850: '#461500ff',
    900: '#330f00ff',
    950: '#200a01ff',
    1000: '#0d0401ff',
  },
  amber: {
    25: '#faf3edff',
    50: '#f9e8d7ff',
    100: '#f9dcc0ff',
    200: '#edbd91ff',
    300: '#e0a165ff',
    400: '#d4873fff',
    500: '#b56a23ff',
    600: '#965211ff',
    700: '#773d07ff',
    800: '#592c02ff',
    850: '#462302ff',
    900: '#331902ff',
    950: '#201001ff',
    1000: '#0d0601ff',
  },
  yellow: {
    25: '#faf5edff',
    50: '#feeed3ff',
    100: '#ffe7b7ff',
    200: '#fbce81ff',
    300: '#f4b64eff',
    400: '#d5962dff',
    500: '#b67a16ff',
    600: '#976208ff',
    700: '#784c01ff',
    800: '#593800ff',
    850: '#462c00ff',
    900: '#332000ff',
    950: '#201401ff',
    1000: '#0d0801ff',
  },
  citron: {
    25: '#fafaedff',
    50: '#f3f2cbff',
    100: '#ecebabff',
    200: '#dad875ff',
    300: '#c7c546ff',
    400: '#adab2aff',
    500: '#939115ff',
    600: '#797709ff',
    700: '#5f5e02ff',
    800: '#454400ff',
    850: '#373600ff',
    900: '#292801ff',
    950: '#1b1a01ff',
    1000: '#0d0d01ff',
  },
  lime: {
    25: '#f6faedff',
    50: '#e6f1d1ff',
    100: '#d7e8b6ff',
    200: '#b9d384ff',
    300: '#9dbf5aff',
    400: '#83aa37ff',
    500: '#6a901fff',
    600: '#547710ff',
    700: '#405d07ff',
    800: '#2e4403ff',
    850: '#253602ff',
    900: '#1b2801ff',
    950: '#121a01ff',
    1000: '#090d01ff',
  },
  green: {
    25: '#f0faedff',
    50: '#d5eecdff',
    100: '#bbe1afff',
    200: '#8ec97dff',
    300: '#68b152ff',
    400: '#489930ff',
    500: '#33821bff',
    600: '#236a0dff',
    700: '#175306ff',
    800: '#0f3b02ff',
    850: '#0c3002ff',
    900: '#092401ff',
    950: '#061801ff',
    1000: '#030d01ff',
  },
  mint: {
    25: '#edfaf0ff',
    50: '#d9eedeff',
    100: '#c6e3ccff',
    200: '#9bcca6ff',
    300: '#75b584ff',
    400: '#559d66ff',
    500: '#3d864eff',
    600: '#256e36ff',
    700: '#155624ff',
    800: '#0b3e17ff',
    850: '#073111ff',
    900: '#04250cff',
    950: '#021907ff',
    1000: '#010d03ff',
  },
  teal: {
    25: '#edfafaff',
    50: '#d4ededff',
    100: '#bbe0e1ff',
    200: '#8dc7c9ff',
    300: '#65afb0ff',
    400: '#449698ff',
    500: '#2a7f81ff',
    600: '#186869ff',
    700: '#0d5152ff',
    800: '#063a3bff',
    850: '#042e2fff',
    900: '#022324ff',
    950: '#011818ff',
    1000: '#010d0dff',
  },
  cyan: {
    25: '#edf4faff',
    50: '#cfe5f6ff',
    100: '#b1d6f2ff',
    200: '#7db6e2ff',
    300: '#4e99d2ff',
    400: '#2f7cb7ff',
    500: '#19639cff',
    600: '#0b4e80ff',
    700: '#043b65ff',
    800: '#012a4aff',
    850: '#01223bff',
    900: '#01192bff',
    950: '#01101cff',
    1000: '#01080dff',
  },
  blue: {
    25: '#edf1faff',
    50: '#dce7ffff',
    100: '#cadcffff',
    200: '#9dbaffff',
    300: '#719afbff',
    400: '#497cf5ff',
    500: '#295bd2ff',
    600: '#1442afff',
    700: '#08308cff',
    800: '#032169ff',
    850: '#021a52ff',
    900: '#02133bff',
    950: '#010c24ff',
    1000: '#01040dff',
  },
  indigo: {
    25: '#efedfaff',
    50: '#e5e1ffff',
    100: '#dad5ffff',
    200: '#b5abffff',
    300: '#9184feff',
    400: '#705ff9ff',
    500: '#5440f4ff',
    600: '#3623ccff',
    700: '#2212a4ff',
    800: '#16087dff',
    850: '#100661ff',
    900: '#0b0345ff',
    950: '#070229ff',
    1000: '#02010dff',
  },
  purple: {
    25: '#f1edfaff',
    50: '#e9dfffff',
    100: '#e1cfffff',
    200: '#c1a4ffff',
    300: '#a37afbff',
    400: '#8754f5ff',
    500: '#6431d2ff',
    600: '#491aafff',
    700: '#350c8cff',
    800: '#250569ff',
    850: '#1d0452ff',
    900: '#14023bff',
    950: '#0c0224ff',
    1000: '#04010dff',
  },
  pink: {
    25: '#faedf7ff',
    50: '#f5d4eeff',
    100: '#f0bae4ff',
    200: '#df8accff',
    300: '#ce5fb5ff',
    400: '#bd3ba0ff',
    500: '#a12185ff',
    600: '#85116bff',
    700: '#690754ff',
    800: '#4d033dff',
    850: '#3d0230ff',
    900: '#2d0223ff',
    950: '#1d0117ff',
    1000: '#0d010aff',
  },
  rose: {
    25: '#faedf1ff',
    50: '#f5d3ddff',
    100: '#f0b9c9ff',
    200: '#df88a1ff',
    300: '#cf5d7dff',
    400: '#be385eff',
    500: '#a21f44ff',
    600: '#860f31ff',
    700: '#6a0622ff',
    800: '#4e0218ff',
    850: '#3d0213ff',
    900: '#2d010eff',
    950: '#1d0109ff',
    1000: '#0d0104ff',
  },
  emerald: {
    50: '#ecfdf5',
    100: '#d1fae5',
    200: '#a7f3d0',
    300: '#6ee7b7',
    400: '#34d399',
    500: '#10b981',
    600: '#059669',
    700: '#047857',
    800: '#065f46',
    900: '#064e3b',
  },
  sky: {
    50: '#f0f9ff',
    100: '#f0f9ff',
    200: '#f0f9ff',
    300: '#7dd3fc',
    400: '#7dd3fc',
    500: '#0ea5e9',
    600: '#0ea5e9',
    700: '#0ea5e9',
    800: '#0ea5e9',
    900: '#0ea5e9',
  },
  violet: {
    50: '#f5f3ff',
    100: '#ede9fe',
    200: '#ddd6fe',
    300: '#c4b5fd',
    400: '#a78bfa',
    500: '#8b5cf6',
    600: '#7c3aed',
    700: '#6d28d9',
    800: '#5b21b6',
    900: '#4c1d95',
  },
  fuchsia: {
    50: '#fdf4ff',
    100: '#fae8ff',
    200: '#f5d0fe',
    300: '#f0abfc',
    400: '#e879f9',
    500: '#d946ef',
    600: '#c026d3',
    700: '#a21caf',
    800: '#86198f',
    900: '#701a75',
  },
  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923',
  },
};

export default colors;
