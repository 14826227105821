import { useState } from 'react';
import {
  Center,
  CircularProgress,
  Fade,
  HStack,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  TInventoryModel,
  TInventoryModelCustomField,
} from '../../../models/inventory_model';
import { getSchemaPropertyByKey } from '../../../utils';
import useModelCustomField from '../../../hooks/useModelCustomField';
import { Label } from '../../Layout';
import MoreInfoPopOver from '../../MoreInfoPopOver';
import CustomFieldForm from './CustomFieldForm';

export interface FieldDisplayProps {
  field: TInventoryModelCustomField;
  onEdit?: (data: any) => void;
}

export interface FieldEditProps extends FieldDisplayProps {
  onEdit: (data: any) => void;
  onCancel: (data: any) => void;
  onChange?: (data: any) => void;
}

export interface CustomFieldProps {
  inventoryModel: TInventoryModel;
  propertyKey: string;
  isCoreField?: boolean;
  readOnly?: boolean;
  displayComponent?: (field: TInventoryModelCustomField) => JSX.Element;
  hideLabel?: boolean;
}

interface CustomFieldTitleProps {
  property: any;
  settings: any;
  hideLabel?: boolean;
}
const CustomFieldTitle = ({
  settings,
  property,
  hideLabel,
}: CustomFieldTitleProps) => {
  if (hideLabel) {
    return <></>;
  }
  return (
    <HStack w={'full'} alignItems={'flex-start'}>
      <Label>{property.title!}</Label>
      {settings.description && (
        <MoreInfoPopOver
          title={property.title!}
          description={settings.description!}
          iconProps={{
            mt: '-2px',
            opacity: 0,
            _groupHover: { opacity: 1 },
          }}
        />
      )}
    </HStack>
  );
};
const CustomField = ({
  inventoryModel,
  propertyKey,
  displayComponent,
  isCoreField = false,
  readOnly = false,
  hideLabel = false,
}: CustomFieldProps) => {
  const [isEditingSaving, setIsEditingSaving] = useState(false);
  const {
    data: customFieldData,
    isLoading,
    postCustomFieldData,
  } = useModelCustomField(inventoryModel, propertyKey);

  const onCancel = () => {
    setIsEditingSaving(false);
  };

  const onEdit = async (data: any) => {
    if (readOnly) {
      return;
    }

    setIsEditingSaving(true);
    postCustomFieldData.mutate(
      {
        inventoryModel,
        propertyKey,
        formData: data,
      },
      {
        onSuccess: () => {
          setIsEditingSaving(false);
        },
      },
    );
  };

  if (postCustomFieldData.isLoading && customFieldData) {
    const [property, settings] = getSchemaPropertyByKey(
      customFieldData,
      propertyKey,
    );
    return (
      <Stack
        w={'full'}
        gap={2}
        borderRadius={'2px'}
        cursor={'wait'}
        bg={
          isEditingSaving
            ? useColorModeValue('neutral.50', 'neutral.800')
            : useColorModeValue('white', 'black')
        }
        boxShadow={
          isEditingSaving
            ? useColorModeValue(
                '0px 0px 0px 8px var(--chakra-colors-neutral-50)',
                '0px 0px 0px 8px var(--chakra-colors-neutral-800)',
              )
            : '0px 0px 0px 8px transparent, 0px 0px 0px 9px transparent'
        }
      >
        <CustomFieldTitle
          settings={settings}
          property={property}
          hideLabel={hideLabel}
        />
        <Center>
          <Fade
            in={true}
            transition={{ exit: { delay: 1 }, enter: { duration: 0.5 } }}
          >
            <CircularProgress
              size="40px"
              thickness="2px"
              isIndeterminate
              color="brand.base"
            />
          </Fade>
        </Center>
      </Stack>
    );
  }

  if (customFieldData) {
    return (
      <CustomFieldForm
        customField={customFieldData}
        onEdit={onEdit}
        onCancel={onCancel}
        readOnly={readOnly}
        hideLabel={hideLabel}
        displayComponent={displayComponent}
      />
    );
  }

  return <></>;
};

export default CustomField;
