import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import {
  Flex,
  FormControl,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  ScaleFade,
  Spinner,
  Text,
  Wrap,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { TRole } from '../../models/role';
import RolePill from '../RolePill';
import { EmptyStateDisplay } from '../EmptyStateDisplay';
import { Label } from '../Layout';

interface RolesPicker {
  assignedRoles: TRole[];
  onChange: (roles: TRole[]) => void;
}

export default function RolesPicker({
  assignedRoles = [],
  onChange,
}: RolesPicker) {
  const { getAccessTokenSilently } = useAuth0();
  const [availableRoles, setAvailableRoles] = useState<TRole[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<TRole[]>([]);

  useEffect(() => {
    onChange(selectedRoles);
  }, [selectedRoles]);

  const excludeAssignedRoles = (role: TRole) => {
    return !assignedRoles.find(exist => exist.cuid === role.cuid);
  };

  const excludeModelScopeRoles = (role: TRole) => {
    return role.scope !== 'Model';
  };

  const { data: orgRoles, isFetching } = useQuery(
    ['roles'],
    async () => {
      setSelectedRoles([]);
      const accessToken = await getAccessTokenSilently();
      const roles = await API.GetOrganizationRoles(accessToken);
      return roles;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: roles => {
        const availableRoles = roles
          .filter(excludeAssignedRoles)
          .filter(excludeModelScopeRoles);
        setAvailableRoles(availableRoles);
      },
    },
  );

  const handleAddRole = (role: TRole) => {
    if (
      !selectedRoles.some(selectedRoles => selectedRoles.cuid === role.cuid)
    ) {
      setSelectedRoles([...selectedRoles, role]);
      setAvailableRoles(
        availableRoles.filter(selectedRole => selectedRole.cuid !== role.cuid),
      );
    }
  };

  const handleRemoveRole = (role: TRole) => {
    const updatedRoles = selectedRoles.filter(
      selectedRole => selectedRole.cuid !== role.cuid,
    );
    setSelectedRoles(updatedRoles);
    setAvailableRoles([...availableRoles, role]);
  };

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = e => {
    const searchTerm = e.target.value.toLowerCase();
    const allRoles = orgRoles ?? [];
    const searchedRoles = allRoles.filter(role => {
      const isNotSelected = !selectedRoles.some(
        selectedRole => selectedRole.cuid === role.cuid,
      );
      return isNotSelected && role.name.toLowerCase().includes(searchTerm);
    });
    const availableRoles = searchedRoles.filter(excludeAssignedRoles).filter(excludeModelScopeRoles);
    setAvailableRoles(availableRoles);
  };

  return (
    <Flex
      direction="row"
      w="full"
      gap={4}
      alignSelf="stretch"
      alignItems="flex-start"
    >
      <Flex
        direction="column"
        gap={4}
        p={4}
        pb={6}
        width="50%"
        borderRadius="lg"
        borderWidth={1}
        borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        backgroundColor={useColorModeValue('neutral.50', 'neutral.900')}
      >
        <FormControl>
          <Label mb={2}>ALL ROLES</Label>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="neutral.400" />
            </InputLeftElement>
            <Input placeholder="Search role" onChange={handleSearch} />
          </InputGroup>
        </FormControl>
        <Wrap w="full">
          {isFetching ? (
            <Spinner size="sm" />
          ) : (
            availableRoles.map((role, index) => (
              <ScaleFade
                key={role.cuid}
                in={true}
                initialScale={0.5}
                delay={index * 0.05}
              >
                <RolePill
                  key={role.cuid}
                  role={role}
                  onAdd={() => handleAddRole(role)}
                />
              </ScaleFade>
            ))
          )}
        </Wrap>
      </Flex>
      <Flex
        direction="column"
        p={4}
        gap={4}
        width="50%"
        alignSelf="stretch"
        borderRadius="lg"
        borderWidth={1}
        borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        backgroundColor={useColorModeValue('neutral.50', 'neutral.900')}
        minHeight="xs"
        data-testid="to-be-assigned-container"
      >
        <Label>TO BE ASSIGNED</Label>
        {!selectedRoles.length && (
          <EmptyStateDisplay variant="no-findings">
            <Heading as="h5">No roles assigned</Heading>
            <Text align="center" pb={5}>
              There are no roles selected yet. Please add a <br />
              role from the left to assign to users.
            </Text>
          </EmptyStateDisplay>
        )}
        <HStack w="full" flexWrap={"wrap"}>
          {selectedRoles.map(role => (
            <ScaleFade key={role.cuid} in={true} initialScale={0.5}>
              <RolePill
                key={role.cuid}
                role={role}
                onClose={() => handleRemoveRole(role)}
              />
            </ScaleFade>
          ))}
        </HStack>
      </Flex>
    </Flex>
  );
}
