import { Template } from '../../models';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Editor, { DiffEditor } from '@monaco-editor/react';
import { parse, stringify } from 'yaml';
import { convertToSectionTree, flattenSections } from '../../models/template';
import { DeleteIcon } from '@chakra-ui/icons';
import { Label } from '../Layout';
import { useMutation } from 'react-query';
import API from '../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import ConfirmationAlert from '../ConfirmationAlert';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export const editorOptions = {
  fontSize: 14,
  folding: true,
  lineHeight: 18,
  contextmenu: true,
  automaticLayout: true,
  roundedSelection: true,
  fontFamily: 'monospace',
  selectOnLineNumbers: true,
  hideCursorInOverviewRuler: true,
  minimap: {
    enabled: true,
  },
  scrollbar: {
    horizontalSliderSize: 24,
    verticalSliderSize: 24,
  },
  autoIndent: 'full',
  detectIndentation: true,
  tabSize: 2,
  matchBrackets: 'always',
};

export const diffOptions = { ...editorOptions, readOnly: true };

interface TemplateEditModalProps {
  open: boolean;
  templateType: string;
  baseTemplate: Template;
  onClose: () => void;
}
export default function TemplateEditModal({
  open,
  templateType,
  baseTemplate,
  onClose,
}: TemplateEditModalProps) {
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const navigate = useNavigate();
  const [isDiffMode, setIsDiffMode] = useState(false);
  const [templateHasChanged, setTemplateHasChanged] = useState(false);
  const [changesDescription, setChangesDescription] = useState('');
  const [saveTemplateError, setSaveTemplateError] = useState('');
  const [validateError, setValidateError] = useState('');
  const [originalCode, setOriginalCode] = useState(stringify({}));
  const [changedCode, setChangedCode] = useState(stringify({}));
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  function getJson() {
    return baseTemplate.current_version!.json;
  }

  function getChangedJson(code: any) {
    /* Build the entire template structure, root and its sections */
    const sectionList = flattenSections(parse(code));
    const currentJson = JSON.parse(JSON.stringify(getJson()));
    currentJson.sections = sectionList;
    return currentJson;
  }

  useEffect(() => {
    const code = convertToSectionTree(getJson().sections);
    setOriginalCode(stringify(code));
    setChangedCode(stringify(code));
  }, [baseTemplate, templateType]);

  const onCodeChanged = (newCode: any) => {
    setChangedCode(newCode);
    setTemplateHasChanged(true);
    setValidateError('');
  };

  const onCodeChangedAndContinueClicked = () => {
    try {
      parse(changedCode);
      validateTemplate.mutate(changedCode);
    } catch (error: any) {
      // Capture YAML parsing errors before sending the request
      setValidateError(error.message);
      return;
    }
    // setIsDiffMode(true);
  };

  const onCloseModal = (confirmed: boolean = true) => {
    setShowCloseConfirmation(false);
    if (confirmed) {
      const code = convertToSectionTree(getJson().sections);
      setOriginalCode(stringify(code));
      setChangedCode(stringify(code));
      setIsDiffMode(false);
      setTemplateHasChanged(false);
      setChangesDescription('');
      setSaveTemplateError('');
      setValidateError('');
      onClose();
    }
  };

  const validateTemplate = useMutation(
    ['template', baseTemplate?.cuid, 'validate'],
    async (changedCode: any) => {
      const changedJson = getChangedJson(changedCode);
      const accessToken = await getAccessTokenSilently();
      return API.ValidateTemplate(accessToken, baseTemplate!.cuid, changedJson);
    },
    {
      onSuccess: result => {
        if (!result.valid) {
          setValidateError(result.message);
        } else {
          setIsDiffMode(true);
        }
      },
      onError: (error: any) => {
        const errorMessage =
          error.response.status === 400
            ? error.response.data.message.split('\n\n')[0]
            : error.message;
        setValidateError(errorMessage);
      },
    },
  );

  const updateTemplate = useMutation(
    ['template', baseTemplate?.cuid],
    async (changedCode: any) => {
      try {
        const changedJson = getChangedJson(changedCode);
        const accessToken = await getAccessTokenSilently();

        return API.UpdateTemplate(
          accessToken,
          baseTemplate!.cuid,
          templateType as 'model_documentation' | 'validation_report',
          changesDescription,
          changedJson,
        );
      } catch (error: any) {
        // Capture parsing errors before sending the validation request
        setSaveTemplateError(error.message);
        return;
      }
    },
    {
      onSuccess: updatedTemplate => {
        if (updatedTemplate) {
          toast({
            description: `Well done, new version was created successfully!. Template's latest version is now version ${updatedTemplate?.current_version?.version_number}.`,
            status: 'success',
            isClosable: true,
          });
          navigate(
            `/settings/templates/${updatedTemplate.cuid}/${templateType}/${updatedTemplate.current_version?.cuid}`,
          );
          onCloseModal();
        }
      },
      onError: (error: any) => {
        const errorMessage =
          error.response.status === 400
            ? error.response.data.message.split('\n\n')[0]
            : error.message;
        setSaveTemplateError(errorMessage);
      },
    },
  );

  const onSaveVersion = () => {
    updateTemplate.mutate(changedCode);
  };

  const onGoBack = () => {
    setIsDiffMode(false);
    setSaveTemplateError('');
  };

  const confirmClose = () => {
    if (templateHasChanged) {
      setShowCloseConfirmation(true);
      // and check ConfirmationAlert
    } else {
      onCloseModal();
    }
  };

  return (
    <>
      <ConfirmationAlert
        open={showCloseConfirmation}
        onConfirm={onCloseModal}
        title={'You have unsaved changes'}
        dialogBody={
          'Are you sure you want to discard this version? Your changes will be lost.'
        }
        cancelButton={'No, go back'}
        confirmButton={<Button ml={3}>Yes, Discard changes</Button>}
      />
      <Modal
        isOpen={open}
        onClose={confirmClose}
        size={'full'}
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent bg={'neutral.50'} margin={16}>
          {!isDiffMode ? (
            <>
              <ModalHeader>Edit Template</ModalHeader>
              <ModalCloseButton data-testid="close-template-modal-btn" />
              <ModalBody>
                <Box
                  height={'calc(90vh - 5rem)'}
                  hidden={isDiffMode}
                  overflow={'hidden'}
                  borderRadius={'md'}
                  border={'1px solid'}
                  borderColor={'neutral.200'}
                >
                  <Editor
                    defaultLanguage="yaml"
                    defaultValue={stringify(originalCode || {})}
                    value={changedCode}
                    options={editorOptions}
                    onChange={onCodeChanged}
                    theme="vs-dark"
                  />
                </Box>

                <Alert
                  hidden={validateError === ''}
                  status="warning"
                  variant="left-accent"
                  alignItems={'flex-start'}
                >
                  <AlertIcon />
                  <Box>
                    <AlertTitle>
                      Your new template version didn't validate correctly.
                    </AlertTitle>
                    <AlertDescription>{validateError}</AlertDescription>
                  </Box>
                </Alert>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => onCloseModal()}
                  leftIcon={<DeleteIcon />}
                  isDisabled={!templateHasChanged}
                  variant={'ghost'}
                  data-testid="template-editor-discard"
                >
                  Discard Changes
                </Button>
                <Spacer />
                <Button
                  isDisabled={!templateHasChanged}
                  onClick={onCodeChangedAndContinueClicked}
                  isLoading={validateTemplate.isLoading}
                  data-testid="template-editor-continue"
                >
                  Continue
                </Button>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalHeader>Review Changes</ModalHeader>
              <ModalBody>
                <HStack>
                  <Stack flex={1}>
                    <Label>Version</Label>
                    <Text>
                      {baseTemplate.current_version?.version_number} (Latest)
                    </Text>
                  </Stack>
                  <Stack flex={1}>
                    <Label>Version</Label>
                    <Text>
                      {baseTemplate.current_version?.version_number! + 1}{' '}
                      (Draft)
                    </Text>
                  </Stack>
                </HStack>
                <Spacer h={8} />
                <Box
                  height={'calc(90vh - 22rem)'}
                  hidden={false}
                  overflow={'hidden'}
                  borderRadius={'md'}
                  border={'1px solid'}
                  borderColor={'neutral.200'}
                >
                  <DiffEditor
                    original={stringify(originalCode || {})}
                    modified={stringify(changedCode || {})}
                    language="yaml"
                    theme="vs-dark"
                    options={diffOptions}
                  />
                </Box>
                <Spacer h={8} />
                <Alert status="info">
                  <HStack align={'flex-start'}>
                    <AlertIcon />
                    <Stack>
                      <AlertTitle>
                        Your changes will be saved as a new version of "
                        {baseTemplate.name}" Template
                      </AlertTitle>
                      <AlertDescription>
                        All future documentation inventory models using this template
                        will include your changes. Models that are currently
                        using this template will not be affected.
                      </AlertDescription>
                    </Stack>
                  </HStack>
                </Alert>
                <Spacer h={8} />

                <FormControl>
                  <Label>VERSION NOTES</Label>
                  <Textarea
                    value={changesDescription}
                    onChange={e => setChangesDescription(e.target.value)}
                    placeholder="Add notes about this version to continue"
                  />
                </FormControl>

                <Alert
                  hidden={saveTemplateError === ''}
                  status="warning"
                  variant="left-accent"
                  alignItems={'flex-start'}
                >
                  <AlertIcon />
                  <Box>
                    <AlertTitle>
                      Your new template version didn't save correctly.
                    </AlertTitle>
                    <AlertDescription>{saveTemplateError}</AlertDescription>
                  </Box>
                </Alert>
              </ModalBody>
              <ModalFooter gap={4}>
                <Button
                  leftIcon={<ArrowLeftIcon width={16} />}
                  onClick={onGoBack}
                >
                  Go back
                </Button>
                <Button
                  onClick={() => onCloseModal()}
                  leftIcon={<DeleteIcon />}
                  variant={'ghost'}
                >
                  Discard Changes
                </Button>
                <Spacer />
                <Button
                  onClick={onSaveVersion}
                  isDisabled={changesDescription.length === 0}
                  isLoading={updateTemplate.isLoading}
                >
                  Save Version{' '}
                  {baseTemplate.current_version?.version_number! + 1}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
