import React, { useState } from 'react';
import {
  Box,
  BoxProps,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  useColorModeValue,
} from '@chakra-ui/react';
import { NodeProps, useReactFlow } from 'reactflow';
import {
  Cog6ToothIcon,
  EllipsisVerticalIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { Label } from '../../../../../../components/Layout';
import ConfirmationAlert from '../../../../../../components/ConfirmationAlert';

interface NodeBoxProps extends BoxProps {
  nodeProps: NodeProps;
  title?: string;
  icon?: React.ComponentType;
  children: React.ReactNode;
  errors?: string[];
}

const NodeBox = ({
  title,
  icon,
  errors = [],
  children,
  nodeProps,
  ...rest
}: NodeBoxProps) => {
  const { setSelectedNodeId } = useWorkflow();
  const { deleteElements } = useReactFlow();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const isBuilder = !nodeProps.data?.execution;

  const onDelete = (confirmed: boolean) => {
    if (confirmed) {
      deleteElements({ nodes: [{ id: nodeProps.id }] });
    }
    setShowDeleteConfirm(false);
  };

  return (
    <>
      <Box
        onDoubleClick={() => setSelectedNodeId!(nodeProps.id)}
        rounded={'sm'}
        shadow={nodeProps.selected ? 'xl' : 'none'}
        onClick={() => console.log(nodeProps)}
        {...rest} // Spread the rest of the props here
        border={nodeProps.selected ? 2 : 1}
        borderColor={
          nodeProps.selected
            ? 'brand.base'
            : useColorModeValue('neutral.200', 'neutral.700')
        }
        role={'group'}
      >
        {(icon || title) && (
          <HStack alignItems={'flex-start'} px={2} pb={4}>
            {icon && (
              <Box mt={3}>
                <Icon as={icon} boxSize={6} color={'neutral.500'} />
              </Box>
            )}

            <HStack w={'full'} mt={2}>
              {title && (
                <Label>
                  {/* TODO: support validation report */}
                  {title}
                </Label>
              )}

              {isBuilder && (
                <>
                  <Spacer />
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                      variant="ghost"
                      size={'sm'}
                      visibility={'hidden'}
                      _groupHover={{ visibility: 'visible' }}
                    />
                    <MenuList>
                      <MenuItem
                        icon={<Icon as={Cog6ToothIcon} boxSize={4} />}
                        onClick={() => setSelectedNodeId!(nodeProps.id)}
                      >
                        Configure
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem
                        icon={<Icon as={TrashIcon} boxSize={4} />}
                        _hover={{
                          bg: 'red.500',
                          color: 'white',
                        }}
                        onClick={() => setShowDeleteConfirm(true)}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </>
              )}
            </HStack>
          </HStack>
        )}

        {children}
      </Box>
      <ConfirmationAlert
        title={`Deleting step`}
        dialogBody={`Are you sure you want to delete this step?`}
        open={showDeleteConfirm}
        onConfirm={onDelete}
      />
    </>
  );
};

export default NodeBox;
