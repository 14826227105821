import { useContext, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Spacer,
  Tag,
  TagLabel,
  Checkbox,
  useColorModeValue,
  HStack,
  Input,
  VStack,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  ListItem,
  UnorderedList,
  PopoverArrow,
  PopoverCloseButton,
  Stack,
  CloseButton,
  Badge,
  Button,
} from '@chakra-ui/react';

import { DataTable } from '../DataTable';
import AvatarProxy from '../AvatarProxy';
import { displayFormatedDateAndTime } from '../../utils';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import { LoadingContainer } from '../LoadingContainer';
import {
  InformationCircleIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { Icon } from '@chakra-ui/icons';
import { TUnitMetric } from '../../models/unit_metric';
import UnitMetricContent from '../Templates/UnitMetricContent';
import MoreInfoPopOver from '../MoreInfoPopOver';
import InventoryModelContext from '../../contexts/InventoryModel';

interface AddBlockMetricOverTimeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (content_type: string, content_id: string) => Promise<void>;
}

const CustomTypeHeader = () => {
  return (
    <HStack>
      <Text>TYPE</Text>
      <Popover placement="bottom" closeOnBlur trigger="hover">
        <PopoverTrigger>
          <Icon as={InformationCircleIcon} boxSize={5} color={'neutral.400'} />
        </PopoverTrigger>
        <PopoverContent rounded={'md'}>
          <PopoverHeader rounded={'md'}>TEST TYPES</PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody
            textTransform={'none'}
            fontWeight={'normal'}
            fontSize={'sm'}
            overflow={'scroll'}
            w={'full'}
            whiteSpace={'normal'}
            rounded={'md'}
          >
            <UnorderedList>
              <ListItem>
                <strong>Default:</strong> A function contained in the ValidMind
                Library, designed to run a specific quantitative test on the
                dataset or model. Tests are the building blocks of ValidMind,
                used to evaluate and document models and datasets, and can be
                run individually or as part of a suite defined by your model
                documentation template.
              </ListItem>
              <ListItem>
                <strong>Custom:</strong> Custom tests are functions that you
                define to evaluate your model or dataset. These functions can be
                registered with ValidMind to be used in the platform.
              </ListItem>
            </UnorderedList>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  );
};

export default function AddBlockMetricOverTimeModal({
  isOpen,
  onClose,
  onAdd,
}: AddBlockMetricOverTimeModalProps) {
  const { inventoryModel } = useContext(InventoryModelContext);
  const { getAccessTokenSilently } = useAuth0();
  const [selectedUnitMetrics, setSelectedUnitMetrics] = useState<TUnitMetric[]>(
    [],
  );
  const [previewUnitMetric, setPreviewUnitMetric] = useState<
    TUnitMetric | undefined
  >();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const { data, isLoading } = useQuery(
    'unit-metrics',
    async () => {
      const token = await getAccessTokenSilently();
      const unitMetrics = await API.GetUnitMetrics(token, inventoryModel!.cuid);

      // sort keys in metricKeys alphabetically
      unitMetrics.sort((a, b) => a.key.localeCompare(b.name));

      return unitMetrics;
    },
    {
      enabled: !!inventoryModel,
    },
  );

  const onInsert = async () => {
    setIsSubmitting(true);
    try {
      for (const test of selectedUnitMetrics) {
        await onAdd('unit_metric', test.key);
      }
      onClose();
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onModalClose = () => {
    onClose();
    setSelectedUnitMetrics([]);
    setPreviewUnitMetric(undefined);
    setSearchQuery('');
  };

  const columns = useMemo(
    () => [
      {
        id: 'checkbox',
        Cell: ({ row }: any) => {
          const foundIndex = selectedUnitMetrics.findIndex(
            key => key === row.original,
          );
          const isChecked = foundIndex !== -1;
          return (
            <Box
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="center"
            >
              <Checkbox
                isChecked={isChecked}
                onChange={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (e.target.checked) {
                    setSelectedUnitMetrics([
                      ...selectedUnitMetrics,
                      row.original,
                    ]);
                  } else {
                    setSelectedUnitMetrics(
                      selectedUnitMetrics.filter(key => key !== row.original),
                    );
                  }
                }}
              />
              {foundIndex !== -1 && (
                <Badge bgColor="brand" h="16px" lineHeight="16px">
                  {foundIndex + 1}
                </Badge>
              )}
            </Box>
          );
        },
      },
      {
        accessor: 'name',
        Header: 'Name',
        Cell: (data: any) => {
          const { row } = data;
          return (
            <Box display="flex" flex={1} flexDirection="column">
              <Text fontSize={'sm'} fontWeight={'bold'}>
                {row.values.name}
              </Text>
              <Text
                fontSize={'sm'}
                color="neutral.500"
                textOverflow="ellipsis"
                overflow="hidden"
                maxW={'300px'}
                textAlign={'left'}
                style={{
                  direction: 'rtl',
                }}
              >
                {row.original.key}
              </Text>
            </Box>
          );
        },
      },
      {
        accessor: 'is_default',
        Header: <CustomTypeHeader />,
        Cell: ({ value }: any) => {
          return <Text fontSize={'sm'}>{value ? 'Default' : 'Custom'}</Text>;
        },
      },
      {
        accessor: 'tag',
        Header: 'Tag',
        Cell: ({ row }: any) => {
          return (
            <Text fontSize={'sm'} fontFamily={'monospace'}>
              {row.values.tag}
            </Text>
          );
        },
      },
      {
        accessor: 'created_at',
        Header: 'Last Updated',
        Cell: ({ value }: any) => {
          return (
            <Text fontSize={'sm'}>{displayFormatedDateAndTime(value)}</Text>
          );
        },
      },
      {
        accessor: 'user',
        Header: 'Updated By',
        disableSortBy: true,
        Cell: ({ value }: any) => {
          return (
            <Box>
              <Tag key={value.cuid} size={'md'}>
                <AvatarProxy
                  src={value.picture}
                  size="xs"
                  name={value.name}
                  ml={-2}
                  mr={2}
                />
                <TagLabel>{value.name}</TagLabel>
              </Tag>
            </Box>
          );
        },
      },
    ],
    [selectedUnitMetrics],
  );

  const filteredData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.filter((test: TUnitMetric) => {
      if (!searchQuery) {
        return true;
      }
      return test.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [data, searchQuery]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
      size={'6xl'}
    >
      <ModalOverlay />
      <ModalContent
        h="95vh"
        maxHeight="95vh"
        width="100%"
        maxW="95%"
        bgColor="white"
      >
        <ModalHeader>
          Select a Metric Over Time Block
          <MoreInfoPopOver
            title="Insert Metrics Over Time"
            link="https://docs.validmind.ai/guide/monitoring/work-with-metrics-over-time.html"
            placement="bottom"
            iconProps={{
              ml: 2,
            }}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flex={1}>
          <LoadingContainer isLoading={isLoading}>
            <VStack flex={1} alignItems="flex-start">
              <InputGroup w={'50%'}>
                <InputLeftElement pointerEvents="none">
                  <Icon
                    as={MagnifyingGlassIcon}
                    boxSize={5}
                    color={'neutral.400'}
                  />
                </InputLeftElement>
                <Input
                  type="search"
                  placeholder="Search by name"
                  maxW="300px"
                  onChange={e => {
                    setSearchQuery(e.target.value);
                  }}
                  value={searchQuery}
                  w={'full'}
                />
              </InputGroup>

              <Allotment vertical separator>
                <Allotment.Pane>
                  <Box w="full" h="full" overflow="scroll" mb={4}>
                    <DataTable
                      data={filteredData}
                      columns={columns}
                      isInteractive={true}
                      pageSize={99999}
                      onClickRow={(row: any) => {
                        setPreviewUnitMetric(row.original);
                      }}
                      getRowProps={(row: any) => {
                        const defaultBgColor =
                          row.index % 2 === 0
                            ? useColorModeValue(
                                'var(--chakra-colors-neutral-25)',
                                'var(--chakra-colors-neutral-1000)',
                              )
                            : 'transparent';
                        const highlightedBgColor = useColorModeValue(
                          'var(--chakra-colors-brand-50)',
                          'var(--chakra-colors-brand-800)',
                        );

                        return {
                          style: {
                            background:
                              previewUnitMetric?.key === row.original.key
                                ? highlightedBgColor
                                : defaultBgColor,
                            borderBottom: '1px solid',
                            borderBottomColor: useColorModeValue(
                              'var(--chakra-colors-neutral-100)',
                              'var(--chakra-colors-neutral-850)',
                            ),
                          },
                        };
                      }}
                    />
                  </Box>
                </Allotment.Pane>
                {previewUnitMetric && (
                  <Allotment.Pane>
                    <VStack h={'full'} p={4}>
                      <Stack alignItems={'flex-end'} w={'full'}>
                        <CloseButton
                          onClick={() => setPreviewUnitMetric(undefined)}
                        />
                      </Stack>
                      <Box
                        display="flex"
                        flex={1}
                        borderRadius={'md'}
                        px={4}
                        w={'full'}
                        overflowY="auto"
                      >
                        <UnitMetricContent
                          readOnly={true}
                          contents={{
                            content_id: previewUnitMetric.key,
                            content_type: 'unit_metric',
                          }}
                        />
                      </Box>
                    </VStack>
                  </Allotment.Pane>
                )}
              </Allotment>
            </VStack>
          </LoadingContainer>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onModalClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            hidden={selectedUnitMetrics.length === 0}
            onClick={onInsert}
            isDisabled={selectedUnitMetrics.length === 0 || isSubmitting}
            isLoading={isSubmitting}
            variant={'primary'}
          >
            {`Insert ${selectedUnitMetrics.length} Metric${
              selectedUnitMetrics.length > 1 ? 's' : ''
            } Over Time to Document`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
