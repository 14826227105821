import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { LoadingContainer } from '../../../../components/LoadingContainer';

import { useDropzone } from 'react-dropzone';
import { DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import { useMutation } from 'react-query';
import API from '../../../../api/API';
import { useNavigate } from 'react-router-dom';
import AttributesRailDocumentOverview from '../../../../components/AttributesRailDocumentOverview';
import RecordDetailsPage from '../../../../components/Layout/RecordDetailsPage';
import DocumentationHeader from '../../../../components/DocumentationHeader';
import AttributesRailValidationReportOverview from '../../../../components/AttributesRailValidationReportOverview';
import ValidationReportHeader from '../../../../components/ValidationReportHeader';
import { Label } from '../../../../components/Layout';
import PdfViewer from '../../../../components/PdfViewer';
import { Icon } from '@chakra-ui/icons';
import TemplatesSelect from '../../../../components/ModelInventoryInputs/TemplatesSelect';
import { useGroups, useGroupTemplates } from '../../../../hooks/useGroups';
import { TGroup } from '../../../../models';
import MonitoringHeader from '../../../../components/MonitoringHeader';
import AttributesRailMonitoringOverview from '../../../../components/AttributesRailMonitoringOverview';
import { InventoryModelStages } from '../../../../models/inventory_model';
import InventoryModelContext from '../../../../contexts/InventoryModel';

interface Props {
  documentType: 'model_documentation' | 'validation_report' | 'monitoring';
}

export default function OfflineDocumentationOverview({ documentType }: Props) {
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { inventoryModel } = useContext(InventoryModelContext);
  const [files, setFiles] = useState<File[]>([]);
  const [templateCuid, setTemplateCuid] = useState('');

  const { groups } = useGroups();
  const [group, setGroup] = useState<TGroup>();
  const { templates } = useGroupTemplates({ group });

  useEffect(() => {
    if (groups.length > 0) {
      setGroup(groups[0]);
    }
  }, [groups]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // handle the file drop or select state, it opens the modal to confirm upload
    setFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  useEffect(() => {
    // Open the upload modal when files are selected
    if (files.length > 0) {
      onOpen();
    }
  }, [files]);

  const uploadDocumentFiles = useMutation(
    [],
    async ({ files }: { files: File[] }) => {
      const accessToken = await getAccessTokenSilently();
      return await API.UploadOfflineDocument(
        accessToken,
        inventoryModel!,
        documentType,
        files,
      );
    },
    {
      onSuccess: data => {
        toast({
          title: 'File uploaded',
          description: 'The file has been uploaded successfully',
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        });
        window.location.reload();
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: error.message,
            status: 'error',
            isClosable: true,
            position: 'bottom-right',
          });
        }
      },
    },
  );

  const deleteDocumentFiles = useMutation(
    [],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.DeleteOfflineDocument(
        accessToken,
        inventoryModel!,
        documentType,
      );
    },
    {
      onSuccess: data => {
        window.location.reload();
      },
    },
  );

  const setTemplate = useMutation(
    [],
    async (templateCuid: string) => {
      const accessToken = await getAccessTokenSilently();
      return await API.SetTemplate(
        accessToken,
        inventoryModel!,
        documentType,
        templateCuid,
      );
    },
    {
      onSuccess: data => {
        toast({
          title: 'Template set',
          description: 'The template has been set successfully',
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        });
        window.location.reload();
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: error.message,
            status: 'error',
            isClosable: true,
            position: 'bottom-right',
          });
        }
      },
    },
  );

  const onUpload = () => {
    uploadDocumentFiles.mutate({ files });
  };

  const documentFiles =
    documentType === 'model_documentation'
      ? inventoryModel?.template.offline_files || []
      : inventoryModel?.template_vr.offline_files || [];

  const showDropzone = documentFiles.length === 0;
  let AttributesRail = {
    model_documentation: AttributesRailDocumentOverview,
    validation_report: AttributesRailValidationReportOverview,
    monitoring: AttributesRailMonitoringOverview,
  }[documentType];

  let Header = {
    model_documentation: DocumentationHeader,
    validation_report: ValidationReportHeader,
    monitoring: MonitoringHeader,
  }[documentType];

  return (
    <LoadingContainer isLoading={false}>
      <Flex minHeight="100vh" w={'full'}>
        <Stack
          px={8}
          w={'full'}
          alignItems={'center'}
          overflow={'auto'}
          className="no-scrollbar"
        >
          <RecordDetailsPage
            right={<AttributesRail inventoryModel={inventoryModel!} />}
          >
            <Header inventoryModel={inventoryModel!} />

            <VStack spacing={12} align="stretch">
              {showDropzone ? (
                <Stack>
                  {['model_documentation', 'validation_report'].includes(
                    documentType,
                  ) && (
                    <>
                      <Heading as={'h4'}>
                        Upload{' '}
                        {documentType === 'model_documentation'
                          ? `Model Documentation`
                          : `Validation Report`}
                      </Heading>

                      <Text>
                        Add the existing{' '}
                        {documentType === 'model_documentation'
                          ? `model documentation`
                          : `validation report`}{' '}
                        here.
                      </Text>
                      <Box
                        {...getRootProps()}
                        border="2px dashed"
                        borderColor={useColorModeValue(
                          'neutral.200',
                          'neutral.600',
                        )}
                        borderRadius="md"
                        pt={12}
                        pb={16}
                        textAlign="center"
                        cursor="pointer"
                        bgColor={useColorModeValue('neutral.50', 'neutral.850')}
                        _hover={{
                          bgColor: useColorModeValue(
                            'neutral.100',
                            'neutral.800',
                          ),
                          borderColor: useColorModeValue(
                            'neutral.300',
                            'neutral.500',
                          ),
                        }}
                        transition={'all 0.3s ease-in-out'}
                      >
                        <Center>
                          <Stack alignItems={'center'}>
                            <input {...getInputProps()} />
                            <Icon
                              as={DocumentArrowUpIcon}
                              boxSize={16}
                              strokeWidth={1}
                              color={'neutral.400'}
                            />
                            <Text>
                              Drag & drop your <strong>.pdf</strong> file here{' '}
                              <br />
                              or{' '}
                              <Button variant="link" colorScheme="brand">
                                choose your file.
                              </Button>
                            </Text>
                          </Stack>
                        </Center>
                      </Box>
                      <Box position="relative" p={8}>
                        <Divider />
                        <AbsoluteCenter
                          bg={useColorModeValue('white', 'neutral.900')}
                          px={8}
                        >
                          <Text>Or</Text>
                        </AbsoluteCenter>
                      </Box>
                    </>
                  )}

                  <Box>
                    <VStack alignItems={'flex-start'}>
                      <Heading as={'h4'}>
                        {documentType === 'monitoring'
                          ? 'Select an Ongoing Monitoring Template'
                          : 'Select a ValidMind Template'}
                      </Heading>
                      <Text>
                        {documentType === 'monitoring'
                          ? 'Start by selecting a template to use to document your ongoing monitoring results.'
                          : "By using one of ValidMind's Templates you will be able to use all of the platform's features."}
                      </Text>
                      <Label mt={4}>{`TEMPLATE`}</Label>
                      <HStack w={'full'}>
                        <TemplatesSelect
                          templates={templates.filter(
                            t => t.type === documentType,
                          )}
                          templateCuid={templateCuid}
                          setTemplateCuid={setTemplateCuid}
                        />
                        <Button
                          isDisabled={
                            templateCuid === '' ||
                            setTemplate.isLoading ||
                            inventoryModel!.stage !==
                              InventoryModelStages.ACTIVE
                          }
                          alignSelf={'flex-end'}
                          onClick={() => setTemplate.mutate(templateCuid)}
                          variant={'primary'}
                        >
                          {setTemplate.isLoading
                            ? 'Setting Template...'
                            : 'Use Template'}
                        </Button>
                      </HStack>
                    </VStack>
                  </Box>
                </Stack>
              ) : (
                <>
                  {documentFiles.map(item => (
                    <PdfViewer key={item.url} fileUrl={item.url} />
                  ))}
                </>
              )}
            </VStack>
          </RecordDetailsPage>
        </Stack>
      </Flex>

      <Modal isCentered onClose={onClose} isOpen={isOpen} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>File Upload</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              {files.map(file => (
                <HStack
                  key={file.name}
                  bgColor={useColorModeValue('white', 'neutral.800')}
                  p={4}
                  rounded={'md'}
                  alignItems={'flex-start'}
                >
                  <Icon
                    as={DocumentArrowUpIcon}
                    strokeWidth={1}
                    boxSize={8}
                    color={'neutral.400'}
                  />
                  <Box>
                    <Text>
                      {file.name} ({Math.round(file.size / 1024)}KB)
                    </Text>
                    <Text fontSize="sm">Ready to upload</Text>
                  </Box>
                </HStack>
              ))}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex width="100%">
              <Button
                variant="ghost"
                onClick={() => {
                  setFiles([]);
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Spacer />
              <Button
                onClick={onUpload}
                isLoading={uploadDocumentFiles.isLoading}
              >
                {uploadDocumentFiles.isLoading ? 'Uploading...' : 'Upload File'}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </LoadingContainer>
  );
}
