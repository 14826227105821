import { useContext, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  FormControl,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import API from '../../../api/API';
import { TUseCase } from '../../../models/use_case';
import UsersContext from '../../../contexts/UsersContext';
import { Label } from '../../../components/Layout';

interface AddUseCaseModalProps {
  isOpen: boolean;
  onSuccess: (data: TUseCase) => void;
  onClose: () => void;
}

export default function AddUseCaseModal({
  isOpen,
  onClose,
  onSuccess,
}: AddUseCaseModalProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { currentOrganization } = useContext(UsersContext);
  const queryClient = useQueryClient();
  const toast = useToast();
  const [useCaseTitle, setUseCaseTitle] = useState<string>('');
  const [useCaseCategory, setUseCaseCategory] = useState<string>('');

  const { data: useCaseCategories = [] } = useQuery(
    ['use-case-categories'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      const response = (
        await API.GetOrganizationUseCaseCategories(
          accessToken,
          currentOrganization,
        )
      ).results;
      return response;
    },
  );

  const addUseCaseMutation = useMutation(
    [],
    async ({
      name,
      category_cuid,
    }: {
      name: string;
      category_cuid: string;
    }) => {
      const accessToken = await getAccessTokenSilently();
      return API.PostOrganizationUseCase(accessToken, currentOrganization, {
        name,
        category_cuid,
      });
    },
    {
      onSuccess: data => {
        onSuccess(data);
        void queryClient.invalidateQueries('use-cases');
        setUseCaseTitle('');
        setUseCaseCategory('');
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error creating use case',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  const onAddUseCase = () => {
    addUseCaseMutation.mutate({
      name: useCaseTitle,
      category_cuid: useCaseCategory,
    });
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent data-testid="add-new-use-case-modal">
        <ModalBody>
          <ModalHeader pl={0}>Add New Use Case</ModalHeader>
          <ModalCloseButton />
          <Stack spacing={4}>
            <FormControl data-testid="form-field-use-case-name">
              <Label mb={2}>Title</Label>
              <Input
                type="text"
                placeholder="Use case title"
                value={useCaseTitle}
                onChange={e => setUseCaseTitle(e.target.value)}
              />
            </FormControl>
            <FormControl data-testid="form-field-use-case-category">
              <Label mb={2}>Category</Label>
              <Select
                value={useCaseCategory}
                onChange={e => setUseCaseCategory(e.target.value)}
                placeholder="Please select"
              >
                {useCaseCategories.map(category => (
                  <option value={category.cuid}>{category.name}</option>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            leftIcon={<Icon as={PlusIcon} boxSize={5} />}
            isDisabled={!(useCaseTitle && useCaseCategory)}
            onClick={() => onAddUseCase()}
            variant="primary"
          >
            Add Use Case
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
