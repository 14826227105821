// https://github.com/chakra-ui/chakra-ui/issues/634

import { extendTheme } from '@chakra-ui/react';
import components from './base/components';
import colors from './base/colors';
import fonts from './base/fonts';
import styles from './base/styles';

export const theme = extendTheme({
  useSystemColorMode: false,
  initialColorMode: 'dark',
  colors,
  fonts,
  styles,
  components,
});
