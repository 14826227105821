import { Box, useColorModeValue } from '@chakra-ui/react';

type Props = {};

export default function NullFieldDisplay({}: Props) {
  return (
    <Box
      h={10}
      py={2}
      px={4}
      w={'full'}
      rounded={'md'}
      border={'1px dashed'}
      bg={useColorModeValue('white', 'brandSecondary.1000')}
      borderColor={useColorModeValue('neutral.300', 'neutral.700')}
    />
  );
}
