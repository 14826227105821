import { Box, Heading, Text, VStack, Icon } from '@chakra-ui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

function UnauthorizedAccess() {
  return (
    <Box p={8} textAlign="center">
      <VStack spacing={4}>
        <Icon as={ExclamationTriangleIcon} w={12} h={12} color="brand.base" />
        <Heading size="lg">Unauthorized Access</Heading>
        <Text color="gray.600">
          You don't have permission to access this resource.
        </Text>
      </VStack>
    </Box>
  );
}

export default UnauthorizedAccess;
