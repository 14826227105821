import { useContext, useEffect, useState } from 'react';
import { Box, Button, Collapse, Icon, useDisclosure } from '@chakra-ui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import GroupView from '../../../components/GroupView';
import UserRoleTable from '../../../components/UserRoleTable';
import { TGroup, TUser } from '../../../models';
import GroupMemberEditor from './GroupMemberEditor';
import GroupModelTable from './GroupModelTable';
import { UsersContext } from '../../../contexts';

export interface TGroupWithMembers extends TGroup {
  members: TUser[];
}

export default function Group({ group }: { group: TGroupWithMembers }) {
  const { isOpen, onToggle } = useDisclosure();
  const [membersToRemove, setMembersToRemove] = useState<string[]>([]);
  const { members, cuid } = group;
  const { userHasPermission } = useContext(UsersContext);

  const canUpdateGroup = userHasPermission(['update_group'], 'all');

  useEffect(() => {
    setMembersToRemove([]);
  }, [members]);

  const handleUserSelect = (selectedUsers: TUser[]): TUser[] => {
    setMembersToRemove(selectedUsers.map(({ cuid }) => cuid));
    return selectedUsers;
  };

  return (
    <Box p={4} rounded={8} borderWidth={1} data-testid="group-container">
      <GroupView
        group={group}
        menu={
          <Button
            rightIcon={
              isOpen ? undefined : <Icon as={ChevronRightIcon} boxSize={4} />
            }
            onClick={onToggle}
            data-testid="group-details-toggle"
          >
            {isOpen ? <Icon as={ChevronUpIcon} boxSize={4} /> : 'See Details'}
          </Button>
        }
      />
      <Collapse in={isOpen}>
        <Box mx={1}>
          <UserRoleTable
            users={members}
            enableRoleManagement={false}
            onUserSelect={canUpdateGroup ? handleUserSelect : undefined}
          />
          {canUpdateGroup && (
            <GroupMemberEditor
              group={group}
              membersToRemove={membersToRemove}
            />
          )}
          <GroupModelTable groupCuid={cuid} />
        </Box>
      </Collapse>
    </Box>
  );
}
