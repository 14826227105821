import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import API from '../../api/API';
import { ModelDocumentTypeEnum } from '../../models/model_document';

export default function useTestResult(
  test_name: string,
  content_type?: ModelDocumentTypeEnum,
  enabled: boolean = true,
  fields: string[] = ['all'],
) {
  // Always scope the test_result to a inventory model identifier
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const queryResponse = useQuery(
    ['test_results', test_name, id, fields],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetTestResultForInventoryModel(
        id!,
        accessToken,
        test_name,
        content_type,
        fields,
      );
    },
    {
      enabled,
      retry: false,
    },
  );

  return { testResult: queryResponse.data, ...queryResponse };
}
