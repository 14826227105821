import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import { TInventoryModel } from '../../../models/inventory_model';
import CoreField from '../../CustomFields/CoreField';
import { ContentPageTitle } from '..';

interface IInventoryModelStatusBarProps {
  item?: TInventoryModel;
  title?: string;
  isMain?: boolean;
  url?: string;
  readOnly?: boolean;
  moreInfoPopOver?: JSX.Element;
}

export default function MasterStatusBar({
  item,
  title,
  isMain = false,
  url,
  readOnly = false,
  moreInfoPopOver,
}: IInventoryModelStatusBarProps) {
  return (
    <VStack align={'stretch'} gap={1}>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={`/model-inventory`}>
            Model Inventory
          </BreadcrumbLink>
          {isMain && <BreadcrumbSeparator />}
        </BreadcrumbItem>
        {item && !isMain && (
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={url || `/model-inventory/${item?.cuid}/overview`}
            >
              {item?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
      <HStack>
        {isMain && item ? (
          <CoreField
            inventoryModel={item as TInventoryModel}
            propertyKey="name"
            readOnly={readOnly}
            hideLabel={true}
            displayComponent={field => (
              <ContentPageTitle data-testid="MasterStatusBarText">
                {field.value}
              </ContentPageTitle>
            )}
          />
        ) : (
          <Text
            fontSize={'3xl'}
            fontWeight="semibold"
            data-testid="MasterStatusBarText"
          >
            {title}
          </Text>
        )}
        {moreInfoPopOver && moreInfoPopOver}
      </HStack>
    </VStack>
  );
}
