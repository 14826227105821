import { Box, Heading, Textarea } from '@chakra-ui/react';

const StyleGuideTextArea = () => {
  return (
    <Box>
      <Heading as={'h2'}>TextArea</Heading>
      <Textarea placeholder="Here is a sample placeholder" />
    </Box>
  );
};

export default StyleGuideTextArea;
