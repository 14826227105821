import {
  Box,
  Heading,
  Alert,
  AlertIcon,
  Stack,
  VStack,
} from '@chakra-ui/react';

const StyleGuideAlert = () => {
  const variants = [
    // 'subtle', 'solid',
    'left-accent',
    // 'top-accent'
  ];
  const types = ['error', 'success', 'warning', 'info', 'loading'];
  return (
    <Box>
      <Heading as={'h2'}>Alert</Heading>

      <Stack spacing={3}>
        {types.reverse().map(type => (
          <VStack key={type}>
            {variants.map(variant => (
              <Alert
                key={`${type}-${variant}`}
                variant={variant}
                status={
                  type as 'error' | 'success' | 'warning' | 'info' | 'loading'
                }
              >
                <AlertIcon />
                {variant} {type}
              </Alert>
            ))}
          </VStack>
        ))}
      </Stack>
    </Box>
  );
};

export default StyleGuideAlert;
