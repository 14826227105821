const styles = {
  global: {
    'html, body': {
      bg: 'white',
      fontSize: '14px',
      _dark: {
        bg: 'neutral.950',
      },
    },
    h1: {
      fontSize: '3xl !important',
      fontWeight: 'semibold !important',
      lineHeight: '1.2',
      letterSpacing: '-0.04rem',
    },
    h2: {
      fontSize: '2xl !important',
      fontWeight: 'semibold !important',
      lineHeight: '1.2',
      letterSpacing: '-0.02rem',
    },
    h3: {
      fontSize: 'xl !important',
      fontWeight: 'semibold !important',
      lineHeight: '1.2',
      letterSpacing: '-0.01rem',
    },
    h4: {
      fontSize: 'lg !important',
      fontWeight: 'semibold !important',
      lineHeight: '1.2',
    },
    h5: {
      fontSize: 'md !important',
      fontWeight: 'bold !important',
      lineHeight: '1.2',
    },
    h6: {
      fontSize: 'sm !important',
      fontWeight: 'bold',
      lineHeight: '1.2',
    },
  },
  // Optionally customize the sizes for text (e.g., sm, md, lg)
  // fontSizes: {
  //   xs: '12px',
  //   sm: '140px',
  //   md: '16px', // Set your preferred default size here
  //   lg: '18px',
  //   xl: '20px',
  //   '2xl': '24px',
  //   '3xl': '30px',
  //   '4xl': '360px',
  //   '5xl': '48px',
  //   '6xl': '64px',
  // },
};

export default styles;
