import { Flex } from '@chakra-ui/react';
import { ContentInventoryModelNameHeading } from '../Layout';
import { useLocation } from 'react-router-dom';
import { TInventoryModel } from '../../models/inventory_model';

interface IInventoryModelStatusBarProps {
  inventoryModel?: TInventoryModel;
}

export default function InventoryModelStatusBar({
  inventoryModel,
}: IInventoryModelStatusBarProps) {
  const currentPath = useLocation();
  const isValidationReportPath = /\w+\/validation-report/.test(
    currentPath.pathname,
  );
  const isMonitoringPath = /\w+\/monitoring/.test(currentPath.pathname);

  const documentType = isValidationReportPath
    ? 'Validation Report'
    : isMonitoringPath
    ? 'Ongoing Monitoring'
    : 'Model Documentation';

  const title = `${inventoryModel?.name} - ${documentType}`;

  return (
    <Flex direction="column" gap={2}>
      <Flex justify="start" data-testid="heading-title">
        <ContentInventoryModelNameHeading data-testid="model-documentation-title">
          {title}
        </ContentInventoryModelNameHeading>
      </Flex>
    </Flex>
  );
}
