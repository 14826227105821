import {
  Box,
  HStack,
  VStack,
  Text,
  Icon,
  Checkbox,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  ArrowPathRoundedSquareIcon,
  BuildingOffice2Icon,
  ClipboardDocumentListIcon,
  CubeIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import RoleManager from '../RoleManager';
import { TRole } from '../../models';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../../api/API';
import { TPermissionAction } from '../../models/role';

type Modes = 'view' | 'edit';

type PermissionChanged = {
  category: string;
  index: number;
  value: boolean;
};
type PermissionChangedEvent = (changes: PermissionChanged[]) => void;

type Category = {
  key: string;
  actions: {
    action_id: TPermissionAction;
    is_visible: boolean;
    assigned?: boolean;
    name?: string;
    description?: string;
    roles?: string[];
    scope?: string;
  }[];
};

type PermissionsListProps = {
  mode: Modes;
  permissionsScope?: string;
  categories?: {
    [key: string]: Category['actions'];
  };
  onPermissionChange?: PermissionChangedEvent;
  enableRoleManagement?: boolean;
  rolesChanged?: () => Promise<any>;
};

const categoriesMap = {
  Organization: { icon: BuildingOffice2Icon },
  Role: { icon: ShieldCheckIcon },
  Group: { icon: UserGroupIcon },
  User: { icon: UserIcon },
  Model: { icon: CubeIcon },
  Template: { icon: ClipboardDocumentListIcon },
  Workflow: { icon: ArrowPathRoundedSquareIcon },
  UNKNOWN: { icon: CubeIcon },
} as {
  [key: string]: {
    icon: any;
  };
};

function formatString(str: string): string {
  // replace underscores with a space and capitalize the first letter of the next word
  str = str.replace(/_(\w)/g, function (_, letter) {
    return ' ' + letter.toUpperCase();
  });

  // make sure first character is uppercase
  str = str.charAt(0).toUpperCase() + str.slice(1);

  // add a space before uppercase letters that are not at the start
  str = str.replace(/([A-Z])/g, ` $1`);

  // If any word is two characters or less, then make it all uppercase
  str = str.replace(/\b\w{1,2}\b/g, function (shortWord) {
    return shortWord.toUpperCase();
  });

  return str.trim();
}

const RenderAction = (
  categoryKey: string,
  action: Category['actions'][0],
  mode: Modes,
  onChange: (value: boolean) => void,
  enableRoleManagement?: boolean,
  permissionsScope?: string,
  rolesChanged?: () => Promise<any>,
) => {
  const { getAccessTokenSilently } = useAuth0();

  const onRoleChange = async (
    role: TRole,
    action: 'add' | 'delete',
    action_id: string,
  ) => {
    const accessToken = await getAccessTokenSilently();
    await API.PatchRolePermissions(accessToken, role.cuid, {
      added_permissions: action === 'add' ? [action_id] : undefined,
      deleted_permissions: action === 'delete' ? [action_id] : undefined,
    });
    await rolesChanged?.();
  };

  return (
    <HStack
      gap={4}
      p={4}
      borderBottom="1px solid"
      borderColor={'var(--chakra-colors-chakra-border-color)'}
      w="full"
    >
      {mode === 'edit' && (
        <Checkbox
          isChecked={action.assigned}
          onChange={e => onChange(e.target.checked)}
        />
      )}
      <VStack gap={4} alignItems="flex-start">
        <VStack gap={0} alignItems="flex-start">
          <Heading as="h5">{formatString(action.action_id || '')}</Heading>
          <Text>{action.description}</Text>
        </VStack>
        {enableRoleManagement && (
          <RoleManager
            selectedRoles={action.roles || []}
            roleScope={permissionsScope}
            onRoleChange={(role, method) =>
              onRoleChange(role, method, action.action_id)
            }
          />
        )}
      </VStack>
    </HStack>
  );
};

const RenderCategory = (
  category: Category,
  mode: Modes,
  onPermissionChange?: PermissionChangedEvent,
  enableRoleManagement?: boolean,
  permissionsScope?: string,
  rolesChanged?: () => Promise<any>,
) => {
  const mappedCategory = categoriesMap[category.key] || categoriesMap.UNKNOWN;
  const isChecked = category.actions.every(action => action.assigned);
  const isIndeterminate =
    !isChecked && category.actions.some(action => action.assigned);

  return (
    <VStack alignItems="flex-start" w="full" spacing={0}>
      <HStack
        spacing={4}
        alignItems="center"
        bg={useColorModeValue('neutral.50', 'neutral.850')}
        borderBottom="1px solid"
        borderColor={'var(--chakra-colors-chakra-border-color)'}
        w="full"
        paddingX={4}
        paddingY={3}
      >
        {mode === 'edit' && (
          <Checkbox
            isChecked={isChecked}
            isIndeterminate={isIndeterminate}
            onChange={e => {
              onPermissionChange?.(
                category.actions.map((_, idx) => ({
                  category: category.key,
                  index: idx,
                  value: e.target.checked,
                })),
              );
            }}
          />
        )}
        <HStack gap={2}>
          <Icon boxSize={6} as={mappedCategory.icon} />
          <Heading as="h4">{formatString(category.key)}</Heading>
        </HStack>
      </HStack>
      <VStack alignItems="flex-start" w="full" spacing={0}>
        {category.actions.map((action, idx) => {
          return RenderAction(
            category.key,
            action,
            mode,
            value => {
              onPermissionChange?.([
                {
                  category: category.key,
                  index: idx,
                  value,
                },
              ]);
            },
            enableRoleManagement,
            permissionsScope,
            rolesChanged,
          );
        })}
      </VStack>
    </VStack>
  );
};

/**
 * Normalizes the input string by ensuring that `null`, `undefined`,
 * or an empty string are all returned as an empty string ('').
 *
 * @param {string | undefined} str - The input string that may be undefined or empty.
 * @returns {string} - Returns the input string if it has a value, otherwise returns an empty string ('').
 */
function normalizeString(str?: string): string {
  return str ?? '';
}

export default function PermissionsList({
  mode,
  categories,
  permissionsScope,
  onPermissionChange,
  enableRoleManagement,
  rolesChanged,
}: PermissionsListProps) {
  if (!categories) return null;

  return (
    <Box
      w="full"
      borderRadius={8}
      overflow={'hidden'}
      borderTop="1px solid "
      borderX="1px solid "
      borderColor={'var(--chakra-colors-chakra-border-color)'}
    >
      {Object.keys(categories).map(categoryKey => {
        const category = categories[categoryKey];
        if (category.length === 0) {
          return;
        }

        return (
          <Box key={categoryKey}>
            {RenderCategory(
              {
                key: categoryKey,
                actions: category,
              },
              mode,
              onPermissionChange,
              enableRoleManagement,
              (permissionsScope = normalizeString(permissionsScope)),
              rolesChanged,
            )}
          </Box>
        );
      })}
    </Box>
  );
}
