import { createContext } from 'react';
import { TInventoryModel } from '../models/inventory_model';
import { TemplateSectionTree } from '../models/template';
import { TemplateSection } from '../models/template';

export interface InventoryModelTemplatesItem {
  sections: TemplateSection[];
  sectionTree: TemplateSectionTree[];
  lookup: { [key: string]: TemplateSection };
}

export interface CurrentInventoryModelTemplates {
  documentType: string;
  content: InventoryModelTemplatesItem;
  home?: TemplateSection;
}

export interface InventoryModelTemplates {
  documentation: InventoryModelTemplatesItem;
  validation_report: InventoryModelTemplatesItem;
  monitoring: InventoryModelTemplatesItem;
  readonly current: CurrentInventoryModelTemplates;
  readonly isReady: boolean;
  readonly isOfflineDocumentation: boolean;
  readonly isOfflineValidationReport: boolean;
}

const initialTemplateItem = {
  sections: [],
  sectionTree: [],
  lookup: {},
};

const initialTemplates: InventoryModelTemplates = {
  documentation: { ...initialTemplateItem },
  validation_report: { ...initialTemplateItem },
  monitoring: { ...initialTemplateItem },
  get current() {
    return {
      documentType: 'documentation',
      content: { ...initialTemplateItem },
      home: undefined,
    };
  },
  get isReady() {
    return false;
  },
  get isOfflineDocumentation() {
    return false;
  },
  get isOfflineValidationReport() {
    return false;
  },
};

interface InventoryModelContextType {
  inventoryModel?: TInventoryModel;
  setInventoryModel: (inventoryModel: TInventoryModel) => void;
  userHasInventoryModelPermission: (
    actions: string[],
    match: 'any' | 'all',
  ) => boolean;
  templates: InventoryModelTemplates;
  setTemplates: (templates: InventoryModelTemplates) => void;
}

const InventoryModelContext = createContext<InventoryModelContextType>({
  userHasInventoryModelPermission: (actions: string[], match: 'any' | 'all') =>
    false,
  setInventoryModel: () => {},
  setTemplates: () => {},
  templates: initialTemplates,
});

export default InventoryModelContext;
