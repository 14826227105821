import {
  Box,
  Heading,
  HStack,
  PinInput,
  PinInputField,
  VStack,
} from '@chakra-ui/react';

const StyleGuidePinInput = ({ sizes }: { sizes: string[] }) => {
  return (
    <Box>
      <Heading as={'h2'}>PinInput</Heading>
      <VStack gap={4}>
        {sizes.map(size => (
          <HStack>
            <PinInput size={size}>
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
        ))}
      </VStack>
    </Box>
  );
};

export default StyleGuidePinInput;
