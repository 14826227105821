import { useContext, useEffect, useState } from 'react';
import { Copyright } from '../../../components/Copyright';
import { LoadingContainer } from '../../../components/LoadingContainer';
import { useTemplates } from '../../../hooks/useTemplates';
import { displayRelativeDate } from '../../../utils';

import {
  Box,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  Tag,
  TagLabel,
  Icon,
} from '@chakra-ui/react';
import SidebarContext from '../../../contexts/SidebarContext';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import { RectangleGroupIcon } from '@heroicons/react/24/outline';
import { ContentPageTitle } from '../../../components/Layout';
import AvatarProxy from '../../../components/AvatarProxy';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';

export default function Templates() {
  const { setInSettings } = useContext(SidebarContext);
  const { templates = [], isLoading } = useTemplates({ includeCopies: false });
  const [currentTemplateType, setCurrentTemplateType] = useState(
    'model_documentation',
  );

  const [limit, setLimit] = useState(30);

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  const allTemplates = templates.map(template => [template]).flat();
  const tableColumns = [
    {
      Header: ' ',
      Cell: ({ row }: any) => {
        return (
          <Icon boxSize={6} color={'neutral.500'} as={RectangleGroupIcon} />
        );
      },
    },
    {
      Header: 'Template Name',
      accessor: 'name',
      Cell: ({ row }: any) => {
        return (
          <Text
            data-testid="Templates-view-template"
            fontWeight={'bold'}
            fontSize={'sm'}
          >
            {row.values.name}
          </Text>
        );
      },
    },
    {
      id: 'owner',
      Header: 'Owner',
      accessor: 'versions',
      Cell: ({ value }: any) => {
        const firstVersion = value[value.length - 1].created_by || {};
        if (!firstVersion.name)
          return (
            <Tag key={firstVersion.cuid} size={'md'} colorScheme="pink">
              ValidMind
            </Tag>
          );
        return (
          <Tag key={firstVersion.cuid} size={'md'}>
            <AvatarProxy
              src={firstVersion.picture}
              size="xs"
              name={firstVersion.name}
              ml={-1}
              mr={2}
            />
            <TagLabel>{firstVersion.name}</TagLabel>
          </Tag>
        );
      },
    },

    {
      Header: 'Created',
      accessor: 'created_at',
      Cell: ({ value }: any) => {
        return <Text fontSize={'sm'}>{displayRelativeDate(value)}</Text>;
      },
    },
    {
      id: 'last_updated_by',
      Header: 'Latest Version author',
      accessor: 'versions',
      Cell: ({ value }: any) => {
        const latest = value[0].created_by || {};
        if (!latest.name)
          return (
            <Tag size={'md'} colorScheme="pink">
              ValidMind
            </Tag>
          );

        return (
          <Tag key={latest.cuid} size={'md'}>
            <AvatarProxy
              src={latest.picture}
              size="xs"
              name={latest.name}
              ml={-1}
              mr={2}
            />
            <TagLabel>{latest.name}</TagLabel>
          </Tag>
        );
      },
    },
    {
      Header: 'Last Updated',
      accessor: 'updated_at',
      Cell: ({ value, cell }: any) => {
        const lastVersion = cell.row.original.versions
          ? cell.row.original.versions[0]
          : null;
        return (
          <Text fontSize={'sm'}>
            {displayRelativeDate(
              lastVersion?.created_at || value || cell.row.original.created_at,
            )}
          </Text>
        );
      },
    },
    {
      Header: 'Template Type',
      accessor: 'type',
      Cell: ({ value }: any) => {
        const isValidationReport = value === 'validation_report';
        const isMonitoring = value === 'monitoring';
        return (
          <Tag key={value.cuid} size={'md'}>
            <TagLabel>
              {isValidationReport
                ? 'Validation Report'
                : isMonitoring
                ? 'Ongoing Monitoring'
                : 'Documentation'}
            </TagLabel>
          </Tag>
        );
      },
    },
  ];
  const navigate = useNavigate();
  return (
    <LoadingContainer isLoading={isLoading}>
      <VStack
        alignItems="start"
        spacing={0}
        paddingTop={12}
        mt={1}
        paddingBottom={16}
        px={14}
        gap={8}
        w="full"
        overflow="auto"
        className="no-scrollbar"
        data-testid="groups-settings"
        maxWidth={'7xl'}
        mx={'auto'}
      >
        <Box>
          <Breadcrumbs />
          <ContentPageTitle>
            Templates
            <MoreInfoPopOver
              title="Customize Documentation Templates"
              link="https://docs.validmind.ai/guide/model-documentation/customize-documentation-templates.html"
              placement="right-end"
              iconProps={{
                ml: 2,
                fill: 'brand.base',
              }}
            />
          </ContentPageTitle>
        </Box>
        <Text fontSize="md">
          Templates are a way to configure required validation tests and
          standardize the documentation table of contents for specific model use
          cases.{' '}
        </Text>

        <Stack w={'full'}>
          <Tabs size="md" colorScheme="brand">
            <TabList>
              <Tab
                onClick={() => setCurrentTemplateType('model_documentation')}
              >
                Documentation
              </Tab>
              <Tab onClick={() => setCurrentTemplateType('validation_report')}>
                Validation Report
              </Tab>
              <Tab onClick={() => setCurrentTemplateType('monitoring')}>
                Ongoing Monitoring
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <DataTable
                  data={allTemplates
                    .filter(t => t.type === 'model_documentation')
                    .sort((a, b) => a.name.localeCompare(b.name))}
                  columns={tableColumns}
                  enableSort={true}
                  pageSize={limit}
                  isInteractive={true}
                  onClickRow={(row: any) => {
                    navigate(
                      `/settings/templates/${row.original.cuid}/model_documentation/latest`,
                    );
                  }}
                />
              </TabPanel>
              <TabPanel>
                <DataTable
                  data={allTemplates
                    .filter(t => t.type === 'validation_report')
                    .sort((a, b) => a.name.localeCompare(b.name))}
                  columns={tableColumns}
                  enableSort={true}
                  pageSize={limit}
                  isInteractive={true}
                  onClickRow={(row: any) => {
                    navigate(
                      `/settings/templates/${row.original.cuid}/validation_report/latest`,
                    );
                  }}
                />
              </TabPanel>
              <TabPanel>
                <DataTable
                  data={allTemplates
                    .filter(t => t.type === 'monitoring')
                    .sort((a, b) => a.name.localeCompare(b.name))}
                  columns={tableColumns}
                  enableSort={true}
                  pageSize={limit}
                  isInteractive={true}
                  onClickRow={(row: any) => {
                    navigate(
                      `/settings/templates/${row.original.cuid}/monitoring/latest`,
                    );
                  }}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Copyright />
        </Stack>
      </VStack>
    </LoadingContainer>
  );
}
