import {
  Box,
  Heading,
  Editable,
  EditableInput,
  EditablePreview,
} from '@chakra-ui/react';

const StyleGuideEditable = () => {
  return (
    <Box>
      <Heading as={'h2'}>Editable</Heading>
      <Editable defaultValue="Click to start editing">
        <EditablePreview />
        <EditableInput />
      </Editable>
    </Box>
  );
};

export default StyleGuideEditable;
