import { Route, Routes } from 'react-router-dom';
import AdminApp from './admin/AdminApp';
import MainApp from './MainApp';
import NotFound from './pages/NotFound';
import { CONFIG } from './config';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

function App() {
  // For local development, use all routes and the admin routes are exposed with the same server
  if (CONFIG.APP_ENV === 'development') {
    return (
      <Routes>
        <Route path="/admin/*" element={<AdminApp />} />
        <Route path="/*" element={<MainApp />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  // For production, use BUILD_TARGET to determine which routes to include in the build
  if (process.env.REACT_APP_BUILD_TARGET === 'admin') {
    return (
      <Routes>
        <Route path="/*" element={<AdminApp />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  // Default to main app routes
  return (
    <Routes>
      <Route path="/*" element={<MainApp />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function withConditionalLDProvider(Component: React.ComponentType) {
  if (CONFIG.USE_LAUNCHDARKLY) {
    const LDApp = withLDProvider({
      clientSideID: CONFIG.REACT_APP_LD_CLIENT_ID,
      options: {
        bootstrap: 'localStorage',
      },
    })(Component);

    return function (props: React.ComponentProps<typeof Component>) {
      return <LDApp {...props} />;
    };
  }

  return function (props: React.ComponentProps<typeof Component>) {
    return <Component {...props} />;
  };
}

const VMApp = withConditionalLDProvider(App);
export default VMApp;
