import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery } from 'react-query';
import API from '../../api/API';
import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
  Spacer,
  Spinner,
  Text,
  useColorModeValue,
  Wrap,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { TRole } from '../../models/role';
import RolePill from '../RolePill';
import { EmptyStateDisplay } from '../EmptyStateDisplay';
import { PuzzlePieceIcon } from '@heroicons/react/24/outline';
import { TUser } from '../../models/user';
import { Label } from '../Layout';

interface AddUserRoleModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRoleAdded?: () => void;
  users: TUser[];
}

export default function AddUserRoleModal({
  isOpen,
  onClose,
  onRoleAdded,
  users,
}: AddUserRoleModalProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [availableRoles, setAvailableRoles] = useState<TRole[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<TRole[]>([]);

  const removeExistingRolesForSingleUser = (roles: TRole[] = []): TRole[] => {
    if (users.length === 1) {
      const existingRoles = users[0].roles.map(userRole => userRole.role);
      return roles.filter(
        role =>
          !existingRoles.find(existingRole => role.cuid === existingRole.cuid),
      );
    }
    return roles;
  };

  const excludeModelScopeRoles = (role: TRole) => {
    return role.scope !== 'Model';
  };

  const { data: orgRoles, isFetching } = useQuery(
    [isOpen, users],
    async () => {
      setSelectedRoles([]);
      const accessToken = await getAccessTokenSilently();
      const roles = await API.GetOrganizationRoles(accessToken);
      return roles;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: roles => {
        setAvailableRoles(
          removeExistingRolesForSingleUser(roles).filter(
            excludeModelScopeRoles,
          ),
        );
      },
    },
  );

  const createUserRoleMutation = useMutation(
    [],
    async ({ userId, roleId }: { userId: string; roleId: string }) => {
      const accessToken = await getAccessTokenSilently();
      return API.CreateUserRole(accessToken, userId, roleId);
    },
    {
      onSuccess: () => {
        onRoleAdded?.();
      },
    },
  );

  const handleFilterRolesBySearchText = (event: {
    target: { value: string };
  }) => {
    const searchTerm = event.target.value.toLowerCase();
    const searchedRoles = orgRoles?.filter(role => {
      const isNotSelected = !selectedRoles.some(
        selectedRole => selectedRole.cuid === role.cuid,
      );
      return isNotSelected && role.name.toLowerCase().includes(searchTerm);
    });
    setAvailableRoles(removeExistingRolesForSingleUser(searchedRoles));
  };

  const handleAddRole = (role: TRole) => {
    if (
      !selectedRoles.some(selectedRoles => selectedRoles.cuid === role.cuid)
    ) {
      setSelectedRoles([...selectedRoles, role]);
      setAvailableRoles(
        availableRoles.filter(selectedRole => selectedRole.cuid !== role.cuid),
      );
    }
  };

  const handleRemoveRole = (role: TRole) => {
    const updatedRoles = selectedRoles.filter(
      selectedRole => selectedRole.cuid !== role.cuid,
    );
    setSelectedRoles(updatedRoles);
    setAvailableRoles([...availableRoles, role]);
  };

  const assignRoles = () => {
    users.forEach(user =>
      selectedRoles.forEach(role => {
        if (
          !user.roles.some(existingRole => existingRole.role.cuid === role.cuid)
        ) {
          createUserRoleMutation.mutate({
            userId: user.cuid,
            roleId: role.cuid,
          });
        }
      }),
    );
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      size="6xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent p={3} data-testid="add-user-roles-modal">
        <ModalHeader>Assign Roles</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box pb={6}>
            <Text>
              Please choose a role below to assign for selected users. Users
              that already have the chosen role will not be affected.
            </Text>
          </Box>
          <Flex
            direction="row"
            w="full"
            gap={4}
            alignSelf="stretch"
            alignItems="flex-start"
          >
            <Flex
              direction="column"
              gap={4}
              p={4}
              pb={6}
              width="50%"
              borderRadius="lg"
              borderWidth={1}
              borderColor={useColorModeValue('neutral.200', 'neutral.800')}
              backgroundColor={useColorModeValue('neutral.50', 'neutral.900')}
            >
              <FormControl>
                <Label>ALL ROLES</Label>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="neutral.400" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search role"
                    onChange={handleFilterRolesBySearchText}
                  />
                </InputGroup>
              </FormControl>
              <HStack w="full" flexWrap={'wrap'}>
                {isFetching ? (
                  <Spinner size="sm" />
                ) : (
                  availableRoles.map(role => (
                    <ScaleFade in={true} initialScale={0.5}>
                      <RolePill
                        key={role.cuid}
                        role={role}
                        onAdd={() => handleAddRole(role)}
                      />
                    </ScaleFade>
                  ))
                )}
              </HStack>
            </Flex>
            <Flex
              direction="column"
              p={4}
              gap={4}
              width="50%"
              alignSelf="stretch"
              borderRadius="lg"
              borderWidth={1}
              borderColor={useColorModeValue('neutral.200', 'neutral.800')}
              backgroundColor={useColorModeValue('neutral.50', 'neutral.900')}
              minHeight="xs"
              data-testid="to-be-assigned-container"
            >
              <Text color="inherit" fontSize="xs" fontWeight="semibold">
                TO BE ASSIGNED
              </Text>
              {!selectedRoles.length && (
                <EmptyStateDisplay variant="no-findings">
                  <Heading as="h5">No roles assigned</Heading>
                  <Text align="center" pb={5}>
                    There are no roles selected yet. Please add a <br />
                    role from the left to assign to users.
                  </Text>
                </EmptyStateDisplay>
              )}
              <Wrap>
                {selectedRoles.map(role => (
                  <ScaleFade in={true} initialScale={0.5}>
                    <RolePill
                      key={role.cuid}
                      role={role}
                      onClose={() => handleRemoveRole(role)}
                    />
                  </ScaleFade>
                ))}
              </Wrap>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Spacer />
          <Button
            onClick={assignRoles}
            isDisabled={selectedRoles.length === 0}
            leftIcon={<Icon as={PuzzlePieceIcon} boxSize={5} />}
            data-testid={'assign-user-roles-button'}
            variant={'primary'}
          >
            Assign Roles to{' '}
            {users.length > 1 ? `${users.length} Users` : 'User'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
