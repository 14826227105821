import {
  Box,
  Heading,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  VStack,
} from '@chakra-ui/react';

const StyleGuideNumberInput = ({ sizes }: { sizes: string[] }) => {
  return (
    <Box>
      <Heading as={'h2'}>NumberInput</Heading>
      <VStack gap={4}>
        {sizes.map(size => (
          <NumberInput size={size} defaultValue={15} min={10} maxW={24}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        ))}
      </VStack>
    </Box>
  );
};

export default StyleGuideNumberInput;
