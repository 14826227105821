import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

interface ContentRowDetailsModalProps {
  content?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

function ContentRowDetailsModal({
  isOpen,
  onClose,
  content,
}: ContentRowDetailsModalProps) {
  return (
    <>
      <Modal size={'6xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>{content}</ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant={'primary'}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ContentRowDetailsModal;
