import {
  ChatBubbleLeftEllipsisIcon,
  CheckIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { useContext, useMemo } from 'react';
import RecordDetailsPage from '../../../components/Layout/RecordDetailsPage';
import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Stack,
  Tag,
  TagLeftIcon,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import ActivityFeedWidget from '../../../components/ActivityFeedWidget';
import { EventFilters } from '../../../models/event';
import { Copyright } from '../../../components/Copyright';
import { TRole } from '../../../models';
import { convertToSectionTree } from '../../../models/template';
import { LoadingContainer } from '../../../components/LoadingContainer';
import { useQuery } from 'react-query';
import API, { TInventoryModelAnnotation } from '../../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import WorkflowApprovalActions from '../../../components/WorkflowApprovalActions';
import DocumentOutline from '../../../components/DocumentOutline';
import TemplateModal from '../../../components/TemplateModal';
import AvatarProxy from '../../../components/AvatarProxy';
import AttributesRailDocumentOverview from '../../../components/AttributesRailDocumentOverview';
import DocumentationHeader from '../../../components/DocumentationHeader';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import InventoryModelContext from '../../../contexts/InventoryModel';

interface IInventoryModelOverviewProps { }

// TODO - refactor user attributes on API file
interface IInventoryModelUser {
  email: string;
  name: string;
  picture: string;
  role?: TRole;
}

export const StakeholderItem = ({ user }: { user?: IInventoryModelUser }) => {
  return (
    <HStack>
      {user ? (
        <AvatarProxy name={user.name} src={user.picture} size={'sm'} />
      ) : (
        <Icon as={ExclamationCircleIcon} boxSize={5} color={'neutral.500'} />
      )}

      <VStack align={'start'} spacing={0}>
        <Text>
          {user ? (
            <Text>{user.name}</Text>
          ) : (
            <Text color={'neutral.500'}>Unassigned</Text>
          )}
        </Text>
        <Text fontSize="sm" margin={'0px'}>
          {user?.role?.name}
        </Text>
      </VStack>
    </HStack>
  );
};

export default function ModelDocumentationOverview({ }: IInventoryModelOverviewProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { inventoryModel, templates } = useContext(InventoryModelContext);

  const {
    isOpen: isOpenTemplateModal,
    onOpen: onOpenTemplateModal,
    onClose: onCloseTemplateModal,
  } = useDisclosure();

  const eventFilters: EventFilters = {
    inventory_models: [inventoryModel?.cuid!],
  };

  const tree = convertToSectionTree(templates.documentation.sections);

  const {
    data: documentationOutline = {},
    refetch: refetchDocumentationOverview,
  } = useQuery(
    ['documentation-overview'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetDocumentationSummary(
        accessToken,
        inventoryModel!.cuid,
      );
    },
    {
      enabled: !!inventoryModel,
    },
  );

  const unresolvedConversationsCount = useMemo(() => {
    let count = 0;
    Object.values(documentationOutline).forEach(section => {
      count += section.unresolved_threads.length;
    });
    return count;
  }, [documentationOutline]);

  const { completedSectionCount, totalSectionCount } = useMemo(() => {
    if (!documentationOutline)
      return { completedSectionCount: 0, totalSectionCount: 0 };

    let completed = 0;
    let total = 0;

    Object.values(documentationOutline).forEach(section => {
      if (section.status === 'done') completed++;
      total++;
    });
    return {
      completedSectionCount: completed,
      totalSectionCount: total,
    };
  }, [documentationOutline]);

  const { highFindingsCount, mediumFindingsCount, lowFindingsCount } =
    useMemo(() => {
      let counts = {
        highFindingsCount: 0,
        mediumFindingsCount: 0,
        lowFindingsCount: 0,
      };
      Object.values(documentationOutline).forEach(section => {
        section.findings.forEach(finding => {
          if (finding.severity.level === 1) counts.highFindingsCount++;
          if (finding.severity.level === 2) counts.mediumFindingsCount++;
          if (finding.severity.level === 3) counts.lowFindingsCount++;
        });
      });
      return counts;
    }, [documentationOutline]);

  return (
    <>
      <LoadingContainer isLoading={false}>
        <Flex minHeight="100vh" w={'full'}>
          <Stack
            px={8}
            w={'full'}
            alignItems={'center'}
            overflow={'auto'}
            className="no-scrollbar"
          >
            <RecordDetailsPage
              right={
                <AttributesRailDocumentOverview
                  inventoryModel={inventoryModel!}
                  onOpenTemplateModal={onOpenTemplateModal}
                  isDocumentComplete={
                    completedSectionCount === totalSectionCount
                  }
                />
              }
            >
              <DocumentationHeader inventoryModel={inventoryModel!} />

              <VStack spacing={12} align="stretch">
                {inventoryModel && (
                  <WorkflowApprovalActions
                    targetType={'InventoryModel'}
                    targetCuid={inventoryModel.cuid}
                    voterStatus={'pending'}
                  />
                )}
                <HStack justifyContent={'flex-end'} w={'full'}>
                  {unresolvedConversationsCount > 0 && (
                    <Tag size={'md'} rounded={'full'}>
                      <TagLeftIcon
                        boxSize={4}
                        as={ChatBubbleLeftEllipsisIcon}
                      />
                      {unresolvedConversationsCount} Unresolved conversations
                    </Tag>
                  )}
                  {(highFindingsCount ||
                    mediumFindingsCount ||
                    lowFindingsCount) && (
                      <Tag
                        size={'md'}
                        colorScheme="light"
                        // bg={useColorModeValue('white', 'neutral.900')}
                        border={'1px solid'}
                        borderColor={useColorModeValue(
                          'neutral.200',
                          'neutral.800',
                        )}
                        rounded={'full'}
                        pl={0}
                      >
                        <HStack mr={2}>
                          {highFindingsCount && (
                            <Tag size={'sm'} colorScheme="red" rounded={'full'}>
                              <TagLeftIcon
                                boxSize={4}
                                as={ExclamationTriangleIcon}
                              />
                              {highFindingsCount}
                            </Tag>
                          )}
                          {mediumFindingsCount && (
                            <Tag
                              size={'sm'}
                              colorScheme="yellow"
                              rounded={'full'}
                            >
                              <TagLeftIcon
                                boxSize={4}
                                as={ExclamationTriangleIcon}
                              />
                              {mediumFindingsCount}
                            </Tag>
                          )}
                          {lowFindingsCount && (
                            <Tag size={'sm'} rounded={'full'}>
                              <TagLeftIcon
                                boxSize={4}
                                as={ExclamationTriangleIcon}
                              />
                              {lowFindingsCount}
                            </Tag>
                          )}
                        </HStack>
                        Model Findings
                      </Tag>
                    )}
                  <Tag size={'md'} colorScheme="green" rounded={'full'}>
                    <TagLeftIcon boxSize={4} as={CheckIcon} />
                    {completedSectionCount}/{totalSectionCount} Done
                  </Tag>
                  <MoreInfoPopOver
                    title="Document Overview"
                    description="Make qualitative edits to model documentation, view guidelines, collaborate with validators, and submit your model documentation for approval."
                    link="https://docs.validmind.ai/guide/model-documentation/working-with-model-documentation.html"
                    placement="right-end"
                  />
                </HStack>
                <Box>
                  <DocumentOutline
                    data={tree}
                    allowMultiple={true}
                    documentationOutline={documentationOutline}
                    resolveAnnotation={async (
                      annotation: TInventoryModelAnnotation,
                    ) => {
                      const accessToken = await getAccessTokenSilently();
                      API.ResolveAnnotation(
                        accessToken,
                        inventoryModel!,
                        annotation.cuid!,
                        true,
                      );
                      refetchDocumentationOverview();
                    }}
                  />
                </Box>
                <Divider />
                <Box>
                  <ActivityFeedWidget
                    variant={'model-inventory-summary'}
                    filters={eventFilters}
                    seeAllUrl={`/model-inventory/${inventoryModel?.cuid}/activity`}
                  />
                </Box>
              </VStack>
              <Copyright />
            </RecordDetailsPage>
          </Stack>
        </Flex>
      </LoadingContainer>
      {inventoryModel && (
        <TemplateModal
          inventoryModel={inventoryModel}
          activeTemplateDocumentType={'model_documentation'}
          isOpen={isOpenTemplateModal}
          onClose={onCloseTemplateModal}
        />
      )}
    </>
  );
}
