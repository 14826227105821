import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Button,
  HStack,
  Text,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Stack,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useState } from 'react';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowsUpDownIcon,
  PlusIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/solid';
import { useOrganizations, useOrganizationDefaults } from '../../hooks/admin';
import { LoadingContainer } from '../../components/LoadingContainer';
import { TOrganization } from '../../api/AdminAPI';
import { displayFormatedDateAndTime } from '../../utils';
import { Select } from 'chakra-react-select';
import { generateStrongPassword } from '../../utils';
import { isValidEmail } from '../../utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from 'react-query';
import AdminAPI, { TCreateOrganizationRequest } from '../../api/AdminAPI';
import { useToast } from '@chakra-ui/react';

type SortField = 'name' | 'created_at' | 'updated_at';
type SortOrder = 'asc' | 'desc';

type OptionType = {
  value: string;
  label: string;
};

interface CreateOrganizationFormData {
  name: string;
  adminEmail: string;
  adminName: string;
  adminPassword: string;
  selectedModels: string[];
  selectedTemplates: string[];
}

function Organizations() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newOrgName, setNewOrgName] = useState('');
  const { data: organizations, isLoading } = useOrganizations();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState<SortField>('created_at');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const itemsPerPage = 10;
  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const { defaults, isLoading: isLoadingDefaults } = useOrganizationDefaults();
  const [adminEmail, setAdminEmail] = useState('');
  const [adminName, setAdminName] = useState('');
  const [adminPassword, setAdminPassword] = useState(generateStrongPassword());
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState<CreateOrganizationFormData>({
    name: '',
    adminEmail: '',
    adminName: '',
    adminPassword: '',
    selectedModels: [],
    selectedTemplates: [],
  });
  const [emailError, setEmailError] = useState<string>('');
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [error, setError] = useState<string | null>(null);

  const createOrganizationMutation = useMutation({
    mutationFn: async (organizationData: TCreateOrganizationRequest) => {
      const accessToken = await getAccessTokenSilently();
      return AdminAPI.CreateOrganization(accessToken, organizationData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'organizations'] });
      toast({
        title: 'Organization created',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setNewOrgName('');
      setSelectedModels([]);
      setSelectedTemplates([]);
      setAdminEmail('');
      setAdminName('');
      setAdminPassword(generateStrongPassword());
      setError(null);
      onClose();
    },
    onError: (error: Error) => {
      setError(error.message || 'Failed to create organization');
    },
  });

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      // Toggle order: asc -> desc -> asc
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
    // Reset to first page when sorting changes
    setCurrentPage(1);
  };

  const getSortIcon = (field: SortField) => {
    if (sortField !== field)
      return <Icon as={ArrowsUpDownIcon} ml={2} w={4} h={4} />;
    if (sortOrder === 'asc')
      return <Icon as={ChevronUpIcon} ml={2} w={4} h={4} />;
    return <Icon as={ChevronDownIcon} ml={2} w={4} h={4} />;
  };

  // Sort organizations
  const sortedOrganizations = organizations
    ? [...organizations].sort((a, b) => {
        const aValue =
          sortField === 'name' ? a[sortField] : new Date(a[sortField]);
        const bValue =
          sortField === 'name' ? b[sortField] : new Date(b[sortField]);

        if (sortOrder === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      })
    : [];

  // Calculate pagination values
  const totalPages = sortedOrganizations
    ? Math.ceil(sortedOrganizations.length / itemsPerPage)
    : 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrganizations = sortedOrganizations?.slice(startIndex, endIndex);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSelectAllModels = () => {
    if (defaults?.initial_demo_models) {
      setSelectedModels(
        selectedModels.length === defaults.initial_demo_models.length
          ? []
          : defaults.initial_demo_models.map(model => model.name),
      );
    }
  };

  const handleSelectAllTemplates = () => {
    if (defaults?.initial_templates) {
      setSelectedTemplates(
        selectedTemplates.length === defaults.initial_templates.length
          ? []
          : defaults.initial_templates.map(template => template.name),
      );
    }
  };

  const handleCreateOrganization = () => {
    const organizationData: TCreateOrganizationRequest = {
      name: newOrgName,
      demo_inventory_models: selectedModels,
      admin_user: {
        email: adminEmail,
        name: adminName,
        password: adminPassword,
      },
      initial_templates: selectedTemplates,
    };

    createOrganizationMutation.mutate(organizationData);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setAdminEmail(email);

    if (!email) {
      setEmailError('Email is required');
    } else if (!isValidEmail(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const isFormValid = () => {
    return (
      newOrgName.trim() &&
      adminName.trim() &&
      adminPassword.trim() &&
      adminEmail.trim() &&
      isValidEmail(adminEmail) &&
      selectedModels.length > 0 &&
      selectedTemplates.length > 0
    );
  };

  const modalBodyContent = (
    <Stack spacing={4}>
      {error && (
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      )}
      <FormControl>
        <FormLabel>Organization Name</FormLabel>
        <Input
          value={newOrgName}
          onChange={e => setNewOrgName(e.target.value)}
          placeholder="Enter organization name"
        />
      </FormControl>

      <Box borderWidth="1px" borderRadius="lg" p={4}>
        <Text fontWeight="bold" mb={2}>
          Admin User Details
        </Text>
        <Text fontSize="sm" color="gray.600" mb={4}>
          Note: If the admin email already exists, the name and password fields
          will be ignored.
        </Text>
        <Stack spacing={4}>
          <FormControl isRequired isInvalid={!!emailError}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={adminEmail}
              onChange={handleEmailChange}
              placeholder="Enter admin email"
            />
            {emailError && <FormErrorMessage>{emailError}</FormErrorMessage>}
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Full Name</FormLabel>
            <Input
              value={adminName}
              onChange={e => setAdminName(e.target.value)}
              placeholder="Enter admin full name"
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? 'text' : 'password'}
                value={adminPassword}
                onChange={e => setAdminPassword(e.target.value)}
                placeholder="Enter password"
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Icon
                    as={showPassword ? EyeSlashIcon : EyeIcon}
                    w={4}
                    h={4}
                  />
                </Button>
              </InputRightElement>
            </InputGroup>
            <Button
              size="sm"
              variant="link"
              mt={1}
              onClick={() => setAdminPassword(generateStrongPassword())}
            >
              Generate new password
            </Button>
          </FormControl>
        </Stack>
      </Box>

      <FormControl>
        <FormLabel>Demo Models</FormLabel>
        <Select<OptionType, true>
          isMulti
          value={selectedModels.map(name => ({
            value: name,
            label: name,
          }))}
          onChange={newValue => {
            setSelectedModels(newValue.map(v => v.value));
          }}
          options={defaults?.initial_demo_models?.map(model => ({
            value: model.name,
            label: model.name,
          }))}
          placeholder="Select demo models..."
          closeMenuOnSelect={false}
          chakraStyles={{
            container: provided => ({
              ...provided,
              background: 'white',
            }),
          }}
        />
        <Button size="sm" variant="link" mt={1} onClick={handleSelectAllModels}>
          {selectedModels.length === defaults?.initial_demo_models?.length
            ? 'Deselect all'
            : 'Select all'}
        </Button>
      </FormControl>

      <FormControl mt={4}>
        <FormLabel>Demo Templates</FormLabel>
        <Select<OptionType, true>
          isMulti
          value={selectedTemplates.map(name => ({
            value: name,
            label: name,
          }))}
          onChange={newValue => {
            setSelectedTemplates(newValue.map(v => v.value));
          }}
          options={defaults?.initial_templates?.map(template => ({
            value: template.name,
            label: template.name,
          }))}
          placeholder="Select demo templates..."
          closeMenuOnSelect={false}
          chakraStyles={{
            container: provided => ({
              ...provided,
              background: 'white',
            }),
          }}
        />
        <Button
          size="sm"
          variant="link"
          mt={1}
          onClick={handleSelectAllTemplates}
        >
          {selectedTemplates.length === defaults?.initial_templates?.length
            ? 'Deselect all'
            : 'Select all'}
        </Button>
      </FormControl>
    </Stack>
  );

  return (
    <Box>
      <Flex justify="space-between" align="center" mb={6}>
        <Heading>Organizations</Heading>
        <Button
          variant="primary"
          leftIcon={<Icon as={PlusIcon} w={4} h={4} />}
          onClick={onOpen}
        >
          New Organization
        </Button>
      </Flex>

      {/* New Organization Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalHeader>Create New Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{modalBodyContent}</ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleCreateOrganization}
              isDisabled={!isFormValid()}
              isLoading={createOrganizationMutation.isLoading}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <LoadingContainer isLoading={isLoading}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>CUID</Th>
              <Th cursor="pointer" onClick={() => handleSort('name')}>
                Organization Name {getSortIcon('name')}
              </Th>
              <Th cursor="pointer" onClick={() => handleSort('created_at')}>
                Created At {getSortIcon('created_at')}
              </Th>
              <Th cursor="pointer" onClick={() => handleSort('updated_at')}>
                Updated At {getSortIcon('updated_at')}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentOrganizations?.map((org: TOrganization) => (
              <Tr key={org.cuid}>
                <Td>{org.cuid}</Td>
                <Td>{org.name}</Td>
                <Td>{displayFormatedDateAndTime(org.created_at)}</Td>
                <Td>{displayFormatedDateAndTime(org.updated_at)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {/* Pagination Controls */}
        {totalPages > 1 && (
          <HStack spacing={2} justify="center" mt={6}>
            <Button
              size="sm"
              onClick={() => handlePageChange(currentPage - 1)}
              isDisabled={currentPage === 1}
            >
              Previous
            </Button>

            <Text>
              Page {currentPage} of {totalPages}
            </Text>

            <Button
              size="sm"
              onClick={() => handlePageChange(currentPage + 1)}
              isDisabled={currentPage === totalPages}
            >
              Next
            </Button>
          </HStack>
        )}
      </LoadingContainer>
    </Box>
  );
}

export default Organizations;
