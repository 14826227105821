import { defineStyleConfig } from '@chakra-ui/react';

export const Modal = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    dialog: {
      bg: 'neutral.25',
      _dark: {
        bg: 'neutral.950',
      },
    },
  },

  // The default size and variant values
  defaultProps: {
    colorScheme: 'brand',
  },
});
