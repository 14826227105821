import {
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Switch,
  useColorMode,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import StyleGuideAccordion from './components/Accordion';
import StyleGuideAlert from './components/Alert';
import StyleGuideAvatar from './components/Avatar';
import StyleGuideBadge from './components/Badge';
import StyleGuideButton from './components/Button';
import StyleGuideBreadcrumb from './components/Breadcrumb';
import StyleGuideCheckbox from './components/Checkbox';
import StyleGuideCircularProgress from './components/CircularProgress';
import StyleGuideCloseButton from './components/CloseButton';
import StyleGuideCode from './components/Code';
import StyleGuideDivider from './components/Divider';
import StyleGuideDrawer from './components/Drawer';
import StyleGuideEditable from './components/Editable';
import StyleGuideFormControl from './components/FormControl';
import StyleGuideHeading from './components/Heading';
import StyleGuideHighlight from './components/Highlight';
import StyleGuideIcon from './components/Icon';
import StyleGuideIconButton from './components/IconButton';
import StyleGuideImage from './components/Image';
import StyleGuideInput from './components/Input';
import StyleGuideKbd from './components/Kbd';
import StyleGuideLink from './components/Link';
import StyleGuideList from './components/List';
import StyleGuideMenu from './components/Menu';
import StyleGuideModal from './components/Modal';
import StyleGuideNumberInput from './components/NumberInput';
import StyleGuidePinInput from './components/PinInput';
import StyleGuidePopover from './components/Popover';
import StyleGuideProgress from './components/Progress';
import StyleGuideRadio from './components/Radio';
import StyleGuideSelect from './components/Select';
import StyleGuideSkeleton from './components/Skeleton';
import StyleGuideSlider from './components/Slider';
import StyleGuideSpinner from './components/Spinner';
import StyleGuideStat from './components/Stat';
import StyleGuideSwitch from './components/Switch';
import StyleGuideTable from './components/Table';
import StyleGuideTabs from './components/Tabs';
import StyleGuideTag from './components/Tag';
import StyleGuideTextarea from './components/Textarea';
import StyleGuideTooltip from './components/Tooltip';
import { useEffect, useState } from 'react';
import { useUserUISettings } from '../../hooks/useUserUISettings';
import { MoonIcon, SunIcon } from '@heroicons/react/24/outline';
import StyleGuideRangeSlider from './components/RangeSlider';
import StyleGuideCard from './components/Card';
import StyleGuideToast from './components/Toast';
import StyleGuideText from './components/Text';
import StyleGuideAlertDialog from './components/AlertDialog';

/*
FORMS

Button
Checkbox
Editable
Form Control
Icon Button
Input
Number Input
Pin Input
Radio
Range Slider
Select
Slider
Switch
Textarea
*/

/*
DATA DISPLAY

Badge
Card
Code
Divider
Kbd
List
Stat
Table
Tag
*/

const StyleGuide = () => {
  const { updateColorMode } = useUserUISettings();
  const [modeName, setModeName] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (colorMode === 'dark') {
      setIsDarkMode(true);
      setModeName('Light Mode');
    } else {
      setModeName('Dark Mode');
      setIsDarkMode(false);
    }
  }, [colorMode]);

  const handleDarkMode = () => {
    // updateColorMode will trigger the toggleColorMode in App.tsx
    if (colorMode === 'dark') {
      updateColorMode('light');
    } else {
      updateColorMode('dark');
    }
  };
  const colors = [
    'brand',
    'brandSecondary',
    'neutral',
    'red',
    'sunset',
    'orange',
    'amber',
    'yellow',
    'citron',
    // 'lime',
    'green',
    'mint',
    'teal',
    'cyan',
    'blue',
    'indigo',
    'purple',
    'pink',
    'rose',
    // 'emerald',
    // 'sky',
    // 'violet',
    // 'fuchsia',
    // 'gray',
  ];
  const sizes = ['xs', 'sm', 'md', 'lg'];
  return (
    <VStack p={12} gap={24}>
      <VStack>
        <Heading as={'h1'}>ValidMind's Styleguide</Heading>
        <HStack>
          <Icon as={MoonIcon} boxSize={4} />
          <Switch size={'sm'} onChange={handleDarkMode}></Switch>
          <Icon as={SunIcon} boxSize={4} />
        </HStack>
      </VStack>
      <VStack bg={useColorModeValue('neutral.25', 'neutral.900')} p={12}>
        <Heading as={'h2'}>Forms</Heading>
        <SimpleGrid gap={24} columns={3}>
          <StyleGuideButton sizes={sizes} />
          <StyleGuideCheckbox sizes={sizes} />
          <StyleGuideEditable />
          <StyleGuideFormControl />
          <StyleGuideIconButton sizes={sizes} />
          <StyleGuideInput sizes={sizes} />
          <StyleGuideNumberInput sizes={sizes} />
          <StyleGuidePinInput sizes={sizes} />
          <StyleGuideRadio sizes={sizes} />
          <StyleGuideRangeSlider />
          <StyleGuideSlider />
          <StyleGuideSelect sizes={sizes} />
          <StyleGuideSwitch sizes={sizes} />
          <StyleGuideTextarea />
        </SimpleGrid>
      </VStack>
      <VStack bg={useColorModeValue('neutral.25', 'neutral.900')} p={12}>
        <Heading as={'h2'}>Data Display</Heading>
        <SimpleGrid gap={24} columns={3}>
          <StyleGuideBadge colors={colors} sizes={sizes} />
          <StyleGuideCard />
          <StyleGuideCode />
          <StyleGuideDivider />
          <StyleGuideKbd />
          <StyleGuideList />
          <StyleGuideStat />
          <StyleGuideTable />
          <StyleGuideTag colors={colors} sizes={sizes} />
        </SimpleGrid>
      </VStack>
      <VStack bg={useColorModeValue('neutral.25', 'neutral.900')} p={12}>
        <Heading as={'h2'}>Feedback</Heading>
        <SimpleGrid gap={24} columns={3}>
          <StyleGuideAlert />
          <StyleGuideCircularProgress />
          <StyleGuideProgress />
          <StyleGuideSkeleton />
          <StyleGuideSpinner />
          <StyleGuideToast />
        </SimpleGrid>
      </VStack>
      <VStack bg={useColorModeValue('neutral.25', 'neutral.900')} p={12}>
        <Heading as={'h2'}>Typography</Heading>
        <SimpleGrid gap={24} columns={3}>
          <StyleGuideText />
          <StyleGuideHeading />
          <StyleGuideHighlight />
        </SimpleGrid>
      </VStack>
      <VStack bg={useColorModeValue('neutral.25', 'neutral.900')} p={12}>
        <Heading as={'h2'}>Overlay</Heading>
        <SimpleGrid gap={24} columns={3}>
          <StyleGuideAlertDialog />
          <StyleGuideDrawer />
          <StyleGuideMenu />
          <StyleGuideModal />
          <StyleGuidePopover />
          <StyleGuideTooltip />
        </SimpleGrid>
      </VStack>
      <VStack bg={useColorModeValue('neutral.25', 'neutral.900')} p={12}>
        <Heading as={'h2'}>Disclosure</Heading>
        <SimpleGrid gap={24} columns={3}>
          <StyleGuideAccordion />
          <StyleGuideTabs />
        </SimpleGrid>
      </VStack>
      <VStack bg={useColorModeValue('neutral.25', 'neutral.900')} p={12}>
        <Heading as={'h2'}>Navigation</Heading>
        <SimpleGrid gap={24} columns={3}>
          <StyleGuideBreadcrumb />
          <StyleGuideLink />
        </SimpleGrid>
      </VStack>
      <VStack bg={useColorModeValue('neutral.25', 'neutral.900')} p={12}>
        <Heading as={'h2'}>Media and Icons</Heading>
        <SimpleGrid gap={24} columns={3}>
          <StyleGuideAvatar sizes={sizes} />
          <StyleGuideIcon />
          <StyleGuideImage />
        </SimpleGrid>
      </VStack>

      <SimpleGrid gap={24} columns={3}>
        <StyleGuideCloseButton />
      </SimpleGrid>
    </VStack>
  );
};

export default StyleGuide;
