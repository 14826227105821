import { createContext } from 'react';
import { UseMutationResult } from 'react-query';
import { SeekMutationVariables } from '../components/WorkflowExecutionsViewer';

type WorkflowExecutionContext = {
  seek?: UseMutationResult<void, unknown, SeekMutationVariables, unknown>;
  resumeWait?: UseMutationResult<void, unknown, any, unknown>;
};

const WorkflowExecutionContext = createContext<WorkflowExecutionContext>({});

export default WorkflowExecutionContext;
