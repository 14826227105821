import { defineStyleConfig } from '@chakra-ui/react';

export const Link = defineStyleConfig({
  baseStyle: {
    color: 'brandSecondary.600 !important',
    fontWeight: 'bold',
    _dark: {
      color: 'brandSecondary.100 !important',
      _hover: {
        color: 'brandSecondary.25 !important',
      },
    },
    _focus: {
      outline: 'none',
      boxShadow: '0 0 0 3px var(--chakra-colors-brand-400)',
    },
  },
});
