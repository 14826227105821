import {
  defineStyleConfig,
  SystemStyleObject,
  useColorModeValue,
} from '@chakra-ui/react';

export const Alert = defineStyleConfig({
  baseStyle: {},
  variants: {
    subtle: (props: { colorScheme: string }): SystemStyleObject => ({
      container: {
        borderRadius: 'var(--chakra-radii-lg)',
        background: useColorModeValue(
          `${props.colorScheme}.25`,
          `${props.colorScheme}.900`,
        ),
        padding: 'var(--chakra-space-4)',
      },
      title: {
        color: useColorModeValue(
          `${props.colorScheme}.800`,
          `${props.colorScheme}.50`,
        ),
      },
      description: {
        color: useColorModeValue(
          `${props.colorScheme}.900`,
          `${props.colorScheme}.25`,
        ),
      },
    }),
  },

  // The default size and variant values
  defaultProps: {},
});
