import {
  Box,
  Heading,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from '@chakra-ui/react';

const StyleGuideRangeSlider = () => {
  return (
    <Box>
      <Heading as={'h2'}>RangeSlider</Heading>
      <RangeSlider aria-label={['min', 'max']} defaultValue={[10, 30]}>
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>
        <RangeSliderThumb index={0} />
        <RangeSliderThumb index={1} />
      </RangeSlider>
      <RangeSlider
        aria-label={['min', 'max']}
        defaultValue={[10, 30]}
        orientation="vertical"
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>
        <RangeSliderThumb index={0} />
        <RangeSliderThumb index={1} />
      </RangeSlider>
    </Box>
  );
};

export default StyleGuideRangeSlider;
