import { keyable } from './utils';
import { TUser } from './user';
import _ from 'lodash';

export const menuItemLabel = (menuItem: IMenuItem) => {
  return menuItem.index
    ? `${menuItem.index}. ${menuItem.label}`
    : menuItem.label;
};

export interface IMenuItem {
  id?: string;
  index?: string;
  label: string;
  path?: string;
  condensed?: boolean;
  indexOnly?: boolean;
  icon?: React.ReactNode;
  children?: IMenuItem[];
}

export type TemplateSectionContents = {
  content_id: string;
  content_type: string;
  options?: keyable;
};

export type TemplateSection = {
  // order: number;
  id: string;
  index?: string; // Index number, e.g. "1.1.1"
  condensed?: boolean; // Condensed pages show all its subpages in a single page
  index_only?: boolean; // Index only pages don't have any context and can't currently be routed to
  parent_section: string;
  title: string;
  guidelines?: string[];
  contents?: TemplateSectionContents[];
};

export interface TemplateSectionTree extends TemplateSection {
  sections?: TemplateSectionTree[];
}

export type JSONTemplate = {
  template_id: string;
  template_name: string;
  version: string;
  sections: TemplateSection[];
  description?: string;
  start_index?: number;
};

export type TemplateVersion = {
  cuid: string;
  version_number: string;
  description: string;
  created_at: number;
  updated_at: number;
  json: JSONTemplate;
  created_by?: TUser;
};

export type SectionInsertPosition = 'before' | 'after';

export interface OfflineDocumentationFile {
  url: string;
  name: string;
  created_at: number;
  content_type: string;
}

export type Template = {
  id: number;
  cuid: string;
  name: string;
  type: string;
  description: string;
  // DEPRECATED: Use versions
  template: JSONTemplate;
  template_cuid?: string;
  template_version_description?: string;
  template_version_by?: TUser;
  version_number?: string;
  versions?: TemplateVersion[];
  // Populates the current version of a template (defaults to 'latest')
  current_version?: TemplateVersion;
  created_at: number;
  updated_at: number;
  created_by?: TUser;

  is_offline: boolean;
  offline_files: OfflineDocumentationFile[];
};

export type TemplateValidation = {
  valid: boolean;
  message: string;
};

/**
 * Gets a template page from the lookup object by its id
 * Ensures to replace _ with - to match the page id from the template
 */
export const getSectionFromLookup = (
  lookup: { [key: string]: TemplateSection },
  sectionId: string,
): TemplateSection => {
  const sectionIdWithDashes = sectionId.replace(/_/g, '-');
  const section = lookup[sectionIdWithDashes];

  return section;
};

/**
 * Traverse the template sections and convert it to a lookup object
 */
export const convertSectionsToLookup = (
  sections: TemplateSection[],
): { [key: string]: TemplateSection } => {
  const lookup: { [key: string]: TemplateSection } = {};

  for (const section of sections) {
    const pageId = section.id.replace(/_/g, '-') || '';
    lookup[pageId] = section;

    if (section.contents) {
      for (const content of section.contents) {
        const contentsId = Array.isArray(content.content_id)
          ? content.content_id
          : [content.content_id];

        for (const id of contentsId) {
          const contentId = id.replace(/_/g, '-') || '';
          if (!lookup[contentId]) {
            lookup[contentId] = { ...section };
          }
        }
      }
    }
  }

  return lookup;
};

/**
 * Converts the list of sections into a section tree where each section
 * has a children `sections' property. We build this by iteratively going
 * over each section and checking if it has a parent section. If it does,
 * we add it as a child to that parent section. If it doesn't, we add it
 * to the root of the tree.
 */
export const convertToSectionTree = (
  sections: TemplateSection[],
  parentId: string = '_root_',
): TemplateSectionTree[] => {
  const sectionTree: TemplateSectionTree[] = [];

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    const sectionParentId = section.parent_section || '_root_';
    if (sectionParentId === parentId) {
      const childSections = convertToSectionTree(sections, section.id);
      if (childSections.length > 0) {
        sectionTree.push({ ...section, sections: childSections });
      } else {
        sectionTree.push({ ...section });
      }
    }
  }

  return sectionTree;
};

/**
 * Adds index numbers to each section's name, e.g.:
 * - A section like "Data" could become "1. Data"
 * - A section like "Data Engineering > Data Preparation" could become "1.1. Data Preparation
 */
export const addIndexNumbersToSections = (
  sectionTree: TemplateSectionTree[],
  lookup: { [key: string]: TemplateSection },
  parentIndex: string = '',
  startIndex: number = 1,
) => {
  sectionTree.forEach((section, index) => {
    const currentIndex = parentIndex
      ? `${parentIndex}.${index + 1}`
      : `${index + startIndex}`;
    section.index = currentIndex;

    // Update lookup with the new index
    const pageId = section.id.replace(/_/g, '-') || '';
    lookup[pageId] = section;

    if (section.sections) {
      addIndexNumbersToSections(section.sections, lookup, currentIndex);
    }
  });
};

/**
 * Utility function to convert the inventory model's list of pages to a flat list
 * Used by the command palette only
 */
export const flattenPages = (
  pages: IMenuItem[],
  section: string = '',
  name: string = '',
  slug: string = '',
) => {
  let result: any[] = [];

  pages.forEach(page => {
    const sectionName = section || menuItemLabel(page);
    const pageLabelWithIndex = menuItemLabel(page);
    const pageName = pageLabelWithIndex;

    result.push({
      id: page.id,
      name: pageName,
      // If we are using the parent's slug on a condensed page,
      // we use the child's page id as the anchor
      slug: `${slug}#${page.id?.replace(/_/g, '-')}` || page.path,
      absolutePath: page.path,
      section: sectionName,
      indexOnly: page.indexOnly,
    });

    if (page.children) {
      const slug = page.condensed ? page.path : '';
      result = result.concat(
        flattenPages(page.children, sectionName, menuItemLabel(page), slug),
      );
    }
  });

  return result;
};

/**
 * Utility function to convert a section tree of a template back to a flat list
 */
export const flattenSections = (
  sections: TemplateSectionTree[],
): TemplateSection[] => {
  let result: TemplateSection[] = [];

  sections.forEach(section => {
    // Section order and index do not need to be saved. We generate these
    // properties dynamically when rendering a document
    const cleanSection = _.omit(section, ['order', 'index']);

    result.push(cleanSection as TemplateSection);

    if (section.sections) {
      result = result.concat(flattenSections(section.sections));
      delete section.sections;
    }
  });

  // Remove nested sections from the original section tree
  result = result.map(section => {
    const sectionAsTree = section as TemplateSectionTree;
    if (sectionAsTree.sections) {
      delete sectionAsTree.sections;
    }
    return sectionAsTree;
  });

  return result;
};
