import { Input, useColorModeValue, Text } from '@chakra-ui/react';
import { CustomFieldProps } from '../types';
import NullFieldDisplay from '../Generic/NullFieldDisplay';
import { NumberProperty } from '../../../models/inventory_model';
import { FormatNumber } from '../../../currencyUtils';

export default function CustomNumber({
  mode,
  value,
  onChange,
  schema,
}: CustomFieldProps) {
  const { props } = schema;
  const { numberOptions } = props as { numberOptions: NumberProperty };

  if (mode === 'read-only' || mode === 'display') {
    if (!value && value !== 0) {
      if (mode === 'display') {
        return <></>;
      }
      return <NullFieldDisplay />;
    }

    return <Text>{FormatNumber(value, numberOptions)}</Text>;
  }

  return (
    <Input
      type={'number'}
      value={value}
      focusBorderColor="brand.base"
      bg={useColorModeValue('white', 'neutral.850')}
      onChange={e => {
        if (e.target.value !== '') {
          const parsed = parseFloat(e.target.value);
          if (!isNaN(parsed)) {
            onChange(parsed);
          }
        } else {
          onChange(null);
        }
      }}
    />
  );
}
