import { Flex, useColorModeValue } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

/**
 * Documentation and Validation Report pages require
 * a submenu to render their subpages. This submenu
 * is rendered with the SideNavSubmenu component.
 */
export const sideNavRequiresSubmenu = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  // Simple check for now, but will need to be more robust
  const requiresSubmenu =
    currentPath.includes('documentation') ||
    currentPath.includes('validation-report') ||
    currentPath.includes('monitoring');

  return requiresSubmenu;
};

export default function MasterDetails({ children }: any) {
  return (
    <Flex
      position="relative"
      id="inventory-model-details"
      direction="column"
      align="center"
      justify="start"
      width={'100%'}
      gap={2}
      px={sideNavRequiresSubmenu() ? 0 : 10}
      pb={12}
      bg={useColorModeValue('white', 'black')}
      overflow={'auto'}
      className="no-scrollbar"
    >
      {/* Need to decided on proper spacing according to current page first */}
      {children}
    </Flex>
  );
}
