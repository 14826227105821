import {
  Box,
  Heading,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Stack,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';

const StyleGuideAvatar = ({ sizes }: { sizes: string[] }) => {
  return (
    <Box>
      <Heading as={'h2'}>Avatar</Heading>
      <VStack gap={6}>
        <SimpleGrid columns={4} gap={12}>
          {sizes.reverse().map(size => (
            <Avatar
              size={size}
              name={`Avatar size ${size}`}
              src="https://bit.ly/dan-abramov"
            />
          ))}
        </SimpleGrid>

        <AvatarGroup size="md" max={2}>
          {sizes.reverse().map(size => (
            <Avatar
              name={`Avatar size ${size}`}
              src="https://bit.ly/dan-abramov"
            />
          ))}
        </AvatarGroup>

        <Stack direction="row" spacing={4}>
          <Avatar>
            <AvatarBadge boxSize={6} bg="green.500" />
          </Avatar>
        </Stack>
      </VStack>
    </Box>
  );
};

export default StyleGuideAvatar;
