import { TInventoryModelAnnotation } from '../../api/API';
import {
  AvatarGroup,
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { TUser } from '../../models';
import dayjs from 'dayjs';
import { Icon } from '@chakra-ui/icons';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import AvatarProxy from '../AvatarProxy';

const UnresolvedConversationsPopover = ({
  annotations,
  resolveAnnotation,
  onAnnotationClick,
}: {
  annotations: TInventoryModelAnnotation[];
  resolveAnnotation: (annotation: TInventoryModelAnnotation) => void;
  onAnnotationClick: (annotation: TInventoryModelAnnotation) => void;
}) => {
  return (
    <VStack gap={2}>
      {annotations.map(annotation => {
        const comments = annotation.comments || [];
        // find last comment
        const lastComment = comments[comments.length - 1];
        const remainingCommentCount = comments.length - 1;

        const usersInThread: TUser[] = [];

        if (remainingCommentCount > 0) {
          const handledUserMap: { [key: string]: boolean } = {};
          for (let i = 0; i < remainingCommentCount; i++) {
            const user = comments[i].user;
            if (user && !handledUserMap[user.cuid]) {
              handledUserMap[user.cuid] = true;
              usersInThread.push(user);
            }
          }
        }
        return (
          <Box
            w="full"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onAnnotationClick(annotation);
            }}
            _hover={{
              bg: useColorModeValue('neutral.100', 'neutral.800'),
            }}
            p={2}
            border={'1px solid'}
            borderColor={'transparent'}
            rounded={'md'}
            transition={'all 0.2s ease-in-out'}
          >
            <VStack gap={0} alignItems={'flex-start'}>
              <Box
                w={'full'}
                textAlign="left"
                p={2}
                border={'1px solid'}
                rounded={'md'}
                bg={useColorModeValue('white', 'neutral.800')}
                borderColor={useColorModeValue('neutral.200', 'neutral.800')}
              >
                <Text
                  as={'span'}
                  fontSize={'sm'}
                  whiteSpace={'normal'}
                  fontWeight={'medium'}
                  align={'left'}
                  backgroundColor={useColorModeValue('brand.50', 'brand.800')}
                  wordBreak={'break-word'}
                  paddingY={0.5}
                  paddingRight={1}
                >
                  {(annotation.context || {}).value}
                </Text>
              </Box>
              {lastComment && (
                <VStack
                  gap={0}
                  alignItems={'flex-start'}
                  ml={2}
                  px={2}
                  borderLeft={'1px solid'}
                  borderColor={useColorModeValue('neutral.200', 'neutral.800')}
                  w={'full'}
                >
                  {remainingCommentCount > 0 && (
                    <Tooltip
                      label={
                        <div>
                          {usersInThread.map(u => (
                            <Text>{u.name}</Text>
                          ))}
                        </div>
                      }
                      placement={'left'}
                      hasArrow
                    >
                      <HStack w={'full'} px={2}>
                        <AvatarGroup max={5}>
                          {usersInThread.map(user => (
                            <AvatarProxy
                              name={user.name}
                              size={'xs'}
                              src={user.picture}
                            />
                          ))}
                        </AvatarGroup>
                        <Text fontSize={'xs'} fontWeight={'normal'} my={2}>
                          {remainingCommentCount} more comment
                          {remainingCommentCount > 1 ? 's' : ''}
                        </Text>
                      </HStack>
                    </Tooltip>
                  )}
                  <HStack w={'full'}>
                    <HStack p={2} w={'full'}>
                      <AvatarProxy
                        name={lastComment.user?.name}
                        size={'xs'}
                        src={lastComment.user?.picture}
                      />
                      <HStack>
                        <Text fontSize={'sm'}>{lastComment.user?.name}</Text>
                        <Text fontSize={'xs'} fontWeight={'normal'}>
                          {dayjs(
                            parseFloat(lastComment.created_at!) * 1000,
                          ).fromNow()}
                        </Text>
                      </HStack>
                    </HStack>
                    <Spacer />

                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<Icon as={EllipsisHorizontalIcon} boxSize={5} />}
                        variant="ghost"
                        size={'sm'}
                        onClick={event => {
                          event.stopPropagation();
                        }}
                      />
                      <MenuList>
                        <MenuItem
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            resolveAnnotation(annotation);
                          }}
                        >
                          Resolve conversation
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </HStack>
                  <Box ml={10}>
                    <Text
                      whiteSpace={'normal'}
                      fontSize={'sm'}
                      textAlign={'left'}
                      fontWeight={'normal'}
                      lineHeight={'1.6'}
                      dangerouslySetInnerHTML={{ __html: lastComment.text }}
                    />
                  </Box>
                </VStack>
              )}
            </VStack>
          </Box>
        );
      })}
    </VStack>
  );
};

export default UnresolvedConversationsPopover;
