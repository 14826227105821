import { Box, Heading } from '@chakra-ui/react';

const StyleGuideIcon = () => {
  return (
    <Box>
      <Heading as={'h2'}>Icon</Heading>
    </Box>
  );
};

export default StyleGuideIcon;
