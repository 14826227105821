import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import UsersContext from '../../contexts/UsersContext';
import { TRole } from '../../models/role';
import API from '../../api/API';

interface UseCustomFieldPermissionsReturn {
  updateRoles: TRole[];
  isLoading: boolean;
  error: string | null;
}

const useCustomFieldPermissions = (
  customFieldKey?: string,
): UseCustomFieldPermissionsReturn => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentOrganization } = useContext(UsersContext);
  const [updateRoles, setUpdateRoles] = useState<TRole[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!customFieldKey || !currentOrganization?.roles) return;

    const fetchPermissions = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const token = await getAccessTokenSilently();
        const rolesWithPermission: TRole[] = [];
        const customFieldCategory = `cf_${customFieldKey}`;

        // Iterate through all organization roles
        for (const role of currentOrganization.roles) {
          const rolePermissions = await API.GetRolePermissions(
            token,
            role.cuid,
          );

          // Check if the custom field category exists in the categories map
          const categoryPermissions =
            rolePermissions.categories[customFieldCategory];
          if (categoryPermissions) {
            // Check for the 'update' permission in the category
            let update_action = 'update_cf_' + customFieldKey;
            const hasUpdatePermission = categoryPermissions.some(
              permission =>
                String(permission.action_id) === update_action &&
                permission.assigned,
            );

            if (hasUpdatePermission) {
              rolesWithPermission.push(role);
            }
          }
        }

        setUpdateRoles(rolesWithPermission);
      } catch (err: any) {
        setError(err.message || 'Failed to fetch permissions');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPermissions();
  }, [customFieldKey, currentOrganization, getAccessTokenSilently]);

  return { updateRoles, isLoading, error };
};

export default useCustomFieldPermissions;
