import { useAuth0 } from '@auth0/auth0-react';
import API from '../../../api/API';
import { useMutation, useQuery } from 'react-query';
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Icon,
  Square,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { LoadingContainer } from '../../../components/LoadingContainer';
import { ContentPageTitle } from '../../../components/Layout';
import {
  TStatusesWorkflow,
  TStatusesWorkflowStatus,
} from '../../../models/statuses_workflow';
import { useContext, useEffect, useState } from 'react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { EmptyStateDisplay } from '../../../components/EmptyStateDisplay';
import AddStatusModal from '../../../components/AddStatusModal';
import StatusesWorkflowContext from '../../../contexts/StatusesWorkflowContext';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import SidebarContext from '../../../contexts/SidebarContext';

interface IWorkflowStatusMutation {
  workflow: TStatusesWorkflow;
  status: TStatusesWorkflowStatus;
}

function ModelStatusesWorkflow() {
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const [openModal, setOpenModal] = useState(false);

  const {
    data: workflows,
    isLoading,
    refetch,
  } = useQuery(
    ['organizations', 'workflows'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetStatusesWorkflows(accessToken);
    },
    {
      onError: err => {
        // track errors
      },
    },
  );

  // The API will now return a single workflow object
  const workflow = (workflows || [])[0];

  const [currentWorkflowStatus, setCurrentWorkflowStatus] = useState<
    TStatusesWorkflowStatus | undefined
  >(undefined);

  const createWorkflowStatusMutation = useMutation(
    ['statuses-workflows', workflow?.cuid, 'status'],
    async (variables: IWorkflowStatusMutation) => {
      const accessToken = await getAccessTokenSilently();
      const { workflow, status } = variables;
      if (status.cuid) {
        return API.PutStatusesWorkflowStatus(
          accessToken,
          workflow.cuid,
          status,
        );
      }
      return API.PostStatusesWorkflowStatus(accessToken, workflow.cuid, status);
    },
    {
      onSuccess: (data, variables) => {
        // status was edited
        let edited = variables.workflow.statuses.find(
          s => s.cuid === data.cuid,
        );

        refetch();

        const description = `Status was ${
          edited ? 'modified' : 'created'
        } successfully`;
        toast({
          title: data.name,
          description,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
      onError: err => {
        // track errors
      },
    },
  );

  const deleteWorkflowStatusMutation = useMutation(
    [],
    async (variables: IWorkflowStatusMutation) => {
      const { workflow, status } = variables;
      const accessToken = await getAccessTokenSilently();
      await API.DeleteStatusesWorkflowStatus(
        accessToken,
        workflow.cuid,
        status,
      );
    },
    {
      onSuccess: (data, variables) => {
        const { status: workflowStatus } = variables;
        refetch();
        toast({
          title: workflowStatus.name,
          description: 'Status was deleted successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (error, variables) => {
        toast({
          title: 'Error',
          description: API.getAPIErrorMessage(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const onEditStatusWorkflowStatus = (
    workflowStatus: TStatusesWorkflowStatus,
  ) => {
    setCurrentWorkflowStatus(workflowStatus);
    setOpenModal(true);
  };

  const onDeleteStatusWorkflowStatus = async (
    status: TStatusesWorkflowStatus,
  ) => {
    if (
      workflow &&
      confirm(`Do you want to delete the "${status.name}" status?`)
    ) {
      deleteWorkflowStatusMutation.mutate({ workflow, status });
    }
  };

  const onModalClosed = () => {
    setOpenModal(false);
    setCurrentWorkflowStatus(undefined);
  };

  return (
    <StatusesWorkflowContext.Provider
      value={{ workflow, createWorkflowStatusMutation }}
    >
      <LoadingContainer isLoading={isLoading}>
        <Stack alignSelf={'stretch'} gap={8}>
          <Box>
            <Breadcrumbs />
            <ContentPageTitle>
              Model Lifecycle Statuses
              <MoreInfoPopOver
                title="Customize Model Lifecycle Statuses"
                link="https://docs.validmind.ai/guide/model-workflows/customize-resource-statuses.html"
                placement="right-end"
                iconProps={{
                  ml: 2,
                }}
              />
            </ContentPageTitle>
          </Box>
          <Text>
            Model lifecycle statuses are manipulated via workflow transitions
            and are used to track the status of models through your
            organization's processes.
          </Text>
          <HStack spacing={2} align={'stretch'} justifyContent={'end'}>
            <Button
              onClick={() => setOpenModal(true)}
              leftIcon={<Icon as={AddIcon} boxSize={4} />}
              variant={'ghost'}
            >
              Add New Model Lifecycle Status
            </Button>
          </HStack>
        </Stack>
        {workflow && (
          <Stack maxWidth={'7xl'} mx={'auto'} w={'full'}>
            <VStack gap={1} alignSelf={'stretch'}>
              {workflow.statuses.length > 0 ? (
                workflow.statuses.map(status => (
                  <Box
                    key={status.cuid}
                    alignSelf={'stretch'}
                    mt={'0 !important'}
                    role="group"
                    bg={useColorModeValue('neutral.50', 'neutral.900')}
                    _hover={{
                      bg: useColorModeValue('neutral.100', 'neutral.850'),
                      cursor: 'pointer',
                    }}
                    rounded={'md'}
                    px={2}
                    py={1}
                    border={'1px solid'}
                    borderColor={useColorModeValue(
                      'neutral.200',
                      'neutral.800',
                    )}
                    onClick={() => onEditStatusWorkflowStatus(status)}
                    transition={'all 0.2s ease-in-out'}
                  >
                    <Flex alignSelf={'stretch'}>
                      <Square
                        m={4}
                        size={6}
                        rounded={'md'}
                        border={1}
                        borderStyle="solid"
                        borderColor={status.colors.tertiary}
                        bgColor={status.colors.primary}
                      ></Square>

                      <VStack align={'start'} m={4} flex="1" spacing={1}>
                        <Heading as={'h4'}>{status.name}</Heading>
                        <Text>{status.description}</Text>
                      </VStack>

                      <VStack
                        alignItems="flex-end"
                        visibility={'hidden'}
                        _groupHover={{ display: 'flex', visibility: 'visible' }}
                      >
                        <Button
                          disabled={['Rejected', 'Approved'].includes(
                            status.name,
                          )}
                          variant={'ghost'}
                          onClick={() => onDeleteStatusWorkflowStatus(status)}
                          leftIcon={<DeleteIcon />}
                          colorScheme={'red'}
                        >
                          Delete
                        </Button>
                      </VStack>
                    </Flex>
                  </Box>
                ))
              ) : (
                <EmptyStateDisplay variant="no-workflow">
                  <Heading as={'h5'}>No model lifecycle statuses yet.</Heading>
                  <Text align={'center'}>
                    Click "+ Add New Model Lifecycle Status" to get started.
                  </Text>
                </EmptyStateDisplay>
              )}
            </VStack>
            <AddStatusModal
              isOpen={openModal}
              workflowStatus={currentWorkflowStatus}
              onClose={onModalClosed}
            />
          </Stack>
        )}
      </LoadingContainer>
    </StatusesWorkflowContext.Provider>
  );
}

export default function Statuses() {
  const { setInSettings } = useContext(SidebarContext);

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  return (
    <VStack
      alignItems="start"
      spacing={0}
      paddingTop={12}
      mt={1}
      paddingBottom={16}
      px={14}
      gap={2}
      w="full"
      overflow="auto"
      className="no-scrollbar"
      maxWidth={'7xl'}
      mx={'auto'}
    >
      <ModelStatusesWorkflow />
    </VStack>
  );
}
