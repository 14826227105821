import axios from 'axios';
import { CONFIG } from '../config';

// Create a custom event name
export const UNAUTHORIZED_EVENT = 'admin-unauthorized';

const axiosInstance = axios.create({
  baseURL: `${CONFIG.REACT_APP_API_URL}`,
  headers: {
    'X-Application': 'Admin',
  },
});

// Update the interceptor to emit an event instead of redirecting
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const errorData = error.response?.data;

    if (error.response?.status === 400 && errorData?.code === 'unauthorized') {
      // Emit custom event instead of redirecting
      window.dispatchEvent(new Event(UNAUTHORIZED_EVENT));
    }

    return Promise.reject(error);
  },
);

export type TOrganization = {
  cuid: string;
  name: string;
  created_at: string;
  updated_at: string;
};

export type TAdminUser = {
  email: string;
  name: string;
  password: string;
};

export type TCreateOrganizationRequest = {
  name: string;
  demo_inventory_models: string[];
  admin_user: TAdminUser;
  initial_templates: string[];
};

export type TDemoModel = {
  name: string;
  area: string;
  description: string;
};

export type TTemplate = {
  name: string;
};

export type TOrganizationDefaults = {
  initial_demo_models: TDemoModel[];
  initial_templates: TTemplate[];
};

const GetOrganizations = async (accessToken: string) => {
  const response = await axiosInstance.get<TOrganization[]>(
    `/admin/organizations`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
  return response.data;
};

// Add the new method in the same file after GetOrganizations
const GetOrganizationDefaults = async (accessToken: string) => {
  const response = await axiosInstance.get<TOrganizationDefaults>(
    `/admin/organizations/defaults`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
  return response.data;
};

const CreateOrganization = async (
  accessToken: string,
  organizationData: TCreateOrganizationRequest,
) => {
  const response = await axiosInstance.post<TOrganization>(
    '/admin/organizations',
    organizationData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
  return response.data;
};

export default {
  GetOrganizations,
  GetOrganizationDefaults,
  CreateOrganization,
};
