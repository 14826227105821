import { Box, Heading } from '@chakra-ui/react';

const StyleGuideMenu = () => {
  return (
    <Box>
      <Heading as={'h2'}>Menu</Heading>
    </Box>
  );
};

export default StyleGuideMenu;
