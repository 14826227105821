import { useContext, useEffect } from 'react';
import { useFlags } from '../../../hooks/useFlags';
import {
  Text,
  HStack,
  VStack,
  Box,
  Stack,
  Icon,
  Tag,
  TagLabel,
  useColorModeValue,
  Heading,
  Button,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import Breadcrumbs from '../../../components/Breadcrumbs';
import AddRoleModal from './AddRoleModal';
import { ContentPageTitle } from '../../../components/Layout';
import SidebarContext from '../../../contexts/SidebarContext';
import { TRole } from '../../../models/role';
import { useOrgRoles } from '../../../hooks/useOrgRoles';
import { getRoleUIProps } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';

export default function Roles() {
  const navigate = useNavigate();
  const { setInSettings } = useContext(SidebarContext);
  const { orgRoles, isLoading } = useOrgRoles();
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { releaseSc5029allowEditModelRoles } = useFlags();

  const bg = useColorModeValue('neutral.100', 'neutral.800');

  const enableModelScopeTab = releaseSc5029allowEditModelRoles || false;

  const onSuccessNewRole = (data: TRole) => {
    // setLastAddedItemId(data.cuid)
    onClose();
  };

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  return (
    <VStack
      alignItems="start"
      spacing={0}
      paddingTop={12}
      mt={1}
      paddingBottom={16}
      px={14}
      gap={8}
      w="full"
      overflow="auto"
      className="no-scrollbar"
      maxWidth={'7xl'}
      mx={'auto'}
    >
      <Box>
        <Breadcrumbs />
        <ContentPageTitle>
          Roles
          <MoreInfoPopOver
            title="User Roles"
            link="https://docs.validmind.ai/guide/configuration/manage-roles.html"
            placement="right-end"
            iconProps={{
              ml: 2,
            }}
          />
        </ContentPageTitle>
      </Box>
      <Text>
        Roles determine the group of permissions that a user has within the
        organization.
      </Text>
      <Stack width={'full'} spacing={4}>
        <VStack alignItems="flex-start">
          <HStack justifyContent="space-between" w="full">
            <Heading as="h3">All Roles</Heading>
            <Button
              isDisabled={false}
              onClick={onOpen}
              rounded="md"
              alignContent={'right'}
              leftIcon={<Icon as={PlusIcon} boxSize={5} />}
              variant={'ghost'}
              pt="5"
              pb="5"
            >
              Add New Role
            </Button>
          </HStack>
          <VStack width={'full'} spacing={2} alignItems="flex-start">
            {orgRoles
              .filter(role => role.scope !== 'Model')
              .map(role => {
                const roleUIProps = getRoleUIProps(role.name);
                return (
                  <HStack
                    width={'full'}
                    key={role.cuid}
                    padding={6}
                    alignItems="flex-start"
                    border={'1px solid'}
                    borderColor={'var(--chakra-colors-chakra-border-color)'}
                    borderRadius={8}
                    cursor="pointer"
                    _hover={{ bg: bg }}
                    onClick={() => {
                      navigate(`/settings/roles/${role.cuid}`);
                    }}
                    gap={4}
                    transition={'all 0.3s ease-in-out'}
                  >
                    <Box
                      display={'flex'}
                      rounded={'md'}
                      w={16}
                      h={16}
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor={`${roleUIProps.colorScheme}.400`}
                      bg={
                        colorMode === 'light'
                          ? roleUIProps.colorScheme + '.200'
                          : roleUIProps.colorScheme + '.900'
                      }
                    >
                      <Icon as={roleUIProps.icon} boxSize={6} />
                    </Box>
                    <VStack
                      display={'flex'}
                      flex={1}
                      alignItems="flex-start"
                      gap={0}
                    >
                      <HStack
                        spacing={2}
                        display={'flex'}
                        flex={1}
                        alignItems="flex-start"
                      >
                        <Heading as="h4">{role.name}</Heading>
                        {(role.is_admin || role.is_staff) && (
                          <Tag>
                            <Icon
                              as={LockClosedIcon}
                              boxSize={3}
                              marginRight={1}
                            />
                            <TagLabel textTransform="uppercase" fontSize={12}>
                              Locked
                            </TagLabel>
                          </Tag>
                        )}
                      </HStack>
                      <Text>{role.description}</Text>
                    </VStack>
                    <Box h="full" display="flex" alignItems="center">
                      <Icon
                        as={ChevronRightIcon}
                        alignSelf="self-end"
                        color={useColorModeValue('neutral.500', 'neutral.400')}
                        _groupHover={{
                          color: useColorModeValue(
                            'neutral.600',
                            'neutral.300',
                          ),
                        }}
                        boxSize={5}
                        strokeWidth={2}
                      />
                    </Box>
                  </HStack>
                );
              })}
          </VStack>
        </VStack>
        <AddRoleModal
          isOpen={isOpen}
          onSuccess={onSuccessNewRole}
          onClose={onClose}
          roleScope="General"
        />
      </Stack>
    </VStack>
  );
}
